import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { type Observable } from 'rxjs';

import { AttachmentStatus, type Attachment } from '../../interfaces';
import { LoaderTheme } from '../../loader';
import { NotificationTheme } from '../../notifications';

@Component({
  selector: 'fcom-attachment-list',
  templateUrl: './attachment-list.component.html',
})
export class AttachmentListComponent {
  readonly AttachmentStatus = AttachmentStatus;
  readonly IconLibrary = IconLibrary;
  readonly LoaderTheme = LoaderTheme;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() attachments$: Observable<Attachment[]>;
  @Output() fileDeleted: EventEmitter<Attachment> = new EventEmitter<Attachment>();

  deleteAttachment(attachment: Attachment): void {
    this.fileDeleted.emit(attachment);
  }
}

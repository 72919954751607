import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { UiTranslateModule } from '@fcom/ui-translate';
import { UiComponentsModule } from '@fcom/ui-components';
import { DirectiveModule } from '@fcom/common/directives';
import { ComponentsModule } from '@fcom/common/components';
import { PipesModule } from '@fcom/common/pipes';

import { DatepickerComponent } from './datepicker.component';
import { FormArrayComponent } from './form-array.component';
import { FormErrorComponent } from './form-error.component';
import { FormInputCheckboxComponent } from './form-input-checkbox.component';
import { FormInputFileComponent } from './form-input-file.component';
import { FormInputGroupComponent } from './form-input-group.component';
import { FormInputNumberComponent } from './form-input-number.component';
import { FormInputRadioComponent } from './form-input-radio.component';
import { FormInputSelectComponent } from './form-input-select.component';
import { FormInputTextComponent } from './form-input-text.component';
import { LocationsAutocompleteComponent } from './locations-autocomplete.component';
import { FormInputCheckboxListComponent } from './form-input-checkbox-list.component';
import { FileValueAccessorDirective } from '../../directives/file-value-accessor.directive';

const components = [
  DatepickerComponent,
  FormArrayComponent,
  FormErrorComponent,
  FormInputCheckboxComponent,
  FormInputCheckboxListComponent,
  FormInputFileComponent,
  FormInputGroupComponent,
  FormInputNumberComponent,
  FormInputRadioComponent,
  FormInputSelectComponent,
  FormInputTextComponent,
  LocationsAutocompleteComponent,
  FileValueAccessorDirective,
];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectiveModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    MatAutocompleteModule,
    UiComponentsModule,
    UiTranslateModule,
    PipesModule,
    MatDatepickerModule,
  ],
  declarations: [...components],
  exports: [...components],
  providers: [],
})
export class CommonServiceFormsModule {}

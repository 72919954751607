import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';
import { DirectiveModule } from '@fcom/common/directives/directive.module';

import { IconsModule } from '../icons/icons.module';
import { ButtonModule } from '../buttons/button.module';
import { NotificationComponent } from './notification/notification.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { ErrorBlockComponent } from './error-block/error-block.component';
import { LoaderModule } from '../loader';

@NgModule({
  declarations: [NotificationComponent, InfoBoxComponent, ErrorBlockComponent],
  imports: [CommonModule, IconsModule, ButtonModule, LoaderModule, UiTranslateModule, DirectiveModule],
  exports: [NotificationComponent, InfoBoxComponent, ErrorBlockComponent],
  providers: [],
})
export class NotificationsModule {}

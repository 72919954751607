import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { map, Observable } from 'rxjs';

import { TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { TagRoundingStyle, TagTheme } from '@fcom/ui-components';
import { ConfigService } from '@fcom/core/index';

import { shouldDisplayBenefit } from '../../../utils/benefit.utils';
import { ExtendedFareFamily, FareFamilyBadgeType } from '../../../interfaces';

@Component({
  selector: 'fin-fare-family-list-option',
  styleUrls: ['./fare-family-list-option.component.scss'],
  templateUrl: './fare-family-list-option.component.html',
})
export class FlightSelectionFareFamilyListOptionComponent implements OnInit {
  readonly FareFamilyBadgeType = FareFamilyBadgeType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagRoundingStyle = TagRoundingStyle;
  readonly TagTheme = TagTheme;

  @Input({ required: true }) fareFamily$: Observable<ExtendedFareFamily>;
  @Input({ required: true }) currencyCode: string;
  @Input({ required: true }) aria: {
    index: number;
    count: number;
  };

  @Output() selectFareFamily = new EventEmitter<boolean>();

  fareFamilyBenefit$: Observable<TranslatedBenefitRow[]>;
  enableLoyaltyAvios$: Observable<boolean>;

  constructor(public configService: ConfigService) {}

  ngOnInit(): void {
    this.enableLoyaltyAvios$ = this.fareFamily$.pipe(
      map((fareFamily) => {
        const isAward = Boolean(fareFamily.points);
        return this.configService.cfg.enableLoyaltyAvios.bookingFlowNotifications && !isAward;
      })
    );

    this.fareFamilyBenefit$ = this.fareFamily$.pipe(
      map((fareFamily) => {
        return fareFamily.benefits.translatedBenefitRows.filter((benefit) => shouldDisplayBenefit(benefit));
      })
    );
  }

  checkboxChange(event: Event): void {
    this.selectFareFamily.emit((event.target as HTMLInputElement).checked);
  }
}

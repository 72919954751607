import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { LanguageService } from '@fcom/ui-translate';
import { ButtonIcon, ButtonTheme, IconPosition, ResponsiveImage } from '@fcom/ui-components';

import { AspectRatioString, ElementActions, ElementTypes, GaContext } from '../../interfaces';
import { GtmService } from '../../gtm';
import { MediaQueryService } from '../../services';

@Component({
  selector: 'fin-promotion-banner',
  templateUrl: './promotion-banner.component.html',
  styleUrls: ['./promotion-banner.component.scss'],
})
export class PromotionBannerComponent implements OnInit {
  @Input()
  image$: Observable<ResponsiveImage>;

  @Input()
  aspectRatio$: Observable<AspectRatioString>;

  @Input()
  translationPrefix: string;

  @Input()
  url: string;

  @Input()
  imageFromFragment: string;

  @Input()
  buttonIcon: ButtonIcon;

  constructor(
    private gtmService: GtmService,
    private mediaQueryService: MediaQueryService,
    private languageService: LanguageService,
    private httpClient: HttpClient
  ) {}
  loadImageFromFragment = (fragment: string): Observable<ResponsiveImage> =>
    this.languageService.translate(fragment).pipe(
      take(1),
      map((translation) => translation?.picture?.jsonUrl),
      filter(Boolean),
      switchMap((jsonUrl: string) => this.httpClient.get<ResponsiveImage>(jsonUrl))
    );
  ngOnInit(): void {
    this.aspectRatio$ = this.mediaQueryService
      .isBreakpoint$('mobile')
      .pipe(map((isMobile: boolean) => (isMobile ? '1x1' : '16x9')));

    if (this.imageFromFragment) {
      this.image$ = this.loadImageFromFragment(this.imageFromFragment);
    }
  }

  trackPromoBanner(banner: string): void {
    this.gtmService.trackElement(
      `${banner}-promotion-banner`,
      GaContext.BOOKING_FLOW,
      ElementTypes.BANNER,
      banner,
      ElementActions.CLICK
    );
  }

  protected readonly IconLibrary = IconLibrary;
  protected readonly IconPosition = IconPosition;
  protected readonly SvgLibraryIcon = SvgLibraryIcon;
  protected readonly ButtonTheme = ButtonTheme;
}

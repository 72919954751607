<div class="control-bar ps-xxlarge-x" [class.expand-on-mobile]="expandOnMobile">
  <fcom-icon-button
    class="control absolute prev"
    [class.translate]="showTags"
    [disabled]="!(visibleMonthIndex > 0)"
    [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.ARROW_LEFT }"
    [theme]="IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
    [size]="IconButtonSize.MEDIUM"
    [aria]="{ label: uiLabels?.showPreviousMonth }"
    (clickBtn)="selectMonth(visibleMonthIndex - 1)"
  >
  </fcom-icon-button>

  <div
    *ngIf="showTags"
    #monthTagParent
    class="ms-large-x ps-xsmall-y"
    [ngClass]="expandOnMobile ? 'tags' : 'scrollable-tags'"
    data-testid="calendar-chips"
  >
    <div #monthTags>
      <fcom-tag-button
        #tag
        *ngFor="let month of months; let i = index"
        [class.ms-xsmall-l]="i !== 0"
        [theme]="isChipHighlighted(i) ? TagTheme.ACTIVE : TagTheme.DEFAULT"
        [text]="getMonthNameForChip(month)"
        (tagClicked)="selectMonth(i)"
      >
      </fcom-tag-button>
    </div>
  </div>

  <fcom-icon-button
    class="control absolute next"
    [class.translate]="showTags"
    [disabled]="!(visibleMonthIndex < months.length - (noOfMonthsVisible === 1 ? 1 : 2))"
    [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.ARROW_RIGHT }"
    [theme]="IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
    [size]="IconButtonSize.MEDIUM"
    [aria]="{ label: uiLabels?.showNextMonth }"
    (clickBtn)="selectMonth(visibleMonthIndex + 1)"
  >
  </fcom-icon-button>
</div>

<div class="monthsArea flex-1 white-bg fill ps-xsmall-b" tabindex="-1" [class.expand-on-mobile]="expandOnMobile">
  <div
    class="months flex nowrap"
    [style.transform]="'translateX(-' + visibleMonthIndex * (noOfMonthsVisible === 1 ? 100 : 50) + '%)'"
    #calendarMonths
  >
    <fcom-calendar-month
      *ngFor="let month of months"
      class="month fill"
      [class.two-month-view]="noOfMonthsVisible === 2"
      [value]="month"
      [dateLabels]="dateLabels"
      [uiLabels]="uiLabels"
      [dayTemplate]="dayTemplate"
      [identifier]="identifier"
    ></fcom-calendar-month>
  </div>
</div>

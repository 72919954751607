import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ElementTypes, PreFlightSearchParams } from '@fcom/common';
import { DataCloudService } from '@fcom/common/datacloud';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { GtmService } from '@fcom/common/gtm';
import { finShare } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';
import { ElementActions, SearchType } from '@fcom/common/interfaces/gtm.interface';

import { FlightSearchParams, WidgetTab } from '../interfaces';
import { activeTab } from '../store';

@Injectable()
export class BookingWidgetGtmService implements OnDestroy {
  private context$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  private searchType$: BehaviorSubject<SearchType> = new BehaviorSubject(undefined);
  private subscriptions: Subscription;

  constructor(
    private store$: Store<BookingAppState>,
    private gtmService: GtmService,
    private dataCloudService: DataCloudService
  ) {
    this.subscriptions = new Subscription();

    this.subscriptions.add(
      this.store$.pipe(activeTab(), finShare()).subscribe((widgetActiveTab: WidgetTab) => {
        if (widgetActiveTab === WidgetTab.AM) {
          this.context$.next('flight-search-aurinkomatkat');
          this.searchType$.next(undefined);
        } else {
          this.context$.next('flight-search');
          this.searchType$.next(widgetActiveTab === WidgetTab.FLIGHT ? SearchType.BOOKING_FLOW : SearchType.AWARD);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  trackElementEvent(id: string, state?: string, action?: ElementActions): void {
    this.gtmService.trackElement(
      id,
      this.context$.getValue(),
      ElementTypes.BOOKING_WIDGET,
      state,
      action,
      undefined,
      this.searchType$.getValue()
    );
  }

  pageViewEvent(path: string): void {
    this.gtmService.pageView(path);
  }

  preFlightSearch(flightSearchParams: FlightSearchParams): void {
    const preFlightSearchParams: PreFlightSearchParams = {
      flights: flightSearchParams.flights.map((f) => ({
        origin: f.origin.locationCode,
        destination: f.destination.locationCode,
        departureDate: f.departureDate,
      })),
      paxAmount: flightSearchParams.paxAmount,
      travelClass: flightSearchParams.travelClass,
      isAward: !!flightSearchParams.isAward,
    };
    this.gtmService.preFlightSearch(preFlightSearchParams);
    this.dataCloudService.preFlightSearch(preFlightSearchParams);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';
import { MediaQueryService } from '@fcom/common/services/media-query/media-query.service';
import { BrowserWindowRef } from '@fcom/core/providers';

import { IconsModule } from '../icons/icons.module';
import { ButtonModule } from '../buttons/button.module';
import { CalendarComponent } from './calendar/calendar.component';
import { DayComponent } from './day/day.component';
import { MonthComponent } from './month/month.component';
import { ModalModule } from '../modal';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { TagModule } from '../tag';
import { CalendarService } from './services/calendar.service';
import { NotificationsModule } from '../notifications';

@NgModule({
  declarations: [CalendarComponent, MonthComponent, DayComponent, DatepickerComponent],
  imports: [
    CommonModule,
    IconsModule,
    UiTranslateModule,
    ButtonModule,
    ModalModule,
    FormsModule,
    TagModule,
    NotificationsModule,
  ],
  exports: [DatepickerComponent, CalendarComponent],
  providers: [MediaQueryService, BrowserWindowRef, CalendarService],
})
export class DatepickerModule {}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Observable, distinctUntilChanged, map } from 'rxjs';

import { Schema, SchemaService } from '../../services';

@Component({
  selector: 'fin-schema',
  templateUrl: 'schema.component.html',
  styles: [],
})
export class SchemaComponent implements OnInit {
  htmls$: Observable<SafeHtml[]>;

  constructor(
    private sanitizer: DomSanitizer,
    private schemaService: SchemaService
  ) {}

  ngOnInit(): void {
    this.htmls$ = this.schemaService.schemas$.pipe(
      distinctUntilChanged(),
      map((schemas: Schema[]) => schemas.map((schema) => this.getSafeHTML(schema)))
    );
  }

  private getSafeHTML(schema: Schema): SafeHtml {
    const json = schema ? JSON.stringify(schema, null, 2).replace(/<\/script>/g, '<\\/script>') : ''; // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoginModule } from '../login';

@NgModule({
  imports: [CommonModule, LoginModule],
  declarations: [],
  exports: [],
})
export class DataCloudModule {}

import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ToasterMessage } from '@fcom/common/interfaces';
import { snapshot } from '@fcom/rx';
import { CustomServiceType, SeatCharacteristics } from '@fcom/dapi';
import {
  Category,
  FinnairBoundItem,
  FinnairCart,
  FinnairItineraryItemFlight,
  FinnairOrder,
  FinnairServiceRequestItem,
} from '@fcom/dapi/api/models';
import { serviceSelectionToUpdateRequest } from '@fcom/common-booking/modules/ancillaries/utils';
import { seatSelectionsForFlight, serviceSelectionsForFragment } from '@fcom/common-booking/store/selectors';
import { NotificationTheme } from '@fcom/ui-components';
import { LocalDate, capitalizeWord } from '@fcom/core/utils';
import { ItineraryModal } from '@fcom/common-booking/modules/ancillaries/interfaces';
import { BookingAppState, SelectionPhase } from '@fcom/common/interfaces/booking';
import { FlightSeatSelections } from '@fcom/common/interfaces/seat-map.interface';

import { isLightFareFamily, isSuperLightFareFamily } from './fare-family.utils';

export interface AncillaryToasterMessages {
  error: ToasterMessage;
}

const getRequests = (
  store$: Store<BookingAppState>,
  category: Category,
  fragmentIds: string[]
): FinnairServiceRequestItem[] => {
  return fragmentIds.map((fragmentId) => ({
    category,
    fragmentId,
    serviceSelection: serviceSelectionToUpdateRequest(
      snapshot(store$.pipe(serviceSelectionsForFragment(fragmentId, category)))
    ),
  }));
};

const getMessage = (category: Category, key: string, theme: ToasterMessage['theme']): ToasterMessage => ({
  id: `ANCILLARY_${category.toString()}_${key}`.toUpperCase(),
  contentKey: `ancillaries.toaster.${key}`,
  theme,
});

const getToasterMessages = (category: Category, errorKey: string): AncillaryToasterMessages => {
  return {
    error: getMessage(category, errorKey, NotificationTheme.ALERT),
  };
};

export const mapToSeatRequest = (
  flightId: string,
  selectionsForFlight: FlightSeatSelections
): FinnairServiceRequestItem[] => {
  return [
    {
      category: Category.SEAT,
      fragmentId: flightId,
      serviceSelection: selectionsForFlight
        ? Object.keys(selectionsForFlight).map((travelerId) => ({
            travelerId,
            variant: selectionsForFlight[travelerId].seatType || '',
            seatNumber: selectionsForFlight[travelerId].seatNumber,
            isExitSeat: selectionsForFlight[travelerId].characteristics?.includes(SeatCharacteristics.EXIT) ?? false,
            quantity: selectionsForFlight[travelerId].seatNumber ? 1 : 0,
          }))
        : [],
    },
  ];
};

export const containsUnpaidSeats = (order: FinnairOrder): boolean => {
  return !!order.services.unpaid?.find((s) => s.category === Category.SEAT);
};

export const getServiceData = (
  store$: Store<BookingAppState>,
  category: Category,
  bounds: FinnairBoundItem[],
  flights: FinnairItineraryItemFlight[]
): [FinnairServiceRequestItem[], AncillaryToasterMessages] => {
  switch (category) {
    case Category.CABIN_BAGGAGE:
    case Category.BAGGAGE:
    case Category.SPORT:
    case Category.PET: {
      const keySuffix = category === Category.BAGGAGE ? 'Bags' : capitalizeWord(category);
      return [
        getRequests(
          store$,
          category,
          bounds.map((b) => b.id)
        ),
        getToasterMessages(category, 'errorSaving' + keySuffix),
      ];
    }
    case Category.SEAT: {
      const requests: FinnairServiceRequestItem[] = flights.reduce((all, flight) => {
        return all.concat(mapToSeatRequest(flight.id, snapshot(store$.pipe(seatSelectionsForFlight(flight.id)))));
      }, []);

      return [requests, getToasterMessages(category, 'errorSavingSeats')];
    }
    case Category.LOUNGE:
    case Category.WIFI:
    case Category.MEAL: {
      const keySuffix = category === Category.MEAL ? 'Meals' : capitalizeWord(category);
      return [
        getRequests(
          store$,
          category,
          flights.map((b) => b.id)
        ),
        getToasterMessages(category, 'errorSaving' + keySuffix),
      ];
    }
    case Category.COVER: {
      return [
        getRequests(store$, category, [CustomServiceType.JOURNEY]),
        getToasterMessages(category, 'errorSavingTripCover'),
      ];
    }
    default:
      return [[], undefined];
  }
};

export const getBoundDetailsModalData = (
  cartOrOrder$: Observable<FinnairCart | FinnairOrder>,
  slideIndex$: Observable<number>
): ItineraryModal => {
  const bounds$ = cartOrOrder$.pipe(map((cart) => cart.bounds));

  return {
    open: true,
    data: {
      outbound$: bounds$.pipe(map((bounds) => bounds[0])),
      inbound$: bounds$.pipe(map((bounds) => (bounds.length > 1 ? bounds[1] : undefined))),
      locations$: cartOrOrder$.pipe(map((c) => c.locations)),
      selectionPhase$: slideIndex$.pipe(
        map((slideIndex) => (slideIndex === 0 ? SelectionPhase.OUTBOUND : SelectionPhase.INBOUND))
      ),
    },
  };
};

// This is temporary solution with hard coded price for ab-testing purposes.
// If test is successful we might try to push Amadeus to develop needed functionality for getting the price

const NORDICS = ['SE', 'DK', 'NO', 'EE', 'LV', 'LT'];

const NORTHERN_AMERICA = ['US', 'CA', 'MX'];

export const getBagPriceStartingFromInEuros = (
  originCountryCode: string,
  destinationCountryCode: string,
  fareFamilyCode: string,
  departureDate: LocalDate
): number => {
  const isSuperLight = isSuperLightFareFamily(fareFamilyCode);
  const isLight = isLightFareFamily(fareFamilyCode);

  const countries = [originCountryCode.toUpperCase(), destinationCountryCode.toUpperCase()];

  if (isLight) {
    if (NORTHERN_AMERICA.some((country) => countries.includes(country))) {
      return 65;
    }

    if (countries.includes('JP')) {
      return 70;
    }

    if (LocalDate.now().plusDays(6).gt(departureDate)) {
      return 85;
    }

    return 75;
  }

  if (isSuperLight) {
    if (countries.every((country) => country === 'FI') || NORDICS.some((country) => countries.includes(country))) {
      return 9;
    }
    return 14;
  }

  return undefined;
};

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { share, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '@fcom/core';
import { ConfigService } from '@fcom/core/services/config/config.service';
import {
  ServiceAvailability,
  ServiceAvailabilityPerFragment,
  ServiceCatalog,
  ServiceCatalogCategory,
  ServiceCatalogServices,
  ServiceCatalogTranslations,
} from '@fcom/dapi';
import { retryWithBackoff } from '@fcom/rx';
import { combine, isPresent, mapValues } from '@fcom/core/utils';
import {
  CallerType,
  Category,
  FinnairServiceCatalogV3,
  Medium,
  RestError,
  SeatmapSvgAndControlData,
} from '@fcom/dapi/api/models';
import { SeatmapService } from '@fcom/dapi/api/services/seatmap.service';
import { CmsContent, CmsContentType, CmsImageData, HtmlString, PageViewType } from '@fcom/core-api/interfaces';
import { language } from '@fcom/core/selectors/language.selector';

import { ServicesActions } from '../../../store/actions';
import { calculatePaxCountFromServiceCatalog, removeServicesWithMaxedOutQuotas } from '../utils';
import { CartOrOrder } from '../../../interfaces';

const asAvailability = <T extends ServiceCatalogServices>(services: T): ServiceAvailabilityPerFragment => {
  if (!isPresent(services)) {
    return {};
  }
  return Object.keys(services).reduce((availability: ServiceAvailabilityPerFragment, fragmentId: string) => {
    availability[fragmentId] = combine(services[fragmentId]).length > 0;
    return availability;
  }, {});
};

export const asServiceAvailability = (catalog: ServiceCatalog): ServiceAvailability => {
  const baseAvailability: ServiceAvailability = {
    [Category.CABIN_BAGGAGE]: {},
    [Category.BAGGAGE]: {},
    [Category.MEAL]: {},
    [Category.WIFI]: {},
    [Category.LOUNGE]: {},
    [Category.COVER]: {},
    [Category.SPORT]: {},
    [Category.PET]: {},
  };

  return mapValues(baseAvailability, (old, key: string) => {
    const category = catalog?.categories.find((c) => c.category.toString() === key) || ({} as ServiceCatalogCategory);

    return asAvailability(category?.services || {}) || old;
  });
};

const asErrorMessage = (error: RestError): string => {
  return `${error?.errorMessage}`.concat(
    error?.amadeusResponse ? `${error.amadeusResponse.messageTitle} (${error.amadeusResponse.messageCode})` : ''
  );
};

const asServiceCatalogTranslations = (translations: { [key: string]: unknown }): ServiceCatalogTranslations => {
  return {
    ...translations,
    title: (translations?.title as string) || '',
  };
};

const asCmsImageData = (medium: Medium): CmsImageData & CmsContent => {
  return medium
    ? {
        ...medium,
        customPlacement: null,
        subjectTaxonomyTags: [],
        contentId: medium.contentId ?? null,
        contentType: CmsContentType[medium.contentType as keyof typeof CmsContentType],
        teaserText: medium.teaserText as HtmlString,
        viewTypeName: PageViewType[medium.viewTypeName as keyof typeof PageViewType],
        alt: medium.alt ?? null,
        responsiveImageLinksData: {
          landscape_ratio16x9: {
            ...medium.responsiveImageLinksData?.landscape_ratio16x9,
          },
          landscape_ratio4x3: {
            ...medium.responsiveImageLinksData?.landscape_ratio4x3,
          },
          landscape_ratio21x9: {
            ...medium.responsiveImageLinksData?.landscape_ratio21x9,
          },
          portrait_ratio1x1: {
            ...medium.responsiveImageLinksData?.portrait_ratio1x1,
          },
        },
      }
    : undefined;
};

export const asServiceCatalog = (catalog: FinnairServiceCatalogV3): ServiceCatalog => {
  return {
    messages: catalog.messages?.map((error) => asErrorMessage(error)) ?? [],
    campaigns: catalog.campaigns ?? [],
    paxLevelQuota: catalog.paxLevelQuota,
    categories: catalog.categories.map((category) => ({
      ...category,
      translations: asServiceCatalogTranslations(category.translations),
      media: asCmsImageData(category.media),
      services: mapValues(category.services, (servicePerTraveler) =>
        mapValues(servicePerTraveler, (servicesForTraveler) =>
          servicesForTraveler.map((service) => ({
            ...service,
            translations: asServiceCatalogTranslations(service.translations),
            media: asCmsImageData(service.media),
            category: category.category,
          }))
        )
      ),
    })),
  };
};

@Injectable()
export class CommonBookingAncillaryService {
  static NUMBER_OF_RETRIES = 1;

  constructor(
    private seatmapService: SeatmapService,
    private configService: ConfigService,
    private store$: Store<AppState>
  ) {}

  setServiceCatalog(cartOrOrder: CartOrOrder): void {
    const responseCatalog: FinnairServiceCatalogV3 = cartOrOrder.serviceCatalog || {
      messages: [],
      categories: [],
      campaigns: [],
      paxLevelQuota: { combinedQuota: 0, variants: [] },
    };
    const serviceCatalog = asServiceCatalog(responseCatalog);
    const catalog = removeServicesWithMaxedOutQuotas(
      serviceCatalog,
      calculatePaxCountFromServiceCatalog(serviceCatalog)
    );
    this.store$.dispatch(ServicesActions.setServiceCatalog({ catalog }));
    this.store$.dispatch(
      ServicesActions.setServiceAvailability({ serviceAvailability: asServiceAvailability(catalog) })
    );
  }

  getCartSeatMapForFlight(
    flightId: string,
    departureDateTime: string,
    cartId: string,
    cartHash: string,
    currencyCode?: string
  ): Observable<SeatmapSvgAndControlData> {
    return this.store$.pipe(
      language(),
      take(1),
      switchMap((locale) => {
        return this.seatmapService
          .getSeatmapFromCart(this.configService.cfg.servicesUrl, {
            flightId,
            departureDateTime,
            cartId,
            hash: cartHash,
            version: 2,
            locale,
            Caller: CallerType.FINNAIRCOM,
            ...(currencyCode ? { currencyCode } : {}),
          })
          .pipe(retryWithBackoff(CommonBookingAncillaryService.NUMBER_OF_RETRIES), share());
      })
    );
  }

  getOrderSeatMapForFlight(
    flightId: string,
    departureDateTime: string,
    orderId: string,
    orderHash: string,
    currencyCode?: string
  ): Observable<SeatmapSvgAndControlData> {
    return this.store$.pipe(
      language(),
      take(1),
      switchMap((locale) => {
        return this.seatmapService
          .getSeatmapFromOrder(this.configService.cfg.servicesUrl, {
            flightId,
            departureDateTime,
            orderId,
            hash: orderHash,
            version: 2,
            locale,
            Caller: CallerType.FINNAIRCOM,
            ...(currencyCode ? { currencyCode } : {}),
          })
          .pipe(retryWithBackoff(CommonBookingAncillaryService.NUMBER_OF_RETRIES), share());
      })
    );
  }
}

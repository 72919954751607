<div class="grey-25-bg pr-xsmall-t" *ngIf="(availableCabinClasses$ | async).length" data-testid="cabin-class-tags">
  <div class="df-desktop mr-xsmall-b pr-xsmall rounded-large border white-bg" data-testid="tags-list">
    <div class="font-body-1 ps-small-r">{{ 'upsellCabinClasses.chipTitle' | finLocalization }}</div>
    <ul class="no-list-style flex flex--wrap flex--nogrow" #tagsList>
      <ng-container *ngFor="let item of availableCabinClasses$ | async">
        <li class="ms-xsmall-r ms-xsmall-b">
          <fcom-tag-button
            [text]="'travelClass.' + item.id | finLocalization | titlecase"
            (tagClicked)="openModal($event, item.id)"
            [ngClass]="item.id"
            [attr.data-testid]="'cabin-class-tag-' + item.id"
            [theme]="TagTheme.ACTIVE"
          ></fcom-tag-button>
        </li>
      </ng-container>
    </ul>
  </div>

  <fcom-modal
    class="test-modal"
    title="{{ 'upsellCabinClasses.modalTitle' | finLocalization }}"
    [buttons]="null"
    [open]="cabinClassModalOpen"
    (close)="closeModal()"
  >
    <ng-container *ngIf="cabinClassModalOpen">
      <div class="relative">
        <fcom-tabs
          [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
          [nextButtonAriaLabel]="'carousel.next' | finLocalization"
          [align]="'right'"
          [layoutType]="TabLayoutType.IN_MODAL"
          class="absolute rounded-top-medium"
        >
          <ng-container *ngFor="let item of availableCabinClasses$ | async">
            <fcom-tab
              [title]="'travelClass.' + item.id | finLocalization | titlecase"
              [active]="item.id === selectedCabinClassId"
              [attr.data-testid]="'cabin-class-' + item.id"
            >
              <div class="pr-large-x">
                <fin-cms-json-content [template$]="item.template"></fin-cms-json-content>
              </div>
            </fcom-tab>
          </ng-container>
        </fcom-tabs>
      </div>
    </ng-container>
  </fcom-modal>
</div>

<div class="flex flex-1 flex--middle flex--end">
  <input
    class="is-vishidden"
    type="checkbox"
    [checked]="checked"
    [tabIndex]="tabIndex"
    [attr.name]="name"
    [attr.value]="value"
    [attr.id]="cid"
    [attr.disabled]="disabled ? 'disabled' : undefined"
    (change)="interactionEvent($event)"
    (click)="clickEvent($event)"
    (blur)="blurEvent()"
    (focus)="focusEvent()"
  />

  <label
    [for]="cid"
    class="block-selection-label flex flex-1 grey-25-bg rounded-medium border overflow-hidden"
    [ngClass]="{ 'nordic-blue-900-border': checked }"
  >
    <div class="selection flex flex--center ps-xsmall-x">
      <div
        class="selection-icon-wrapper flex--nogrow flex flex--center circle border"
        [ngClass]="checked ? 'nordic-blue-900-bg white-border' : 'white-bg nordic-blue-900-border'"
      >
        <fcom-icon *ngIf="checked" class="selection-icon white-text icon-small" [name]="SvgLibraryIcon.CHECKMARK">
        </fcom-icon>
      </div>
    </div>

    <div class="payment-type flex flex-1 flex--space-between flex--center ps-xsmall-x ps-small-y ps-xxlarge-r">
      <ng-content></ng-content>
    </div>
  </label>
  <ng-content select="[tooltip]"></ng-content>
</div>

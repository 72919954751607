import { NotificationTheme } from '@fcom/ui-components';
import { ReplaceParams } from '@fcom/core/utils';

export enum NotificationWarning {
  NO_FLIGHTS = 'noFlights',
  NO_AWARD_FLIGHTS = 'noAwardFlights',
  SEASONAL_ROUTE = 'seasonalRoute',
  REGULATORY = 'regulatory',
  GENERAL = 'general',
}

export enum NotificationSpecific {
  DESTINATION_SPECIFIC = 'destinationSpecific',
  BUS_CONNECTION = 'busConnection',
  AM_EARN_POINTS = 'amEarnPoints',
  MULTICITY_NOT_CONSECUTIVE = 'multiCityNotConsecutive',
  MULTICITY_MAX_NUMBER_OF_FLIGHTS = 'multiCityMaxNumberOfFlights',
}

export type NotificationType = NotificationWarning | NotificationSpecific;

export interface NotificationData {
  type: NotificationType;
  theme: NotificationTheme;
  isCloseable?: boolean;
  text: {
    label: string;
    params?: ReplaceParams;
  };
  text1?: {
    label: string;
    params?: ReplaceParams;
  };
  link?: {
    text: string;
    url: string;
    external?: boolean;
    openInNewTab?: boolean;
  };
}

export interface SeasonalNotificationData {
  start?: string;
  end?: string;
  start2?: string;
  end2?: string;
}

import { Injectable, OnDestroy, signal } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
  iif,
  throwError,
} from 'rxjs';

import { finShare, retryWithBackoff } from '@fcom/rx';
import { Location, LocationRouteCffData, LocationSuggestions, LocationGeoLocData } from '@fcom/core-api';
import {
  LatLng,
  LocalDate,
  LocationRouteCffService,
  StorageService,
  equals,
  isNotBlank,
  isPresent,
  unsubscribe,
  isEmptyObjectOrHasEmptyValues,
} from '@fcom/core';
import { GlobalBookingTripDates, RecommendationService } from '@fcom/common';
import {
  CommonFeatureState,
  GlobalBookingActions,
  GlobalBookingFlight,
  LocationPair,
  globalBookingFlights,
  globalBookingDiscountCode,
  globalBookingLocations,
  globalBookingPaxAmount,
  globalBookingTravelClass,
  globalBookingTripType,
  GlobalBookingState,
  globalBookingSelections,
  TravelClassAvailabilityMap,
  globalBookingAvailableTravelClasses,
  globalBookingTravelDates,
} from '@fcom/common/store';
import { FINNISH_SITES, TripType } from '@fcom/core/constants';
import { GlobalBookingTravelClass } from '@fcom/core/interfaces';
import { LoginStatus, Profile } from '@fcom/core-api/login';
import { Amount, PaxAmount } from '@fcom/dapi/interfaces';
import { AirCalendarList } from '@fcom/dapi/api/models';
import { LanguageService } from '@fcom/ui-translate';
import { loginStatus, profile, akamaiGeolocation } from '@fcom/core/selectors';
import { mapCffsToTravelClasses } from '@fcom/common/utils/booking.utils';
import { LocationsService } from '@fcom/locations/services/locations.service';

import { getGtmPassengers } from '../utils/utils.gtm';
import {
  createPreviousSearches,
  getStartingFromPrice,
  createDeeplinkPathFromFlightSearchParams,
  hasCorrectAmountOfFlights,
} from '../utils/utils';
import {
  BookingWidgetActions,
  BookingWidgetAppState,
  activeTab,
  airCalendarPrices,
  followingMonthPrices,
  fullYearPrices,
  histogramPrices,
  notificationWarnings,
} from '../store';
import {
  AmountUpdateType,
  DatePickerPrices,
  InstantSearchBaseParams,
  PaxUpdateEvent,
  PreviousSearch,
  WidgetTab,
  FlightSearchParams,
  PriceParams,
  Warnings,
  NotificationWarning,
  SelectionType,
  LocationType,
  GlobalBookingWidgetSelectionChangeMap,
} from '../interfaces';
import { AVAILABLE_TRIP_TYPES, PREVIOUS_SEARCHES_KEY } from '../constants';
import { BookingWidgetGtmService } from './booking-widget-gtm.service';
import { BookingWidgetCalendarService } from './booking-widget-calendar.service';

@Injectable({ providedIn: 'root' })
export class BookingWidgetService implements OnDestroy {
  private _profile$: Observable<Profile>;
  private _notificationWarning$: Observable<Warnings>;
  private _loginStatus$: Observable<LoginStatus>;
  private _activeTab$: Observable<WidgetTab>;
  private _selectedTripType$: Observable<TripType>;
  private _availableTripTypes$: Observable<TripType[]>;
  private _selectedTravelClass$: Observable<GlobalBookingTravelClass>;
  private _availableTravelClasses$: Observable<GlobalBookingTravelClass[]>;
  private _paxAmount$: Observable<PaxAmount>;
  private _locations$: Observable<LocationPair>;
  private _travelDates$: Observable<GlobalBookingTripDates>;
  private _continueEnabled$: Observable<boolean>;
  private _discountCode$: Observable<string>;
  private _flightSearchParams$: Observable<FlightSearchParams>;
  private _startingPrice$: Observable<Amount> = of(null);
  private _prices$: Observable<DatePickerPrices>;
  private _airCalendarPrices$: Observable<AirCalendarList>;
  private _priceRequestBaseParams$: Observable<InstantSearchBaseParams>;
  private _flexibleDates$ = new BehaviorSubject<boolean>(false);
  private _suggestedLocationsMap$ = new BehaviorSubject<Record<string, LocationSuggestions>>({});
  private _trendingDestinationsMap$ = new BehaviorSubject<Record<string, Location[]>>({});
  private _flights$: Observable<GlobalBookingFlight[]>;
  private _subscription = new Subscription();
  private _setPaxDetailsGtm$: Subject<boolean> = new Subject<boolean>();
  private _showCompact$ = new BehaviorSubject<boolean>(true);
  private _defaultLocations$ = new BehaviorSubject<LocationPair[]>([]);
  private _originalBookingSelection$ = new BehaviorSubject<GlobalBookingState>(undefined);
  private _globalBookingWidgetSelectionChanges$ = new BehaviorSubject<GlobalBookingWidgetSelectionChangeMap>({});
  private _isMultiCity$: Observable<boolean>;
  private _selectedPreviousSearches$ = new BehaviorSubject<PreviousSearch>(undefined);

  private usePopoverSelectorsSignal = signal(false);

  readonly usePopoverSelectors = this.usePopoverSelectorsSignal.asReadonly();

  get notificationWarning$(): Observable<Warnings> {
    return this._notificationWarning$;
  }

  get profile$(): Observable<Profile> {
    return this._profile$;
  }

  get loginStatus$(): Observable<LoginStatus> {
    return this._loginStatus$;
  }

  get activeTab$(): Observable<WidgetTab> {
    return this._activeTab$;
  }

  get tripType$(): Observable<TripType> {
    return this._selectedTripType$;
  }

  get availableTripTypes$(): Observable<TripType[]> {
    return this._availableTripTypes$;
  }

  get travelClass$(): Observable<GlobalBookingTravelClass> {
    return this._selectedTravelClass$;
  }

  get availableTravelClasses$(): Observable<GlobalBookingTravelClass[]> {
    return this._availableTravelClasses$;
  }

  get paxAmount$(): Observable<PaxAmount> {
    return this._paxAmount$;
  }

  get locations$(): Observable<LocationPair> {
    return this._locations$;
  }

  get flights$(): Observable<GlobalBookingFlight[]> {
    return this._flights$;
  }

  get travelDates$(): Observable<GlobalBookingTripDates> {
    return this._travelDates$;
  }

  get discountCode$(): Observable<string> {
    return this._discountCode$;
  }

  get flexibleDates$(): Observable<boolean> {
    return this._flexibleDates$.asObservable();
  }

  get continueEnabled$(): Observable<boolean> {
    return this._continueEnabled$;
  }

  get startingPrice$(): Observable<Amount> {
    return this._startingPrice$;
  }

  get suggestedLocations$(): Observable<LocationSuggestions> {
    return combineLatest([this.languageService.countryCode, this._suggestedLocationsMap$]).pipe(
      map(([countryCode, suggestedLocationsMap]) => suggestedLocationsMap[countryCode]),
      finShare()
    );
  }

  get trendingDestinations$(): Observable<Location[]> {
    return combineLatest([this.locations$, this._trendingDestinationsMap$]).pipe(
      map(([locations, trendingDestinationsMap]) => trendingDestinationsMap[locations?.origin?.locationCode]),
      finShare()
    );
  }

  get airCalendarPrices$(): Observable<AirCalendarList> {
    return this._airCalendarPrices$;
  }

  get prices$(): Observable<DatePickerPrices> {
    return this._prices$;
  }

  get showCompact$(): Observable<boolean> {
    return this._showCompact$.asObservable();
  }

  get isMultiCity$(): Observable<boolean> {
    return this._isMultiCity$;
  }

  get originalBookingSelection$(): Observable<GlobalBookingState> {
    return this._originalBookingSelection$.asObservable();
  }

  get globalBookingWidgetSelectionChanges$(): Observable<GlobalBookingWidgetSelectionChangeMap> {
    return this._globalBookingWidgetSelectionChanges$.asObservable();
  }

  constructor(
    private store$: Store<CommonFeatureState & BookingWidgetAppState>,
    private languageService: LanguageService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private locationRouteCffService: LocationRouteCffService,
    private recommendationService: RecommendationService,
    private storageService: StorageService,
    private bookingWidgetCalendarService: BookingWidgetCalendarService,
    private locationService: LocationsService,
    private router: Router
  ) {
    this._profile$ = this.store$.pipe(profile(), startWith(undefined), finShare());
    this._loginStatus$ = this.store$.pipe(loginStatus(), finShare());

    this._activeTab$ = this.store$.pipe(
      activeTab(),
      map((tab: WidgetTab) => tab ?? WidgetTab.FLIGHT),
      distinctUntilChanged(),
      finShare()
    );

    this._selectedTripType$ = this.store$.pipe(
      globalBookingTripType(),
      map((tripType: TripType) => tripType ?? TripType.RETURN),
      finShare()
    );

    this._availableTripTypes$ = this._activeTab$.pipe(
      map((tab: WidgetTab) =>
        tab === WidgetTab.AWARD ? AVAILABLE_TRIP_TYPES.filter((t) => t !== TripType.MULTICITY) : AVAILABLE_TRIP_TYPES
      ),
      finShare()
    );

    this._availableTravelClasses$ = this.store$.pipe(globalBookingAvailableTravelClasses(), finShare());

    this._selectedTravelClass$ = this.store$.pipe(globalBookingTravelClass(), distinctUntilChanged(), finShare());

    this._flights$ = this.store$.pipe(
      globalBookingFlights(),
      distinctUntilChanged((prev, next) => equals(prev, next)),
      finShare()
    );

    this._paxAmount$ = this.store$.pipe(globalBookingPaxAmount(), finShare());

    this._notificationWarning$ = this.store$.pipe(notificationWarnings(), finShare());

    this._travelDates$ = this.store$.pipe(
      globalBookingTravelDates(),
      distinctUntilChanged(
        (prev, next) => prev.departureDate?.id === next.departureDate?.id && prev.returnDate?.id === next.returnDate?.id
      ),
      finShare()
    );

    const bookingLocations$ = this.store$.pipe(
      globalBookingLocations(),
      distinctUntilChanged((prev, next) => prev.length === next.length && next.every((l, i) => equals(l, prev[i]))),
      finShare()
    );

    this._locations$ = bookingLocations$.pipe(
      map((locations) => locations[0]),
      distinctUntilChanged((prev, next) => equals(prev, next)),
      finShare()
    );

    this._discountCode$ = this.store$.pipe(
      globalBookingDiscountCode(),
      map((discountCode: string) => (isPresent(discountCode) ? discountCode : null)),
      finShare()
    );

    this._airCalendarPrices$ = this.store$.pipe(airCalendarPrices(), finShare());

    this._flightSearchParams$ = combineLatest([
      this._selectedTripType$,
      this._selectedTravelClass$,
      this._flights$,
      this._paxAmount$,
      this._activeTab$,
      this._discountCode$,
      this._profile$,
    ]).pipe(
      map(([tripType, travelClass, flights, paxAmount, tab, discountCode, userProfile]) => ({
        tripType,
        travelClass,
        flights,
        paxAmount,
        locale: this.languageService.localeValue,
        isAward: tab === WidgetTab.AWARD,
        promoCode: discountCode,
        isCorporate: userProfile?.isCorporate,
      })),
      finShare()
    );

    this._priceRequestBaseParams$ = combineLatest([
      this._selectedTripType$,
      this._selectedTravelClass$,
      this._locations$.pipe(
        filter((locations) => !!(locations.origin && locations.destination)),
        distinctUntilChanged(
          (previous, current) =>
            previous?.origin?.locationCode === current?.origin?.locationCode &&
            previous?.destination?.locationCode === current?.destination?.locationCode
        )
      ),
      this._activeTab$,
    ]).pipe(
      tap(() => {
        this.store$.dispatch(BookingWidgetActions.clearNotificationWarning({ key: NotificationWarning.NO_FLIGHTS }));
        this.store$.dispatch(
          BookingWidgetActions.clearNotificationWarning({ key: NotificationWarning.NO_AWARD_FLIGHTS })
        );
      }),
      filter(([, , , tab]) => tab === WidgetTab.FLIGHT || tab === WidgetTab.AWARD),
      withLatestFrom(this._paxAmount$),
      map(([[tripType, travelClass, locations], paxAmount]) => ({
        tripType,
        travelClass,
        locations,
        paxAmount,
      })),
      filter((params) => this.priceParamsAreValid(params) && params.tripType !== TripType.MULTICITY),
      map((params) => this.mapToPriceRequestBaseParams(params)),
      distinctUntilChanged((prev, next) => !this.priceBaseParamsChanged(prev, next)),
      tap(() => {
        this.store$.dispatch(
          BookingWidgetActions.clearNotificationWarning({ key: NotificationWarning.SEASONAL_ROUTE })
        );
      }),
      finShare()
    );

    this._continueEnabled$ = this._flightSearchParams$.pipe(
      map((flightSearchParams) => this.validateFlightSearchParams(flightSearchParams))
    );

    this._prices$ = combineLatest([
      this.store$.pipe(fullYearPrices()),
      this.store$.pipe(followingMonthPrices()),
      this.store$.pipe(histogramPrices()),
      this._selectedTripType$,
      this._travelDates$,
      this._activeTab$,
    ]).pipe(
      map(([fullYear, followingMonth, histogram, tripType, { departureDate, returnDate }, tab]) => {
        if (tab !== WidgetTab.FLIGHT) {
          return undefined;
        }

        const onlyDepartureSelectedAndNotOneway =
          isPresent(departureDate) && tripType !== TripType.ONEWAY && !isPresent(returnDate);
        const prices = onlyDepartureSelectedAndNotOneway ? followingMonth : fullYear;

        const pricesWithSelections =
          departureDate && returnDate ? { ...prices, [returnDate?.id]: followingMonth?.[returnDate?.id] } : prices;

        return {
          calendar: pricesWithSelections,
          histogram,
        };
      }),
      finShare()
    );

    this._startingPrice$ = combineLatest([this._prices$, this._travelDates$, this._selectedTripType$]).pipe(
      map(([prices, travelDates, tripType]) => getStartingFromPrice(prices, travelDates, tripType === TripType.ONEWAY))
    );

    this._isMultiCity$ = this._flightSearchParams$.pipe(map((params) => params.flights.length > 2));

    this._subscription.add(
      this.languageService.countryCode
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(this._suggestedLocationsMap$),
          filter(([countryCode, suggestedLocationsMap]) => !suggestedLocationsMap[countryCode]),
          switchMap(([countryCode, suggestedLocationsMap]) =>
            this.locationRouteCffService.getSuggestedLocations(countryCode, this.languageService.localeValue).pipe(
              map((suggestedLocationByCountryCode) => ({
                ...suggestedLocationsMap,
                [countryCode]: suggestedLocationByCountryCode,
              }))
            )
          )
        )
        .subscribe((suggestedLocations) => {
          this._suggestedLocationsMap$.next(suggestedLocations);
        })
    );

    this._subscription.add(
      this._locations$
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(this._trendingDestinationsMap$),
          filter(
            ([locations, trendingDestinationsMap]) =>
              locations?.origin?.locationCode && !trendingDestinationsMap[locations?.origin?.locationCode]
          ),
          switchMap(([locations, trendingDestinationsMap]) =>
            this.recommendationService.getTrendingDestinations(locations?.origin?.locationCode, 0, 10).pipe(
              map((trendingDestinations) => ({
                ...trendingDestinationsMap,
                [locations?.origin?.locationCode]: trendingDestinations,
              }))
            )
          )
        )
        .subscribe((trendingDestinations) => {
          this._trendingDestinationsMap$.next(trendingDestinations);
        })
    );

    this._subscription.add(
      this._flightSearchParams$
        .pipe(
          filter((flightSearchParams) => this.validateFlightSearchParams(flightSearchParams)),
          distinctUntilChanged((prev, next) => equals(prev, next))
        )
        .subscribe((flightSearchParams) => this.bookingWidgetGtmService.preFlightSearch(flightSearchParams))
    );

    // subscription
    this.getAvailableTravelClasses(bookingLocations$);
    this.setPaxDetailsGtm();
    this.setTripTypeBasedOnTab();
    this.fetchPriceBasedOnSelection();
    this.fetchFollowingMonthPrice();
    this.checkLocationAvailability();
    this.fetchUserLocationAndSetDefaultOrigin(this._defaultLocations$);
    this.initDefaultLocations();
  }

  ngOnDestroy(): void {
    unsubscribe(this._subscription);
    this.resetSelection();
  }

  setActiveTab(tab: WidgetTab): void {
    this.store$.dispatch(BookingWidgetActions.setActiveTab({ activeTab: tab }));
  }

  setTripType(tripType: TripType, isGlobalBookingWidget = false): void {
    if (isGlobalBookingWidget) {
      this.setSelectionChangeType(SelectionType.TRIP_TYPE);
      this.setSelectionChangeType(SelectionType.TRAVEL_DATES);
    }
    this.bookingWidgetGtmService.trackElementEvent('travel-type', tripType.toUpperCase());
    this.store$.dispatch(GlobalBookingActions.setTripType({ tripType }));

    if (tripType === TripType.MULTICITY) {
      // we need to reset discount code when going to multicity tab because its not in use
      this.store$.dispatch(GlobalBookingActions.setDiscountCode(null));
    }
  }

  setTravelClass(travelClass: GlobalBookingTravelClass): void {
    this.bookingWidgetGtmService.trackElementEvent('travel-class', travelClass);
    this.store$.dispatch(GlobalBookingActions.setTravelClass({ travelClass }));
  }

  setPreviousFlightSelection(previousSearch: PreviousSearch, isGlobalBookingWidget: boolean): void {
    if (isGlobalBookingWidget) {
      this._selectedPreviousSearches$.next(previousSearch);
    }
    this.store$.dispatch(GlobalBookingActions.setSelection({ selection: previousSearch }));
  }

  setPaxAmount({ paxType, amount, updateType }: PaxUpdateEvent, isGlobalBookingWidget = false): void {
    if (isGlobalBookingWidget) {
      this.setSelectionChangeType(SelectionType.PAX);
    }
    if (updateType === AmountUpdateType.DECREMENT) {
      this.store$.dispatch(GlobalBookingActions.decreasePaxAmountField({ field: paxType, decrement: amount }));
    } else {
      this.store$.dispatch(GlobalBookingActions.increasePaxAmountField({ field: paxType, increment: amount }));
    }
    this._setPaxDetailsGtm$.next(true);
  }

  updateFlight(flight: GlobalBookingFlight, index: number): void {
    this.store$.dispatch(GlobalBookingActions.updateFlight({ flight, index }));
  }

  setPreviousSearchChange(): void {
    this._subscription.add(
      combineLatest([this._selectedPreviousSearches$, this.originalBookingSelection$])
        .pipe(
          distinctUntilChanged(
            ([preSearch, preOriginal], [nextSearch, nextOrignal]) =>
              equals(preSearch, nextSearch) && equals(preOriginal, nextOrignal)
          ),
          filter(([previousSearch, originalSelection]) => isPresent(previousSearch) && isPresent(originalSelection))
        )
        .subscribe(([previousSearches, originalBookingSelection]) => {
          this.previousSearchChanged(previousSearches, originalBookingSelection).forEach((changedType) => {
            this.setSelectionChangeType(changedType);
          });
        })
    );
  }
  setTravelDates(
    dates: GlobalBookingTripDates,
    index: number,
    isAirCalendar = false,
    isGlobalBookingWidget = false
  ): void {
    if (isGlobalBookingWidget) {
      this.setSelectionChangeType(SelectionType.TRAVEL_DATES);
    }

    if (!isAirCalendar) {
      // TODO: check if we would like a separate and different multi-city event as this does not really translate for that purpose
      this.bookingWidgetGtmService.trackElementEvent(
        'travel-dates',
        `DEPARTURE: ${dates.departureDate}, RETURN: ${dates.returnDate}`
      );
    }

    this.store$.dispatch(GlobalBookingActions.setFlightDate({ dates, index }));
  }

  setDiscountCode(discountCode: string, isGlobalBookingWidget = false): void {
    if (isGlobalBookingWidget) {
      this.setSelectionChangeType(SelectionType.DISCOUNT_CODE);
    }
    this.store$.dispatch(GlobalBookingActions.setDiscountCode({ discountCode }));
    if (discountCode) {
      this.bookingWidgetGtmService.trackElementEvent('promo-code-modal-done', discountCode);
    }
  }

  setFlexibleDates(isFlexibleDates: boolean): void {
    this._flexibleDates$.next(isFlexibleDates);
  }

  setLocations(
    { origin, destination }: LocationPair,
    index = 0,
    isGlobalBookingWidget = false,
    locationType: LocationType[] = []
  ): void {
    if (isGlobalBookingWidget) {
      locationType.forEach((type) => {
        this.setSelectionChangeType(type as unknown as SelectionType);
      });
    }
    this.bookingWidgetGtmService.trackElementEvent(
      'locations',
      `${origin?.locationCode} - ${destination?.locationCode}`
    );

    this.store$.dispatch(GlobalBookingActions.updateFlight({ flight: { origin, destination }, index }));
  }

  setPreviousSearch = (flightSearchParams: FlightSearchParams): void => {
    if (flightSearchParams.isAward) {
      return;
    }

    let previousSearches: PreviousSearch[];
    const previousSearchesStorage = this.storageService.LOCAL.get(PREVIOUS_SEARCHES_KEY);

    try {
      previousSearches = JSON.parse(previousSearchesStorage) ?? [];
    } catch {
      previousSearches = [];
    }

    const updatedPreviousSearches = createPreviousSearches(flightSearchParams, previousSearches);
    this.storageService.LOCAL.set(PREVIOUS_SEARCHES_KEY, JSON.stringify(updatedPreviousSearches));
  };

  isAMTabEnabled(enableAM: boolean): Observable<boolean> {
    return of(enableAM && FINNISH_SITES.includes(this.languageService.langValue));
  }

  validateFlightSearchParams({ flights, tripType, travelClass, paxAmount }: FlightSearchParams): boolean {
    const travelClassIsValid = isPresent(travelClass);
    const paxAmountIsValid = paxAmount.adults >= 1;
    const flightsAreValid = flights.every((flight, i) => {
      const previousFlight = flights[i - 1];
      const hasConsecutiveDates = !previousFlight || flight.departureDate?.gte(previousFlight.departureDate);
      return flight.origin && flight.destination && flight.departureDate && hasConsecutiveDates;
    });

    return travelClassIsValid && paxAmountIsValid && flightsAreValid && hasCorrectAmountOfFlights(tripType, flights);
  }

  setReturnTripType(isGlobalBookingWidget = false): void {
    if (isGlobalBookingWidget) {
      this.setSelectionChangeType(SelectionType.TRIP_TYPE);
    }
    this.setTripType(TripType.RETURN);
    this.bookingWidgetGtmService.trackElementEvent('add-return-button');
  }

  resetSelection(): void {
    this.store$.dispatch(GlobalBookingActions.resetSelection());
  }

  expandCompactWidget(): void {
    if (this._showCompact$.getValue()) {
      this._showCompact$.next(false);
    }
  }

  setCompactWidget(showCompactWigetStatus: boolean): void {
    this._showCompact$.next(showCompactWigetStatus);
  }

  setDefaultLocations(defaultLocations$: Observable<LocationPair[]>): void {
    this._subscription.add(
      defaultLocations$.subscribe((defaultLocations) => {
        this._defaultLocations$.next(defaultLocations);
      })
    );
  }

  startNewSearch(): void {
    this.bookingWidgetGtmService.trackElementEvent('global-widget-start-new-search');
    this.resetLocalOriginalBookingSelection();
    //reset shadow
    this._subscription.add(
      this._flightSearchParams$.pipe(take(1)).subscribe((flightSearchParams) => {
        this.setPreviousSearch(flightSearchParams);
        this.router.navigateByUrl(
          createDeeplinkPathFromFlightSearchParams({
            ...flightSearchParams,
            locale: this.languageService.langValue,
          })
        );
      })
    );
  }

  navigateToBookingFlow(
    fromMatrix = false,
    airCalendarPrice: string | undefined = undefined,
    loading$: Subject<boolean>
  ): void {
    this._subscription.add(
      this._continueEnabled$
        .pipe(
          take(1),
          filter(Boolean),
          withLatestFrom(this._flightSearchParams$, this._flexibleDates$, this._startingPrice$),
          switchMap(([_, flightSearchParams, flexibleDates, startingPrice]) => {
            loading$.next(true);
            this.sendRecommendationsFlightSearchEvent(flightSearchParams);

            if (fromMatrix) {
              return of({ continueToBooking: true, flightSearchParams, airCalendarPrice, startingPrice });
            } else {
              return iif(
                () => flexibleDates || flightSearchParams.isAward,
                this.bookingWidgetCalendarService.getAirCalendar(flightSearchParams).pipe(map(() => false)),
                this.bookingWidgetCalendarService.checkFlightAvailabilityAndContinue(flightSearchParams)
              ).pipe(
                map((continueToBooking) => ({
                  continueToBooking,
                  flightSearchParams,
                  startingPrice,
                  airCalendarPrice,
                }))
              );
            }
          }),
          tap(() => loading$.next(false)),
          filter(({ continueToBooking }) => continueToBooking),
          map(({ flightSearchParams, startingPrice, airCalendarPrice }) => {
            this.setPreviousSearch(flightSearchParams);
            return {
              deeplink: createDeeplinkPathFromFlightSearchParams({
                ...flightSearchParams,
                locale: this.languageService.langValue,
              }),
              instantSearchMonitoring: {
                airCalendarPrice,
                instantSearchPrice: startingPrice?.amount,
              },
            };
          })
        )
        .subscribe(({ deeplink, instantSearchMonitoring }) => {
          this.store$.dispatch(BookingWidgetActions.setInstantSearchMonitoring({ instantSearchMonitoring }));
          this.router.navigateByUrl(deeplink);
        })
    );
  }

  navigateToMultiCityBookingFlow(loading$: Subject<boolean>): void {
    this._subscription.add(
      this.continueEnabled$
        .pipe(
          take(1),
          filter(Boolean),
          withLatestFrom(this._flightSearchParams$),
          switchMap(([continueToBooking, flightSearchParams]) => {
            loading$.next(true);
            return of({ continueToBooking, flightSearchParams });
          }),
          tap(() => loading$.next(false)),
          filter(({ continueToBooking }) => continueToBooking),
          map(({ flightSearchParams }) => {
            this.setPreviousSearch(flightSearchParams);
            return {
              deeplink: createDeeplinkPathFromFlightSearchParams({
                ...flightSearchParams,
                locale: this.languageService.langValue,
              }),
            };
          })
        )
        .subscribe(({ deeplink }) => {
          this.router.navigateByUrl(deeplink);
        })
    );
  }

  removeFlight(index: number): void {
    this.store$.dispatch(GlobalBookingActions.removeFlight({ index }));
  }

  addFlight(): void {
    this.store$.dispatch(GlobalBookingActions.addFlight());
  }

  setUsePopoverSelectors(value: boolean): void {
    this.usePopoverSelectorsSignal.set(value);
  }

  setLocalOriginalBookingSelection(): void {
    if (!this._originalBookingSelection$.getValue()) {
      this._subscription.add(
        combineLatest([
          this._globalBookingWidgetSelectionChanges$.pipe(startWith({})),
          this._selectedPreviousSearches$.pipe(startWith(undefined)),
        ])
          .pipe(
            distinctUntilChanged(
              ([preChange, preSearch], [nextChange, nextSearch]) =>
                equals(preChange, nextChange) && equals(preSearch, nextSearch)
            ),
            filter(([change, search]) => !isEmptyObjectOrHasEmptyValues(change) || isPresent(search)),
            withLatestFrom(this.store$.pipe(globalBookingSelections()))
          )
          .subscribe(([_, flightSelection]) => {
            this._originalBookingSelection$.next(flightSelection);
          })
      );
    }
  }

  resetLocalOriginalBookingSelection(): void {
    this._globalBookingWidgetSelectionChanges$.next({});
    this._selectedPreviousSearches$.next(undefined);
    this._originalBookingSelection$.next(undefined);
  }

  setGlobalBookingSelectionToOriginal(): void {
    this.store$.dispatch(
      GlobalBookingActions.updateSelection({
        selection: this._originalBookingSelection$.getValue(),
      })
    );
    this.resetLocalOriginalBookingSelection();
  }

  updateLocationsForUserLanguage(): void {
    this._subscription.add(
      this._flights$
        .pipe(
          switchMap((locations) => {
            const uniqueLocationCodes = locations.reduce((arr, flight) => {
              if (flight.origin && !arr.includes(flight.origin?.locationCode)) {
                arr.push(flight.origin.locationCode);
              }

              if (flight.destination && !arr.includes(flight.destination?.locationCode)) {
                arr.push(flight.destination.locationCode);
              }

              return arr;
            }, []);

            return combineLatest([this._flights$, this.locationService.triggerLocationFetchAll(uniqueLocationCodes)]);
          }),
          take(1),
          map(([flights, locations]) => {
            return flights.map((flight) => {
              return {
                ...flight,
                origin: flight.origin ? locations[flight.origin.locationCode] : undefined,
                destination: flight.destination ? locations[flight.destination.locationCode] : undefined,
              };
            });
          })
        )
        .subscribe((flights) => {
          this.store$.dispatch(GlobalBookingActions.setFlights({ flights }));
        })
    );
  }
  private previousSearchChanged(
    previousSearches: PreviousSearch,
    originalBookingSelection: GlobalBookingState
  ): SelectionType[] {
    const selectionChangeType = [];
    if (!equals(previousSearches.paxAmount, originalBookingSelection.paxAmount)) {
      selectionChangeType.push(SelectionType.PAX);
    }
    if (!equals(previousSearches.tripType, originalBookingSelection.tripType)) {
      selectionChangeType.push(SelectionType.TRIP_TYPE);
    }
    if (previousSearches.flights[0].origin.locationCode !== originalBookingSelection.flights[0].origin.locationCode) {
      selectionChangeType.push(SelectionType.ORIGIN);
    }
    if (
      previousSearches.flights[0].destination.locationCode !==
      originalBookingSelection.flights[0].destination.locationCode
    ) {
      selectionChangeType.push(SelectionType.DESTINATION);
    }
    if (
      !equals(
        previousSearches.flights.map(({ departureDate }) => departureDate),
        originalBookingSelection.flights.map(({ departureDate }) => departureDate)
      )
    ) {
      selectionChangeType.push(SelectionType.TRAVEL_DATES);
    }
    return selectionChangeType;
  }

  private setSelectionChangeType(type: SelectionType): void {
    const currentChange = this._globalBookingWidgetSelectionChanges$.getValue();
    if (!currentChange[type]) {
      this._globalBookingWidgetSelectionChanges$.next({
        ...currentChange,
        [type]: true,
      });
    }
  }

  private getAvailableTravelClasses(bookingLocations$: Observable<LocationPair[]>): void {
    combineLatest([
      bookingLocations$.pipe(
        map((locations) => this.getValidlocationPairs(locations)),
        distinctUntilChanged(
          (prev, next) =>
            prev.length === next.length &&
            next.every(
              (l, i) =>
                l.origin?.locationCode === prev[i]?.origin?.locationCode &&
                l.destination?.locationCode === prev[i]?.destination?.locationCode
            )
        )
      ),
      this._activeTab$,
      this._profile$,
    ])
      .pipe(
        filter(
          ([locations, activeTab, profile]) =>
            locations.length > 0 &&
            (activeTab === WidgetTab.FLIGHT || (activeTab === WidgetTab.AWARD && isPresent(profile))) &&
            locations.every((location) => location.origin && location.destination)
        ),
        switchMap(([validLocations, tab, userProfile]) =>
          forkJoin(
            validLocations.map(({ origin, destination }) =>
              this.locationRouteCffService.routeCffsFor(
                origin.locationCode,
                destination.locationCode,
                tab === WidgetTab.AWARD ? 'true' : 'false',
                this.languageService.localeValue,
                userProfile?.isCorporate ? 'true' : 'false'
              )
            )
          )
        ),
        map((routeCffDataForFlights: LocationRouteCffData[]) =>
          this.mapCffsToAvailableTravelClasses(routeCffDataForFlights)
        ),
        finShare()
      )
      .subscribe((availableTravelClass) => {
        this.store$.dispatch(GlobalBookingActions.setAvailableClasses({ availability: availableTravelClass }));
      });
  }

  private checkLocationAvailability(): void {
    this._subscription.add(
      this._locations$
        .pipe(
          map(({ origin, destination }) => isNotBlank(origin?.locationCode) && isNotBlank(destination?.locationCode)),
          distinctUntilChanged()
        )
        .subscribe((hasBothLocations) => {
          if (!hasBothLocations) {
            BookingWidgetActions.resetPrices();
          }
          this._showCompact$.next(!hasBothLocations);
        })
    );
  }

  // following month prices need to be fetched only for round trips
  private fetchFollowingMonthPrice(): void {
    this._subscription.add(
      combineLatest([
        this._priceRequestBaseParams$,
        this._travelDates$.pipe(distinctUntilChanged((prev, next) => prev.departureDate === next.departureDate)),
      ])
        .pipe(
          filter(([{ oneway }, travelDates]) => travelDates?.departureDate && !oneway),
          switchMap(([baseParams, travelDates]) =>
            this.bookingWidgetCalendarService.getPricesForFollowingMonth({
              ...baseParams,
              departureDate: travelDates.departureDate?.id,
              numberOfDays: 30,
            })
          )
        )
        .subscribe((prices) => {
          this.store$.dispatch(BookingWidgetActions.setFollowingMonthPrices({ prices }));
        })
    );
  }

  private fetchPriceBasedOnSelection(): void {
    // We fetch calendar prices whenever user changes the selections
    this._subscription.add(
      this._priceRequestBaseParams$
        .pipe(
          switchMap((baseParams) =>
            this.bookingWidgetCalendarService.getPricesForFullYear({
              ...baseParams,
              startDate: LocalDate.now().id,
            })
          )
        )
        .subscribe(({ fullYear, histogram }) => {
          this.store$.dispatch(BookingWidgetActions.setFullYearPrices({ prices: fullYear }));
          this.store$.dispatch(BookingWidgetActions.setHistogramPrices({ prices: histogram }));
        })
    );
  }

  private setTripTypeBasedOnTab(): void {
    // We don't offer multi-city for award flow so we default to return if multi-city is selected
    this._subscription.add(
      this._activeTab$
        .pipe(
          withLatestFrom(this._selectedTripType$),
          filter(([tab, tripType]) => tab === WidgetTab.AWARD && tripType === TripType.MULTICITY)
        )
        .subscribe(() => {
          this.setTripType(TripType.RETURN);
        })
    );
  }

  private setPaxDetailsGtm(): void {
    this._subscription.add(
      this._setPaxDetailsGtm$
        .pipe(
          filter((value) => Boolean(value)),
          withLatestFrom(this._paxAmount$),
          map(([_, paxAmount]) => paxAmount)
        )
        .subscribe((paxDetails: PaxAmount) => {
          const { adults, children, infants } = getGtmPassengers(paxDetails);
          this.bookingWidgetGtmService.trackElementEvent(
            'pax-amount',
            `MAINPAX: ${adults}, CHD: ${children}, INF: ${infants}, MAINPAXTYPE: ADT`
          );
          this._setPaxDetailsGtm$.next(false);
        })
    );
  }

  private initDefaultLocations(): void {
    this._defaultLocations$
      .pipe(
        distinctUntilChanged((prev, next) => equals(prev, next)),
        map((defaultLocations) => (isEmptyObjectOrHasEmptyValues(defaultLocations) ? null : defaultLocations)),
        filter(Boolean),
        withLatestFrom(this._locations$),
        switchMap(([locationsToBeSet, locationFromStore]) => {
          const origin = isPresent(locationsToBeSet?.[0]?.origin?.locationCode)
            ? locationsToBeSet?.[0]?.origin
            : locationFromStore?.origin;
          const destination = isPresent(locationsToBeSet?.[0]?.destination?.locationCode)
            ? locationsToBeSet?.[0]?.destination
            : locationFromStore?.destination;

          // fetch default locations
          return forkJoin({
            origin: origin?.locationCode
              ? this.locationRouteCffService.bestGuessFor(origin.locationCode, this.languageService.localeValue)
              : of(origin),
            destination: destination?.locationCode
              ? this.locationRouteCffService.bestGuessFor(destination?.locationCode, this.languageService.localeValue)
              : of(destination),
          });
        }),
        finShare()
      )
      .subscribe((location) => {
        this.setLocations(location);
      });
  }

  private sendRecommendationsFlightSearchEvent(params: FlightSearchParams): void {
    const { origin, destination } = params.flights[0];

    this._subscription.add(
      this.recommendationService
        .sendFlightSearchEvent(
          params.flights[0].departureDate.toISOString().substring(0, 10),
          params.flights[1]?.departureDate?.toISOString().substring(0, 10),
          origin.type === 'airport' ? origin.locationCode : undefined,
          destination.type === 'airport' ? destination.locationCode : undefined,
          origin.type === 'city' ? origin.locationCode : undefined,
          destination.type === 'city' ? destination.locationCode : undefined,
          // combining adults + c15s was in the initial requirement and logic was the same in the old widget
          params.paxAmount.adults + params.paxAmount.c15s,
          params.paxAmount.children + params.paxAmount.infants
        )
        .subscribe()
    );
  }

  // fetch user geoLocation data and set to origin, when origin data is missing or default location is missing
  private fetchUserLocationAndSetDefaultOrigin(defaultLocations$: Observable<LocationPair[]>): void {
    this._subscription.add(
      combineLatest([this._locations$, defaultLocations$, this.store$.pipe(akamaiGeolocation())])
        .pipe(
          take(1),
          filter(
            ([{ origin }, defaultLocations]) =>
              !isPresent(origin) || (defaultLocations.length > 0 && !isPresent(defaultLocations[0]?.origin))
          ),
          map(([_, __, geoLocation]) => geoLocation)
        )
        .pipe(
          switchMap((value: LatLng) =>
            this.locationRouteCffService.geolocMatchFor(value.lat, value.lng, this.languageService.localeValue)
          ),
          switchMap((location: LocationGeoLocData) =>
            this.locationRouteCffService.bestGuessFor(location.item.locationCode, this.languageService.localeValue)
          ),
          retryWithBackoff(2),
          catchError((err: unknown) => {
            return throwError(() => err);
          }),
          withLatestFrom(defaultLocations$),
          switchMap(([cffLocation, defaultLocations]) =>
            iif(
              () => isPresent(defaultLocations?.[0]?.destination?.locationCode),
              this.locationRouteCffService
                .bestGuessFor(defaultLocations?.[0]?.destination?.locationCode, this.languageService.localeValue)
                .pipe(map((destination) => [cffLocation, destination])),
              of([cffLocation])
            )
          ),
          finShare()
        )
        .subscribe(([cffLocation, destination]) => {
          this.setLocations({
            origin: cffLocation,
            ...(isPresent(destination) && { destination }),
          });
        })
    );
  }

  private priceParamsAreValid({ locations, travelClass, tripType, paxAmount }: PriceParams): boolean {
    return (
      isPresent(travelClass) &&
      isPresent(tripType) &&
      isPresent(locations?.origin?.locationCode) &&
      isPresent(locations?.destination?.locationCode) &&
      paxAmount?.adults >= 1
    );
  }

  private priceBaseParamsChanged(prev: InstantSearchBaseParams, next: InstantSearchBaseParams): boolean {
    return (
      prev.departureLocationCode !== next.departureLocationCode ||
      prev.destinationLocationCode !== next.destinationLocationCode ||
      prev.travelClass !== next.travelClass ||
      prev.oneway !== next.oneway
    );
  }

  private mapToPriceRequestBaseParams({
    tripType,
    travelClass,
    locations,
    paxAmount,
  }: PriceParams): InstantSearchBaseParams {
    return {
      departureLocationCode: locations.origin.locationCode,
      destinationLocationCode: locations.destination.locationCode,
      travelClass: travelClass === GlobalBookingTravelClass.MIXED ? undefined : travelClass,
      adults: paxAmount.adults + paxAmount.c15s,
      children: paxAmount.children,
      infants: paxAmount.infants,
      numberOfDays: 360,
      oneway: tripType === TripType.ONEWAY,
      directFlights: false,
      locale: this.languageService.localeValue,
    };
  }

  private getValidlocationPairs(locationPairs: LocationPair[]): LocationPair[] {
    return locationPairs.filter(
      ({ origin, destination }) => isNotBlank(origin?.locationCode) && isNotBlank(destination?.locationCode)
    );
  }

  private mapCffsToAvailableTravelClasses(locationRouteCffData: LocationRouteCffData[]): TravelClassAvailabilityMap {
    return locationRouteCffData
      .map((cffsForFlight) =>
        mapCffsToTravelClasses(cffsForFlight).filter((travelClass) => travelClass !== GlobalBookingTravelClass.FIRST)
      )
      .flat()
      .reduce((uniqueClasses, classes) => {
        uniqueClasses[classes] = true;
        return uniqueClasses;
      }, {}) as TravelClassAvailabilityMap;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconsModule, ModalModule } from '@fcom/ui-components';
import { UiTranslateModule } from '@fcom/ui-translate';
import { ComponentsModule } from '@fcom/common/components';
import { ImagesModule } from '@fcom/common/components/images/images.module';
import { DirectiveModule } from '@fcom/common/directives';
import { GtmModule } from '@fcom/common/gtm';
import { PipesModule } from '@fcom/common/pipes';

import { ContentServicesModule } from '../services/content-services.module';
import { BlockOfferTileComponent } from './block-offer/block-offer-tile.component';

// Exports dummy content components as shared module for importing on child modules of cms module and other use cases

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectiveModule,
    GtmModule,
    ImagesModule,
    UiTranslateModule,
    PipesModule,
    IconsModule,
    ContentServicesModule,
    ModalModule,
  ],
  declarations: [BlockOfferTileComponent],
  exports: [BlockOfferTileComponent],
})
export class ContentComponentsModule {}

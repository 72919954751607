import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { BookingHeaderSummary } from '@fcom/common/interfaces';
import { MediaQueryService } from '@fcom/common/services';
import { TripType } from '@fcom/core/constants';

@Component({
  selector: 'fin-booking-header-summary',
  templateUrl: './booking-header-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHeaderSummaryComponent implements OnInit {
  readonly TripType = TripType;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  isMobile$: Observable<boolean>;

  @Input() bookingSummary: BookingHeaderSummary;
  @Input() isClickable: boolean = false;
  @Output() modifySearch: EventEmitter<void> = new EventEmitter();

  constructor(private mediaQueryService: MediaQueryService) {}

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
  }
  openBookingWidget(): void {
    this.modifySearch.emit();
  }
}

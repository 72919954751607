import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ButtonMode, ButtonTheme, ButtonType, toKebabCase } from '@fcom/ui-components';
import { CmsCollection, CmsContentType } from '@fcom/core-api';
import { LoginStatus } from '@fcom/core-api/login';
import { loginStatus } from '@fcom/core/selectors';
import { AppState, isNotBlank } from '@fcom/core';
import { LoginService } from '@fcom/common/login';
import { unsubscribe } from '@fcom/core/utils';
import { finShare, safeMap, snapshot } from '@fcom/rx';

export enum RadioLevels {
  LANDER_FORM = 'landerForm',
  TOP_LEVEL = 'topLevel',
  SUB_LEVEL = 'subLevel',
}

const validateLanderForm = (): ValidatorFn => {
  return (form: UntypedFormGroup): { [key: string]: any } | null => {
    if (
      form.get(RadioLevels.TOP_LEVEL)?.value === '' ||
      (form.get(RadioLevels.TOP_LEVEL)?.value?.contentType === CmsContentType.CMCollection &&
        form.get(RadioLevels.SUB_LEVEL)?.value === null)
    ) {
      return { mismatch: true };
    } else {
      return null;
    }
  };
};

@Component({
  selector: 'fin-radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioListComponent implements OnInit, OnDestroy {
  readonly LoginStatus = LoginStatus;
  readonly ButtonMode = ButtonMode;
  readonly ButtonType = ButtonType;
  readonly ButtonTheme = ButtonTheme;
  readonly RadioLevels = RadioLevels;

  loginStatus$: Observable<LoginStatus>;
  form: UntypedFormGroup = new UntypedFormGroup({});
  group: UntypedFormGroup;

  private subscriptions: Subscription = new Subscription();

  @Input()
  content$: Observable<CmsCollection>;
  formTestId$: Observable<string | null>;

  constructor(
    private store$: Store<AppState>,
    private loginService: LoginService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.formTestId$ = this.content$.pipe(
      safeMap((content: CmsCollection): string | null => {
        const formId = toKebabCase(content.teaserTitle);
        return formId ? `${formId}-form` : null;
      }),
      filter(isNotBlank)
    );
    this.loginStatus$ = this.store$.pipe(loginStatus(), finShare());
    const oldGroup = this.form;
    this.group = this.fb.group(
      {
        [RadioLevels.TOP_LEVEL]: [oldGroup?.get(RadioLevels.TOP_LEVEL)?.value || ''],
        [RadioLevels.SUB_LEVEL]: [oldGroup?.get(RadioLevels.SUB_LEVEL)?.value || ''],
      },

      { validators: [validateLanderForm()] }
    );

    this.form.setControl(RadioLevels.LANDER_FORM, this.group);

    this.subscriptions.add(this.group.get(RadioLevels.TOP_LEVEL).valueChanges.subscribe(() => this.topLevelChanged()));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  redirectToSimplifiedLogin(redirectUrl: string): void {
    this.loginService.redirectToSimplifiedLogin({ redirectPath: redirectUrl });
  }

  private topLevelChanged = (): void => {
    this.group.get(RadioLevels.SUB_LEVEL).reset();
  };

  private isLoggedIn = (): boolean => {
    return snapshot(
      this.store$.pipe(
        loginStatus(),
        map((status: LoginStatus) => status === LoginStatus.LOGGED_IN)
      )
    );
  };

  navigateBasedOnTheChoice = (): void => {
    const isLoggedIn = this.isLoggedIn();

    const finalChoice =
      this.form.value.landerForm.topLevel.contentType === CmsContentType.CMCollection
        ? this.form.value.landerForm.subLevel
        : this.form.value.landerForm.topLevel;

    const finalUrl = finalChoice.localSettings.formType
      ? `${finalChoice.target.url}?formType=${finalChoice.localSettings.formType}`
      : finalChoice.target.url;

    if (!finalChoice.localSettings.requireCAS || isLoggedIn) {
      this.router.navigateByUrl(finalUrl);
    } else {
      this.redirectToSimplifiedLogin(finalUrl);
    }
  };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SentryModule } from '@fcom/core/services';
import { PipesModule } from '@fcom/common/pipes/pipes.module';

import { BadgesModule } from './components/badges/badges.module';
import { ButtonModule } from './components/buttons/button.module';
import { CarouselModule } from './components/carousel/carousel.module';
import { FcomFormsModule } from './components/forms/fcom-forms.module';
import { FlightsModule } from './components/flights/flights.module';
import { HeadersModule } from './components/headers/headers.module';
import { IconsModule } from './components/icons/icons.module';
import { LoaderModule } from './components/loader/loader.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { PopoverModule } from './components/popover/popover.module';
import { TabsModule } from './components/tabs/tabs.module';
import { ModalModule } from './components/modal/modal.module';
import { SideBarModule } from './components/sidebar/sidebar.module';
import { ProgressIndicatorModule } from './components/progress-indicator/progress-indicator.module';
import { ExpandModule } from './components/expand/expand.module';
import { ImagesModule } from './components/images/images.module';
import { DatepickerModule } from './components/datepicker/datepicker.module';
import { ProductsModule } from './components/products/products.module';
import { TagModule } from './components/tag/tag.module';
import { TrapFocusModule } from './directives/trap-focus/trap-focus.module';
import { ClickOutsideDirectiveModule } from './directives/click-outside/click-outside-directive.module';
import { FocusOutDirectiveModule } from './directives/focus-out/focus-out.directive.module';
import { ToggleSwitchModule } from './components/toggle-switch/toggle-switch.module';
import { PromoCardModule } from './components/promo-card/promo-card.module';
import { TableModule } from './components/table/table.module';
import { PaginationModule } from './components/pagination/pagination.module';
import { FiltersModule } from './components/filters/filters.module';
import { StepperModule } from './components/stepper/stepper.module';
import { TitledCardModule } from './components/product-variants/titled-card.module';
import { LinkModule } from './components/link/link.module';
import { InformBlockModule } from './components/inform-block/inform-block.module';

const uiModules = [
  CarouselModule,
  BadgesModule,
  ButtonModule,
  TagModule,
  FcomFormsModule,
  FlightsModule,
  HeadersModule,
  IconsModule,
  LoaderModule,
  NotificationsModule,
  PopoverModule,
  TabsModule,
  ModalModule,
  SideBarModule,
  ProgressIndicatorModule,
  TrapFocusModule,
  ClickOutsideDirectiveModule,
  FocusOutDirectiveModule,
  ExpandModule,
  ImagesModule,
  DatepickerModule,
  ProductsModule,
  SentryModule,
  ToggleSwitchModule,
  PromoCardModule,
  TableModule,
  PaginationModule,
  FiltersModule,
  StepperModule,
  TitledCardModule,
  LinkModule,
  InformBlockModule,
];

@NgModule({
  imports: [CommonModule, PipesModule, ...uiModules],
  exports: [...uiModules],
})
export class UiComponentsModule {}

import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { ButtonMode, IconPosition } from '@fcom/ui-components';
import { CmsContent, CMSelectionRules } from '@fcom/core-api';
import { FragmentService } from '@fcom/common';
import { LoginService } from '@fcom/common/login';
import { AppState, ConfigService } from '@fcom/core';
import { LoginStatus, LoginStep } from '@fcom/core-api/login';
import { loginStatus } from '@fcom/core/selectors';
import { finShare } from '@fcom/rx';
import { stopPropagation } from '@fcom/core/utils';
import { LoginActions } from '@fcom/core/actions';

import { HelpButtonStatus } from '../../interfaces';

@Component({
  selector: 'fin-pre-chat',
  templateUrl: 'pre-chat.component.html',
  styleUrls: ['pre-chat.component.scss'],
})
export class PreChatComponent implements OnInit {
  readonly ButtonMode = ButtonMode;
  readonly chatExpandKey = 'chat-expand';
  readonly CustomIcon = CustomIcon;
  readonly HelpButtonStatus = HelpButtonStatus;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly LoginStatus = LoginStatus;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Output()
  chatToggle: EventEmitter<HelpButtonStatus> = new EventEmitter();

  @Output()
  startChat: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('minimize', { static: false })
  minimizeButton: ElementRef;

  availableAccordions$: Observable<CmsContent[]>;
  loginStatus$: Observable<LoginStatus>;
  loginDialogIsEnabled = false;

  constructor(
    private fragmentService: FragmentService,
    private store$: Store<AppState>,
    private loginService: LoginService,
    private configService: ConfigService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.availableAccordions$ = this.fragmentService
      .getFragment<CMSelectionRules>('fragments.chatPreScreen.url')
      .pipe(map((collection) => collection.items));
    setTimeout(() => {
      this.minimizeButton.nativeElement.focus();
    }, 0);

    this.loginStatus$ = this.store$.pipe(loginStatus(), finShare());

    // check first if enableLoginDialog is true, then check if disabled on page
    if (this.configService.cfg.enableLoginDialog) {
      this.loginDialogIsEnabled = !this.checkIfLoginDialogDisabled(this.route.url);
    }
  }

  handleLogin = (e: MouseEvent) => {
    stopPropagation(e);

    if (this.loginDialogIsEnabled) {
      this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
    } else {
      this.loginService.redirectToLogin();
    }
  };

  // if url is included, loginDialog should be disabled
  private checkIfLoginDialogDisabled(pathUrl: string): boolean {
    return ['compensation-wizard'].some((page) => pathUrl.includes(page));
  }
}

<ng-container *ngIf="enableNewsletterSignUpForm && (isLoggedInUser$ | async) === false">
  <div
    data-testid="newsletter-sign-up-form"
    class="newsletter-signup-form form-container service-form ms-large-t col-1-12"
  >
    <ng-template #success>
      <div data-testid="newsletter-sign-up-success" class="col-1-12">
        <div class="df-mobile flex--left-center">
          <fcom-icon
            class="icon-large nordic-blue-900-text mr-xsmall-b"
            [name]="SvgLibraryIcon.CHECKMARK_OUTLINE"
          ></fcom-icon>
        </div>
        <h3>{{ 'newsletterSignUpForm.success.title' | finLocalization }}</h3>
        <div class="font-body-1 regular-type">
          {{ 'newsletterSignUpForm.success.subtitle' | finLocalization: { email: reactiveForm.get('email').value } }}
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="reactiveForm && formStatus !== FormStatus.COMPLETE; else success">
      <div class="col-1-12 form-container">
        <h2>{{ 'newsletterSignUpForm.title' | finLocalization }}</h2>
        <div class="font-body-1 regular-type">
          {{ 'newsletterSignUpForm.subtitle' | finLocalization }}
        </div>
        <div
          *ngIf="reactiveForm && (formStatus === FormStatus.INITIAL || formStatus === FormStatus.INITIALIZING_ERROR)"
          class="font-body-1 regular-type pr-xxsmall-t"
          [class.pr-small-b]="
            reactiveForm && (formStatus === FormStatus.INITIAL || formStatus === FormStatus.INITIALIZING_ERROR)
          "
        >
          {{ 'newsletterSignUpForm.mandatoryFields' | finLocalization }}
        </div>

        <form
          *ngIf="reactiveForm && (formStatus === FormStatus.INITIAL || formStatus === FormStatus.INITIALIZING_ERROR)"
          [formGroup]="reactiveForm"
          (ngSubmit)="onSubmit()"
          autocomplete="off"
        >
          <div class="flex flex--wrap columns-2">
            <fcom-text-input
              class="flex-1"
              [parentForm]="reactiveForm"
              [translationPrefix]="'newsletterSignUpForm.formDetails.firstName'"
              [controlName]="'firstName'"
              [placeHolder]="'newsletterSignUpForm.formDetails.firstName.placeholder'"
            ></fcom-text-input>

            <fcom-text-input
              class="flex-1"
              [parentForm]="reactiveForm"
              [translationPrefix]="'newsletterSignUpForm.formDetails.lastName'"
              [controlName]="'lastName'"
              [placeHolder]="'newsletterSignUpForm.formDetails.lastName.placeholder'"
            ></fcom-text-input>
          </div>

          <fcom-text-input
            [parentForm]="reactiveForm"
            [translationPrefix]="'newsletterSignUpForm.formDetails.email'"
            [controlName]="'email'"
            [placeHolder]="'newsletterSignUpForm.formDetails.email.placeholder'"
          ></fcom-text-input>

          <div class="flex flex--wrap columns-2">
            <fin-select-input
              class="flex-1"
              [parentForm]="reactiveForm"
              [controlName]="'country'"
              [options]="countries"
              [selectLabelTranslationKey]="'newsletterSignUpForm.formDetails.country.placeholder'"
              [translationPrefix]="'newsletterSignUpForm.formDetails.country'"
              (change)="countryChanged(reactiveForm.get('country').value)"
            ></fin-select-input>

            <fin-select-input
              *ngIf="languages?.length !== 1"
              class="flex-1"
              [parentForm]="reactiveForm"
              [controlName]="'language'"
              [options]="languages"
              [selectLabelTranslationKey]="'newsletterSignUpForm.formDetails.language.placeholder'"
              [translationPrefix]="'newsletterSignUpForm.formDetails.language'"
            ></fin-select-input>

            <div *ngIf="languages?.length === 1">
              <div data-testid="newsletter-signup-language" class="ps-xxsmall-b ms-xlarge-b">
                <span class="font-body-1 regular-type block nordic-blue-400-text">
                  {{ 'newsletterSignUpForm.languageTitle' | finLocalization }}
                </span>
                <div class="font-body-1">{{ languages?.[0]?.name }}</div>
              </div>
            </div>
          </div>

          <div *ngIf="enableNewNewsletterConsents" class="font-body-1">
            <span class="block ms-xsmall-b">{{
              'newsletterSignUpForm.formDetails.checkAtLeastOne' | finLocalization
            }}</span>
            <fcom-checkbox
              class="block ms-small-b"
              [formGroup]="reactiveForm"
              [name]="'marketingNewsletter'"
              [formControlName]="'marketingNewsletter'"
            >
              <span>
                {{ 'newsletterSignUpForm.formDetails.marketingNewsletter' | finLocalization }}
              </span>
            </fcom-checkbox>

            <fcom-checkbox [formGroup]="reactiveForm" [name]="'advertising'" [formControlName]="'advertising'">
              <span>
                {{ 'newsletterSignUpForm.formDetails.advertising' | finLocalization }}
              </span>
            </fcom-checkbox>

            <div
              class="ms-large-y"
              [innerHTML]="'newsletterSignUpForm.formDetails.newsLetterTerms' | finLocalization | finSafe: 'html'"
            ></div>
          </div>

          <fcom-notification
            *ngIf="formStatus === FormStatus.INITIALIZING_ERROR"
            [theme]="NotificationTheme.ALERT"
            class="ms-xlarge-y"
          >
            {{ 'errors.generic.title' | finLocalization }}
          </fcom-notification>

          <div class="flex flex--end ms-small-y">
            <fcom-button
              data-testid="newsletter-signup-submit"
              (clickBtn)="onSubmit()"
              [text]="'newsletterSignUpForm.submit' | finLocalization"
              [disabled]="reactiveForm.invalid"
            >
            </fcom-button>
          </div>
        </form>
      </div>

      <div *ngIf="formStatus === FormStatus.LOADING" class="mr-xlarge-y center col-1-12">
        <fcom-loader [loaderType]="LoaderTheme.INLINE"></fcom-loader>
      </div>

      <ng-container *ngIf="errorArticle$ | async as error">
        <div *ngIf="formStatus === FormStatus.ERROR" data-testid="newsletter-signup-already-signup" class="col-1-12">
          <h3>{{ error.teaserTitle }}</h3>
          <div class="error" [innerHTML]="error.teaserText | finSafe: 'html'"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { LanguageService } from '@fcom/ui-translate';
import { RootPaths } from '@fcom/core/constants';
import {
  ButtonSize,
  ButtonTheme,
  ButtonType,
  IconButtonSize,
  IconButtonTheme,
  IconPosition,
} from '@fcom/ui-components';

@Component({
  selector: 'fin-site-search-header-widget-starter',
  templateUrl: 'site-search-header-widget-starter.component.html',
  styleUrls: ['site-search-header-widget-starter.component.scss'],
})
export class SiteSearchHeaderWidgetStarterComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonType = ButtonType;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconButtonSize = IconButtonSize;

  @ViewChild('headerQueryField', { static: false })
  headerQueryField: ElementRef;

  @Input()
  expandable = false;

  @Input()
  enableNewLanguageSelector = false;

  @Input()
  searchButtonIconOnly = false;

  query = '';
  siteSearchPath = '';
  searchForm: UntypedFormGroup;
  inputExpanded: boolean;

  constructor(
    private languageService: LanguageService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.siteSearchPath = `/${[this.languageService.langValue, RootPaths.SITE_SEARCH_ROOT].filter(Boolean).join('/')}`;
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchKey: [],
    });
    this.inputExpanded = !this.expandable;
  }

  expandSearch() {
    this.inputExpanded = true;
    this.headerQueryField.nativeElement.focus();
  }

  collapseSearch() {
    this.inputExpanded = false;
  }

  onSubmit() {
    const q = this.searchForm.get('searchKey').value;
    this.triggerSearch(q);
  }

  onBlur() {
    if (this.expandable && !this.searchForm.get('searchKey').value) {
      this.collapseSearch();
    }
  }

  triggerSearch(query: string): void {
    const q = query ? `?query=${query}` : '';
    this.router.navigateByUrl(`${this.languageService.langValue}/search${q}`);

    this.collapseSearch();
    this.searchForm.reset(this.searchForm.get('searchKey'));
  }
}

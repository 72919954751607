import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, map, Observable, of } from 'rxjs';

import { PaxAmount } from '@fcom/dapi';
import { finShare } from '@fcom/rx';
import { GlobalBookingTravelClass } from '@fcom/core';
import { ButtonTheme, PopoverOptions, PopoverService } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';

import { AmountUpdateType, PaxAmountTypeConfig, PaxUpdateEvent } from '../../interfaces';
import { defaultWidgetPopoverOptions } from '../../constants';
import { BookingWidgetService } from '../../services/booking-widget.service';
import { mapPaxAmountToPaxBreakdown } from '../../utils/utils.pax-amount';

const MAX_PAX_AMOUNT = 9;
const MIN_LEAD_PAX_AMOUNT = 1;

@Component({
  selector: 'fin-pax-amount-selector',
  templateUrl: './pax-amount-selector.component.html',
  styleUrls: ['./pax-amount-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaxAmountSelectorComponent implements OnInit {
  protected readonly ButtonTheme = ButtonTheme;
  protected readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  disabled = false;

  @Input()
  travelClass$: Observable<GlobalBookingTravelClass>;

  @Input()
  availableTravelClasses$: Observable<GlobalBookingTravelClass[]>;

  @Input()
  paxAmount$: Observable<PaxAmount>;

  @Output()
  setTravelClass = new EventEmitter<GlobalBookingTravelClass>();

  @Output()
  setPaxAmount = new EventEmitter<PaxUpdateEvent>();

  @Input()
  highLight$ = of(false);

  readonly GlobalBookingTravelClass = GlobalBookingTravelClass;
  readonly AmountUpdateType = AmountUpdateType;
  readonly popoverOptions: PopoverOptions = {
    ...defaultWidgetPopoverOptions,
    popoverID: 'paxAmountSelectorPopover',
    disableAutoFocus: true,
  };

  modalOpen = false;
  totalPax$: Observable<number>;
  paxAmountConfig$: Observable<PaxAmountTypeConfig>;
  paxAmountBreakdown$: Observable<string>;

  readonly usePopoverSelectors = this.bookingWidgetService.usePopoverSelectors();

  constructor(
    private popoverService: PopoverService,
    private bookingWidgetService: BookingWidgetService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.totalPax$ = this.paxAmount$.pipe(
      map((paxAmount: PaxAmount) => Object.values(paxAmount).reduce((acc, val) => acc + val, 0))
    );

    this.paxAmountBreakdown$ = combineLatest([this.paxAmount$, this.languageService.translate('passenger')]).pipe(
      map(([paxAmount, passengerTranslations]) => mapPaxAmountToPaxBreakdown(paxAmount, passengerTranslations))
    );

    this.paxAmountConfig$ = this.paxAmount$.pipe(
      map((paxAmount: PaxAmount) => ({
        adults: {
          min: MIN_LEAD_PAX_AMOUNT,
          max: MAX_PAX_AMOUNT - paxAmount.c15s - paxAmount.children,
        },
        c15s: {
          min: 0,
          max: MAX_PAX_AMOUNT - paxAmount.adults - paxAmount.children,
        },
        children: {
          min: 0,
          max: MAX_PAX_AMOUNT - paxAmount.adults - paxAmount.c15s,
        },
        infants: { min: 0, max: paxAmount.adults },
      })),
      finShare()
    );
  }

  openModal(): void {
    if (!this.usePopoverSelectors) {
      this.modalOpen = true;
    }
  }

  onTravelClassSelect(selectedTravelClass: GlobalBookingTravelClass): void {
    this.setTravelClass.emit(selectedTravelClass);
  }

  onPaxDetailsUpdate(amount: number, type: string, updateType: AmountUpdateType): void {
    this.setPaxAmount.emit({ paxType: type as keyof PaxAmount, amount, updateType });
  }

  closePopover(): void {
    this.popoverService.close();
  }
}

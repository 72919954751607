<div
  [formGroup]="parentForm"
  class="autocomplete-container ms-xlarge-b"
  [class.theme-web2]="theme === DatepickerTheme.WEB_2"
  matAutocompleteOrigin
  #origin="matAutocompleteOrigin"
>
  <div class="relative ps-xxsmall-b" [class.new-theme]="theme === DatepickerTheme.WEB_2">
    <label
      class="font-body-1 flex ps-xxsmall-b"
      [for]="idOrControlName"
      [ngClass]="{
        'grey-600-text': !(ctrlField.invalid && ctrlField.touched) && theme === DatepickerTheme.DEFAULT,
        'nordic-blue-400-text': !(ctrlField.invalid && ctrlField.touched) && theme === DatepickerTheme.WEB_2,
        'error-900-text': ctrlField.invalid && ctrlField.touched,
      }"
    >
      <div class="flex-1">
        {{ translationPrefix + '.label' | finLocalization }}
        <span *ngIf="isRequired" class="flex-1 flex--nogrow"> *</span>
      </div>
    </label>
    <div [class.relative]="theme === DatepickerTheme.WEB_2">
      <input
        class="font-body-1 light-type"
        [min]="minDate"
        [max]="maxDate"
        [class.form-field-empty]="!(ctrlField.value || placeHolder)"
        [formControlName]="controlName"
        [matDatepicker]="dp"
        [id]="idOrControlName"
        [placeholder]="placeHolder ? (placeHolder | finLocalization) : null"
        (dateChange)="signalSelection($event)"
        [name]="controlName"
        [ngClass]="{
          'light-type': theme !== DatepickerTheme.WEB_2,
          'medium-type': theme === DatepickerTheme.WEB_2,
          'ps-small': theme === DatepickerTheme.WEB_2,
          'rounded-large': theme === DatepickerTheme.WEB_2,
        }"
      />
      <span class="divider" *ngIf="theme !== DatepickerTheme.WEB_2"></span>
      <div class="absolute datepicker-container">
        <button
          (click)="dp.open()"
          type="button"
          [attr.aria-label]="'SERVICE_FORMS.NTServiceForms.helpers.datePicker.openCalendarLabel' | finLocalization"
        >
          <fcom-icon class="icon-medium" [name]="icon"></fcom-icon>
        </button>
        <mat-datepicker
          panelClass="service-datepicker shadow font-body-1 pr-small white-bg regular-type"
          #dp
        ></mat-datepicker>
      </div>
    </div>
  </div>
  <div *ngIf="ctrlField.invalid && ctrlField.touched" class="error-label font-body-2 error-900-text" aria-live="polite">
    <span *ngIf="ctrlField.errors?.required" aria-live="polite" class="required">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
    <span *ngIf="ctrlField.errors?.pattern" aria-live="polite" class="pattern">
      {{ translationPrefix + '.pattern' | finLocalization }}
    </span>
    <span *ngIf="ctrlField.errors?.matDatepickerMax" aria-live="polite" class="max">
      {{ translationPrefix + '.max' | finLocalization }}
    </span>
    <span *ngIf="ctrlField.errors?.matDatepickerMin" aria-live="polite" class="min">
      {{ translationPrefix + '.min' | finLocalization }}
    </span>
  </div>
</div>
<div aria-live="polite" class="result-helper is-vishidden">
  <span tabindex="-1" *ngIf="selectedDay">
    {{
      translationPrefix + '.selection' | finLocalization: { day: selectedDay, month: selectedMonth, year: selectedYear }
    }}</span
  >
</div>

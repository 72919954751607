import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LocalDate } from '@fcom/core/utils';

import { Month, Day, DateSelection, UiDay } from '../../../utils/date.interface';
import { CalendarService } from '../services/calendar.service';

enum SelectedDayType {
  STARTDATE,
  ENDDATE,
  SINGLE,
  INBETWEEN,
  NONE,
}

@Component({
  selector: 'fcom-calendar-day, [fcom-calendar-day]',
  styleUrls: ['./day.component.scss'],
  templateUrl: './day.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayComponent implements OnInit, OnDestroy, OnChanges {
  public readonly SelectedDayType = SelectedDayType;
  private ngDestroyed$ = new Subject();

  @ViewChild('buttonElement') buttonElement: ElementRef;

  @Input() uiDay: UiDay;
  @Input() month: Month;
  @Input() selectedLabel: string;
  @Input() template: TemplateRef<{ dayString: number; dayValue: Day }>;
  @Input() identifier: string;

  @Output() selected: EventEmitter<boolean> = new EventEmitter();

  day: Day;
  ariaLabel: string;
  isDateRange = false;
  isActiveCell = false;
  hoveredDay: Day;
  isSelectionHover = false;
  activeCell: LocalDate;
  datesSelected: DateSelection;
  cellSelectionStyling: SelectedDayType = SelectedDayType.NONE;

  constructor(
    private cd: ChangeDetectorRef,
    private calendarService: CalendarService
  ) {}

  ngOnInit(): void {
    this.calendarService.isDateRange$.pipe(takeUntil(this.ngDestroyed$)).subscribe((isRange) => {
      this.isDateRange = isRange[this.identifier];
    });

    this.calendarService.activeCell$.pipe(takeUntil(this.ngDestroyed$)).subscribe((activeDay) => {
      this.activeCell = activeDay;
      if (this.day) {
        this.isActiveCell = activeDay.equals(this.day.date);
      }
      this.cellSelectionStyling = this.getSelectionState();
      this.cd.detectChanges();
    });

    this.calendarService.hoveredDay$.pipe(takeUntil(this.ngDestroyed$)).subscribe((hoveredDay) => {
      this.hoveredDay = hoveredDay;
      if (hoveredDay || this.cellSelectionStyling !== SelectedDayType.NONE) {
        this.cellSelectionStyling = this.getSelectionState();
      }
      this.cd.detectChanges();
    });

    this.calendarService
      .getDatesSelected(this.identifier)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((datesSelected) => {
        this.datesSelected = datesSelected;
        if (this.day) {
          const isSelected =
            this.day.date.equals(datesSelected.startDate) || this.day.date.equals(datesSelected.endDate);
          this.selected.emit(isSelected);
          this.ariaLabel = `${this.uiDay.label} ${isSelected ? this.selectedLabel : ''}`;
        }
        this.cellSelectionStyling = this.getSelectionState();
        this.cd.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('uiDay')) {
      if (this.uiDay.instance) {
        this.day = this.uiDay.instance;
      }
    }
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(true);
  }

  hoverDay(day: Day): void {
    this.calendarService.setHoveredDay(day);
  }

  selectDay(day: Day): void {
    this.calendarService.selectDay(this.identifier, day);
  }

  private getSelectionState(): SelectedDayType {
    if (!this.datesSelected) {
      return SelectedDayType.NONE;
    }

    const day = this.day || this.uiDay.reference;
    if (this.isDateRange) {
      return this.getRangeSelectionState(day);
    } else {
      // Single date selection
      if (this.datesSelected.startDate && day.id === this.datesSelected.startDate.id) {
        // Day is selected start day
        return SelectedDayType.SINGLE;
      }
    }

    return SelectedDayType.NONE;
  }

  private getRangeSelectionState(day): SelectedDayType {
    const isSelectedStartDate = this.datesSelected.startDate && day.id === this.datesSelected.startDate.id;
    if (isSelectedStartDate) {
      // Day is same selected start day
      return SelectedDayType.STARTDATE;
    }

    const isSelectedEndDate = this.datesSelected.endDate && day.id === this.datesSelected.endDate.id;
    if (isSelectedEndDate) {
      // Day is same selected end day
      return SelectedDayType.ENDDATE;
    }

    const returnDateNotSelected = this.datesSelected.startDate && !this.datesSelected.endDate;
    if (returnDateNotSelected) {
      const isHoveredDateAndPossibleEndDate =
        this.hoveredDay &&
        day.id === this.hoveredDay.id &&
        new LocalDate(this.hoveredDay.id).gte(new LocalDate(this.datesSelected.startDate.id));
      if (isHoveredDateAndPossibleEndDate) {
        // Check if a prospect end date is hovered and this day is the one hovered
        // Only use this hover style for dates that are past the departure date
        return SelectedDayType.ENDDATE;
      }
      const isInbetweenHoverSelection =
        this.hoveredDay && day.date.gt(this.datesSelected.startDate) && day.date.lt(this.hoveredDay.date);
      if (isInbetweenHoverSelection) {
        // Check if a prospect return date is hovered and this day is within these days
        return SelectedDayType.INBETWEEN;
      }
    }

    const isInbetweenSelection =
      this.datesSelected.startDate &&
      this.datesSelected.endDate &&
      day.date.gt(this.datesSelected.startDate) &&
      day.date.lt(this.datesSelected.endDate);
    if (isInbetweenSelection) {
      // Departure and return dates are selected and this day is between them
      return SelectedDayType.INBETWEEN;
    }

    return SelectedDayType.NONE;
  }
}

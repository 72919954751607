import { GlobalBookingTravelClass, TripType } from '@fcom/core';
import { PaxAmount } from '@fcom/dapi';
import { GlobalBookingFlight } from '@fcom/common/store';

export enum WidgetTab {
  FLIGHT = 'flight',
  AWARD = 'award',
  AM = 'am',
  CARTRAWLER = 'carTrawler',
}

export interface FlightSearchParams {
  flights: GlobalBookingFlight[];
  paxAmount: PaxAmount;
  travelClass: GlobalBookingTravelClass;
  tripType: TripType;
  locale?: string;
  activatedTab?: WidgetTab;
  promoCode?: string | null;
  isAward?: boolean;
  isCorporate?: boolean;
}

export interface InstantSearchBaseParams {
  departureLocationCode: string;
  destinationLocationCode: string;
  numberOfDays: number;
  travelClass?: string;
  oneway?: boolean;
  adults?: number;
  children?: number;
  infants?: number;
  directFlights?: boolean;
  locale?: string;
}

export interface InstantSearchFullYearParams extends InstantSearchBaseParams {
  startDate: string;
}

export interface InstantSearchFollowingMonthParams extends InstantSearchBaseParams {
  departureDate: string;
}

/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CancelAndRefundRequest } from '../models/cancel-and-refund-request';
import { EmailSenderOrderRequest } from '../models/email-sender-order-request';
import { FetchOrderRequest } from '../models/fetch-order-request';
import { FinnairOrder } from '../models/finnair-order';
import { FinnairOrderCancelRefund } from '../models/finnair-order-cancel-refund';
import { FinnairOrderPreviewsResponse } from '../models/finnair-order-previews-response';
import { FinnairPassengerRequest } from '../models/finnair-passenger-request';
import { FinnairServiceRequestItem } from '../models/finnair-service-request-item';
import { RequestInitiator } from '../models/request-initiator';


/**
 * Booking list for finnair plus user
 */
@Injectable({
  providedIn: 'root',
})
export class OrderService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation initializeCheckoutV2
   */
  static readonly InitializeCheckoutV2Path = '/api/v2/checkout/initialize';

  /**
   * initializeCheckoutV2.
   *
   * Initialize the checkout process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initializeCheckoutV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  initializeCheckoutV2$Response(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    orderChangeId?: string;
    locale?: string;
    hash?: string;
    'True-Client-IP'?: string;
    'X-Forwarded-For'?: string;
    device?: string;
    service?: string;
    successUrl: string;
    failureUrl: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.InitializeCheckoutV2Path, 'post');

    if (params) {
      rb.query('cartId', params.cartId, {});
      rb.query('orderId', params.orderId, {});
      rb.query('orderChangeId', params.orderChangeId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.header('True-Client-IP', params['True-Client-IP'], {});
      rb.header('X-Forwarded-For', params['X-Forwarded-For'], {});
      rb.query('device', params.device, {});
      rb.query('service', params.service, {});
      rb.query('successUrl', params.successUrl, {});
      rb.query('failureUrl', params.failureUrl, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * initializeCheckoutV2.
   *
   * Initialize the checkout process
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `initializeCheckoutV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  initializeCheckoutV2(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    orderChangeId?: string;
    locale?: string;
    hash?: string;
    'True-Client-IP'?: string;
    'X-Forwarded-For'?: string;
    device?: string;
    service?: string;
    successUrl: string;
    failureUrl: string;
  },
  context?: HttpContext

): Observable<void> {
    return this.initializeCheckoutV2$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fetchOrderPreviews
   */
  static readonly FetchOrderPreviewsPath = '/api/v2/order-previews';

  /**
   * fetchOrderPreviews.
   *
   * API public; Fetch order previews content secured by hash
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchOrderPreviews()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOrderPreviews$Response(rootUrl: string, params?: {
    locale?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairOrderPreviewsResponse>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.FetchOrderPreviewsPath, 'get');

    if (params) {
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairOrderPreviewsResponse>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * fetchOrderPreviews.
   *
   * API public; Fetch order previews content secured by hash
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchOrderPreviews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOrderPreviews(rootUrl: string, params?: {
    locale?: string;
  },
  context?: HttpContext

): Observable<FinnairOrderPreviewsResponse> {
    return this.fetchOrderPreviews$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairOrderPreviewsResponse>) => r.body as FinnairOrderPreviewsResponse)
    );
  }

  /**
   * Path part for operation fetchOrderByEmailSenderOrderRequest1
   */
  static readonly FetchOrderByEmailSenderOrderRequest1Path = '/api/v2/order/email-sender';

  /**
   * fetchOrderByEmailSenderOrderRequest.
   *
   * Deprecated endpoint, use the private endpoint instead using sig4 signature
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchOrderByEmailSenderOrderRequest1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  fetchOrderByEmailSenderOrderRequest1$Response(rootUrl: string, params: {
    body: EmailSenderOrderRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairOrder>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.FetchOrderByEmailSenderOrderRequest1Path, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairOrder>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * fetchOrderByEmailSenderOrderRequest.
   *
   * Deprecated endpoint, use the private endpoint instead using sig4 signature
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchOrderByEmailSenderOrderRequest1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  fetchOrderByEmailSenderOrderRequest1(rootUrl: string, params: {
    body: EmailSenderOrderRequest
  },
  context?: HttpContext

): Observable<FinnairOrder> {
    return this.fetchOrderByEmailSenderOrderRequest1$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairOrder>) => r.body as FinnairOrder)
    );
  }

  /**
   * Path part for operation getOrderByPnrAndLastName
   */
  static readonly GetOrderByPnrAndLastNamePath = '/api/v2/order/get-order';

  /**
   * getOrderByPnrAndLastName.
   *
   * Gets URL to fetch Order. Uses record locator or e-ticket number, and lastname, optionally first name, as input. Url to fetch the order is part of the Location header
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderByPnrAndLastName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderByPnrAndLastName$Response(rootUrl: string, params: {
    body: FetchOrderRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.GetOrderByPnrAndLastNamePath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getOrderByPnrAndLastName.
   *
   * Gets URL to fetch Order. Uses record locator or e-ticket number, and lastname, optionally first name, as input. Url to fetch the order is part of the Location header
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderByPnrAndLastName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderByPnrAndLastName(rootUrl: string, params: {
    body: FetchOrderRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.getOrderByPnrAndLastName$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOrderByTourOperatorAndLastName
   */
  static readonly GetOrderByTourOperatorAndLastNamePath = '/api/v2/order/get-order-tour-operator';

  /**
   * getOrderByTourOperatorIdAndLastName.
   *
   * Get order with Tour Operator ID and last name for manage my booking
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderByTourOperatorAndLastName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderByTourOperatorAndLastName$Response(rootUrl: string, params: {
    body: FetchOrderRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.GetOrderByTourOperatorAndLastNamePath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getOrderByTourOperatorIdAndLastName.
   *
   * Get order with Tour Operator ID and last name for manage my booking
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderByTourOperatorAndLastName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderByTourOperatorAndLastName(rootUrl: string, params: {
    body: FetchOrderRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.getOrderByTourOperatorAndLastName$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fetchOrder
   */
  static readonly FetchOrderPath = '/api/v2/order/{orderId}';

  /**
   * fetchOrder.
   *
   * API public; Fetch order content secured by hash
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOrder$Response(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    includeServiceCatalog?: boolean;
    requestInitiator?: RequestInitiator;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairOrder>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.FetchOrderPath, 'get');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.query('includeServiceCatalog', params.includeServiceCatalog, {});
      rb.query('requestInitiator', params.requestInitiator, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairOrder>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * fetchOrder.
   *
   * API public; Fetch order content secured by hash
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOrder(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    includeServiceCatalog?: boolean;
    requestInitiator?: RequestInitiator;
  },
  context?: HttpContext

): Observable<FinnairOrder> {
    return this.fetchOrder$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairOrder>) => r.body as FinnairOrder)
    );
  }

  /**
   * Path part for operation updatePassengerDetails
   */
  static readonly UpdatePassengerDetailsPath = '/api/v2/order/{orderId}/passengers';

  /**
   * updatePassengerDetailsOrder.
   *
   * add/modify/delete details into order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassengerDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassengerDetails$Response(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    body: FinnairPassengerRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.UpdatePassengerDetailsPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * updatePassengerDetailsOrder.
   *
   * add/modify/delete details into order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePassengerDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassengerDetails(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    body: FinnairPassengerRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.updatePassengerDetails$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation confirmCancelAndRefund
   */
  static readonly ConfirmCancelAndRefundPath = '/api/v2/order/{orderId}/refund/confirm';

  /**
   * confirmCancelAndRefund.
   *
   * API public; Confirms cancel and refund
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmCancelAndRefund()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmCancelAndRefund$Response(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    body: CancelAndRefundRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairOrderCancelRefund>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.ConfirmCancelAndRefundPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairOrderCancelRefund>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * confirmCancelAndRefund.
   *
   * API public; Confirms cancel and refund
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmCancelAndRefund$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmCancelAndRefund(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    body: CancelAndRefundRequest
  },
  context?: HttpContext

): Observable<FinnairOrderCancelRefund> {
    return this.confirmCancelAndRefund$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairOrderCancelRefund>) => r.body as FinnairOrderCancelRefund)
    );
  }

  /**
   * Path part for operation fetchOrderCancelRefund
   */
  static readonly FetchOrderCancelRefundPath = '/api/v2/order/{orderId}/refund/informative';

  /**
   * fetchOrderCancelRefund.
   *
   * API public; Fetch order content with cancel refund information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchOrderCancelRefund()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOrderCancelRefund$Response(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairOrderCancelRefund>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.FetchOrderCancelRefundPath, 'get');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairOrderCancelRefund>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * fetchOrderCancelRefund.
   *
   * API public; Fetch order content with cancel refund information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchOrderCancelRefund$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOrderCancelRefund(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
  },
  context?: HttpContext

): Observable<FinnairOrderCancelRefund> {
    return this.fetchOrderCancelRefund$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairOrderCancelRefund>) => r.body as FinnairOrderCancelRefund)
    );
  }

  /**
   * Path part for operation setServices
   */
  static readonly SetServicesPath = '/api/v2/order/{orderId}/services';

  /**
   * manageServicesToOrder.
   *
   * Adds/Removes/Updates services to the order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setServices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setServices$Response(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    locale?: string;
    body: Array<FinnairServiceRequestItem>
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderService.SetServicesPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('hash', params.hash, {});
      rb.query('locale', params.locale, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * manageServicesToOrder.
   *
   * Adds/Removes/Updates services to the order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setServices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setServices(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    locale?: string;
    body: Array<FinnairServiceRequestItem>
  },
  context?: HttpContext

): Observable<void> {
    return this.setServices$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

}

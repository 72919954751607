export enum TextInputType {
  INPUT = 'input',
  TEXTAREA = 'textarea',
}

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
}

export enum CheckBoxTheme {
  SQUARE = 'square',
  ROUNDED = 'rounded',
}

export enum RadioButtonTheme {
  BOX = 'box',
  ROUND = 'round',
}

export enum CursorType {
  POINTER = 'pointer',
}

export const ATTACHMENT_ALLOWED_FILE_EXTENSIONS = ['doc', 'docx', 'txt', 'pdf', 'jpg', 'bmp', 'gif', 'png', 'jpeg'];

export const ATTACHMENT_MAX_SIZE_IN_BYTES = 3670016; //3.5MB

export const TEXTAREA_LIMIT_1000 = 1000;

export const TEXTAREA_LIMIT_3000 = 3000;

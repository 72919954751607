import { Inject, Injectable, OnDestroy, Optional, RendererFactory2 } from '@angular/core';
import { APP_BASE_HREF, DOCUMENT, PlatformLocation } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { finalize, first, map, take } from 'rxjs/operators';

import { IconsConfig } from '../icons.config';
import { IconsService } from './icons.service';

type SymbolDefs = [SVGSymbolElement, SVGDefsElement];

export const IN_PROGRESS = 'Already in Progress';
@Injectable({
  providedIn: 'root',
})
export class ClientIconsService extends IconsService implements OnDestroy {
  private readonly _cache: Map<string, SymbolDefs> = new Map<string, SymbolDefs>();
  private readonly _inProgressReqs: Set<string> = new Set<string>();

  private _baseUrl = '';

  constructor(
    @Inject(DOCUMENT) readonly document: Document,
    rendererFactory: RendererFactory2,
    @Optional() @Inject(APP_BASE_HREF) private readonly _appBase: string,
    @Optional() private readonly _location: PlatformLocation,
    @Optional() private readonly _config: IconsConfig,
    private readonly _http: HttpClient
  ) {
    super(document, rendererFactory);
    this.setBaseUrl();
  }

  private setBaseUrl(): void {
    if (!!this._config && !!this._config.baseUrl) {
      this._baseUrl = this._config.baseUrl;
    } else if (this._appBase) {
      this._baseUrl = this._appBase;
    } else if (this._location) {
      this._baseUrl = this._location.getBaseHrefFromDOM();
    }
  }

  private getAbsoluteUrl(url: string): string {
    // Prepend user-configured base if present and URL doesn't seem to have its own
    if (this._baseUrl && !/^https?:\/\//i.test(url)) {
      url = `${this._baseUrl.replace(/\/$/, '')}/${url.replace(/^\//, '')}`;
    }

    const base = this._renderer.createElement('BASE');
    base.href = url;

    return base.href;
  }

  addIcon(name: string, url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.alreadyRendered(name)) {
        resolve();
        return;
      }

      // if not already cached, or already requested then fetch symbol
      if (!this._cache.has(name) && !this._inProgressReqs.has(name) && !!url) {
        this.subscriptions.add(
          this._getSymbol(url)
            .pipe(
              first(),
              map<SymbolDefs, SymbolDefs>(([_symbol, _def]) => {
                if (_symbol) {
                  _symbol.id = name;
                }
                return [_symbol, _def];
              })
            )
            .subscribe({
              next: ([_symbol, _def]) => this.addToGlobal(name, _symbol, _def),
              error: (err: unknown) => {
                if ((err as HttpErrorResponse)?.message === IN_PROGRESS) {
                  resolve();
                }

                /**
                 * If we tried to do a request whilst offline, don't alert to Sentry. As the SVG is
                 * not marked as "loaded", it can be re-requested later when the connection is
                 * re-established.
                 */
                if (!window.navigator.onLine) {
                  resolve();
                }

                reject(err);
              },
              complete: () => resolve(),
            })
        );
      } else {
        resolve();
      }
    });
  }

  private _getSymbol(url: string): Observable<SymbolDefs> {
    const absUrl = this.getAbsoluteUrl(url);

    // Return cached copy if it exists
    if (this._cache.has(absUrl)) {
      return of(this._cache.get(absUrl));
    }

    // Return existing fetch observable
    if (this._inProgressReqs.has(absUrl)) {
      return throwError(() => new Error(IN_PROGRESS));
    }

    // Otherwise, make the HTTP call to fetch
    const req$ = this._http.get(absUrl, { responseType: 'text' }).pipe(
      take(1),
      finalize(() => {
        this._inProgressReqs.delete(absUrl);
      }),
      map((svgText: string) => {
        const symbolAndDefs = this._svgSymbolElementFromString(svgText);
        this._cache.set(absUrl, symbolAndDefs);
        return symbolAndDefs;
      })
    );

    this._inProgressReqs.add(absUrl);

    return req$;
  }
}

import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { ToasterMessage } from '../../interfaces';

@Injectable()
export class ToasterService {
  public readonly toasterMessage$: Observable<ToasterMessage>;
  public readonly messageToRemove$: Observable<string>;
  private queue$: Subject<ToasterMessage> = new Subject();
  private removalQueue$: Subject<string> = new Subject();

  constructor() {
    this.toasterMessage$ = this.queue$.pipe(startWith(null as ToasterMessage));
    this.messageToRemove$ = this.removalQueue$.pipe(startWith(null as string));
  }

  addMessageToQueue(message: ToasterMessage): void {
    this.queue$.next(message);
  }

  removeMessage(id: string): void {
    this.removalQueue$.next(id);
  }
}

enum PersonalizationParameter {
  TIER = 'tier',
  GROUP = 'group',
  AKTIA_CREDIT_CARD = 'aktiavisacreditcard',
}

// Only used for core media preview mechanics
enum CmsPreviewPersonalisationParameter {
  P13N_TEST = 'p13n_test',
  P13N_TEST_CONTEXT = 'p13n_testcontext',
  PREVIEW_DATE = 'previewDate',
}
export const CmsPersonalizationParameter = { ...PersonalizationParameter, ...CmsPreviewPersonalisationParameter };
export type CmsPersonalizationParameter = typeof CmsPersonalizationParameter;

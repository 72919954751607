<div class="teaser-item light-type">
  <a
    class="teaser-link shadow-small interactions-tile fill flex no-underline rounded-large"
    (click)="emitClick()"
    [finLink]="content.url"
  >
    <div class="teaser-image">
      <fin-block-image
        *ngFor="let teaserImage of getPics(content.media)"
        [aspectRatio]="aspectRatio"
        [imageData]="teaserImage"
      ></fin-block-image>
    </div>
    <div class="fill white-bg ps-large teaser-text-content flex flex--column flex-1">
      <h3 class="teaser-title font-body-1 ms-clear-t nordic-blue-900-text" *ngIf="content.teaserTitle">
        {{ content.teaserTitle }}
      </h3>
      <div
        class="nordic-blue-900-text teaser-lead font-body-1"
        [innerHTML]="content.teaserText | finSafe: 'html'"
      ></div>
      <fin-date
        class="flex flex--end flex--align-end teaser-date ms-xsmall-t"
        [date]="content.externallyDisplayedDate"
      ></fin-date>
    </div>
  </a>
</div>

<div class="relative">
  <ng-container *ngIf="target$ | async as target; else imageContainer">
    <a [finLink]="target.url" [external]="target.external" class="no-underline">
      <fin-text-on-image-no-link
        [imageData$]="imageData$"
        [teaserTitle$]="teaserTitle$"
        [teaserText$]="teaserText$"
        [imageAspectRatio$]="imageAspectRatio$"
      ></fin-text-on-image-no-link>
    </a>
  </ng-container>
</div>

<ng-template #imageContainer>
  <fin-text-on-image-no-link
    [imageData$]="imageData$"
    [teaserTitle$]="teaserTitle$"
    [teaserText$]="teaserText$"
    [imageAspectRatio$]="imageAspectRatio$"
  ></fin-text-on-image-no-link>
</ng-template>

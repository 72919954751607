<button
  *ngIf="!href"
  (click)="onClick($event)"
  [type]="type"
  class="relative"
  [class]="theme"
  [class.btn-disabled]="appearDisabled"
  [disabled]="disabled"
  [attr.title]="title"
  [attr.aria-label]="aria.label"
  [attr.aria-describedby]="aria && aria.describedby"
  [attr.aria-controls]="aria && aria.controls"
  [attr.role]="'button'"
  [attr.aria-disabled]="aria && aria.disabled"
  [attr.aria-hidden]="aria && aria.hidden"
>
  <ng-container
    *ngTemplateOutlet="
      iconTemplate;
      context: {
        icon: icon,
        size: size,
        title: title,
      }
    "
  >
  </ng-container>
</button>

<a
  *ngIf="href"
  class="relative flex--inline"
  [finLink]="href"
  [external]="externalLink"
  [openInNewTab]="openInNewTab"
  [class]="theme"
  [class.btn-disabled]="appearDisabled"
  [attr.title]="title"
  [attr.aria-label]="aria.label"
  [attr.aria-describedby]="aria && aria.describedby"
  [attr.aria-controls]="aria && aria.controls"
  [attr.role]="'link'"
  [attr.aria-disabled]="aria && aria.disabled"
  [attr.aria-hidden]="aria && aria.hidden"
>
  <ng-container
    *ngTemplateOutlet="
      iconTemplate;
      context: {
        icon: icon,
        size: size,
        title: title,
      }
    "
  >
  </ng-container>
</a>

<ng-template #iconTemplate let-icon="icon" let-size="size" let-text="title">
  <span class="flex" [class]="size">
    <fcom-icon
      [category]="icon.category"
      [name]="icon.name"
      [ngClass]="{
        'icon-small': size === IconButtonSize.SMALL,
        'icon-medium': size === IconButtonSize.MEDIUM || size === IconButtonSize.LARGE,
      }"
    >
    </fcom-icon>
  </span>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

import { globalBookingFlights, globalBookingSelections } from '@fcom/common/store/selectors/global-booking.selectors';
import { AppConstants } from '@fcom/booking/app-constants';
import { BOOKING_STEPS } from '@fcom/common/config/booking-config';
import { BookingAndAppState } from '@fcom/common/interfaces/booking';
import { Step } from '@fcom/common/interfaces';
import { currentOfferSelection, currentPassengers } from '@fcom/booking/store/selectors/booking-cart-state.selector';
import { finShare } from '@fcom/rx';
import { TripType } from '@fcom/core/constants';
import { IconButtonTheme, IconButtonSize } from '@fcom/ui-components';

import {
  BookingSummaryBound,
  BookingSummaryPassenger,
  BookingSummaryService,
  SummaryTripDetails,
} from '../../interfaces';

@Component({
  selector: 'fin-booking-summary',
  styleUrls: ['./booking-summary.component.scss'],
  templateUrl: './booking-summary.component.html',
})
export class BookingSummaryComponent implements OnInit {
  readonly C: typeof AppConstants = AppConstants;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly IconLibrary = IconLibrary;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconButtonSize = IconButtonSize;

  @Output()
  close: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  edit: EventEmitter<Step> = new EventEmitter<Step>();

  @Input()
  currentStep$: Observable<Step>;

  @Input()
  unpaidServices$: Observable<BookingSummaryService[]>;

  @Input()
  onlyShowAncillaries = false;

  @Input()
  flowContext: string;

  @Input()
  hideChangeButton = false;

  STEPS: any = BOOKING_STEPS;

  tripDetails$: Observable<SummaryTripDetails>;
  travelers$: Observable<BookingSummaryPassenger[]>;
  isOneWay$: Observable<boolean>;
  currentOffer$: Observable<BookingSummaryBound[]>;
  hasUnpaidServices$: Observable<boolean>;
  ctaOpenDelayPassed$: Observable<boolean>;

  constructor(private store$: Store<BookingAndAppState>) {
    this.tripDetails$ = this.store$.pipe(globalBookingSelections()).pipe(
      map((common) => ({
        ...common,
        travelClass: common.travelClasses.selected,
      }))
    );

    this.currentOffer$ = combineLatest([
      this.store$.pipe(currentOfferSelection()),
      this.store$.pipe(globalBookingFlights()),
    ]).pipe(
      filter(([offers]) => offers && Boolean(offers.length)),
      map(([offers, flights]) => {
        return offers.map((offer, index) => {
          offer.departure.cityName = flights[index].origin.cityName;
          offer.arrival.cityName = flights[index].destination.cityName;

          return offer;
        });
      })
    );

    // Passenger details from cart
    this.travelers$ = this.store$.pipe(currentPassengers(), finShare());
    // Total
    this.ctaOpenDelayPassed$ = of(true).pipe(delay(350)); // Open (height) transition + 100ms
    this.isOneWay$ = this.tripDetails$.pipe(map((details) => details.tripType === TripType.ONEWAY));
  }

  ngOnInit(): void {
    this.hasUnpaidServices$ = this.unpaidServices$.pipe(map((services) => services.length > 0));
  }
}

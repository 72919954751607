export interface AriaOptions {
  label?: string;
  loadingLabel?: string;
  describedby?: string;
  details?: string;
  labelledby?: string;
  role?: 'button' | 'link' | 'searchbox' | 'tab';
  disabled?: boolean;
  controls?: string;
  selected?: boolean;
  activedescendant?: string;
  expanded?: boolean;
  hidden?: boolean;
}

export interface Attachment {
  error?: string;
  file: File;
  fileId?: string;
  status?: AttachmentStatus;
}

export enum AttachmentStatus {
  ERROR = 'ERROR',
  READY = 'READY',
  UPLOADING = 'UPLOADING',
}

export interface DatepickerTitleAreaOptions {
  titleAreaStyle?: string;
  title?: string;
  titleStyle?: string;
}

export interface FileUploaderI18n {
  attachFile: string;
  attachFiles: string;
  browseFiles: string;
  dragAndDrop: string;
  errors: {
    invalidFileType: string;
    invalidFileSize: string;
    required: string;
    requiredMultiple: string;
  };
  filesSupported: string;
  maxFileSize: string;
  megaBytes: string;
  or: string;
}

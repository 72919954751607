<fcom-modal [open]="modalOpen" [buttons]="null" [forceRemainOpen]="modalOpen">
  <form #f="ngForm" (ngSubmit)="sendConsents(f)" class="marketing-preferences pr-medium-x pr-large-y text">
    <div class="align-center">
      <fcom-icon
        class="nordic-blue-900-text flex--nogrow finnair-emblem-medium"
        [category]="IconLibrary.CUSTOM"
        [name]="CustomIcon.FINNAIR_EMBLEM"
      ></fcom-icon>
      <div class="marketing-preferences-intro font-body-1 pr-large-y" (click)="shouldCloseModal($event)">
        <cms-unstyled-fragment [contentUrl$]="marketingPreferencesUrl$"></cms-unstyled-fragment>
      </div>
    </div>

    <fieldset>
      <legend class="font-body-1 bold pr-xsmall-b" [innerHTML]="title$ | async | finSafe: 'html'"></legend>
      <ng-container *ngFor="let consent of consents$ | async">
        <input
          #box
          [ngModel]="consent.consentStatus === 'accepted'"
          type="checkbox"
          [name]="consent.consentTextId"
          [id]="consent.consentTextId"
        />
        <label class="pr-xsmall-b" [for]="consent.consentTextId">
          <span class="checkbox" role="presentation">
            <fcom-icon
              *ngIf="box.checked"
              class="icon-medium"
              [dataTestId]="SvgLibraryIcon.CHECKBOX_SELECTED"
              [name]="SvgLibraryIcon.CHECKBOX_SELECTED"
            ></fcom-icon>
            <fcom-icon
              *ngIf="!box.checked"
              class="icon-medium"
              [dataTestId]="SvgLibraryIcon.CHECKBOX_UNSELECTED"
              [name]="SvgLibraryIcon.CHECKBOX_UNSELECTED"
            ></fcom-icon>
          </span>
          <span class="content" [innerHTML]="consent.text | finSafe: 'html'"></span>
        </label>
      </ng-container>

      <p class="pr-xsmall-b">{{ 'marketingConsent.confirmSave.description' | finLocalization }}</p>

      <input #checkbox [ngModel]="false" required type="checkbox" name="confirm" id="confirm-save" />
      <label class="flex-1 pr-xsmall-b" for="confirm-save">
        <span class="checkbox" role="presentation">
          <fcom-icon *ngIf="checkbox.checked" [name]="SvgLibraryIcon.CHECKBOX_SELECTED" class="icon-medium"></fcom-icon>
          <fcom-icon
            *ngIf="!checkbox.checked"
            [name]="SvgLibraryIcon.CHECKBOX_UNSELECTED"
            class="icon-medium"
          ></fcom-icon>
        </span>
        <span class="content">{{ 'marketingConsent.confirmSave.label' | finLocalization }}</span>
      </label>
    </fieldset>

    <div class="pr-xsmall-t font-body-1 align-center">
      <button
        id="send-marketing-preferences"
        type="submit"
        class="btn btn--minwidth pr-large-x bold"
        [disabled]="f.form.invalid"
      >
        {{ 'marketingConsent.save' | finLocalization }}
      </button>
    </div>
  </form>
</fcom-modal>

<div
  id="{{ popoverOptions.popoverID }}"
  (click)="$event.stopPropagation()"
  class="popover-container border-rounded"
  [class.rounded-large]="roundedCorners"
  #popoverContent
  [ngStyle]="{
    top: (popoverPositionProperties$ | async).top,
    left: (popoverPositionProperties$ | async).left,
    zIndex: (popoverPositionProperties$ | async)?.['z-index'],
  }"
  [ngClass]="{
    fixed: isFixedPosition,
    absolute: !isFixedPosition,
    shadow: popoverOptions.shadow,
  }"
  [class.fullscreen]="isFullscreen$ | async"
  fcomTrapFocus
>
  <div
    *ngIf="showArrowCaret"
    class="arrow-container"
    [ngClass]="arrowPositionClass"
    [style.left.px]="arrowPosition$ | async"
  >
    <div class="arrow"></div>
  </div>
  <div
    class="white-bg grow fill shadow flex flex--column flex--wrap flex--align-start align-left"
    [class.rounded-large]="roundedCorners"
    [class.ps-medium]="hasPadding"
    [class.popover-border]="showLeftBorder"
    [ngStyle]="{
      width: (popoverPositionProperties$ | async).width,
    }"
    data-testid="popover-content-wrapper"
  >
    <div
      class="flex fill"
      [ngClass]="{
        'flex--align-start': !popoverOptions.fullWidthContent,
        'flex--align-end': popoverOptions.fullWidthContent,
        'flex--column-reverse': popoverOptions.fullWidthContent,
      }"
    >
      <div
        class="flex flex--column flex-1 fill"
        aria-live="polite"
        [ngStyle]="{
          maxHeight: (popoverPositionProperties$ | async)?.['max-height'],
        }"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      <button
        class="popover-close absolute nordic-blue-900-text"
        type="button"
        [attr.aria-label]="ariaCloseText"
        (click)="closePopover()"
        #closeButton
        *ngIf="popoverOptions.showCloseButton"
        data-testid="popover-close-button"
      >
        <fcom-icon class="icon-small" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
      </button>
    </div>

    <button type="button" class="font-body-1 mr-xxsmall-t" (click)="closePopover()" *ngIf="closeText">
      {{ closeText }}
    </button>
  </div>
</div>

/* eslint-disable */
export enum Category {
  BAGGAGE = 'baggage',
  CABIN_BAGGAGE = 'cabinBaggage',
  MEAL = 'meal',
  SEAT = 'seat',
  WIFI = 'wifi',
  LOUNGE = 'lounge',
  TRAVEL_COMFORT = 'travelComfort',
  COVER = 'cover',
  OTHER = 'other',
  PET = 'pet',
  SPORT = 'sport',
  SPECIAL = 'special',
  FIREARM = 'firearm',
  MEDIC = 'medic',
  SPECIAL_NEED = 'specialNeed',
  CHILD = 'child',
  PRIORITY = 'priority'
}


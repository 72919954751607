<div class="image-wrapper relative flex" *ngIf="image$ | async as imageData">
  <fcom-image [ratio]="aspectRatio$ | async" [scale]="false" [imageData]="imageData" [fillParent]="true"></fcom-image>
</div>
<div class="pr-small flex flex-1 flex--column fill" [attr.data-testid]="'banner-' + translationPrefix">
  <div class="flex flex-1 flex--column">
    <h2 class="font-heading-4 nordic-blue-900-text ms-xsmall-b">
      {{ translationPrefix + '.title' | finLocalization }}
    </h2>
    <p>
      {{ translationPrefix + '.text' | finLocalization }}
    </p>
  </div>
  <div class="flex flex--end">
    <fcom-button
      [href]="url"
      [externalLink]="true"
      [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
      class="ms-medium-t pr-small-l"
      [text]="translationPrefix + '.button' | finLocalization"
      [icon]="buttonIcon"
      (clickBtn)="trackPromoBanner(translationPrefix)"
    ></fcom-button>
  </div>
</div>

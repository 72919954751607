import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

import { BadgeVariant } from './enums';

/**
 * This component can be used under any element to attach a badge to it.
 *
 * In order for the fcom-badge to be displayed correctly, it is mandatory that his host has a relative position.
 *
 * @example
 * <b>Example</b>
 *
 * <code>
 *   <pre>
 *     <div class="border mr-medium ps-small relative">
 *       <fcom-badge [variant]="BadgeVariant.BUMP_OUT_HEATHER">Badge Label</fcom-badge>
 *       Some content
 *     </div>
 *   </pre>
 * </code>
 */
@Component({
  selector: 'fcom-badge',
  template: `
    <div
      class="fcom-badge font-body-2 medium-type white-text ps-xxsmall-y relative {{ variant }}"
      [class.ps-xsmall-x]="variant !== BadgeVariant.ROUNDED_TOP_RIGHT"
      [class.ps-medium-x]="variant === BadgeVariant.ROUNDED_TOP_RIGHT"
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  readonly BadgeVariant = BadgeVariant;

  @HostBinding('class') get class(): string {
    return this.variant;
  }

  /**
   * Variant to use for rendering
   */
  @Input()
  variant: BadgeVariant = BadgeVariant.BUMP_OUT_HEATHER;
}

import { Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { BookingLocationCodes } from '@fcom/common/interfaces/booking';
import { TripType } from '@fcom/core/constants';

@Component({
  selector: 'fin-trip-location-info',
  templateUrl: './trip-location-info.component.html',
})
export class TripLocationInfoComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;

  @Input() tripType: TripType;
  @Input() locations: BookingLocationCodes[];
}

import { Injectable } from '@angular/core';

import { Observable, map, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';

import { LoginCurrentPeriod, Profile, ProfileTier } from '@fcom/core-api/login';
import { profile } from '@fcom/core/selectors';
import { finShare } from '@fcom/rx';
import { AppState, ConfigService } from '@fcom/core';

import {
  AVIOS_LIFETIME_TIER_LIMITS,
  AVIOS_TIER_LIMITS,
  LIFETIME_TIER_LIMITS,
  LIFETIME_TIER_TEXT,
  TIER_DATA_MAP,
  TIER_FLIGHT_LIMITS,
  TIER_LIMITS,
  TierName,
  TierState,
} from '../../interfaces';

export type MemberTierDetails = {
  tier: ProfileTier;
  tierName: string;
  tierState: TierState;
  isJuniorMember: boolean;
  isStartingTier: boolean;
  isGainingMaintainingOrHighestLumo: boolean;
  targetTier: ProfileTier;
  targetTierName: string;
  cardTier: ProfileTier;
  cardLifetimeTierFlag: boolean;
  cardTierName: string;
  /** Including Lifetime if it is. */
  cardTierFullName: string;
  lifetimeTier: ProfileTier.GOLD | ProfileTier.PLATINUM;
  lifetimeTierFlag: boolean;
  lifetimeTargetTier: ProfileTier.PLATINUM | ProfileTier.GOLD;

  trackingPeriodStart?: string;
  trackingPeriodEnd?: string;
  cardExpirationDate?: string;

  targetTierLimit: number;
  tierPointsCollected: number;
  aySecretTierPointsRequired: number;
  aySecretTierPoints: number;
  qualifyingAYSecretTierPoints: number;
  partnerTierPointsCollected: number;
  qualifyingPartnerTierPointsLimit: number;
  qualifyingPartnerTierPoints: number;
  /** Tier points that count towards progression. */
  qualifyingTierPoints: number;

  targetTierFlightLimit: number;
  qualifyingFlightsFlown: number;
  aySecretNumQFlightRequired: number;
  aySecretNumQFlightCalc: number;
  partnerFlightsFlown: number;
  qualifyingPartnerFlightsLimit: number;
  qualifyingPartnerFlights: number;
  /** Number of flights that count towards progression. */
  qualifyingFlights: number;

  tierProgress: number;

  lifetimeTargetTierLimit: number;
  lifetimeTierPoints: number;
  qualifyingLifetimeTierPoints: number;
  lifetimeProgress: number;
};

export type TierLimitsData = {
  tierLimits: typeof TIER_LIMITS | typeof AVIOS_TIER_LIMITS;
  lifetimeTierLimits: typeof LIFETIME_TIER_LIMITS | typeof AVIOS_LIFETIME_TIER_LIMITS;
};

@Injectable()
export class LoyaltyTierService {
  enableLoyaltyOverhaulSwitchFromQualifyingFlightsTrackingToTierPoints = false;
  private enableLoyaltyAvios = false;

  private profile$: Observable<Profile>;

  constructor(
    private store$: Store<AppState>,
    private configService: ConfigService
  ) {
    this.enableLoyaltyOverhaulSwitchFromQualifyingFlightsTrackingToTierPoints =
      this.configService.cfg.enableLoyaltyOverhaul.tierProgress.switchFromQualifyingFlightsTrackingToTierPoints;
    this.enableLoyaltyAvios = this.configService.cfg.enableLoyaltyAvios.tierProgress;

    this.profile$ = this.store$.pipe(profile(), finShare());
  }

  public getMemberTierDetails(): Observable<MemberTierDetails> {
    return this.getTierLimitsData().pipe(
      withLatestFrom(this.profile$),
      map(
        ([
          { tierLimits, lifetimeTierLimits },
          {
            cardTier,
            tier,
            isJuniorMember,
            nextTier,
            currentPeriod,
            lifetimeTier,
            lifetimeTierFlag,
            lifetimeTierPoints,
            cardExpirationDate,
          },
        ]) => {
          const tierState = this.getTierState(tierLimits, tier, currentPeriod);
          const isStartingTier = this.isStartingTier(tier);
          const isGainingMaintainingOrHighestLumo =
            (tier === ProfileTier.PLATINUM && tierState === TierState.GAIN) ||
            (tier === ProfileTier.LUMO && (tierState === TierState.MAINTAIN || tierState === TierState.HIGHEST));

          const targetTier = tierState === TierState.GAIN && nextTier?.nextTier ? nextTier.nextTier : tier;

          const cardLifetimeTierFlag = cardTier === lifetimeTier;
          const cardTierName = TIER_DATA_MAP[cardTier].tierName;
          const cardTierFullName = this.getCardTierFullName(
            cardTier,
            cardTierName,
            cardLifetimeTierFlag,
            isJuniorMember
          );

          const lifetimeTargetTier =
            lifetimeTier === ProfileTier.PLATINUM || lifetimeTier === ProfileTier.GOLD
              ? ProfileTier.PLATINUM
              : ProfileTier.GOLD;

          const targetTierLimit = tierLimits[targetTier];
          const tierPointsCollected = currentPeriod?.tierPointsCollected ?? 0;
          const aySecretTierPointsRequired = currentPeriod?.aySecretTierPointsRequired ?? 0;
          const aySecretTierPoints = currentPeriod?.aySecretTierPoints ?? 0;
          const qualifyingAYSecretTierPoints = Math.min(aySecretTierPoints, aySecretTierPointsRequired);

          const partnerTierPointsCollected = tierPointsCollected - aySecretTierPoints;
          const qualifyingPartnerTierPointsLimit = targetTierLimit - aySecretTierPointsRequired;
          const qualifyingPartnerTierPoints = Math.min(partnerTierPointsCollected, qualifyingPartnerTierPointsLimit);

          const qualifyingTierPoints = Math.min(
            isGainingMaintainingOrHighestLumo ? qualifyingPartnerTierPoints + aySecretTierPoints : tierPointsCollected,
            targetTierLimit
          );

          const targetTierFlightLimit = TIER_FLIGHT_LIMITS[targetTier];
          const qualifyingFlightsFlown = currentPeriod?.qualifyingFlightsFlown ?? 0;
          const aySecretNumQFlightRequired = currentPeriod?.aySecretNumQFlightRequired ?? 0;
          const aySecretNumQFlightCalc = currentPeriod?.aySecretNumQFlightCalc ?? 0;

          const partnerFlightsFlown = qualifyingFlightsFlown - aySecretNumQFlightCalc;
          const qualifyingPartnerFlightsLimit = targetTierFlightLimit - aySecretNumQFlightRequired;
          const qualifyingPartnerFlights = Math.min(partnerFlightsFlown, qualifyingPartnerFlightsLimit);

          const qualifyingFlights = Math.min(
            isGainingMaintainingOrHighestLumo
              ? qualifyingPartnerFlights + aySecretNumQFlightCalc
              : qualifyingFlightsFlown,
            targetTierFlightLimit
          );

          const tierProgress = Math.floor((qualifyingTierPoints / targetTierLimit) * 100);

          const lifetimeTargetTierLimit = lifetimeTierLimits[lifetimeTargetTier];
          lifetimeTierPoints = lifetimeTierPoints ?? 0;
          const qualifyingLifetimeTierPoints = Math.min(lifetimeTierPoints, lifetimeTargetTierLimit);
          const lifetimeProgress = Math.floor((qualifyingLifetimeTierPoints / lifetimeTargetTierLimit) * 100);

          return {
            tier,
            tierName: TIER_DATA_MAP[tier].tierName,
            tierState,
            isJuniorMember,
            isStartingTier,
            isGainingMaintainingOrHighestLumo,
            targetTier,
            targetTierName: TIER_DATA_MAP[targetTier].tierName,
            cardTier,
            cardLifetimeTierFlag,
            cardTierName,
            cardTierFullName,
            lifetimeTier,
            lifetimeTierFlag,
            lifetimeTargetTier,

            trackingPeriodStart: currentPeriod?.trackingPeriodStart,
            trackingPeriodEnd: currentPeriod?.trackingPeriodEnd,
            cardExpirationDate,

            targetTierLimit,
            tierPointsCollected,
            aySecretTierPointsRequired,
            aySecretTierPoints,
            qualifyingAYSecretTierPoints,
            partnerTierPointsCollected,
            qualifyingPartnerTierPointsLimit,
            qualifyingPartnerTierPoints,
            qualifyingTierPoints,

            targetTierFlightLimit,
            qualifyingFlightsFlown,
            aySecretNumQFlightRequired,
            aySecretNumQFlightCalc,
            partnerFlightsFlown,
            qualifyingPartnerFlightsLimit,
            qualifyingPartnerFlights,
            qualifyingFlights,

            tierProgress,

            lifetimeTargetTierLimit,
            lifetimeTierPoints,
            qualifyingLifetimeTierPoints,
            lifetimeProgress,
          };
        }
      )
    );
  }

  public getTierLimitsData(): Observable<TierLimitsData> {
    return of({
      tierLimits: this.enableLoyaltyAvios ? AVIOS_TIER_LIMITS : TIER_LIMITS,
      lifetimeTierLimits: this.enableLoyaltyAvios ? AVIOS_LIFETIME_TIER_LIMITS : LIFETIME_TIER_LIMITS,
    });
  }

  private getTierState(
    tierLimits: typeof TIER_LIMITS | typeof AVIOS_TIER_LIMITS,
    tier: ProfileTier,
    currentPeriod: LoginCurrentPeriod
  ): TierState {
    if (this.isStartingTier(tier)) {
      return TierState.GAIN;
    } else if (tier === ProfileTier.LUMO) {
      if (
        (currentPeriod.tierPointsCollected >= tierLimits[tier] &&
          currentPeriod.aySecretTierPoints >= currentPeriod.aySecretTierPointsRequired) ||
        (!this.enableLoyaltyOverhaulSwitchFromQualifyingFlightsTrackingToTierPoints &&
          currentPeriod.qualifyingFlightsFlown >= TIER_FLIGHT_LIMITS[tier] &&
          currentPeriod.aySecretNumQFlightCalc >= currentPeriod.aySecretNumQFlightRequired)
      ) {
        return TierState.HIGHEST;
      }
      return TierState.MAINTAIN;
    } else if (
      (!this.enableLoyaltyOverhaulSwitchFromQualifyingFlightsTrackingToTierPoints &&
        tierLimits[tier] > currentPeriod.tierPointsCollected &&
        TIER_FLIGHT_LIMITS[tier] > currentPeriod.qualifyingFlightsFlown) ||
      (this.enableLoyaltyOverhaulSwitchFromQualifyingFlightsTrackingToTierPoints &&
        tierLimits[tier] > currentPeriod.tierPointsCollected)
    ) {
      return TierState.MAINTAIN;
    } else {
      return TierState.GAIN;
    }
  }

  private isStartingTier(profileTier: ProfileTier): boolean {
    return [ProfileTier.JUNIOR, ProfileTier.BASIC].includes(profileTier);
  }

  private getCardTierFullName(
    cardTier: ProfileTier,
    cardTierName: TierName,
    cardLifetimeTierFlag: boolean,
    isJuniorMember: boolean
  ): string {
    const fullName = cardLifetimeTierFlag && !isJuniorMember ? `${LIFETIME_TIER_TEXT} ${cardTierName}` : cardTierName;

    if (isJuniorMember && cardTier !== ProfileTier.JUNIOR) {
      return `${TierName.JUNIOR} ${fullName}`;
    }

    return fullName;
  }
}

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { CmsImageData } from '@fcom/core-api/interfaces';

import { Amount } from './dapi.interface';
import {
  Category,
  FinnairAmount,
  FinnairBoundItem,
  FinnairCodeAndName,
  FinnairPassengerCode,
  FinnairPassengerServiceItem,
  FinnairPassengerServiceSelectionItem,
  FinnairServiceCatalogCampaign,
  FinnairServiceItem,
  FinnairServiceSegmentItem,
  FinnairServiceVariantItemV3,
  PaxLevelQuota,
  SubCategory,
} from '../api/models';

export interface ServiceAvailabilityPerFragment {
  [fragmentId: string]: boolean;
}

export enum ServicesStatus {
  /**
   * No request for services has been placed.
   */
  INITIAL,

  /**
   * A request for fetching the services is ongoing.
   */
  PENDING,

  /**
   * The request for the services failed.
   */
  LOAD_ERROR,

  /**
   * The services request passed succesfully and should be in the state.
   */
  OK,
}

export interface ServiceAvailability {
  [Category.CABIN_BAGGAGE]: ServiceAvailabilityPerFragment;
  [Category.BAGGAGE]: ServiceAvailabilityPerFragment;
  [Category.MEAL]: ServiceAvailabilityPerFragment;
  [Category.WIFI]: ServiceAvailabilityPerFragment;
  [Category.LOUNGE]: ServiceAvailabilityPerFragment;
  [Category.COVER]: ServiceAvailabilityPerFragment;
  [Category.SPORT]: ServiceAvailabilityPerFragment;
  [Category.PET]: ServiceAvailabilityPerFragment;
}

export interface BaseService {
  /**
   * The semantic identifier of the service.
   * <p/>
   * Combined with the type of the service (baggage/meals), this uniquely identifies the type of the service.
   * Can be used for detecting whether a service is the same for multiple bounds or flights.
   * <p/>
   * Can be used as a localization key in combination with the service type.
   */
  variant: string;

  /**
   * The top-level category of the service
   */
  category: Category;
}

export interface CartService extends BaseService {
  /**
   * Category name
   */
  subCategory: string;

  /**
   * The identifier of the cart's traveler to whom this service is assigned to.
   */
  travelerId: string;

  /**
   * The desired services count, e.g., amount of extra bags.
   */
  quantity: number;

  /**
   * The price for a single item. Total price can be calculated from quantity * price.
   */
  price?: Amount;

  /**
   * Flight ids this service is attached to
   */
  flightIds: string[];
}

export interface ServiceCatalogTranslations {
  title: string;
  description?: string;

  /** Ingredients if meal service */
  ingredients?: string;
  /** HTML content describing the nutrition facts for meal service. Contains title. */
  nutrition?: string;
  /** meal categories, e.g. low-lactose. These need to be translated. */
  itemCategory?: string[];

  /** Specific to cancelation cover */
  detailText?: string;
  disclaimerText?: string;
  actionText?: string;
}

export interface ServiceCatalogService extends Omit<FinnairServiceVariantItemV3, 'translations' | 'media'> {
  /**
   * The category of the service
   */
  category: Category;

  /**
   * The child-level category of the service
   */
  subCategory: SubCategory;

  /**
   * Translations for category coming from cms
   */
  translations: ServiceCatalogTranslations;

  /**
   * Picture for category coming from cms
   */
  media?: CmsImageData;

  /**
   * If discount is given then this is the original price if we know it
   */
  originalPrice?: Amount;

  isPaid?: boolean;
  isTicketed?: boolean;
  isModifiable?: boolean;
}

export interface ServicesPerTraveler {
  [travelerId: string]: ServiceCatalogService[];
}

export interface ServiceCatalogServices {
  // fragmentId can be for journey, bound or flight
  [fragmentId: string]: ServicesPerTraveler;
}

export interface SelectionsPerTraveler<T> {
  [travelerId: string]: T;
}

export interface ServiceCatalogSelections<T> {
  // fragmentId can be for journey, bound or flight
  [fragmentId: string]: SelectionsPerTraveler<T>;
}

export interface ServiceCatalogSelectionsForAllTravelers {
  [fragmentId: string]: TravelerService[];
}

export interface ServiceCatalogCategoryServicesMap {
  [category: string]: ServiceCatalogServices;
}

export interface ServiceCatalogCategory {
  category: Category;
  services: ServiceCatalogServices;
  translations: ServiceCatalogTranslations;
  media?: CmsImageData;
}

export interface ServiceCatalog {
  messages: string[];
  categories: ServiceCatalogCategory[];
  campaigns?: FinnairServiceCatalogCampaign[];
  paxLevelQuota: PaxLevelQuota;
}

export interface ServiceSelections {
  [Category.BAGGAGE]?: ServiceCatalogSelections<TravelerService[]>;
  [Category.WIFI]?: ServiceCatalogSelections<TravelerService>;
  [Category.LOUNGE]?: ServiceCatalogSelections<TravelerService[]>;
  [Category.COVER]?: ServiceCatalogSelections<TravelerService[]>;
  [Category.MEAL]?: ServiceCatalogSelections<TravelerService>;
  [Category.PET]?: ServiceCatalogSelections<TravelerService>;
  [Category.SPORT]?: ServiceCatalogSelections<TravelerService>;
}

export enum CustomServiceType {
  JOURNEY = 'JOURNEY',
  GROUP = 'GROUP',
}

export interface TravelerService {
  category: SubCategory;

  /**
   * Identifies the type of the service in combination with service category.
   * @see BaseService#variant
   */
  variant: string;
  quantity: number;
  /**
   * If discount is given this is the original price
   */
  originalPricePerItem?: Amount;
  pricePerItem: Amount;
  includedInTicketType: boolean;
  includedInTierBenefit: boolean;

  displayName: string;
  paid?: boolean;
  ticketed?: boolean;
  modifiable?: boolean;
  travelerId?: string;

  /**
   * Extra fields for translations
   */
  title?: string;
  seatNumber?: string;
  maxBaggageWeight?: string;
}

export interface ServicesState {
  status: ServicesStatus;
  services?: ServiceCatalog;
  selections?: ServiceSelections;
  upsell?: SelectedServiceData;
  campaignsShown: string[];
  serviceAvailability?: ServiceAvailability;
}

export interface ServicePassenger extends FinnairPassengerServiceItem {
  withInfant: boolean;
  withInfantFullName: string;
}

export interface Upsell {
  enabled: boolean;
  articleKey: string;
  expand: boolean;
  lowestPrice: FinnairAmount | null;
  isShowMoreBtn: boolean;
  updatingService$: Observable<boolean>;
  hasPlusTierBenefit: boolean;
}

export interface BoundWithServices extends FinnairBoundItem {
  services: FinnairServiceItemWithUpsell[];
}

export interface FinnairServiceItemWithUpsell extends FinnairServiceItem {
  upsell?: Upsell;
  complimentaryService?: FinnairPassengerServiceSelectionItem[];
  notIncludedServices?: boolean;
}

export interface UpsellCategoriesForBound {
  [boundId: string]: {
    [category: string]: boolean;
  };
}

export interface SelectedServiceData {
  category: Category;
  index?: number;
  fragmentId?: string;
  paxId?: string;
}

export enum ServiceStatus {
  CONFIRMED = 'confirmed',
  PENDING_PAYMENT = 'pendingPayment',
  AVAILABLE_FOR_SELL = 'availableForSell',
}

export interface GroupedServices {
  key: 'confirmed' | 'pendingPayment' | 'availableForSell';
  icon?: SvgLibraryIcon.SHOPPING_CART | SvgLibraryIcon.CHECKMARK;
  services: FinnairPassengerServiceSelectionItem[];
  tierLevel?: string;
}

interface ServicesForPassenger extends FinnairPassengerServiceItem {
  groupedServices?: GroupedServices[];
  passengerTypeCode?: FinnairPassengerCode;
}

export interface FlightServices extends Omit<FinnairServiceSegmentItem, 'passengers'> {
  operatingAirline?: FinnairCodeAndName;
  passengers?: ServicesForPassenger[];
  complimentaryServices: FinnairPassengerServiceSelectionItem[];
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { catchError, map, Observable, Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';

import { LanguageService } from '@fcom/ui-translate';
import { FragmentService, GtmEvent, SelectOption } from '@fcom/common';
import { AppState, ConfigService, CountryLanguage, CountryState, LATIN_ALPHA_PATTERN } from '@fcom/core';
import { EMAIL_PATTERN, unsubscribe } from '@fcom/core/utils';
import { shownConfs } from '@fcom/core/reducers/langs';
import { ButtonTheme, LoaderTheme, NotificationTheme } from '@fcom/ui-components';
import { FormStatus } from '@fcom/service-forms';
import { profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';
import { CmsArticle } from '@fcom/core-api';
import { GtmService } from '@fcom/common/gtm';
import { atLeastOneRequired } from '@fcom/service-forms-lazy/utils';

import { NewsletterSignupService } from '../../../services/newsletter-signup/newsletter-signup.service';
import { NewsletterSignupPayload, NewsLetterSource } from '../../../interfaces/newsletter-signup.interface';

@Component({
  selector: 'fin-newsletter-signup-form',
  templateUrl: './newsletter-signup-form.component.html',
  styleUrls: ['./newsletter-signup-form.component.scss'],
})
export class NewsletterSignupFormComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly FormStatus = FormStatus;
  readonly LoaderTheme = LoaderTheme;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  reactiveForm: UntypedFormGroup;
  countries: SelectOption[];
  languages: SelectOption[];
  formStatus: FormStatus = FormStatus.INITIAL;
  isLoggedInUser$: Observable<boolean>;
  errorArticle$: Observable<CmsArticle>;
  enableNewsletterSignUpForm = false;
  enableNewNewsletterConsents = false;

  private countryLanguageList: CountryState[];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private newsletterService: NewsletterSignupService,
    private fragmentService: FragmentService,
    private configService: ConfigService,
    private store$: Store<AppState>,
    private languageService: LanguageService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.countryLanguageList = shownConfs
      .filter((conf) => conf.code !== 'INT')
      .map((country) => ({
        ...country,
        languages: country.languages.map(
          (language): CountryLanguage => ({
            name: language.name,
            code: language.code.split('-').pop(),
          })
        ),
      }));

    this.isLoggedInUser$ = this.store$.pipe(
      profileOrUndefinedIfNotLoggedIn(),
      map((profile) => !!profile)
    );

    this.errorArticle$ = this.fragmentService
      .getFragment<CmsArticle>('fragments.newsletterSignUpForm.url')
      .pipe(take(1));

    this.enableNewsletterSignUpForm = this.configService.cfg.enableNewsletterSignUpForm;
    this.enableNewNewsletterConsents = this.configService.cfg.enableNewNewsletterConsents;

    this.countries = this.createSelectOption(this.countryLanguageList);
    this.createForm();

    this.subscriptions.add(
      this.reactiveForm.get('country').valueChanges.subscribe((val) => {
        if (val) {
          this.reactiveForm.get('language').enable();
        } else {
          this.reactiveForm.get('language').disable();
        }
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  onSubmit(): void {
    if (!this.reactiveForm.valid) {
      return;
    }

    this.formStatus = FormStatus.LOADING;
    const countryCode = this.reactiveForm.get('country').value;
    const countryName = this.countries.find((country) => country.value === countryCode)?.name;
    const singleLanguage = this.countryLanguageList.find((item) => item.code === countryCode).languages[0]?.code;
    const languageCode = ((this.reactiveForm.get('language').value as string) || singleLanguage || '').toUpperCase();
    const languageName = this.countryLanguageList
      .find((item) => item.code === countryCode)
      .languages.find((language) => language.code === languageCode.toLowerCase()).name;

    const payload: NewsletterSignupPayload = {
      firstName: this.reactiveForm.get('firstName').value,
      lastName: this.reactiveForm.get('lastName').value,
      email: this.reactiveForm.get('email').value,
      country: countryCode,
      language: languageCode,
      pageUrl: this.router.url,
    };

    if (this.enableNewNewsletterConsents) {
      payload.marketingNewsletter = this.reactiveForm.get('marketingNewsletter').value;
      payload.advertising = this.reactiveForm.get('advertising').value;
      payload.source = NewsLetterSource.NEWSLETTER_FORM;
    }

    this.subscriptions.add(
      this.newsletterService
        .postSubscribeRequest(payload)
        .pipe(
          take(1),
          catchError((err: unknown) => {
            this.formStatus = FormStatus.INITIALIZING_ERROR;
            throw err;
          })
        )
        .subscribe((result) => {
          if (result?.message) {
            this.formStatus = FormStatus.COMPLETE;

            this.gtmService.pushEventToDataLayer(GtmEvent.NEWSLETTER_FORM_SUBMITTED, {
              selected_country: countryName,
              selected_language: languageName,
            });
          } else {
            this.formStatus = FormStatus.INITIALIZING_ERROR;
          }
        })
    );
  }

  countryChanged(countryCode: string): void {
    this.languages = this.createSelectOption(
      this.countryLanguageList.find((item) => item.code === countryCode).languages
    );

    if (this.languages.length === 1) {
      this.reactiveForm.controls['language'].reset();
      this.reactiveForm.controls['language'].clearValidators();
    } else {
      this.reactiveForm.controls['language'].setValidators([Validators.required]);
    }
    this.reactiveForm.controls['language'].updateValueAndValidity();
  }

  private createForm = (): void => {
    const [language, country] = (this.languageService.localeValue || '_').split('_');

    this.reactiveForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(LATIN_ALPHA_PATTERN), Validators.maxLength(20)]],
      lastName: ['', [Validators.required, Validators.pattern(LATIN_ALPHA_PATTERN), Validators.maxLength(30)]],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      country: [country, Validators.required],
      language: [{ value: language, disabled: !country }, Validators.required],
      marketingNewsletter: [false],
      advertising: [false],
    });

    if (this.enableNewNewsletterConsents) {
      this.reactiveForm.addValidators(atLeastOneRequired(['marketingNewsletter', 'advertising']));
    }

    if (country) {
      this.countryChanged(country);
    }
  };

  private createSelectOption = (object: CountryLanguage[]): SelectOption[] =>
    object.map((item: CountryLanguage) => ({ value: item.code, name: item.name }) as SelectOption);
}

import { DOCUMENT } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Renderer2,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { SIDEBAR_ANIMATION_DELAY } from './constants';

/**
 * The sidebar component contains only the design of the sidebar and the open/close mechanism.
 *
 * The content is displayed thanks to the `ng-content` tags. The `open` input is using angular 2
 * way data binding and you should set a boolean property (such as `sidebarOpen` in the example
 * below) in the parent component to control open and close behavior.
 *
 * By default, the z-index is the same as the one used for the modal.
 *
 * The sidebar will be displayed on the left of the page and will be automatically closed after
 * clicking on the * overlay thanks to the `fcomFocusOut` directive.
 *
 * @example
 * <button (click)="sidebarOpen = true">Open sideBar</button>
 * <fcom-sidebar [(open)]="sidebarOpen">
 *   <header>
 *     <h2 class="font-heading-3 flex-1 truncate overflow-hidden nordic-blue-900-text">
 *       TRIP
 *     </h2>
 *     <button type="button" (click)="sidebarOpen = false">
 *       <fcom-icon class="icon-small" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
 *     </button>
 *   </header>
 * </fcom-sidebar>
 */
@Component({
  selector: 'fcom-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('sliderInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate(SIDEBAR_ANIMATION_DELAY)]),
      transition('* => void', [animate(SIDEBAR_ANIMATION_DELAY, style({ transform: 'translateX(100%)' }))]),
    ]),
    trigger('overlayInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      state(
        '*',
        style({
          opacity: 1,
        })
      ),
      transition('void => *', [animate(SIDEBAR_ANIMATION_DELAY)]),
      transition('* => void', [animate(SIDEBAR_ANIMATION_DELAY)]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarComponent {
  public readonly sidebarId = uuid();
  public readonly sidebarTitleId = `sidebar-${this.sidebarId}-title`;
  public readonly sidebarDescriptionId = `sidebar-${this.sidebarId}-description`;
  public readonly SvgLibraryIcon = SvgLibraryIcon;

  @ViewChild('sideBarHeading')
  sideBarHeading: ElementRef;

  private _openState = false;

  @Input()
  set open(_open: boolean) {
    this._openState = _open;

    if (_open) {
      this.onOpen();
    } else {
      this.onClose();
    }

    this.openChange.emit(this._openState);
  }

  get open(): boolean {
    return this._openState;
  }

  /**
   * The title to use in the header of the sidebar.
   */
  @Input()
  title: string;

  /**
   * The aria tag for the close icon button.
   */
  @Input()
  ariaClose = 'Close';

  /**
   * Emits an event when the sidebar is closed
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  close: EventEmitter<void> = new EventEmitter();

  @Output()
  openChange = new EventEmitter<boolean>();

  showSideBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly renderer: Renderer2
  ) {}

  onOpen(): void {
    this.showSideBar$.next(true);
    this.renderer.addClass(this.document.body, 'modal-open');
    this.changeDetectorRef.detectChanges();
    this.sideBarHeading.nativeElement.focus();
  }

  onClose(): void {
    this.showSideBar$.next(false);
    this.close.emit();
    this.renderer.removeClass(this.document.body, 'modal-open');
    this.changeDetectorRef.detectChanges();
  }
}

import { CmsImageData } from '@fcom/core-api';
import { LocalDate } from '@fcom/core/utils';
import { Amount } from '@fcom/dapi';

/**
 * Microservice response, info part
 */
export interface DestinationSearchResponseInfo {
  totalCount: number;
  locale: string;
}

/**
 * Microservice response, a single record
 */
export interface DestinationSearchResponseItem {
  title: string;
  titleMaster: string; // From master-en (en_001)
  country?: string; // Empty for countries
  countryMaster?: string; // From master-en (en_001). Empty for countries
  countryCode?: string;
  locationCode?: string;
  continent: string;
  continentMaster: string; // From master-en (en_001)
  flightType: string;
  tags: string[] | Set<string>;
  url: string;
  rank: number;
  type: DestinationSearchItemType;
  picture?: CmsImageData;
  items?: DestinationSearchResponseItem[];
  lastModified?: string;
}

/**
 * Microservice response, combines DestinationSearchResponseItem and DestinationSearchResponseInfo
 */
export interface DestinationSearchResponseData {
  items: DestinationSearchResponseItem[];
  info: DestinationSearchResponseInfo;
}

/**
 * AurinkoMatkat destination response
 */

export interface AmDestinationOfferBase {
  dest: string;
  bookUrl: string;
  duration: number;
}

export interface AmDestinationOffer extends AmDestinationOfferBase {
  price: Amount;
}

export interface AmDestinationResponseItem extends AmDestinationOfferBase {
  price: number;
  destName_fi: string;
}

/**
 * Mapped from DestinationSearchResponseItem
 * Search item is client's expanded version of response
 */
export interface DestinationSearchItem extends DestinationSearchResponseItem {
  tags: Set<string>;
  matchesQuery?: boolean;
  id: string;
  items?: DestinationSearchItem[];
  normalizedTitle: string;
  normalizedTitleMaster: string;
  price?: number;
  currency?: string;
  origin?: string;
  count?: number;
}

export interface DestinationSearchItemFilterSelection {
  destination: DestinationSearchItem;
  action: FilterAction;
  selectedDestinations: DestinationSearchItem[];
}

export enum FilterAction {
  ADD = 'add',
  REMOVE = 'remove',
  REMOVE_ALL = 'remove_all',
  CUSTOM = 'custom',
}

/**
 * Mapped from DestinationSearchResponseData
 */
export interface DestinationSearchResult {
  items: DestinationSearchItem[];
  info: DestinationSearchResponseInfo;
}

export enum DestinationSearchViewStyle {
  TILES = 'TILES',
  LIST = 'LIST',
}

export enum DestinationSearchItemType {
  DESTINATION = 'DESTINATION',
  COUNTRY = 'COUNTRY',
  CONTINENT = 'CONTINENT',
  DESTINATIONSBYORIGIN = 'DESTINATIONSBYORIGIN',
}

export interface DestinationListItem {
  titleMaster: string;
  listPosition: number;
}

/**
 * Object received by sorting dropdown component etc.
 */
export interface SortOption {
  value: string;
  label?: string;
}

/**
 * DestinationSearch query params
 */
export enum DestinationSearchQueryParams {
  country = 'country',
  continent = 'continent',
  destination = 'destination',
  type = 'type',
  cabin = 'cabin',
  origin = 'origin',
}

export interface DestinationSearchTripDates {
  departureDate: LocalDate | undefined;
  returnDate: LocalDate | undefined;
}

export enum PriceLoadingState {
  INITIAL,
  LOADING,
  LOADED,
}

export type DestinationSearchQueryParamNames = {
  [DestinationSearchQueryParams.destination]?: string[];
  [DestinationSearchQueryParams.continent]?: string[];
  [DestinationSearchQueryParams.country]?: string[];
  [DestinationSearchQueryParams.cabin]?: string;
  [DestinationSearchQueryParams.type]?: string;
};

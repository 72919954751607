import { Selector, select } from '@ngrx/store';
import { Observable, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isPresent } from '@fcom/core/utils';

export type StateSelector<A, B> = Selector<Observable<A>, Observable<B>>;

export function safeSelect<T, K>(mapFn: (state: T) => K): (source$: Observable<T>) => Observable<K> {
  return pipe(select(mapFn), filter(isPresent));
}

export function safeStringSelect<T, a extends keyof T>(mapFn: a): (source$: Observable<T>) => Observable<T[a]> {
  return pipe(select(mapFn), filter(isPresent));
}

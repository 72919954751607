import { RouterNavigatedAction } from '@ngrx/router-store';
import { createAction, props, union } from '@ngrx/store';

import { FinnairOrder } from '@fcom/dapi/api/models';
import { DapiErrorStatus, IdAndHash } from '@fcom/dapi';

import { PassengerNameRecord } from '../store.interface';

export const loadStart = createAction('[OrderActions] LoadStart');
export const loadError = createAction('[OrderActions] LoadError', props<{ status: DapiErrorStatus }>());
export const reset = createAction('[OrderActions] Reset');
export const setOrderData = createAction('[OrderActions] SetOrderData', props<{ order: FinnairOrder }>());
export const setOrderFetchInformation = createAction(
  '[OrderActions] SetOrderInformation',
  props<{ orderInformation: IdAndHash }>()
);

export const setCategoryUpdating = createAction(
  '[OrderActions] SetCategoryUpdating',
  props<{ orderPartIds: string | string[] }>()
);

export const clearCategoryUpdating = createAction(
  '[OrderActions] ClearCategoryUpdating',
  props<{ orderPartIds: string | string[] }>()
);

export const resetCategoriesUpdating = createAction('[OrderActions] resetCategoriesUpdating');

export const setPNR = createAction('[OrderActions] SetPNR', props<{ PNR: PassengerNameRecord }>());
export const clearPNR = createAction('[OrderActions] ClearPNR');
export const acceptTerms = createAction('[OrderActions] AcceptTerms', props<{ accept: boolean }>());

const all = union({
  loadStart,
  loadError,
  reset,
  setOrderData,
  setOrderFetchInformation,
  setPNR,
  clearPNR,
  setCategoryUpdating,
  clearCategoryUpdating,
  resetCategoriesUpdating,
  acceptTerms,
});

export type ActionsUnion = typeof all | RouterNavigatedAction;

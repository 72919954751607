import { FinnairFrequentFlyerCardItem, FinnairOrder, FinnairPassengerItem } from '@fcom/dapi/api/models';
import { equals, TzDate } from '@fcom/core/utils';
import { FrequentFlyerTierLevel } from '@fcom/common-booking/interfaces/utils.interface';

/**
 * Get first eligible boundIndex for disruption handling and startDate to show alternative-offers
 * @param order
 * @return [startDate, eligibleBoundIndex]
 */
export const getEligibleBoundIndexAndStartDate = (order: FinnairOrder): [string, number] => {
  const eligibility = order.eligibilities.disruptionRebooking.find((eligibility) => eligibility.isAllowedToUse);
  const eligibleBoundIndex = order.bounds.findIndex((b) => b.id === eligibility?.id);
  const bound = order.bounds[eligibleBoundIndex]?.disruptedBound?.disruptedBound ?? order.bounds[eligibleBoundIndex];
  const originalLocalDate = TzDate.of(bound?.departure.dateTime)?.toLocalDate();
  const isOriginalDateEligible =
    eligibility?.eligibleDateRanges?.some((range) =>
      originalLocalDate.isBetween(
        TzDate.of(range.fromDateTime).toLocalDate(),
        TzDate.of(range.toDateTime).toLocalDate()
      )
    ) ?? false;
  const startDate = isOriginalDateEligible
    ? originalLocalDate.toString()
    : TzDate.of(eligibility?.eligibleDateRanges?.[0]?.fromDateTime)?.toLocalDate().toString() ??
      originalLocalDate?.toString() ??
      '';
  return [startDate, eligibleBoundIndex];
};

const RE_FETCH_TIERS = [
  FrequentFlyerTierLevel.SILVER,
  FrequentFlyerTierLevel.GOLD,
  FrequentFlyerTierLevel.PLATINUM,
  FrequentFlyerTierLevel.LUMO,
];

const hasRefetchTierLevels = (card: FinnairFrequentFlyerCardItem) =>
  RE_FETCH_TIERS.includes(card.tierLevel as FrequentFlyerTierLevel) && card.companyCode === 'AY';

export const isPassengerFFTierChanged = (
  order: FinnairOrder,
  passengerToValidate: FinnairPassengerItem | undefined
): boolean => {
  if (!passengerToValidate) {
    return true;
  }
  const oldTiers =
    passengerToValidate.frequentFlyerCards?.filter(hasRefetchTierLevels).map((card) => card.tierLevel) ?? [];
  const newTiers =
    order.passengers
      .find((p) => p.id === passengerToValidate.id)
      ?.frequentFlyerCards?.filter(hasRefetchTierLevels)
      .map((card) => card.tierLevel) ?? [];

  return !equals(oldTiers, newTiers);
};

<ng-container *ngIf="enableMultiCity && flights$ | async as flights" data-testid="multicity-selector-new">
  <ng-container *ngFor="let flight of flights; index as i; first as first; last as last; trackBy: locationsHaveChanged">
    <div class="flex flex--space-between flex--middle ms-xsmall-b">
      <h2 class="font-body-1" [class.ms-xsmall-y]="i < 2">
        {{ 'bookingSearch.boundSelection.flightTitle' | finLocalization: { index: i + 1 } }}
      </h2>
      <fcom-icon-button
        *ngIf="i >= 2"
        class="flex flex--center flex--self-center location-switch"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.CLOSE_DELETE }"
        [theme]="IconButtonTheme.LIGHT_TERTIARY_GHOST"
        [size]="IconButtonSize.MEDIUM"
        [aria]="{ label: 'bookingSearch.boundSelection.removeFlight' | finLocalization: { index: i + 1 } }"
        (clickBtn)="removeFlight(i)"
        data-testid="remove-flight-button"
      >
      </fcom-icon-button>
    </div>
    <div class="grid-col-12 no-gutters selector-grid" [class.ms-small-b]="!last">
      <fin-location-selector
        [locationPair$]="flight | finToObservable"
        [suggestedLocations$]="first ? suggestedLocations$ : (undefined | finToObservable)"
        [trendingDestinations$]="first ? trendingDestinations$ : (undefined | finToObservable)"
        [showPreviousSearches]="enableMultiCity"
        [showSeparator]="true"
        [showLocateMe]="first"
        (setLocations)="setLocations($event, i)"
        [attr.data-testid]="'booking-widget-full-multicity-location-selector-' + i"
      ></fin-location-selector>
      <fin-travel-dates-selector
        [previousFlightDepartureDate$]="flights[i - 1] | finToObservable | finMap: 'departureDate'"
        [travelDates$]="
          {
            departureDate: flight.departureDate,
            returnDate: null,
          } | finToObservable
        "
        [showSingular]="'departureDate'"
        [showSeparator]="false"
        [showAddReturn]="false"
        [isOneway$]="true | finToObservable"
        [showFlexibleDatesSelection]="false"
        (setTravelDates)="setTravelDates($event, i, false, enableMultiCity)"
        (setFlexibleDates)="setFlexibleDates($event)"
        [attr.data-testid]="'booking-widget-multicity-dates-selector-' + i"
      ></fin-travel-dates-selector>
    </div>
  </ng-container>
  <fcom-button
    class="ms-medium-y"
    [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
    [text]="'bookingSearch.boundSelection.addFlightButton' | finLocalization"
    [disabled]="(flights$ | async).length === 6"
    (clickBtn)="addFlight()"
    data-testid="add-flight-button"
  >
  </fcom-button>
</ng-container>

<ng-container *ngIf="!enableMultiCity && flights$ | async as flights" data-testid="multicity-selector-old">
  <ng-container *ngFor="let flight of flights; index as i; first as first; last as last; trackBy: locationsHaveChanged">
    <h2 class="font-body-1 col-f">
      {{ 'bookingSearch.' + (first ? 'departure' : 'return') | finLocalization }}
    </h2>
    <div class="grid-col-12 no-gutters selector-grid" [class.ms-small-b]="!last">
      <fin-location-selector
        [locationPair$]="flight | finToObservable"
        [suggestedLocations$]="first ? suggestedLocations$ : (undefined | finToObservable)"
        [trendingDestinations$]="first ? trendingDestinations$ : (undefined | finToObservable)"
        [originDisabled]="!first && flights[i - 1].destination === undefined"
        [destinationDisabled]="last && flights[0].origin === undefined"
        [allowedOriginContinent]="!first ? flights[i - 1]?.destination?.continentCode : undefined"
        [allowedDestinationContinent]="last ? flights[0]?.origin?.continentCode : undefined"
        [showSeparator]="false"
        [showLocateMe]="first"
        (setLocations)="setLocations($event, i)"
        data-testid="booking-widget-multicity-location-selector"
      ></fin-location-selector>
      <fin-travel-dates-selector
        [travelDates$]="
          {
            departureDate: flights[0].departureDate,
            returnDate: flights[1].departureDate,
          } | finToObservable
        "
        [showSingular]="first ? 'departureDate' : 'returnDate'"
        [showSeparator]="false"
        [showAddReturn]="false"
        (setTravelDates)="setTravelDates($event, i, false, enableMultiCity)"
        (setFlexibleDates)="setFlexibleDates($event)"
        data-testid="booking-widget-multicity-dates-selector"
      ></fin-travel-dates-selector>
    </div>
  </ng-container>
</ng-container>

/**
 * Removes all spaces from a string.
 * @example '1234 5678 1234 5678' -> '1234567812345678'
 */
export const stripSpaces = (value: string): string => {
  return value.replace(/\s/g, '');
};

/**
 * Convert kebab-case or separate words to camelCase
 */
export const toCamelCase = (str: string): string =>
  str
    .replace(/-/g, ' ')
    .replace(/\s(.)/g, (value) => value.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, (value) => value.toLowerCase());

/**
 * Converts a string to kebab-case.
 *
 * Only supports the following characters: `a-z, å, ä, ö, 0-9, -`.
 * Underscore characters `_` will be converted to hyphens `-`.
 * Other special characters will be removed.
 *
 * @example
 * ```ts
 * const converted = toKebabCase('Hello World!');
 * console.log(converted); // 'hello-world'
 * ```
 */
export const toKebabCase = (str: string): string =>
  str
    .toLowerCase()
    .replace(/[^a-zåäö0-9-]+/g, '-')
    .replace(/[\s_]+/g, '-')
    .replace(/-{2,}/g, '-')
    .replace(/^-/, '')
    .replace(/-$/, '');

export type ReplaceParams = Array<string | number> | { [key: string]: string | number };

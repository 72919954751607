import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';

import { buffer, fromEvent, map, mergeAll, Observable, Subject, Subscription, withLatestFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
  ConsentTextId,
  DataCloudFlightSelection,
  DataCloudFlightSelectionData,
  DataCloudIdentityData,
  OfferListFetchParams,
  PreFlightSearchParams,
} from '@fcom/common';
import { DataCloudService } from '@fcom/common/datacloud';
import { ConsentService } from '@fcom/common/login';
import { combineOriginDestination, getTripTypeForFlightSearchParams } from '@fcom/common/utils/gtm.utils';
import { AppState, ConfigService, unsubscribe, WindowRef } from '@fcom/core';
import { profile } from '@fcom/core/selectors';
import { Profile } from '@fcom/core-api/login';
import { BoundType } from '@fcom/dapi';
import { finShare } from '@fcom/rx';

import { mapCabinToTravelClass } from './client-gtm.service';

interface SalesforceEvent {
  name: SalesforceEventName;
  type: SalesforceEventName;
  additionalData: SalesforceEventDeviceData;
}

enum SalesforceEventName {
  PRE_FLIGHT_SEARCH = 'preFlightSearch',
  FLIGHT_SEARCH = 'flightSearch',
  FLIGHT_SELECTION_SELECT_OUTBOUND = 'flightSelectionSelect_outbound',
  FLIGHT_SELECTION_SELECT_INBOUND = 'flightSelectionSelect_inbound',
  IDENTITY = 'identity',
  PURCHASE = 'purchase',
}

type SalesforceEventDeviceData = SalesforceEventData & SalesforceDeviceData;

type SalesforceEventData =
  | SalesforceFlightSearchData
  | SalesforcePreFlightSearchData
  | SalesforceOutboundFlightSelectionData
  | SalesforceInboundFlightSelectionData
  | SalesforceIdentityData
  | SalesforcePassengerIdentityData
  | SalesforceSubscriberIdData
  | SalesforcePurchaseData;

type addPrefixToType<T, P extends string> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K];
};

interface SalesforceFlightSearchData {
  origin: string;
  destination: string;
  start: string;
  end: string;
  tripType: string;
  travelClass: string;
  passengers: number;
  adults: number;
  children: number;
  infants: number;
  alllocations: string;
}

type SalesforcePreFlightSearchData = addPrefixToType<SalesforceFlightSearchData, 'preFlightSearch'>;

interface SalesforceFlightSelectionData {
  aircraft: string;
  flightNumber: string;
  fareFamilyName: string;
  price: number;
  points: string;
  currencyCode: string;
  travelClass: string;
  boundType: string;
  route: string;
  paxAmount: number;
  purchaseFlow: string;
}

type SalesforceOutboundFlightSelectionData = addPrefixToType<SalesforceFlightSelectionData, 'outbound'>;

type SalesforceInboundFlightSelectionData = addPrefixToType<SalesforceFlightSelectionData, 'inbound'>;

type SalesforceBoundFlightSelectionData = SalesforceOutboundFlightSelectionData | SalesforceInboundFlightSelectionData;

interface SalesforceIdentityData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  countryCode: string;
  phoneNumber: string;
  memberProgram: string;
  memberNumber: string;
}

type SalesforcePassengerIdentityData = addPrefixToType<SalesforceIdentityData, 'passenger'>;

interface SalesforceSubscriberIdData {
  subscriberId: string;
}

interface SalesforcePurchaseData {
  purchaseDone: boolean;
}

interface SalesforceDeviceData {
  deviceId: string;
}

interface SalesforceInteractions {
  sendCustomEvent(event: SalesforceEvent): void;
  sendIdentityEvent(data: SalesforceEventDeviceData): void;
}

@Injectable()
export class ClientDataCloudService extends DataCloudService implements OnDestroy {
  private renderer: Renderer2;
  private scriptReady$: Observable<unknown>;
  private consents$: Observable<boolean>;
  private events$: Subject<[SalesforceEventName, SalesforceEventData]> = new Subject<
    [SalesforceEventName, SalesforceEventData]
  >();
  private salesforceInteractions: SalesforceInteractions;

  private subscriptions = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private consentService: ConsentService,
    private windowRef: WindowRef,
    rendererFactory: RendererFactory2,
    configService: ConfigService
  ) {
    super();

    if (configService.cfg.enableSalesforceDataCloud) {
      this.consents$ = this.consentService
        .getCookieConsentStatusById(ConsentTextId.COOKIE_PERSONALIZATION)
        .pipe(finShare());

      // Services cannot have Renderer2 directly injected (only Components can) so we need to create it explicitly
      this.renderer = rendererFactory.createRenderer(null, null);

      this.injectScript(configService.cfg.salesforceDataCloudScriptSrcURL);

      this.subscriptions.add(
        this.scriptReady$.subscribe(() => {
          this.salesforceInteractions = this.windowRef.nativeWindow['SalesforceInteractions'];
        })
      );

      this.subscriptions.add(
        this.store$.pipe(profile(), take(1)).subscribe((memberProfile: Profile) => {
          this.queueEventToDataCloud(SalesforceEventName.IDENTITY, {
            countryCode: memberProfile.parsedMobilePhone?.countryCode?.toString(),
            emailAddress: memberProfile.email,
            firstName: memberProfile.firstname,
            lastName: memberProfile.lastname,
            memberNumber: memberProfile.memberNumber,
            memberProgram: memberProfile.memberNumber ? 'AY' : undefined,
            phoneNumber: memberProfile.parsedMobilePhone?.nationalNumber?.toString(),
          });
        })
      );

      // We need to buffer events until the data cloud script has loaded, and until something is said about consents
      // Before the script has loaded and consents are set, events are buffered into an array
      // After the script has loaded and consents have been set, the buffer is flushed
      // Any subsequent events flush immediately from the buffer
      // After that, there is still the check that consent has been given (if not, then nothing is sent to data cloud)
      // It should be noted that to avoid a memory leak, the buffer should be flushed as soon as possible
      this.subscriptions.add(
        this.events$
          .pipe(
            buffer(this.events$.pipe(withLatestFrom(this.scriptReady$, this.consents$))),
            mergeAll(),
            withLatestFrom(this.consents$),
            filter(([, consent]) => consent),
            map(([event]) => event)
          )
          .subscribe(([name, data]) => {
            this.sendEventToDataCloud(name, data);
          })
      );
    }
  }

  injectScript(src: string): void {
    const script: HTMLScriptElement = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);

    this.scriptReady$ = fromEvent(script, 'load').pipe(finShare());
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  preFlightSearch(params: PreFlightSearchParams): void {
    this.queueEventToDataCloud(SalesforceEventName.PRE_FLIGHT_SEARCH, {
      preFlightSearchorigin: params.flights[0].origin,
      preFlightSearchdestination: params.flights[0].destination,
      preFlightSearchstart: params.flights[0].departureDate?.toString(),
      preFlightSearchend: (params.flights.length > 1 ? params.flights.at(-1).departureDate : undefined)?.toString(),
      preFlightSearchtripType: getTripTypeForFlightSearchParams(params.flights).toString(),
      preFlightSearchtravelClass: params.travelClass.toString(),
      preFlightSearchadults: params.paxAmount.adults,
      preFlightSearchchildren: params.paxAmount.children + params.paxAmount.c15s,
      preFlightSearchinfants: params.paxAmount.infants,
      preFlightSearchpassengers: Object.values(params.paxAmount).reduce((total, val) => total + val, 0),
      preFlightSearchalllocations: combineOriginDestination(params.flights),
    });
  }

  flightSearch(params: OfferListFetchParams): void {
    this.queueEventToDataCloud(SalesforceEventName.FLIGHT_SEARCH, {
      origin: params.flights[0].origin,
      destination: params.flights[0].destination,
      start: params.flights[0].departureDate?.toString(),
      end: (params.flights.length > 1 ? params.flights.at(-1).departureDate : undefined)?.toString(),
      tripType: getTripTypeForFlightSearchParams(params.flights).toString(),
      travelClass: mapCabinToTravelClass(params.cabin).toString(),
      adults: params.paxAmount.adults,
      children: params.paxAmount.children + params.paxAmount.c15s,
      infants: params.paxAmount.infants,
      passengers: Object.values(params.paxAmount).reduce((total, val) => total + val, 0),
      alllocations: combineOriginDestination(params.flights),
    });
  }

  flightSelectionSelect(data: DataCloudFlightSelectionData[]): void {
    data
      .filter(
        (item: DataCloudFlightSelectionData) =>
          item.boundType === BoundType.outbound || item.boundType === BoundType.inbound
      )
      .forEach((item: DataCloudFlightSelectionData) => {
        const boundType: BoundType = item.boundType;
        const prefix: string = boundType;
        const additionalData: SalesforceBoundFlightSelectionData = {
          [prefix + DataCloudFlightSelection.AIRCRAFT]: item[DataCloudFlightSelection.AIRCRAFT],
          [prefix + DataCloudFlightSelection.FLIGHT_NUMBER]: item[DataCloudFlightSelection.FLIGHT_NUMBER],
          [prefix + DataCloudFlightSelection.FARE_FAMILY_NAME]: item[DataCloudFlightSelection.FARE_FAMILY_NAME],
          [prefix + DataCloudFlightSelection.PRICE]: item[DataCloudFlightSelection.PRICE]
            ? parseFloat(item[DataCloudFlightSelection.PRICE])
            : undefined,
          [prefix + DataCloudFlightSelection.POINTS]: item[DataCloudFlightSelection.POINTS],
          [prefix + DataCloudFlightSelection.CURRENCY_CODE]: item[DataCloudFlightSelection.CURRENCY_CODE],
          [prefix + DataCloudFlightSelection.TRAVEL_CLASS]: item[DataCloudFlightSelection.TRAVEL_CLASS],
          [prefix + DataCloudFlightSelection.BOUND_TYPE]: item[DataCloudFlightSelection.BOUND_TYPE],
          [prefix + DataCloudFlightSelection.ROUTE]: item[DataCloudFlightSelection.ROUTE],
          [prefix + DataCloudFlightSelection.PAX_AMOUNT]: item[DataCloudFlightSelection.PAX_AMOUNT]
            ? parseFloat(item[DataCloudFlightSelection.PAX_AMOUNT])
            : undefined,
          [prefix + DataCloudFlightSelection.PURCHASE_FLOW]: item[DataCloudFlightSelection.PURCHASE_FLOW],
        } as SalesforceBoundFlightSelectionData;
        const name: SalesforceEventName =
          boundType === BoundType.outbound
            ? SalesforceEventName.FLIGHT_SELECTION_SELECT_OUTBOUND
            : SalesforceEventName.FLIGHT_SELECTION_SELECT_INBOUND;
        this.queueEventToDataCloud(name, additionalData);
      });
  }

  paxDetails(data: DataCloudIdentityData): void {
    this.queueEventToDataCloud(SalesforceEventName.IDENTITY, {
      passengerfirstName: data.firstName,
      passengerlastName: data.lastName,
      passengeremailAddress: data.emailAddress,
      passengercountryCode: data.countryCode?.replace(/^.*\|/, ''),
      passengerphoneNumber: data.phoneNumber,
      passengermemberProgram: data.memberProgram,
      passengermemberNumber: data.memberNumber,
    });
  }

  subscriberId(subscriberId: string): void {
    this.queueEventToDataCloud(SalesforceEventName.IDENTITY, { subscriberId });
  }

  completePurchase(): void {
    this.queueEventToDataCloud(SalesforceEventName.PURCHASE, {
      purchaseDone: true,
    });
  }

  queueEventToDataCloud(name: SalesforceEventName, data: SalesforceEventData): void {
    this.events$.next([name, data]);
  }

  sendEventToDataCloud(name: SalesforceEventName, data: SalesforceEventData): void {
    const deviceId: string = this.consentService.getCookieIdFromSnippet();
    const deviceData: SalesforceEventDeviceData = {
      deviceId,
      ...data,
    };
    if (name === SalesforceEventName.IDENTITY) {
      this.salesforceInteractions.sendIdentityEvent(deviceData);
    } else {
      this.salesforceInteractions.sendCustomEvent({
        type: name,
        name: name,
        additionalData: deviceData,
      });
    }
  }
}

<div [formGroup]="parentForm" class="ms-xlarge-b">
  <div class="relative">
    <label
      class="font-body-1"
      [for]="idOrControlName"
      [ngClass]="{
        'light-type nordic-blue-900-text': theme === FilePickerTheme.DEFAULT,
        'regular-type nordic-blue-400-text': theme === FilePickerTheme.BORDERED,
        'error-900-text': ctrlField.invalid && ctrlField.touched,
      }"
    >
      <div [class.ps-xxsmall-b]="theme === FilePickerTheme.BORDERED">
        {{ translationPrefix + '.label' | finLocalization }}
        <span *ngIf="isRequired"> *</span>
      </div>
      <div
        class="flex action-select-file"
        [ngClass]="{
          'border rounded-large ps-small': theme === FilePickerTheme.BORDERED,
          'nordic-blue-400-border': !ctrlField.invalid || !ctrlField.touched,
          'error-900-border': ctrlField.invalid && ctrlField.touched,
        }"
      >
        <div
          class="nordic-blue-900-text bold-type font-body-1 flex link-hover"
          [ngClass]="{
            'flex--nogrow ps-xxsmall-b': theme === FilePickerTheme.DEFAULT,
            'flex-1': theme === FilePickerTheme.BORDERED,
          }"
        >
          <div
            class="flex flex--align-center"
            [ngClass]="{ 'flex-1 flex--row-reverse flex--space-between': theme === FilePickerTheme.BORDERED }"
          >
            <fcom-icon
              class="file-input-icon flex flex--nogrow flex--self-center plus-ball ms-xsmall-r icon-medium"
              [name]="SvgLibraryIcon.PLUS_OUTLINE"
            ></fcom-icon>
            <span
              role="button"
              class="flex flex--self-center"
              *ngIf="theme === FilePickerTheme.DEFAULT || files.length === 0"
              [ngClass]="{ 'grey-600-text medium-type': theme === FilePickerTheme.BORDERED }"
              >{{
                genericTranslationPrefix + '.chooseFile.' + (multiple ? 'plural' : 'singular') | finLocalization
              }}</span
            >
            <span *ngIf="theme === FilePickerTheme.BORDERED && files.length > 0" class="regular-type">
              {{
                files.length > 1
                  ? (genericTranslationPrefix + '.filesAttached' | finLocalization: { amount: files.length })
                  : files[0].name
              }}
            </span>
          </div>
        </div>
        <div
          *ngIf="files.length > 0 && theme === FilePickerTheme.DEFAULT"
          id="filesAttached"
          class="flex flex--self-center ms-medium-l ms-xxsmall-b transform-none nordic-blue-900-text overflow-hidden"
        >
          <span class="truncate overflow-hidden">{{
            files.length > 1
              ? (genericTranslationPrefix + '.filesAttached' | finLocalization: { amount: files.length })
              : files[0].name
          }}</span>
        </div>
      </div>
    </label>
    <input
      class="file-input font-body-1 light-type overflow-hidden absolute"
      [class.form-field-empty]="!ctrlField.value"
      type="file"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [multiple]="multiple"
      (change)="onFileChange($event)"
      [name]="controlName"
      [attr.aria-required]="isRequired"
      [attr.accept]="allowedFileTypes"
      #fileInput
    />
    <span class="divider" *ngIf="theme !== FilePickerTheme.BORDERED"></span>
  </div>
  <div
    *ngIf="ctrlField.invalid"
    class="error-label font-body-2 light-type"
    aria-live="polite"
    [ngClass]="{
      'heather-700-text': theme === FilePickerTheme.DEFAULT,
      'error-900-text': theme === FilePickerTheme.BORDERED,
    }"
  >
    <span *ngIf="ctrlField.errors?.required && ctrlField.touched">
      <ng-container
        *ngIf="
          translationPrefix + '.required' | finLocalization: undefined : { logErrors: false } as translation;
          else genericTranslation
        "
        >{{ translation }}</ng-container
      >
      <ng-template #genericTranslation>{{ genericTranslationPrefix + '.required' | finLocalization }}</ng-template>
    </span>
    <span *ngIf="ctrlField.errors?.extension">
      <ng-container
        *ngIf="
          translationPrefix + '.extension' | finLocalization: undefined : { logErrors: false } as translation;
          else genericTranslation
        "
        >{{ translation }}</ng-container
      >
      <ng-template #genericTranslation>{{ genericTranslationPrefix + '.extension' | finLocalization }}</ng-template>
    </span>
    <span *ngIf="ctrlField.errors?.maxSize">
      {{ genericTranslationPrefix + '.maxSize' | finLocalization: { maxSize: maxSizeMb } }}
    </span>
  </div>
  <ng-container *ngIf="files.length > 0">
    <div
      [ngClass]="{ 'align-right': files.length === 1, 'flex flex--space-between': files.length > 1 }"
      class="action-remove-file"
    >
      <ul aria-labelledby="filesAttached" class="unstyled ms-xxsmall-t col-6-12" *ngIf="files.length > 1">
        <li *ngFor="let file of files" class="ms-xxsmall-b font-body-1 truncate overflow-hidden">{{ file.name }}</li>
      </ul>
      <button
        [type]="'button'"
        class="nordic-blue-900-text font-body-1 bold-type ms-xxsmall-t link-hover"
        [ngClass]="{ 'flex flex--self-start flex--nogrow': files.length > 1 }"
        (click)="onRemoveFile(true)"
      >
        <span class="flex">
          <fcom-icon
            class="file-input-icon flex flex--nogrow flex--self-center valign-top plus-ball ms-xsmall-r icon-medium"
            [name]="SvgLibraryIcon.MINUS_OUTLINE"
          ></fcom-icon>
          <span class="button-text flex-1 flex--self-center">
            {{
              genericTranslationPrefix + '.removeFile.' + (files.length > 1 ? 'plural' : 'singular') | finLocalization
            }}
          </span>
        </span>
      </button>
    </div>
  </ng-container>
</div>

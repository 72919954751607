import { createAction, props, union } from '@ngrx/store';

import { TripType } from '@fcom/core/constants';
import { GlobalBookingTravelClass } from '@fcom/core';
import { GlobalBookingTripDates } from '@fcom/common';
import { LocationRouteType } from '@fcom/core-api/interfaces';
import { PaxAmount } from '@fcom/dapi/interfaces';
import { PreviousSearch } from '@fcom/booking-widget/interfaces';

import { GlobalBookingFlight, GlobalBookingState, TravelClassAvailabilityMap } from '../store.interface';

export const updateSelection = createAction(
  '[GlobalBookingActions] UpdateSelection',
  props<{ selection: GlobalBookingState }>()
);

export const setTripType = createAction('[GlobalBookingActions] SetTripType', props<{ tripType: TripType }>());

export const setTravelClass = createAction(
  '[GlobalBookingActions] SetTravelClass',
  props<{ travelClass: GlobalBookingTravelClass }>()
);

export const setOrigin = createAction('[GlobalBookingActions] SetOrigin', props<{ origin: string }>());

export const setRouteType = createAction(
  '[GlobalBookingActions] SetRouteType',
  props<{ routeType: LocationRouteType }>()
);

export const increasePaxAmountField = createAction(
  '[GlobalBookingActions] IncreasePaxAmountField',
  props<{ field: string; increment: number }>()
);

export const decreasePaxAmountField = createAction(
  '[GlobalBookingActions] DecreasePaxAmountField',
  props<{ field: string; decrement: number }>()
);

export const setDiscountCode = createAction(
  '[GlobalBookingActions] SetDiscountCode',
  props<{ discountCode: string }>()
);
export const setAvailableClasses = createAction(
  '[GlobalBookingActions] setAvailableClasses',
  props<{ availability: TravelClassAvailabilityMap }>()
);

export const setSelection = createAction('[GlobalBookingActions] SetSelection', props<{ selection: PreviousSearch }>());

export const setPaxAmount = createAction('[GlobalBookingActions] SetPaxAmount', props<{ paxAmount: PaxAmount }>());

export const paymentVerified = createAction('[GlobalBookingActions] HandlePaymentStatus');

export const resetSelection = createAction('[GlobalBookingActions] ResetSelection');

export const setFlights = createAction(
  '[GlobalBookingActions] SetFlights',
  props<{
    flights: GlobalBookingFlight[];
  }>()
);
export const updateFlight = createAction(
  '[GlobalBookingActions] UpdateFlight',
  props<{
    flight: GlobalBookingFlight;
    index: number;
  }>()
);

export const addFlight = createAction('[GlobalBookingActions] AddFlight');
export const removeFlight = createAction('[GlobalBookingActions] RemoveFlight', props<{ index: number }>());

export const setFlightDate = createAction(
  '[GlobalBookingActions] SetFlightDate',
  props<{ dates: GlobalBookingTripDates; index: number }>()
);

const all = union({
  setFlightDate,
  setTripType,
  setTravelClass,
  setOrigin,
  setRouteType,
  setPaxAmount,
  decreasePaxAmountField,
  increasePaxAmountField,
  setDiscountCode,
  setSelection,
  paymentVerified,
  resetSelection,
  setFlights,
  updateFlight,
  updateSelection,
});

export type GlobalBookingActionsUnion = typeof all;

import { ActionReducerMap } from '@ngrx/store';

import { CommonBookingState } from '../store.interface';
import { servicesReducer } from './services.reducer';
import { orderReducer } from './order.reducer';
import { seatMapReducer } from './seat-map.reducer';
import { paymentReducer } from './payment.reducer';
import { disruptionReducer } from './disruption.reducer';
import { upgradeOfferReducer } from './upgrade-offer.reducer';

export const reducers: ActionReducerMap<CommonBookingState> = {
  services: servicesReducer,
  order: orderReducer,
  seatMap: seatMapReducer,
  payment: paymentReducer,
  disruption: disruptionReducer,
  upgradeOffer: upgradeOfferReducer,
};

const LIGHT_FARE_FAMILIES = [
  'AELIGHT', // (North America)
  'AELCAMP', // (North America campaign)
  'SELIGHT', // (Japan)
  'SELCAMP', // (Japan campaign)
  'ELIGHT', // (Everything else in Asia)
  'ELCAMP', // (Everything else in Asia campaign)
];

const SUPER_LIGHT_FARE_FAMILIES = [
  'ESLIGHT', //(Europe)
  'ESLCAMP', // (Europe campaign)
];

export const isLightFareFamily = (fareFamilyCode: string): boolean =>
  LIGHT_FARE_FAMILIES.some((ff) => !!fareFamilyCode?.startsWith(ff));

export const isSuperLightFareFamily = (fareFamilyCode: string): boolean =>
  SUPER_LIGHT_FARE_FAMILIES.some((ff) => !!fareFamilyCode?.startsWith(ff));

import { Validators, type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';

import {
  EMAIL_REGEX,
  formatFinnairPlusCardNumber,
  isFinnairCustomerNumber,
  isFinnairPlusCardNumber,
  isValidIBAN,
  LocalDate,
} from '@fcom/core/utils';
import { AttachmentStatus, type Attachment } from '@fcom/ui-components/components/interfaces';

import { isPnrCode } from './utils';

/**
 * Validates attachment extensions based on files used with the `fcom-file-uploader` component.
 * @example
 * Usage with form builder:
 * ```ts
 * this.reactiveForm = fb.group({
 *   [Enum.CONTROL_NAME]: ['', [attachmentExtensionValidator(['jpg', 'png'])]]
 * });
 * ```
 * @example
 * Validation error:
 * ```ts
 * {
 *   extension: {
 *     value: [{ file: 'file.pdf', status: AttachmentStatus.ERROR }]
 *   }
 * }
 * ```
 * @returns Null or `extension` validation error with an array of files not matching the extensions given.
 */
export const attachmentExtensionValidator = (allowedExtensions: string[]): ValidatorFn => {
  const hasForbiddenAttachmentExtension = (extensions: string[], { file }: Attachment): boolean => {
    const extName = (file?.name || '').toLowerCase().split('.').pop();
    return !extensions.includes(extName);
  };

  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const attachmentsWithForbiddenExtensions: Attachment[] = (
      Array.isArray(control.value) ? control.value : [control.value]
    )
      .filter(Boolean)
      .filter((attachment: Attachment): boolean => hasForbiddenAttachmentExtension(allowedExtensions, attachment));
    return attachmentsWithForbiddenExtensions.length
      ? {
          extension: {
            value: attachmentsWithForbiddenExtensions.map((attachment: Attachment): Attachment => {
              attachment.status = AttachmentStatus.ERROR;
              return attachment;
            }),
          },
        }
      : null;
  };
};

/**
 * Validates attachment file sizes based on files used with the `fcom-file-uploader` component.
 * @example
 * Usage with form builder:
 * ```ts
 * this.reactiveForm = fb.group({
 *   [Enum.CONTROL_NAME]: ['', [attachmentFileSizeValidator(1024)]]
 * });
 * ```
 * @example
 * Validation error:
 * ```ts
 * {
 *   maxSize: {
 *     value: [{ file: 'file.pdf', status: AttachmentStatus.ERROR }]
 *   }
 * }
 * ```
 * @returns Null or `maxSize` validation error with an array of files
 * not matching the max size in bytes given.
 */
export const attachmentFileSizeValidator = (maxSizeInBytes: number): ValidatorFn => {
  const exceedsMaxSizeInBytes = ({ file }: Attachment, size: number): boolean => file.size > size;
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const attachmentsWithInvalidFileSizes: Attachment[] = (
      Array.isArray(control.value) ? control.value : [control.value]
    )
      .filter(Boolean)
      .filter(
        (attachment: Attachment): boolean => !attachment.file?.size || exceedsMaxSizeInBytes(attachment, maxSizeInBytes)
      );
    return attachmentsWithInvalidFileSizes.length
      ? {
          maxSize: {
            value: attachmentsWithInvalidFileSizes.map((attachment: Attachment): Attachment => {
              attachment.status = AttachmentStatus.ERROR;
              return attachment;
            }),
          },
        }
      : null;
  };
};

/**
 * Amadeus PNR sync validator
 */
export const pnrFormatValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || isPnrCode(control.value.toUpperCase())) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

/**
 * Ticket number validation, 13-digits and MANDATORY dash
 * Valid sample: 105-1231231231
 */
export const ticketNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dashPattern = /^(\d+-)+\d+$/;

    if (
      control.value === null ||
      control.value === '' ||
      (dashPattern.test(control.value) && control.value.length === 14)
    ) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

const TICKET_PATTERN = /^\d{3}[ -]?\d{10}$/;

/**
 * Ticket number validation, 13-digits and OPTIONAL dash
 * Valid samples: 105-1231231231, 105 1231231231, 1051231231231
 */
export const softTicketNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    if (control.value !== null && control.value !== '') {
      const strippedValue = control.value ? control.value.replace(/[- ]/g, '') : null;
      if (!/^\d+$/.test(strippedValue)) {
        return { custom: 'formatInvalid' };
      } else if (!TICKET_PATTERN.test(control.value)) {
        return { pattern: { value: control.value } };
      }
    }
    return null;
  };
};

/**
 * FPlus number validation
 */
export const frequentFlyerValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value === null ||
      control.value === '' ||
      isFinnairPlusCardNumber(formatFinnairPlusCardNumber(control.value))
    ) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

/**
 * FPlus number validation without AY prefix
 */
export const finnairPlusNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const numericPattern = /^\d+$/;
    if (
      control.value === null ||
      control.value === '' ||
      (numericPattern.test(control.value) && isFinnairPlusCardNumber(control.value))
    ) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

export const usernameValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const invalidEmail = Validators.pattern(EMAIL_REGEX)(control);
    const invalidMemberNumber = finnairPlusNumberValidator()(control);
    if (control.value === '') {
      return null;
    } else if (invalidEmail && invalidMemberNumber) {
      return { ...invalidEmail, ...invalidMemberNumber };
    } else {
      return null;
    }
  };
};

/**
 * dateValidator
 */
export const dateValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || (control.value && control.value instanceof LocalDate)) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

export const dateStringValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || LocalDate.isLocalDate(control.value)) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

/**
 * The Required format is "(airport code) title".
 * @todo: could be deprecated now as option selection is forced on the
 */
export const locationFormatValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || control.value.locationCode) {
      return null;
    } else {
      return { forbiddenFormat: { value: control.value } };
    }
  };
};

const hasForbiddenExtension = (allowedExtensions: string[], values: FileList): boolean => {
  return Array.from(values).some((file: File): boolean => {
    const extName = (file?.name || '').toLowerCase().split('.').pop();
    return !allowedExtensions.includes(extName);
  });
};

/**
 * Validates file extensions based on files used with the `fin-form-input-file` component.
 * @deprecated Use `attachmentExtensionValidator` together with `fcom-file-uploader` instead.
 */
export const extensionValidator = (allowedExtensions: string[]): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value === null ||
      control.value === '' ||
      control.value.length === 0 ||
      !hasForbiddenExtension(allowedExtensions, control.value)
    ) {
      return null;
    } else {
      const name = Object.keys(control.value).map((i) => control.value[i].name);
      return { extension: { value: name } };
    }
  };
};

const isValidFileSize = (files: FileList, maxSizeInBytes: number) =>
  !Array.from(files).some((file) => file.size > maxSizeInBytes);

/**
 * Validates file sizes based on files used with the `fin-form-input-file` component.
 * @deprecated Use `attachmentFileSizeValidator` together with `fcom-file-uploader` instead.
 */
export const fileSizeValidator = (maxSizeInBytes: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || isValidFileSize(control.value, maxSizeInBytes)) {
      return null;
    } else {
      return { maxSize: true };
    }
  };
};

/**
 * IBAN validation
 */
export const ibanValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || isValidIBAN(control.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

/**
 * BIC/SWIFT validation
 * Checks that the string contains 8 or 11 alphanumeric characters
 * Source: https://en.wikipedia.org/wiki/ISO_9362
 */
export const bicValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pattern = /^[a-zA-Z0-9]{8}$|^[a-zA-Z0-9]{11}$/;
    if (control.value === null || control.value === '' || pattern.test(control.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

export const finnairCustomerNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || isFinnairCustomerNumber(control.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

import { FormGroup } from '@angular/forms';

import { Location } from '@fcom/core-api';
import { SelectOption } from '@fcom/common/interfaces';
import { LoginAddress, Profile, ProfileTier } from '@fcom/core-api/login';
import { TypedFormGroup } from '@fcom/service-forms';

export const formatRoute = (origin: Location | undefined, destination: Location | undefined): string => {
  if (origin?.locationCode && destination?.locationCode) {
    return `${origin.locationCode} - ${destination.locationCode}`;
  }
  return '';
};

export const parsePhoneNumber = (profile: Profile | undefined): string => {
  if (!profile?.parsedMobilePhone?.countryCode || !profile?.parsedMobilePhone?.nationalNumber) {
    return '';
  }
  return `+${profile.parsedMobilePhone.countryCode}${profile.parsedMobilePhone.nationalNumber}`;
};

export const parseCountryCode = (regionCodes: string[] | undefined, countryCode: number | undefined): string => {
  if (!regionCodes?.length || !countryCode) {
    return '';
  }

  const [regionCode] = regionCodes;
  return `${regionCode}|${countryCode}`;
};

export const buildAddress = (address: LoginAddress | undefined): string => {
  if (!address) {
    return '';
  }
  let result = '';
  if (address.streetAddress && address.streetAddress !== '-') {
    result += address.streetAddress + '\n';
  }
  if (address.postalCode) {
    result += address.postalCode + ' ';
  }
  if (address.city && address.city !== '-') {
    result += address.city;
  }

  return result;
};

export const CUSTOMER_SUPPORT_LANGUAGES = ['ZH', 'EN', 'ET', 'FI', 'FR', 'DE', 'IT', 'JA', 'KO', 'RU', 'ES', 'SV'];

export const BOOKING_CLASSES = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const bookingClassSelectOptions: SelectOption[] = BOOKING_CLASSES.map((bookingClass) => ({
  name: bookingClass,
  value: bookingClass,
}));

export const mapProfileTier = (tier: ProfileTier): string => {
  switch (tier) {
    case ProfileTier.BASIC:
      return 'basic';
    case ProfileTier.SILVER:
      return 'silver';
    case ProfileTier.GOLD:
      return 'gold';
    case ProfileTier.PLATINUM:
      return 'platinum';
    case ProfileTier.LUMO:
      return 'platinumLumo';
    default:
      return '';
  }
};

export const asTypedFormGroup = (formGroup: FormGroup): TypedFormGroup => {
  return formGroup as TypedFormGroup;
};

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { skip, withLatestFrom, filter, map } from 'rxjs/operators';

import { DateFormat, LocalDate, TzDate, Pattern, unsubscribe } from '@fcom/core/utils';
import { HistogramBar } from '@fcom/common';
import { MediaQueryService } from '@fcom/common/services/media-query/media-query.service';

import { DatepickerTitleAreaOptions } from '../../interfaces';
import { DateSelection, Day, Month } from '../../../utils/date.interface';
import { CalendarService } from '../services/calendar.service';
import { ParseUserInput } from '../../../utils/date.format.utils';
import { NotificationLayout, NotificationTheme } from '../../notifications';
import { DateRange } from '../interfaces';
import { ButtonTheme } from '../../buttons';
import { IconPosition } from '../../icons';

const DEFAULT_TITLE_AREA_CONFIGURATION: DatepickerTitleAreaOptions = {
  titleAreaStyle: 'ps-large',
  title: undefined,
  titleStyle: 'font-body-1',
};

@Component({
  selector: 'fcom-datepicker',
  styleUrls: ['./datepicker.component.scss'],
  templateUrl: './datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('titleElement') titleElement: ElementRef<HTMLElement>;

  @Input() doneBtnHidden = false;

  /**
   * Object of translated and localized date labels
   * (e.g. month names, date formats)
   */
  @Input() dateLabels: any;

  /**
   * Object of translated UI labels
   * (e.g. error messages, instructions)
   */
  @Input() uiLabels: any;

  @Input() set titleAreaConfiguration(configuration: DatepickerTitleAreaOptions) {
    this.titleAreaConfig = { ...DEFAULT_TITLE_AREA_CONFIGURATION, ...configuration };
  }

  /**
   * H2 title to be shown in top left of modal
   */
  @Input() title: string = undefined;

  /**
   * Initially preselected dates
   * Array of (one or two) YYYY-MM-DD strings
   */
  @Input() selectedDates: [LocalDate] | DateRange;

  /**
   * Defined range of calendar (first and last selectable date)
   * Array of two YYYY-MM-DD strings
   */
  @Input() selectableDatesRange: DateRange;

  /**
   * Ranges where dates should be disabled
   * An array which includes array of two LocalDates
   */
  @Input() disabledDateRanges?: DateRange[];

  /**
   * If datepicker should select range or single date
   */
  @Input() isDateRange = false;

  /**
   * Focus header on init
   */
  @Input() focusHeaderOnInit = false;

  @Input() topNotification: string = undefined;

  /**
   * Show month chip buttons on top of calendar
   */
  @Input() showTags = true;

  /**
   * Show histogram on top of the calendar
   */
  @Input() headerTemplate?: TemplateRef<HistogramBar[]>;

  /**
   * Custom day template to be rendered within the calendar
   */
  @Input() dayTemplate?: TemplateRef<{ dayValue: Day; dayString: number }>;

  /**
   * Set the month index where the calendar should scroll to
   */
  @Input() scrollToMonthIndex$: Observable<number> = of(0);

  /**
   * enable vertical scroll to calendar months when in mobile
   */
  @Input() calendarScrollEnabled = false;

  @Input() isActive = false;

  @Input() showAddReturn = false;

  @Input() identifier: string;

  /**
   * Calendar vew will start be shown from this date
   */
  @Input() firstVisibleDate$: Observable<LocalDate> = of(null);

  /**
   * Event emitter for selected date or date range
   * Array of (one or two) YYYY-MM-DD strings
   */
  @Output() selectedDatesChange: EventEmitter<[string] | [string, string]> = new EventEmitter<
    [string] | [string, string]
  >();

  /**
   * Event emitter for close event
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  /**
   * Event emitter for monthChange
   */
  @Output() monthChange: EventEmitter<Month> = new EventEmitter();

  @Output() addReturn: EventEmitter<void> = new EventEmitter();

  readonly ButtonTheme = ButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  titleAreaConfig = DEFAULT_TITLE_AREA_CONFIGURATION;
  startDate = '';
  endDate = '';
  startDateError: string = undefined;
  endDateError: string = undefined;
  NotificationTheme = NotificationTheme;
  NotificationLayout = NotificationLayout;

  private clearDates$ = new Subject<void>();
  private subscription: Subscription = new Subscription();

  constructor(
    private calendarService: CalendarService,
    private cd: ChangeDetectorRef,
    private mediaQueryService: MediaQueryService,
    private hostElement: ElementRef
  ) {}

  ngOnInit(): void {
    this.identifier = this.identifier || uuid();

    this.subscription.add(
      this.calendarService
        .getDatesSelected(this.identifier)
        .pipe(skip(1))
        .subscribe((values: DateSelection) => {
          if (values.startDate) {
            this.startDate = new DateFormat(this.dateLabels).format(
              values.startDate,
              new Pattern('dateFormatUserInput', true)
            );
          } else {
            this.startDate = undefined;
          }

          if (values.endDate) {
            this.endDate = new DateFormat(this.dateLabels).format(
              values.endDate,
              new Pattern('dateFormatUserInput', true)
            );
          } else {
            this.endDate = undefined;
          }

          if (this.isDateRange) {
            this.selectedDatesChange.emit([
              values.startDate ? values.startDate.toString() : undefined,
              values.endDate ? values.endDate.toString() : undefined,
            ]);
          } else {
            this.selectedDatesChange.emit([values.startDate ? values.startDate.toString() : undefined]);
          }
          this.clearErrors();
          this.cd.detectChanges();
        })
    );
  }

  ngAfterViewInit(): void {
    if (this.focusHeaderOnInit) {
      this.titleElement.nativeElement.focus();
    }

    this.subscription.add(
      this.clearDates$
        .pipe(
          withLatestFrom(this.mediaQueryService.isBreakpoint$('laptop_up')),
          map(([_, isLaptopUp]) => isLaptopUp),
          filter(Boolean)
        )
        .subscribe(() => {
          this.scrollToTop();
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  setDate(event: Event): void {
    const element = event.target as HTMLInputElement;
    const isEndDate = element.name === 'endDate';
    let errorMessage;

    const userInputISOString = new ParseUserInput(element.value, this.dateLabels.dateFormatUserInput).getISOString();
    if (!userInputISOString && element.value !== '') {
      errorMessage = this.uiLabels.error.invalidDate;
    } else if (userInputISOString) {
      try {
        const date = LocalDate.fromTzDate(new TzDate(userInputISOString));
        if (
          !date.isBetween(this.selectableDatesRange[0], this.selectableDatesRange[1]) ||
          this.disabledDateRanges.some((range) => date.isBetween(range[0], range[1]))
        ) {
          errorMessage = this.uiLabels.error.outOfRange;
        } else {
          this.calendarService.setDate(this.identifier, date, isEndDate);
          errorMessage = undefined;
        }
      } catch (e) {
        switch (e.message) {
          case 'startAfterEnd':
            errorMessage = this.uiLabels.error.startAfterEnd;
            break;
          case 'endBeforeStart':
            errorMessage = this.uiLabels.error.endBeforeStart;
            break;
          case 'outOfRange':
            errorMessage = this.uiLabels.error.outOfRange;
            break;
          default:
            errorMessage = this.uiLabels.error.invalidDate;
            break;
        }
      }
    } else if (element.value === '') {
      this.calendarService.setDate(this.identifier, undefined, isEndDate);
      errorMessage = undefined;
    }

    if (isEndDate) {
      this.endDateError = errorMessage;
    } else {
      this.startDateError = errorMessage;
    }
  }

  clearErrors(): void {
    this.endDateError = undefined;
    this.startDateError = undefined;
  }

  clearDates(): void {
    this.calendarService.clearDates(this.identifier);
    this.clearDates$.next();
  }

  closeDatepicker(): void {
    this.close.emit();
  }

  private scrollToTop() {
    requestAnimationFrame(() => {
      this.hostElement.nativeElement.scrollIntoView();
    });
  }
}

/* eslint-disable */
export enum SubCategory {
  BAGGAGE = 'baggage',
  OVERSIZED_BAGGAGE = 'oversizedBaggage',
  CHECKED_BAGGAGE = 'checkedBaggage',
  CHECKED_BAGGAGE_NON_AY = 'checkedBaggageNonAy',
  CABIN_BAGGAGE = 'cabinBaggage',
  CABIN_BAGGAGE_NON_AY = 'cabinBaggageNonAy',
  CABIN_ITEM = 'cabinItem',
  CABIN_INFANT = 'cabinInfant',
  CABIN_WEIGHT = 'cabinWeight',
  MEAL = 'meal',
  SPECIAL_DIET = 'specialDiet',
  SEAT = 'seat',
  WIFI = 'wifi',
  LOUNGE = 'lounge',
  MEDICAL = 'medical',
  ASSISTANCE = 'assistance',
  PETS = 'pets',
  TRAVEL_COMFORT = 'travelComfort',
  COVER = 'cover',
  OTHER = 'other',
  PET_IN_HOLD = 'petInHold',
  PET_IN_CABIN = 'petInCabin',
  BIKE = 'bike',
  WINDSURFING = 'windsurfing',
  SKIS = 'skis',
  SKI_JUMPING = 'skiJumping',
  SPORTS_MEDIUM = 'sportsMedium',
  SPORTS_LARGE = 'sportsLarge',
  HOCKEY = 'hockey',
  GOLF = 'golf',
  SURF = 'surf',
  TIER_BENEFIT_SPORT_EQUIPMENT = 'tierBenefitSportEquipment',
  TANDEM_BIKE = 'tandemBike',
  VAULTING_POLE = 'vaultingPole',
  SPECIAL_EQUIPMENT = 'specialEquipment',
  MUSICAL_MEDIUM = 'musicalMedium',
  MUSICAL_LARGE = 'musicalLarge',
  MEDIA = 'media',
  HEAVY_OR_LARGE = 'heavyOrLarge',
  FIREARM = 'firearm',
  OXYGEN = 'oxygen',
  APNEA_DEVICE = 'apneaDevice',
  WHEELCHAIR_DRY_CELL = 'wheelchairDryCell',
  WHEELCHAIR_WET_CELL = 'wheelchairWetCell',
  WHEELCHAIR_ASSISTANCE_SEAT = 'wheelchairAssistanceSeat',
  WHEELCHAIR_ASSISTANCE_RAMP = 'wheelchairAssistanceRamp',
  WHEELCHAIR_ASSISTANCE_STEPS = 'wheelchairAssistanceSteps',
  WHEELCHAIR_MANUAL_POWER = 'wheelchairManualPower',
  WHEELCHAIR_ON_BOARD = 'wheelchairOnBoard',
  WHEELCHAIR_LITHIUM_ION = 'wheelchairLithiumIon',
  MOBILITY_AID = 'mobilityAid',
  CHILD_ASSISTANCE = 'childAssistance',
  FRESH_MEAL = 'freshMeal',
  HOT_PRE_ORDER_MEAL = 'hotPreOrderMeal',
  SERVICE_ANIMAL = 'serviceAnimal',
  EMOTIONAL_SUPPORT_ANIMAL = 'emotionalSupportAnimal',
  FAST_TRACK = 'fastTrack'
}


import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { ProfileType, ProfileTier, LoginMemberStatus } from '@fcom/core-api/login';
import { AppState } from '@fcom/core';
import { loginToken, profileType, profile } from '@fcom/core/selectors';
import { unsubscribe } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';

import { Consent, ConsentGroup, ConsentStatus } from '../../interfaces';
import { ConsentService } from '../services/consent.service';

@Component({
  selector: 'fin-marketing-preferences',
  styleUrls: ['marketing-preferences.component.scss'],
  templateUrl: 'marketing-preferences.component.html',
})
export class MarketingPreferencesComponent implements OnInit, OnDestroy {
  // @TODO: rename f to something more meaningful
  @ViewChild('f', { static: false })
  marketingPreferencesForm: NgForm;

  title$: Observable<string>;
  consents$: Observable<Consent[]>;
  marketingPreferencesUrl$: Observable<string>;
  modalOpen = false;

  private consentGroup$: Observable<ConsentGroup>;
  private subscription: Subscription = new Subscription();

  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(
    private marketingConsentService: ConsentService,
    private store$: Store<AppState>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.marketingPreferencesUrl$ = this.languageService.translate('fragments.marketingPreferences.url').pipe(
      distinctUntilChanged(),
      filter((v) => !!v)
    );

    const accessToken$: Observable<string> = this.store$.pipe(
      loginToken(),
      filter((v) => !!v),
      take(1)
    );

    const accountType$: Observable<ProfileType> = this.store$.pipe(
      profileType(),
      filter((v) => !!v),
      take(1)
    );

    this.consentGroup$ = combineLatest([accessToken$, accountType$]).pipe(
      switchMap(([token, type]) => this.marketingConsentService.getMarketingConsents(token, type)),
      filter((v) => !!v),
      finShare()
    );

    this.title$ = this.consentGroup$.pipe(map((group) => group.introductionText));
    this.consents$ = this.consentGroup$.pipe(map((group) => group.consents));

    this.subscription.add(
      this.store$.pipe(profile()).subscribe((currentProfile) => {
        if (currentProfile.tier === ProfileTier.ACCOUNT && currentProfile.memberStatus === LoginMemberStatus.PENDING) {
          this.sendConsents(this.marketingPreferencesForm);
        } else {
          this.checkReconsent();
        }
      })
    );
  }

  checkReconsent(): void {
    this.subscription.add(
      this.consentGroup$
        .pipe(
          filter((group) => group.consents.filter((a) => a.reConsentRequired === true).length > 0),
          take(1)
        )
        .subscribe(() => {
          this.modalOpen = true;
        })
    );
  }

  sendConsents(formData: NgForm): void {
    const accessToken$ = this.store$.pipe(loginToken(), filter(Boolean), take(1));

    this.subscription.add(
      combineLatest([accessToken$, this.consents$])
        .pipe(
          switchMap(([token, consents]: [string, Consent[]]) => {
            return this.marketingConsentService.sendConsents(
              token,
              consents.map(
                (c) =>
                  ({
                    ...c,
                    consentStatus: formData.value[c.consentTextId] ? ConsentStatus.ACCEPTED : ConsentStatus.DECLINED,
                  }) as Consent
              )
            );
          })
        )
        .subscribe(() => {
          this.modalOpen = false;
        })
    );
  }

  shouldCloseModal(event: Event): void {
    const target: any = event.target;

    if (target && typeof target.tagName === 'string' && target.tagName.toLowerCase() === 'a') {
      this.modalOpen = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }
}

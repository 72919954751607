import { Component, createNgModuleRef, Injector, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsFormType, CmsPlaceholder, CmsTemplate, CmsViewType } from '@fcom/core-api';

@Component({
  selector: 'fin-render-lazy-component',
  templateUrl: './render-lazy-component.component.html',
  styleUrls: [],
})
export class RenderLazyComponent implements OnInit {
  @ViewChild('lazyLoadComponent', { read: ViewContainerRef })
  lazyLoadComponent!: ViewContainerRef;

  @Input()
  viewType: CmsViewType | CmsFormType;

  @Input()
  content?: CmsPlaceholder;

  @Input()
  content$?: Observable<CmsPlaceholder>;

  @Input()
  template?: CmsTemplate;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    //  Form
    if (this.viewType === CmsViewType.RETRIEVE_PAGE_CONTENT) {
      this.loadRetrievePage();
    } else if (
      Object.keys(CmsFormType)
        .map((key) => CmsFormType[key])
        .includes(this.viewType)
    ) {
      this.loadForm();
    }
    //  Destination-search
    if (this.viewType === CmsViewType.DESTINATIONS_SEARCH || this.viewType === CmsViewType.DESTINATION_SEARCH_HERO) {
      this.loadDestinationSearch();
    }
  }

  async loadDestinationSearch(): Promise<void> {
    const { DestinationSearchLazyModule, DestinationSearchPageComponent, DestinationSearchHeroComponent } =
      await import('@fcom/destination-search-lazy');

    const destinationSearchTypeToComponentMap = {
      [CmsViewType.DESTINATIONS_SEARCH]: DestinationSearchPageComponent,
      [CmsViewType.DESTINATION_SEARCH_HERO]: DestinationSearchHeroComponent,
    };
    const moduleRef = createNgModuleRef(DestinationSearchLazyModule, this.injector);

    const { instance } = this.lazyLoadComponent.createComponent<any>(
      destinationSearchTypeToComponentMap[this.viewType],
      {
        ngModuleRef: moduleRef,
      }
    );

    instance.template = this.template;
    instance.content$ = this.content$;
    instance.content = this.content;
  }

  async loadForm(): Promise<void> {
    const {
      BaggageTracingFormComponent,
      CancellationCoverClaimFormComponent,
      CaseAttachmentsFormComponent,
      CertificatesForInsuranceCompanyFormComponent,
      ClaimMissingPartnerPointsFormComponent,
      ClaimMissingPointsFormComponent,
      CorporateContactFormComponent,
      CustomerFeedbackFormComponent,
      ETicketReceiptFormComponent,
      GiftCardInformationFormComponent,
      JoinCorporateFormComponent,
      RefundFormComponent,
      RefundStatusCheckerFormComponent,
      SafDownloadFormComponent,
      ServiceFormsLazyModule,
      SpecialAssistanceFormComponent,
      SpecialAssistanceWizardComponent,
      TravelClassUpgradeFormComponent,
      VatReceiptFormComponent,
    } = await import('@fcom/service-forms-lazy');
    const moduleRef = createNgModuleRef(ServiceFormsLazyModule, this.injector);

    const formTypeToComponentMap = {
      [CmsFormType.BAGGAGE_TRACING_FORM]: BaggageTracingFormComponent,
      [CmsFormType.CANCELLATION_COVER_CLAIM_FORM]: CancellationCoverClaimFormComponent,
      [CmsFormType.CASE_ATTACHMENT_FORM]: CaseAttachmentsFormComponent,
      [CmsFormType.CERTIFICATES_FOR_INSURANCE_COMPANY_FORM]: CertificatesForInsuranceCompanyFormComponent,
      [CmsFormType.CLAIM_MISSING_PARTNER_POINTS_FORM]: ClaimMissingPartnerPointsFormComponent,
      [CmsFormType.CLAIM_MISSING_POINTS_FORM]: ClaimMissingPointsFormComponent,
      [CmsFormType.CORPORATE_CONTACT_FORM]: CorporateContactFormComponent,
      [CmsFormType.CUSTOMER_FEEDBACK_FORM]: CustomerFeedbackFormComponent,
      [CmsFormType.E_TICKET_RECEIPT_FORM]: ETicketReceiptFormComponent,
      [CmsFormType.GIFT_CARD_INFORMATION_FORM]: GiftCardInformationFormComponent,
      [CmsFormType.JOIN_CORPORATE_FORM]: JoinCorporateFormComponent,
      [CmsFormType.REFUND_FORM]: RefundFormComponent,
      [CmsFormType.REFUND_STATUS_CHECKER_FORM]: RefundStatusCheckerFormComponent,
      [CmsFormType.SAF_DOWNLOADABLE_FORM]: SafDownloadFormComponent,
      [CmsFormType.SPECIAL_ASSISTANCE_FORM]: SpecialAssistanceFormComponent,
      [CmsFormType.SPECIAL_ASSISTANCE_WIZARD]: SpecialAssistanceWizardComponent,
      [CmsFormType.TRAVEL_CLASS_UPGRADE_FORM]: TravelClassUpgradeFormComponent,
      [CmsFormType.VAT_RECEIPT_FORM]: VatReceiptFormComponent,
    };

    if (formTypeToComponentMap[this.viewType]) {
      const { instance } = this.lazyLoadComponent.createComponent<any>(formTypeToComponentMap[this.viewType], {
        ngModuleRef: moduleRef,
      });
      instance.template = this.template;
    }
  }

  async loadRetrievePage(): Promise<void> {
    const { RetrieveModule, RetrievePageContentComponent } = await import(
      '@fcom/service-forms-lazy/components/retrieve-page'
    );

    const moduleRef = createNgModuleRef(RetrieveModule, this.injector);

    this.lazyLoadComponent.createComponent<any>(RetrievePageContentComponent, { ngModuleRef: moduleRef });
  }
}

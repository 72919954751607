import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, Subject, Subscription, take, tap } from 'rxjs';
import { distinctUntilChanged, pairwise, startWith, withLatestFrom } from 'rxjs/operators';

import { ElementActions, ElementTypes, GaContext, MediaQueryService, OfferListFetchParams } from '@fcom/common';
import { GtmService } from '@fcom/common/gtm/services/gtm.service';
import { Cabin } from '@fcom/dapi/api/models';
import { getLocation } from '@fcom/locations';
import {
  ButtonSize,
  ButtonTheme,
  ButtonType,
  CheckBoxTheme,
  IconPosition,
  RadioItemGroupOption,
  TabTheme,
  PopoverOptions,
  PopoverPosition,
  PopoverService,
} from '@fcom/ui-components';
import { unsubscribe } from '@fcom/core/utils';
import { TicketSelectionPhase } from '@fcom/voluntary-change/enums';
import { AirOffersStatus, BookingAppState, SelectionPhase } from '@fcom/common/interfaces/booking';
import { BookingAirBoundsService } from '@fcom/booking/modules/ticket-selection/services';
import { profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';

import { defaultFilterValues, FlightFilters, FlightTimeOption } from '../../interfaces/flight-filters.interface';
import { isShortHaulFlight } from '../../utils/flight.utils';

export interface ResetFiltersEvent {
  type: keyof FlightFilters;
  cabin?: Cabin;
}

export interface ResetAllFiltersEvent {
  defaultCabin: Cabin | undefined;
}

@Component({
  selector: 'fin-flight-selection-filters',
  styleUrls: ['./flight-selection-filters.component.scss'],
  templateUrl: './flight-selection-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightSelectionFiltersComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Whether radio buttons in travel class filter should be disabled
   */
  @Input()
  isTravelClassChoicesDisabled: boolean;

  @Input()
  selectionPhase: SelectionPhase | TicketSelectionPhase;

  @Input()
  showRefundability = true;

  @Input()
  travelClasses$: Observable<Cabin[]>;

  /**
   * How many results the current filters produce
   * In desktop version used in all dropdowns' footer buttons
   * In mobile used in modal's footer button
   */
  @Input()
  resultsAmount: number;

  /**
   * Amount of layovers each flight has.
   * Used in stops filter
   */
  @Input()
  layoverAmounts: number[] = [];

  /**
   * Used in times filter
   */
  @Input()
  originLocationCode: string;

  /**
   * Used in times filter
   */
  @Input()
  destinationLocationCode: string;

  @Input() filters$: Observable<FlightFilters>;

  @Input()
  cabinLastRequestParam$: Observable<{ cabin: Cabin }> = of({ cabin: Cabin.MIXED });

  @Input()
  isTravelClassDisabled = false;

  @Input()
  showFlightSelectionFilters: boolean;

  @Output()
  setFilter: EventEmitter<FlightFilters> = new EventEmitter();
  @Output()
  resetFilter: EventEmitter<ResetFiltersEvent> = new EventEmitter();
  @Output()
  resetAllFilters: EventEmitter<ResetAllFiltersEvent> = new EventEmitter();
  @Output()
  showSpinner: EventEmitter<void> = new EventEmitter();

  selectedStopsOption: number = defaultFilterValues.defaultStopsOption;
  selectedRefundabilityOption: boolean = defaultFilterValues.defaultRefundabilityOption;
  selectedTravelClass: Cabin;
  selectedDepartureTimes: FlightTimeOption[] = defaultFilterValues.defaultTimeOptions;
  selectedArrivalTimes: FlightTimeOption[] = defaultFilterValues.defaultTimeOptions;
  defaultInboundTravelClass: Cabin;

  departureCityName: string;
  arrivalCityName: string;

  isModalOpen: boolean;

  readonly ButtonTheme = ButtonTheme;
  readonly ButtonType = ButtonType;
  readonly CheckBoxTheme = CheckBoxTheme;
  readonly ButtonSize = ButtonSize;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly TabTheme = TabTheme;
  readonly FlightTimeOption = FlightTimeOption;
  readonly Cabin = Cabin;
  readonly AirOffersStatus = AirOffersStatus;
  readonly SelectionPhase = SelectionPhase;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TicketSelectionPhase = TicketSelectionPhase;

  popoverOptions: PopoverOptions = {
    popoverID: 'flight-selection-filters',
    openByDefault: false,
    closeOnScroll: false,
    showArrowCaret: false,
    showLeftBorder: false,
    popoverPosition: PopoverPosition.BOTTOM,
    roundedCorners: true,
  };

  activeTab$ = new BehaviorSubject(0);
  showStopsFilter$: Observable<boolean>;
  awardQueryParams$: Observable<OfferListFetchParams>;
  isMobile$: Observable<boolean>;
  showClearAllBtn: boolean;
  shouldNotClearTravelClass: boolean;
  defaultTravelClass: Cabin;
  selectedFiltersLength$: BehaviorSubject<number> = new BehaviorSubject(0);
  resizedWatcher$: Subject<void> = new Subject();
  subscriptions: Subscription = new Subscription();
  filtersForm: FormGroup<{
    stopsCount: FormControl<null | string>;
    refundable: FormControl<string>;
    cabin: FormControl<Cabin>;
  }>;
  travelClassFilters: RadioItemGroupOption[] = [];

  constructor(
    private store$: Store<BookingAppState>,
    private popoverService: PopoverService,
    private mediaQueryService: MediaQueryService,
    private gtmService: GtmService,
    private bookingAirBoundsService: BookingAirBoundsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.cabinLastRequestParam$
        ?.pipe(
          filter(Boolean),
          withLatestFrom(this.store$.pipe(profileOrUndefinedIfNotLoggedIn()), this.isLongHaul()),
          // Don't do initial filtering for corporate long-hauls
          filter(([_, profile, isLongHaul]) => !(profile?.isCorporate && isLongHaul)),
          take(1),
          tap(([params]) => {
            if (this.selectionPhase === SelectionPhase.OUTBOUND) {
              this.setFilter.emit({ cabin: params.cabin });
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.travelClasses$
        .pipe(
          tap((travelClasses) => {
            const defaultIsNotMixedCabinForInbound =
              this.selectionPhase === SelectionPhase.INBOUND && this.defaultInboundTravelClass !== Cabin.MIXED;
            this.shouldNotClearTravelClass =
              this.isTravelClassDisabled || defaultIsNotMixedCabinForInbound || travelClasses.length === 1;
          }),
          take(1)
        )
        .subscribe()
    );
    this.subscriptions.add(
      combineLatest([this.travelClasses$, this.cabinLastRequestParam$])
        .pipe(take(1))
        .subscribe(([travelClasses, params]) => {
          this.defaultTravelClass = travelClasses.includes(Cabin.MIXED) ? Cabin.MIXED : params.cabin;
        })
    );

    this.subscriptions.add(
      this.travelClasses$.pipe(take(1)).subscribe((cabinArray) => {
        cabinArray.forEach((cabin) => {
          this.travelClassFilters.push({
            value: cabin,
            label: 'travelClass.' + cabin.toLowerCase(),
          });
        });
      })
    );

    this.subscriptions.add(this.filters$.pipe(tap(this.handleFilterChange.bind(this))).subscribe());

    this.showStopsFilter$ = this.shouldShowStopsFilter();
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
    this.updateLocations();

    this.filtersForm = this.fb.group({
      stopsCount: this.fb.control(null),
      cabin: this.fb.control({ value: this.defaultTravelClass, disabled: this.isTravelClassChoicesDisabled }),
      refundable: this.fb.control(false),
    });

    this.subscriptions.add(
      this.filters$
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
        .subscribe((filters) => {
          const refundable = filters.refundable === true ? 'true' : 'false';
          const stopsCount = filters.stopsCount === null ? null : String(filters.stopsCount);

          this.filtersForm.setValue({
            stopsCount: stopsCount,
            cabin: filters.cabin,
            refundable: refundable,
          });
        })
    );

    this.updateStoreFromFormChanges();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  ngOnChanges(): void {
    this.updateLocations();
  }

  handleFilterChange(filters: FlightFilters): void {
    if (
      this.selectionPhase === SelectionPhase.OUTBOUND &&
      this.selectedTravelClass &&
      filters.cabin !== this.selectedTravelClass
    ) {
      this.subscriptions.add(
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((p) => {
          const changedJson = JSON.stringify({
            ...JSON.parse(p.json),
            cabin: filters.cabin,
          });
          const navigationExtras: NavigationExtras = {
            queryParams: {
              json: changedJson,
            },
            queryParamsHandling: 'merge',
          };
          this.router.navigate([], navigationExtras);
        })
      );
    }

    if (
      this.selectedTravelClass &&
      this.selectedTravelClass !== filters.cabin &&
      this.selectionPhase === SelectionPhase.OUTBOUND
    ) {
      this.refetchBounds(filters.cabin);
    }

    this.selectedStopsOption = filters.stopsCount;
    this.selectedRefundabilityOption = filters.refundable;

    this.selectedTravelClass = filters.cabin;
    this.selectedDepartureTimes = filters.departureTime;
    this.selectedArrivalTimes = filters.arrivalTime;

    if (this.selectionPhase === SelectionPhase.INBOUND && !this.defaultInboundTravelClass) {
      this.defaultInboundTravelClass = filters.cabin;
    }

    this.updateSelectedFiltersLength();
    this.updateClearAllButton();
  }

  updateStoreFromFormChanges(): void {
    this.subscriptions.add(
      this.filtersForm.valueChanges
        .pipe(
          startWith(this.filtersForm.getRawValue()),
          distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
          pairwise()
        )
        .subscribe(([prev, next]) => {
          this.showSpinner.emit();
          this.updateCarouselWidth();

          if (prev.stopsCount !== next.stopsCount) {
            const stopsCount = next.stopsCount === null ? null : Number(next.stopsCount);
            this.setFilter.emit({ stopsCount });
            this.sendFiltersGaEvent(`booking-flow-select-filter-stops-${next.stopsCount}`);
          }

          if (prev.cabin !== next.cabin) {
            this.setFilter.emit({ cabin: next.cabin });
            this.sendFiltersGaEvent(`booking-flow-select-filter-class-${next.cabin.toLowerCase()}`);
          }

          if (prev.refundable !== next.refundable) {
            const refundable = next.refundable === 'true' ? true : false;
            this.setFilter.emit({ refundable });
            this.sendFiltersGaEvent(
              `booking-flow-select-filter-refundability-${refundable ? 'refundable' : 'nonrefundable'}`
            );
          }
        })
    );
  }

  sendFiltersGaEvent(elementName: string): void {
    this.gtmService.trackElement(
      elementName,
      GaContext.BOOKING_FLOW,
      ElementTypes.RADIO,
      undefined,
      ElementActions.CLICK
    );
  }

  updateClearAllButton(): void {
    // Check if selected stops option is not the default
    const isNotDefaultStopsOption = this.selectedStopsOption !== defaultFilterValues.defaultStopsOption;

    // Check if selected refundability option is not the default
    const isNotDefaultRefundabilityOption =
      this.selectedRefundabilityOption &&
      this.selectedRefundabilityOption !== defaultFilterValues.defaultRefundabilityOption;

    // Check if selected travel class is not the default and the flag allows clearing
    const isNotDefaultTravelClass =
      this.selectedTravelClass !== defaultFilterValues.defaultTravelClass && this.shouldNotClearTravelClass === false;

    // Check if selected departure times or arrival times are less than 3
    const isLessThanThreeDepartureTimes = this.selectedDepartureTimes?.length < 3;
    const isLessThanThreeArrivalTimes = this.selectedArrivalTimes?.length < 3;

    // Set the clear button visibility based on the conditions
    this.showClearAllBtn =
      isNotDefaultStopsOption ||
      isNotDefaultRefundabilityOption ||
      isNotDefaultTravelClass ||
      isLessThanThreeDepartureTimes ||
      isLessThanThreeArrivalTimes;
  }

  updateSelectedFiltersLength(): void {
    const selectedFilters = [
      this.selectedStopsOption !== defaultFilterValues.defaultStopsOption,
      this.selectedRefundabilityOption !== defaultFilterValues.defaultRefundabilityOption &&
        this.selectedRefundabilityOption !== undefined,
      this.selectedTravelClass !== defaultFilterValues.defaultTravelClass && this.selectedTravelClass !== undefined,
      (this.selectedDepartureTimes?.length ?? 0) < defaultFilterValues.defaultTimeOptions.length,
      (this.selectedArrivalTimes?.length ?? 0) < defaultFilterValues.defaultTimeOptions.length,
    ].filter(Boolean).length;

    this.selectedFiltersLength$.next(selectedFilters);
  }

  private isLongHaul(): Observable<boolean> {
    return combineLatest([
      this.store$.select(getLocation(this.originLocationCode)),
      this.store$.select(getLocation(this.destinationLocationCode)),
    ]).pipe(
      take(1),
      map(([origin, destination]) => !isShortHaulFlight(origin, destination))
    );
  }

  private refetchBounds(cabin: Cabin) {
    const queryParams = this.bookingAirBoundsService.getLastRequestParams();

    if (this.selectionPhase === SelectionPhase.OUTBOUND && !queryParams?.isAward) {
      return this.bookingAirBoundsService.triggerFetchBounds({ ...queryParams, cabin });
    } else {
      return null;
    }
  }

  clearFilter(flightFilter: keyof FlightFilters): void {
    this.showSpinner.emit();
    this.updateCarouselWidth();
    this.resetFilter.emit({
      type: flightFilter,
      cabin: this.defaultTravelClass,
    });
  }

  clearAllFilters(contextForAnalytics: 'desktop' | 'mobile' | 'empty-state'): void {
    this.showSpinner.emit();

    this.resetAllFilters.emit({
      defaultCabin: this.defaultTravelClass,
    });

    this.updateCarouselWidth();

    this.gtmService.trackElement(
      `booking-flow-clear-all-filters-${contextForAnalytics}`,
      GaContext.BOOKING_FLOW,
      ElementTypes.BUTTON,
      undefined,
      ElementActions.CLICK
    );
  }

  onSelectDepartureTime(departureTime: FlightTimeOption): void {
    const newDepartureTime = this.selectedDepartureTimes?.includes(departureTime)
      ? this.selectedDepartureTimes?.filter((d) => d !== departureTime)
      : [...this.selectedDepartureTimes, departureTime];

    this.showSpinner.emit();
    this.setFilter.emit({ departureTime: newDepartureTime });

    this.updateCarouselWidth();

    this.gtmService.trackElement(
      `booking-flow-select-filter-departure-${departureTime.toLowerCase()}`,
      GaContext.BOOKING_FLOW,
      ElementTypes.CHECKBOX,
      undefined,
      ElementActions.CLICK
    );
  }

  onSelectArrivalTime(arrivalTime: FlightTimeOption): void {
    const newArrivalTime = this.selectedArrivalTimes?.includes(arrivalTime)
      ? this.selectedArrivalTimes.filter((d) => d !== arrivalTime)
      : [...this.selectedArrivalTimes, arrivalTime];

    this.showSpinner.emit();
    this.setFilter.emit({ arrivalTime: newArrivalTime });

    this.updateCarouselWidth();

    this.gtmService.trackElement(
      `booking-flow-select-filter-arrival-${arrivalTime.toLowerCase()}`,
      GaContext.BOOKING_FLOW,
      ElementTypes.CHECKBOX,
      undefined,
      ElementActions.CLICK
    );
  }

  shouldShowStopsFilter(): Observable<boolean> {
    return this.filters$.pipe(
      take(1),
      map((filters) => {
        if (typeof filters?.stopsCount === 'number') {
          return true;
        }

        const hasDirectFlights = this.layoverAmounts.some((value) => value === 0);
        const hasMultipleStops = this.layoverAmounts.some((value) => value > 0);

        return hasDirectFlights && hasMultipleStops;
      })
    );
  }

  updateLocations(): void {
    this.subscriptions.add(
      combineLatest([
        this.store$.select(getLocation(this.originLocationCode)),
        this.store$.select(getLocation(this.destinationLocationCode)),
      ])
        .pipe(take(1))
        .subscribe(([origin, destination]) => {
          this.departureCityName = origin?.cityName;
          this.arrivalCityName = destination?.cityName;
        })
    );
  }

  updateCarouselWidth(): void {
    this.resizedWatcher$.next();
  }

  closePopover(): void {
    this.popoverService.close(true);
  }

  keepOrder(_a: unknown, _b: unknown): number {
    return 0;
  }

  getTimeRange(option: FlightTimeOption): string {
    switch (option) {
      case FlightTimeOption.MORNING:
        return '00:00 - 11:59 h';
      case FlightTimeOption.AFTERNOON:
        return '12:00 - 16:59 h';
      case FlightTimeOption.EVENING:
        return '17:00 - 23:59 h';
      default:
        return '';
    }
  }

  setActiveTimesTab({ index }: { index: number; data: unknown }): void {
    this.activeTab$.next(index);
  }

  showModal(): void {
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  sendAnalytics(contextForAnalytics: string): void {
    this.gtmService.trackElement(
      `booking-flow-open-filters-${contextForAnalytics}`,
      GaContext.BOOKING_FLOW,
      ElementTypes.BUTTON,
      undefined,
      ElementActions.CLICK
    );
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { BOOKING_STEPS } from '@fcom/common/config/booking-config';
import { LanguageService } from '@fcom/ui-translate';
import { NavigationMenuService } from '@fcom/common/services';
import { getBookingStepLink } from '@fcom/common/utils/booking-common.utils';
import { TripType } from '@fcom/core/constants';

import { FooterOffer } from '../../interfaces';

@Component({
  selector: 'fin-footer-offer',
  styleUrls: ['./footer-offer.component.scss'],
  templateUrl: './footer-offer.component.html',
})
export class FooterOfferComponent implements OnInit, OnDestroy {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;

  @Input()
  offer: FooterOffer;
  locationCode: string;
  visible = false;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private navMenuService: NavigationMenuService
  ) {}

  ngOnInit(): void {
    this.locationCode = this.offer.locationCode;
  }

  ngOnDestroy(): void {
    this.navMenuService.setFooterClosed();
  }

  toggleVisible(isVisible: boolean): void {
    this.visible = isVisible;

    if (this.visible) {
      this.navMenuService.setFooterOpen();
    } else {
      this.navMenuService.setFooterClosed();
    }
  }

  navigateTo(event: Event): void {
    event.preventDefault();
    this.router.navigateByUrl(
      getBookingStepLink(this.languageService.langValue, BOOKING_STEPS.ENTRY, { destination: this.locationCode })
    );
  }
}

import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, startWith } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { AppState, CmsDataService, RootPaths, WindowRef } from '@fcom/core';
import { CorporateUserRole, Profile, ProfileTier } from '@fcom/core-api/login';
import { profile } from '@fcom/core/selectors';
import { finShare } from '@fcom/rx';
import { pathIsWithinHardcodedPath } from '@fcom/core/utils/app-url-utils';
import { isNotBlank } from '@fcom/core/utils';
import { TIER_DATA_MAP } from '@fcom/common/interfaces';

import { LoyaltyRoutesPath } from '../../../../loyalty-core/interfaces/loyalty.interface';
import { MenuItemWithChildren, MenuJson } from '../../../interfaces';
import { LoginService } from '../../services';

export type TierDataMap = Record<
  ProfileTier[number],
  {
    name?: string;
    juniorName?: string;
    links: string;
    className: string;
    cardIcon?: CustomIcon;
    [CorporateUserRole.ADMIN]?: string;
    [CorporateUserRole.TRAVELLER]?: string;
  }
>;

@Component({
  selector: 'fin-profile-quick-view',
  styleUrls: ['profile-quick-view.component.scss'],
  templateUrl: 'profile-quick-view.component.html',
})
export class ProfileQuickViewComponent implements OnInit {
  @Output()
  close: EventEmitter<any> = new EventEmitter<any>();

  readonly IconLibrary = IconLibrary;
  readonly ProfileTier = ProfileTier;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  readonly tierDataMap: TierDataMap = {
    [ProfileTier.ACCOUNT]: {
      name: 'finnairPlus.tier.account',
      links: 'fragments.quickProfileViewLinksAccount.url',
      className: 'tier-account',
    },
    [ProfileTier.BASIC]: {
      name: 'finnairPlus.tier.basic',
      links: 'fragments.quickProfileViewLinksBasic.url',
      className: 'tier-basic',
      cardIcon: TIER_DATA_MAP[ProfileTier.BASIC].cardIcon,
    },
    [ProfileTier.JUNIOR]: {
      name: 'finnairPlus.tier.junior',
      links: 'fragments.quickProfileViewLinksJunior.url',
      className: 'tier-junior',
      cardIcon: TIER_DATA_MAP[ProfileTier.JUNIOR].cardIcon,
    },
    [ProfileTier.SILVER]: {
      name: 'finnairPlus.tier.silver',
      juniorName: 'finnairPlus.tier.juniorSilver',
      links: 'fragments.quickProfileViewLinksSilver.url',
      className: 'tier-silver',
      cardIcon: TIER_DATA_MAP[ProfileTier.SILVER].cardIcon,
    },
    [ProfileTier.GOLD]: {
      name: 'finnairPlus.tier.gold',
      juniorName: 'finnairPlus.tier.juniorGold',
      links: 'fragments.quickProfileViewLinksGold.url',
      className: 'tier-gold',
      cardIcon: TIER_DATA_MAP[ProfileTier.GOLD].cardIcon,
    },
    [ProfileTier.PLATINUM]: {
      name: 'finnairPlus.tier.platinum',
      juniorName: 'finnairPlus.tier.juniorPlatinum',
      links: 'fragments.quickProfileViewLinksPlatinum.url',
      className: 'tier-platinum',
      cardIcon: TIER_DATA_MAP[ProfileTier.PLATINUM].cardIcon,
    },
    [ProfileTier.LUMO]: {
      name: 'finnairPlus.tier.lumo',
      links: 'fragments.quickProfileViewLinksLumo.url',
      className: 'tier-lumo',
      cardIcon: TIER_DATA_MAP[ProfileTier.LUMO].cardIcon,
    },
    [ProfileTier.CORPORATE]: {
      links: 'fragments.quickProfileViewLinksCorporate.url',
      className: 'tier-corporate',
      [CorporateUserRole.ADMIN]: 'finnairPlus.corporate.admin',
      [CorporateUserRole.TRAVELLER]: 'finnairPlus.corporate.traveller',
    },
  };

  profile$: Observable<Profile>;
  menuItems$: Observable<MenuItemWithChildren[]>;
  dashboardUrl$: Observable<string>;

  constructor(
    private loginService: LoginService,
    private store$: Store<AppState>,
    private elementRef: ElementRef,
    private languageService: LanguageService,
    private cmsDataService: CmsDataService,
    private windowRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.profile$ = this.store$.pipe(profile(), finShare());

    this.menuItems$ = this.profile$.pipe(
      switchMap((memberProfile: Profile) => {
        const links = this.tierDataMap[memberProfile.tier]?.links ?? 'fragments.quickProfileViewLinksBasic.url';
        return this.languageService.translate(links).pipe(
          filter(isNotBlank),
          switchMap((url) => this.cmsDataService.getFragmentJson<MenuJson>(url)),
          map(({ top }) => top.filter(({ link }) => !link.includes(LoyaltyRoutesPath.TIER_PROGRESS_AND_BENEFITS))),
          startWith([])
        );
      }),
      distinctUntilChanged()
    );

    this.dashboardUrl$ = combineLatest([this.languageService.lang, this.profile$]).pipe(
      map(([lang, memberProfile]) =>
        memberProfile.tier === ProfileTier.CORPORATE
          ? `/${lang}/corporate`
          : memberProfile.tier === ProfileTier.ACCOUNT
            ? ''
            : `/${lang}/${LoyaltyRoutesPath.PLUS}`
      )
    );
  }

  logout(event: Event): void {
    const inFinnairPlus = pathIsWithinHardcodedPath(this.windowRef.nativeWindow.location.pathname, RootPaths.PLUS_ROOT);
    this.loginService.logout(inFinnairPlus ? { redirectPath: `/${this.languageService.langValue}` } : undefined);
    event.preventDefault();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.close.emit();
    }
  }
}

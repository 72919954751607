import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { EMPTY, Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';

import { FinnairBoundFareFamily, TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { LocalDate, TzDate, stopPropagation } from '@fcom/core/utils';
import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';
import { MediaQueryService } from '@fcom/common/services';
import { LocationRouteType } from '@fcom/core-api';
import { ConfigService, CurrencyService } from '@fcom/core/services';
import { TestVariant } from '@fcom/common/interfaces';
import { TripType } from '@fcom/core/constants';

import { ExtendedFareFamily, FareFamilyBadgeType } from '../../../interfaces';
import { shouldDisplayBenefit } from '../../../utils/benefit.utils';
import { isLightFareFamily, isSuperLightFareFamily } from '../../../utils/fare-family.utils';
import { getBagPriceStartingFromInEuros } from '../../../utils/ancillaries.utils';

@Component({
  selector: 'fin-fare-family-option',
  styleUrls: ['./fare-family-option.component.scss'],
  templateUrl: './fare-family-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirBoundsFareFamilyOptionComponent implements OnInit {
  expandOpen = false;

  @Input() context: string;

  @Input() accordionId: string;

  @Input() currencyCode$: Observable<string>;

  @Input()
  set fareFamily(fareFamily: ExtendedFareFamily) {
    this.filterBenefits(fareFamily);
  }

  @Input() routeType: LocationRouteType;

  @Input() isShortHaul: boolean;

  @Input() hasSpecialOffer = false;

  @Input() originCountryCode: string;

  @Input() destinationCountryCode: string;

  @Input() departureDate: string;

  @Input() tripType$: Observable<TripType> = EMPTY;

  @Output() selectFareFamily: EventEmitter<FinnairBoundFareFamily> = new EventEmitter();
  @Output() showDimensionsAndDetails: EventEmitter<FinnairBoundFareFamily> = new EventEmitter();

  extendedFareFamily: ExtendedFareFamily;
  highlightedBenefits: TranslatedBenefitRow[] = [];
  isMobile$: Observable<boolean>;
  enableLoyaltyAvios: boolean;
  enableBenefitChanges$: Observable<boolean>;
  showBaggagePrice$: Observable<boolean>;
  bagHighlightLabel$: Observable<string>;
  bagPrice$: Observable<string>;
  isSuperlightFareFamily: boolean;

  readonly FareFamilyBadgeType = FareFamilyBadgeType;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(
    private mediaQueryService: MediaQueryService,
    private configService: ConfigService,
    private currencyService: CurrencyService
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
    const isAward = !!this.extendedFareFamily.points;
    this.enableLoyaltyAvios = this.configService.cfg.enableLoyaltyAvios.bookingFlowNotifications && !isAward;

    const testVariant$ = of(TestVariant.NOT_IN_TEST);

    this.enableBenefitChanges$ = testVariant$.pipe(
      map((variant) => variant === TestVariant.B || variant === TestVariant.C)
    );

    this.showBaggagePrice$ = combineLatest([testVariant$, this.currencyCode$]).pipe(
      map(([testVariant, currencyCode]) => testVariant === TestVariant.B && currencyCode === 'EUR')
    );

    this.bagPrice$ = this.showBaggagePrice$.pipe(
      filter(Boolean),
      switchMap(() => {
        const price = getBagPriceStartingFromInEuros(
          this.originCountryCode,
          this.destinationCountryCode,
          this.extendedFareFamily.fareFamilyCode,
          LocalDate.fromTzDate(new TzDate(this.departureDate))
        );

        if (price) {
          return this.currencyService.formatPrice(
            {
              amount: price.toString(),
              currencyCode: 'EUR',
            },
            { stripZeroCents: true }
          );
        }

        return undefined;
      })
    );

    this.isSuperlightFareFamily = isSuperLightFareFamily(this.extendedFareFamily.fareFamilyCode);

    this.bagHighlightLabel$ = combineLatest([this.enableBenefitChanges$, this.showBaggagePrice$]).pipe(
      map(([enableBenefitChanges, showPrice]) => {
        if (enableBenefitChanges) {
          if (isLightFareFamily(this.extendedFareFamily.fareFamilyCode)) {
            return showPrice
              ? 'ticketSelection.fareFamily.bagHighlightWithPriceLight'
              : 'ticketSelection.fareFamily.bagHighlightLight';
          }

          if (this.isSuperlightFareFamily) {
            return showPrice
              ? 'ticketSelection.fareFamily.bagHighlightWithPriceSuperlight'
              : 'ticketSelection.fareFamily.bagHighlightSuperlight';
          }
        }

        return undefined;
      })
    );
  }

  handleSelectFareFamilyClick(e: Event, { ...baseBoundFareFamily }: ExtendedFareFamily): void {
    stopPropagation(e);
    this.selectFareFamily.emit(baseBoundFareFamily as FinnairBoundFareFamily);
  }

  private filterBenefits(fareFamily: ExtendedFareFamily) {
    this.extendedFareFamily = fareFamily;
    this.highlightedBenefits = fareFamily.benefits.translatedBenefitRows.filter((benefit) =>
      shouldDisplayBenefit(benefit)
    );
  }

  toggleExpand(isOpen: boolean): void {
    this.expandOpen = isOpen;
  }

  handleShowDetails(fareFamily: ExtendedFareFamily): void {
    this.showDimensionsAndDetails.emit(fareFamily as FinnairBoundFareFamily);
  }
}

<div class="ps-medium">
  <fin-scroll-handle-container #scrollContainer [elements]="headersList">
    <ng-container *ngIf="activeCabinClass$ | async as activeCabinClass">
      <ul
        class="no-list-style flex flex--wrap flex--row ps-small-y ms-small-l"
        *ngIf="(availableClasses$ | async)?.length > 1"
      >
        <ng-container *ngFor="let item of availableClasses$ | async; let index = index">
          <li class="ms-xsmall-r ms-xsmall-b">
            <fcom-tag-button
              [text]="'travelClass.' + item.toLowerCase() | finLocalization | titlecase"
              (tagClicked)="selectCabinClass(item)"
              [ngClass]="item"
              [attr.data-testid]="'cabin-class-chip-' + item"
              [theme]="activeCabinClass === item ? TagTheme.ACTIVE : TagTheme.DEFAULT"
            ></fcom-tag-button>
          </li>
        </ng-container>
      </ul>
    </ng-container>

    <ng-template #headerTemplate let-item>
      <div class="align-left font-body-1 relative">
        <div
          *ngIf="item.options.isMarked"
          class="absolute nordic-blue-900-bg white-text medium-type ps-xsmall-x new-fare-family-badge ng-star-inserted font-body-2 align-center rounded-bottom-left-large"
        >
          {{ 'booking.newFareFamilyLabel' | finLocalization }}
        </div>
        {{ item.value }}
      </div>
    </ng-template>

    <ng-template #itemTemplate let-item>
      <div
        class="flex-1"
        [ngClass]="{
          'nordic-blue-900-text': (enableBenefitChanges$ | async) === false || item.isPositive,
          'grey-600-text': (enableBenefitChanges$ | async) && !item.isPositive,
        }"
      >
        <div
          class="bold-type font-body-2"
          [ngClass]="{
            'heather-700-text': showKoreaNotification && ['cancel', 'rebook'].includes(item.key),
          }"
        >
          {{ item?.label }}
        </div>
        <div class="regular-type font-body-2">
          {{ item?.value }}
        </div>
      </div>
      <ng-container *ngFor="let child of item.children">
        <div
          class="ps-small-y ps-xlarge-l"
          [ngClass]="{
            'nordic-blue-900-text': (enableBenefitChanges$ | async) === false || child.isPositive,
            'grey-600-text': (enableBenefitChanges$ | async) && !child.isPositive,
          }"
        >
          <div class="bold-type font-body-2">
            {{ child.label }}
          </div>
          <div class="regular-type font-body-2">
            {{ child.value }}
          </div>
        </div>
      </ng-container>
    </ng-template>

    <fcom-table
      #tableComponent
      [rowData]="rowData"
      [headers]="headers"
      [cellTemplate]="itemTemplate"
      [caption]="'ticketSelection.compareBenefits' | finLocalization"
      [headerTemplate]="headerTemplate"
    ></fcom-table>
  </fin-scroll-handle-container>

  <article class="font-body-1">
    <section class="mr-xsmall-t">
      <p class="nordic-blue-900-text mr-medium-b font-body-1">
        {{ 'bookingSearch.boundSelection.rulesMayVary' | finLocalization }}
      </p>
      <fcom-notification *ngIf="hasBusLeg$ | async" class="block mr-xsmall-b">
        {{ 'bookingSearch.boundSelection.ticketTypeBusNotification' | finLocalization }}
      </fcom-notification>
      <fin-expand
        [id]="'generalFareFamilyRulesAndRestrictions'"
        [key]="'generalFareFamilyRulesAndRestrictions'"
        [finElemTracker]="'benefits-more-information'"
        [finElemContext]="TRACKING_CONTEXT"
        [finElemType]="ElementTypes.ACCORDION"
        #expand
      >
        <div class="trigger flex flex--space-between nordic-blue-900-text">
          <h3 class="font-heading-3">
            {{ 'bookingSearch.boundSelection.informationAboutBenefits' | finLocalization }}
          </h3>
          <fcom-icon
            class="inline-block rotate icon-medium"
            [class.rotate-active]="expand.isOpen$ | async"
            [name]="SvgLibraryIcon.CHEVRON_DOWN"
          ></fcom-icon>
        </div>
        <div class="content pr-medium-y">
          <cms-unstyled-fragment
            class="benefits-information"
            [contentUrl$]="'fragments.benefitsInformation.url' | finLocalization | finToObservable"
          ></cms-unstyled-fragment>
        </div>
      </fin-expand>
    </section>
  </article>
</div>

import { LocalDate } from '@fcom/core/utils';

interface MonthInfo {
  id: string;
  year: number;
  value: number;
  i18n: number;
}

export enum SpacerType {
  START,
  END,
}

export interface Month extends MonthInfo {
  days: Day[];
  monthArrayIndex?: number;
}

export interface Day {
  id: string;
  month: MonthInfo;
  value: number;
  weekday: number;
  i18n: number;
  today: boolean;
  past: boolean;
  future: boolean;
  date: LocalDate;
  selected?: boolean;
  spacer?: number;
  spacerType?: SpacerType;
  disabled?: boolean;
}

export interface UiDay {
  reference?: Day;
  instance?: Day;
  label?: string;
}

export interface DateSelection {
  startDate: LocalDate;
  endDate?: LocalDate;
}

import { Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, NEVER, Observable, Subscription, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { LoaderColor, LoaderTheme } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { AppState } from '@fcom/core';
import { unsubscribe } from '@fcom/core/utils';
import { WindowRef } from '@fcom/core/providers/window';
import { CmsDataService } from '@fcom/core/services';
import { profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors/login.selector';
import { MediaQueryService } from '@fcom/common';
import { finShare } from '@fcom/rx';

import { ChatStatus, HelpButtonStatus, PrechatFields } from '../../interfaces';
import { SalesforceChatConfig, SalesforceChatConfigService } from '../../services/salesforce-chat-config.service';
import { SalesforceChatService } from '../../services/salesforce-chat.service';

// The class of the div for "All agents are busy or offline, please try again later" message
// that we replace with our custom HTML
const OFFLINE_DIALOG_CLASS_NAME = 'embeddedServiceLiveAgentStateOfflineSupportDefaultUI';
const MODAL_OPEN_CLASS_NAME = 'modal-open';

@Component({
  selector: 'fin-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private allAgentsAreBusyOrOfflineSubscription = new Subscription();

  readonly HelpButtonStatus = HelpButtonStatus;
  readonly LoaderTheme = LoaderTheme;
  readonly LoaderColor = LoaderColor;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  chatStatus: ChatStatus = ChatStatus.INITIAL;
  embeddedSvc: {
    settings: { [key: string]: any };
    init: Function;
    bootstrapEmbeddedService: Function;
    addEventHandler: Function;
  };
  openChatTrigger$: BehaviorSubject<HelpButtonStatus> = new BehaviorSubject(HelpButtonStatus.VISIBLE);
  dirty$: Observable<boolean>;

  @ViewChild('target', { static: true }) target: ElementRef;

  // This is to set the mask all chat content in Hotjar sessions
  @HostBinding('attr.data-hj-suppress') maskHotJar = '';

  constructor(
    private cmsDataService: CmsDataService,
    private languageService: LanguageService,
    private salesforceChatConfigService: SalesforceChatConfigService,
    private salesforceChatService: SalesforceChatService,
    private store$: Store<AppState>,
    private windowRef: WindowRef,
    private mediaQueryService: MediaQueryService,
    private route: ActivatedRoute,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.initChat(this.salesforceChatConfigService.scriptSrcURL);

    this.subscription.add(
      this.salesforceChatService.openChat$.pipe(filter(() => !!this.embeddedSvc)).subscribe((fields: PrechatFields) => {
        this.embeddedSvc.settings.prepopulatedPrechatFields = {
          FirstName: fields.firstName,
          LastName: fields.lastName,
          Email: fields.email,
          Booking_Reference__c: fields.bookingReference,
        };
        this.startChat();
      })
    );

    // mimic the same behavior from the old SF chat button
    // when chat button is clicked once then it is visible always and everywhere until refresh of the page
    this.dirty$ = this.openChatTrigger$.pipe(
      filter((helpButtonStatus) => helpButtonStatus !== HelpButtonStatus.VISIBLE),
      map((helpButtonStatus) => helpButtonStatus !== HelpButtonStatus.VISIBLE),
      take(1),
      finShare()
    );

    this.subscription.add(
      // prevent from scrolling page when chat and prechat screens are open in mobile
      combineLatest([this.mediaQueryService.isBreakpoint$('mobile'), this.openChatTrigger$])
        .pipe(
          map(([isMobile, openChatTrigger]) => isMobile && openChatTrigger !== HelpButtonStatus.VISIBLE),
          distinctUntilChanged()
        )
        .subscribe((shouldSetModalOpen) => {
          if (shouldSetModalOpen) {
            this.renderer.addClass(this.document.body, MODAL_OPEN_CLASS_NAME);
          } else {
            this.renderer.removeClass(this.document.body, MODAL_OPEN_CLASS_NAME);
          }
        })
    );

    this.subscription.add(
      this.route.queryParams
        .pipe(
          take(1),
          filter((params) => params.openChat?.toLowerCase() === 'true')
        )
        .subscribe(() => {
          this.chatTrigger(HelpButtonStatus.CLICKED);
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
    unsubscribe(this.allAgentsAreBusyOrOfflineSubscription);
  }

  chat(): void {
    if (!this.embeddedSvc) {
      // Salesforce script failed to load/initialize, we don't have base settings, so unable to boot.
      this.chatStatus = ChatStatus.ERROR;
      return;
    }
    this.chatStatus = ChatStatus.LOADING;
    const config: SalesforceChatConfig = this.salesforceChatConfigService.config;

    // HACK. Salesforce Chat is unable to reboot with a new serviceQueue (language, buttonId) after
    // it has been booted, so we have to reload the whole page whenever language changes.
    this.subscription.add(
      this.languageService.lang.subscribe(() => {
        if (this.embeddedSvc.settings.buttonId) {
          window.location.reload();
        }
      })
    );

    this.subscription.add(
      combineLatest([
        zip(this.languageService.lang, this.languageService.localizations),
        this.store$.pipe(profileOrUndefinedIfNotLoggedIn()),
      ]).subscribe(([[langValue, translations], profile]) => {
        // Call config.setLanguage to set configuration based on language,
        // serviceQueueId is set by that if Chat is enabled for that language
        config.setLanguage(langValue);

        if (config.serviceQueueId) {
          config.setTargetElement(this.target.nativeElement);

          // If the user is logged in, pass information to pre-chat form
          if (profile) {
            config.settings.prepopulatedPrechatFields = {
              FirstName: profile.firstname,
              LastName: profile.lastname,
              Email: profile.email,
              Subject: '',
              Finnair_Plus_Number__c: profile.memberNumber,
              Case_Member_Tier__c: profile.tier,
            };
          }
          if (translations.chat) {
            config.settings.defaultMinimizedText = translations.chat.defaultMinimizedText;
            config.settings.disabledMinimizedText = translations.chat.defaultMinimizedText;
            config.settings.offlineSupportMinimizedText = translations.chat.defaultMinimizedText;
          } else {
            delete config.settings.disabledMinimizedText;
            delete config.settings.defaultMinimizedText;
            delete config.settings.offlineSupportMinimizedText;
          }

          // when chat is fully expanded and visible
          this.embeddedSvc.addEventHandler('afterMaximize', () => {
            this.openChatTrigger$.next(HelpButtonStatus.HIDDEN);
          });

          // when chat is closed (X is clicked)
          this.embeddedSvc.addEventHandler('afterDestroy', () => {
            this.openChatTrigger$.next(HelpButtonStatus.VISIBLE);
          });

          this.embeddedSvc.settings = { ...this.embeddedSvc.settings, ...config.settings };
          this.embeddedSvc.init(...config.initParams);

          this.chatStatus = ChatStatus.COMPLETE;

          // TODO: add masking class via javascript?
        }
      })
    );
  }
  /*
    Ask our service to load SalesForce's external .js file. That .js file adds an object to window.embedded_svc.
    Store that reference and then boot up the chat with this.chat()
  */
  initChat(scriptSrcURL: string): void {
    this.salesforceChatService
      .load(scriptSrcURL)
      .then(() => {
        this.embeddedSvc = this.windowRef.nativeWindow['embedded_svc'];
        this.chat();
      })
      .catch((_error) => {
        this.chatStatus = ChatStatus.ERROR;
      });
  }

  /*
   * Listens to events of MutationObserver (finMutationObserver) and replaces the basic unlocalized
   * "All agents are offline or busy" message with a Finnair custom message that contains chat's service
   * hours and information that the US site's chat is open 24/7
   */
  onDomChange($event: any): void {
    const addedNodes: NodeList = $event.addedNodes;
    addedNodes.forEach((node: Element) => {
      if (node.classList?.contains(OFFLINE_DIALOG_CLASS_NAME)) {
        // The "All agents are busy or offline" message appeared, replace it
        // with a HTML fragment from CMS
        this.subscription.remove(this.allAgentsAreBusyOrOfflineSubscription);
        this.allAgentsAreBusyOrOfflineSubscription = this.languageService
          .translate('fragments.chatAgentsBusyOrOffline.url')
          .pipe(
            filter((v) => !!v),
            switchMap((url) => (!url ? NEVER : this.cmsDataService.getFragmentJson(url))),
            filter((s: any) => s.detailText),
            map((s: any) => s.detailText),
            take(1)
          )
          .subscribe((detailTextHtml: string) => {
            node.innerHTML = detailTextHtml;
          });
        this.subscription.add(this.allAgentsAreBusyOrOfflineSubscription);
      }
    });
  }

  chatTrigger(shouldOpen: HelpButtonStatus): void {
    this.openChatTrigger$.next(shouldOpen);
  }

  startChat(): void {
    this.embeddedSvc.bootstrapEmbeddedService();
    this.openChatTrigger$.next(HelpButtonStatus.CHAT);
  }
}

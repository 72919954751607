import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  EMPTY,
  BehaviorSubject,
  Observable,
  Subscription,
  filter,
  of,
  take,
  combineLatest,
  map,
  switchMap,
} from 'rxjs';
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { Direction, GlobalBookingTripDates, HistogramBar } from '@fcom/common';
import { LocalDate, isPresent, unsubscribe } from '@fcom/core/utils';
import { ButtonMode, ButtonSize, ButtonTheme, DateRange } from '@fcom/ui-components';
import { TripType } from '@fcom/core';
import { Amount } from '@fcom/dapi';
import { finShare } from '@fcom/rx';

import { DatePickerPrices } from '../../interfaces';
import { BookingWidgetGtmService } from '../../services/booking-widget-gtm.service';
import { getStartingFromPrice } from '../../utils/utils';
import {
  AmAvailability,
  AmHolidayType,
  AmHolidayTypeDuration,
  AmLocation,
  AmSearchable,
} from '../../interfaces/am-api.interface';

@Component({
  selector: 'fin-travel-dates-selector',
  templateUrl: 'travel-dates-selector.component.html',
  styleUrls: ['./travel-dates-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelDatesSelectorComponent implements OnInit, OnDestroy {
  readonly AmHolidayType = AmHolidayType;
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;

  @Input()
  disabled = false;

  @Input()
  previousFlightDepartureDate$: Observable<LocalDate> = of(null);

  @Input()
  travelDates$: Observable<GlobalBookingTripDates>;

  @Input()
  isOneway$: Observable<boolean> = of(false);

  @Input()
  flexibleDates = false;

  @Input()
  showFlexibleDatesSelection = true;

  @Input()
  showSingular?: keyof GlobalBookingTripDates;

  @Input()
  showSeparator = true;

  @Input()
  prices$: Observable<DatePickerPrices> = EMPTY;

  @Input()
  isAm = false;

  @Input()
  amDestination$: Observable<AmLocation> = of(undefined);

  @Input()
  amAvailability$: Observable<AmAvailability> = EMPTY;

  @Input()
  showAddReturn = false;

  @Input()
  identifier: string;

  @Input()
  highLight$ = of(false);

  @Output()
  setTravelDates = new EventEmitter<GlobalBookingTripDates>();

  @Output()
  setFlexibleDates = new EventEmitter<boolean>();

  @Output()
  setDuration = new EventEmitter<AmHolidayTypeDuration>();

  @Output()
  addReturn = new EventEmitter<void>();

  subscription = new Subscription();
  modalOpen = false;
  calendarRange: DateRange = [LocalDate.now(), LocalDate.now().plusDays(360)];
  pricePerAdult$: Observable<Amount> = of(undefined);
  datesSelected$: Observable<boolean>;
  selectedDuration$ = new BehaviorSubject<AmHolidayTypeDuration>(undefined);
  disabledDateRanges$: Observable<DateRange[]>;
  suggestedAmTravelDays$: Observable<string[]> = EMPTY;
  datePickerTitleLabel$: Observable<string>;

  selectedHistogramMonth$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  scrollToHistogramMonth$: BehaviorSubject<number> = new BehaviorSubject(undefined);

  @ViewChild('calendarHeader')
  calendarHeader: TemplateRef<unknown>;

  constructor(private bookingWidgetGtmService: BookingWidgetGtmService) {}

  ngOnInit(): void {
    this.pricePerAdult$ = combineLatest([this.prices$, this.travelDates$, this.isOneway$]).pipe(
      map(([prices, travelDates, isOneWay]) => getStartingFromPrice(prices, travelDates, isOneWay))
    );

    this.datesSelected$ = combineLatest([this.travelDates$, this.isOneway$]).pipe(
      map(([travelDates, isOneWay]) =>
        isOneWay
          ? isPresent(travelDates?.departureDate)
          : isPresent(travelDates?.departureDate) && isPresent(travelDates?.returnDate)
      )
    );

    this.datePickerTitleLabel$ = combineLatest([this.travelDates$, this.isOneway$]).pipe(
      map(([travelDates, isOneWay]) => {
        if (isOneWay) {
          return 'bookingWidget.priceCalendar.selectDeparture.oneWay';
        }
        return travelDates.departureDate
          ? 'bookingWidget.priceCalendar.selectReturn'
          : 'bookingWidget.priceCalendar.selectDeparture.roundTrip';
      })
    );

    this.disabledDateRanges$ = this.amDestination$.pipe(
      switchMap((amDestination) => {
        if (!isPresent(amDestination)) {
          return this.calculateDisabledDatesBasedOnPreviousFlightDepartureDate();
        }

        if (amDestination.holidayType === AmHolidayType.AM) {
          return of(this.calculateDisabledDatesBasedOnSearchables(amDestination.searchable));
        }

        return combineLatest([this.amAvailability$, this.travelDates$]).pipe(
          map(([amAvailability, travelDates]) => this.getDisabledDatesForDpHolidayType(amAvailability, travelDates))
        );
      }),
      finShare()
    );

    this.suggestedAmTravelDays$ = combineLatest([this.amAvailability$, this.selectedDuration$]).pipe(
      filter(([availability, duration]) => !!availability && !!duration),
      map(([availability, duration]) =>
        Object.entries(availability)
          .filter((dayEntry) => !!dayEntry[1][duration.code])
          .map((dayEntry) => dayEntry[0])
      ),
      finShare()
    );
  }

  selectDuration(duration: AmHolidayTypeDuration): void {
    this.selectedDuration$.next(duration);
    this.setDuration.emit(duration);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  openModal(): void {
    this.modalOpen = true;
  }

  closeModal(): void {
    this.modalOpen = false;
    // reset histogram selected month when modal closes
    this.selectedHistogramMonth$.next(undefined);
  }

  updateCalendarDates(calendarDates: [string] | [string, string]): void {
    const [departureDate, returnDate] = calendarDates;

    // Currently the calendar emits (selectedDatesChange) events even when hovering on dates (on every render), so we'll limit
    // unnecessary emissions here by checking that the values have changed
    this.subscription.add(
      this.travelDates$
        .pipe(
          take(1),
          filter(
            (travelDates) =>
              departureDate !== travelDates.departureDate?.id || returnDate !== travelDates.returnDate?.id
          )
        )
        .subscribe(() => {
          this.setTravelDates.emit({
            departureDate: departureDate ? new LocalDate(departureDate) : undefined,
            returnDate: returnDate ? new LocalDate(returnDate) : undefined,
          });
        })
    );
  }

  setHistogramMonth(selectedMonthIndex = 0): void {
    this.selectedHistogramMonth$.next(selectedMonthIndex);
  }

  handleHistogramClick(selectedBar: HistogramBar): void {
    if (!selectedBar.noFlight) {
      this.bookingWidgetGtmService.trackElementEvent(
        'histogram',
        selectedBar?.isCheapest ? 'lowest-month-click' : 'other-month-click'
      );
    }

    this.scrollToHistogramMonth$.next(selectedBar.index);
  }

  handleBarsScrolled(_direction: Direction): void {
    // TODO: for future, maybe handle the direction where the histogram is scrolled
    this.bookingWidgetGtmService.trackElementEvent('histogram', 'arrow-click');
  }

  private calculateDisabledDatesBasedOnPreviousFlightDepartureDate(): Observable<DateRange[]> {
    return this.previousFlightDepartureDate$.pipe(
      map((departureDate: LocalDate) => (departureDate ? [[LocalDate.now(), departureDate.minusDays(1)]] : []))
    );
  }

  private calculateDisabledDatesBasedOnSearchables(searchables: AmSearchable[]): DateRange[] {
    return [...searchables]
      .sort((a, b) => (a.from > b.from ? 1 : a.from === b.from ? 0 : -1))
      .reduce((disabledDateRanges: DateRange[], searchable, currentIndex, allSearchables) => {
        const returnValue = disabledDateRanges;

        // disable all days before first searchable's "from" date
        if (currentIndex === 0) {
          returnValue.push([LocalDate.now(), new LocalDate(searchable.from).minusDays(1)]);
        }

        // disable all days after last searchable's "to" date
        if (currentIndex === allSearchables.length - 1) {
          returnValue.push([new LocalDate(searchable.to).plusDays(1), new LocalDate(searchable.to).plusYears(1)]);
        } else {
          // disable days between current and next searchable if not last
          returnValue.push([
            new LocalDate(searchable.to).plusDays(1),
            new LocalDate(allSearchables[currentIndex + 1].from).minusDays(1),
          ]);
        }

        return returnValue;
      }, []);
  }

  private getDisabledDatesForDpHolidayType(
    amAvailability: AmAvailability,
    travelDates: GlobalBookingTripDates
  ): DateRange[] {
    if (travelDates.departureDate && !travelDates.returnDate) {
      return this.calculateDisabledDaysBasedOnDepartureAndAvailableDurations(amAvailability, travelDates.departureDate);
    }
    return this.calculateDisabledDaysBasedOnAvailableDays(Object.keys(amAvailability));
  }

  private calculateDisabledDaysBasedOnDepartureAndAvailableDurations(
    amAvailability: AmAvailability,
    departureDate: LocalDate
  ): DateRange[] {
    return Object.keys(amAvailability).length > 0
      ? this.calculateDisabledDaysBasedOnAvailableDays(
          Object.keys(amAvailability[departureDate.toISOString().substring(0, 10)] || {})
            .map((duration) => parseInt(duration, 10))
            .sort((a, b) => a - b)
            .reduce((dates: LocalDate[], duration) => [...dates, departureDate.plusDays(duration)], [departureDate])
            .map((date) => date.toISOString().substring(0, 10))
        )
      : [];
  }

  private calculateDisabledDaysBasedOnAvailableDays(days: string[]): DateRange[] {
    return [...days]
      .sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))
      .reduce((disabledDateRanges: DateRange[], availableDay, currentIndex, availableDays) => {
        const returnValue = disabledDateRanges;

        if (currentIndex === 0) {
          // disable days before first available date
          returnValue.push([LocalDate.now(), new LocalDate(availableDay).minusDays(1)]);
        }

        // disable all days after last available day
        if (currentIndex === availableDays.length - 1) {
          returnValue.push([new LocalDate(availableDay).plusDays(1), new LocalDate(availableDay).plusYears(1)]);
        } else if (new LocalDate(availableDay).plusDays(1).lt(new LocalDate(availableDays[currentIndex + 1]))) {
          // disable days between current and next available day if not last
          returnValue.push([
            new LocalDate(availableDay).plusDays(1),
            new LocalDate(availableDays[currentIndex + 1]).minusDays(1),
          ]);
        }

        return returnValue;
      }, []);
  }
}

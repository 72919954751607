import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  Subscription,
  take,
  withLatestFrom,
  map,
  distinctUntilChanged,
} from 'rxjs';

import { TripType } from '@fcom/core/constants';
import { stopPropagation } from '@fcom/core/utils';
import { CmsTripTypeMap } from '@fcom/core/utils/tripType.utils';
import { ButtonTheme, RadioButtonTheme, PopoverOptions, PopoverService } from '@fcom/ui-components';

import { BookingWidgetService } from '../../services/booking-widget.service';
import { defaultWidgetPopoverOptions } from '../../constants';

@Component({
  selector: 'fin-trip-type-selector',
  styleUrls: ['./trip-type-selector.component.scss'],
  templateUrl: './trip-type-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripTypeSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  disabled = false;

  @Input()
  selectedTripType$: Observable<TripType>;

  @Input()
  availableTripTypes$: Observable<TripType[]>;

  @Input()
  highLight$: Observable<boolean>;

  @Output()
  setTripType = new EventEmitter<TripType>();

  readonly FORM_CONTROL_NAME = 'tripType';
  readonly RadioButtonTheme = RadioButtonTheme;
  readonly ButtonTheme = ButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;
  readonly CmsTripTypeMap = CmsTripTypeMap;
  readonly popoverOptions: PopoverOptions = {
    ...defaultWidgetPopoverOptions,
    popoverID: 'tripTypeSelectorPopover',
    alignToLeft: true,
    disableAutoFocus: true,
  };

  modalOpen = false;
  selectedElement: ElementRef = null;
  currentTripType$ = new BehaviorSubject<TripType>(TripType.RETURN);
  selectedTripTypeIndex$ = new BehaviorSubject<number>(0);
  subscription = new Subscription();
  popOverFormGroup: FormGroup = new FormGroup({
    [this.FORM_CONTROL_NAME]: new FormControl(),
  });
  tripTypeOptions$: Observable<{ value: TripType; label: string }[]>;

  @ViewChildren('tripTypeRadioBtn', { read: ElementRef }) tripTypeRadioBtn: QueryList<ElementRef>;

  constructor(
    private popoverService: PopoverService,
    private bookingWidgetService: BookingWidgetService
  ) {}

  ngOnInit(): void {
    this.tripTypeOptions$ = this.availableTripTypes$.pipe(
      map((tripTypes) =>
        tripTypes.map((tripType) => ({
          value: tripType,
          label: `travelType.${CmsTripTypeMap[tripType]}`,
        }))
      )
    );

    this.subscription.add(
      this.selectedTripType$
        .pipe(withLatestFrom(this.availableTripTypes$))
        .subscribe(([tripType, availableTripTypes]) => {
          const selectedIndex = availableTripTypes.findIndex((t) => t === tripType);
          this.selectedTripTypeIndex$.next(selectedIndex);
        })
    );

    if (this.bookingWidgetService.usePopoverSelectors()) {
      this.subscription.add(
        this.selectedTripType$.subscribe((tripType) => {
          this.popOverFormGroup.controls[this.FORM_CONTROL_NAME].setValue(tripType);
        })
      );
      this.subscription.add(
        this.popOverFormGroup
          .get(this.FORM_CONTROL_NAME)
          .valueChanges.pipe(distinctUntilChanged())
          .subscribe((value) => {
            this.setTripType.emit(value);
          })
      );
    }
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.selectedTripTypeIndex$
        .pipe(withLatestFrom(this.availableTripTypes$))
        .subscribe(([index, availableTripTypes]) => {
          this.currentTripType$.next(availableTripTypes[index]);
          this.tripTypeRadioBtn.get(index).nativeElement?.focus();
        })
    );
  }

  handleKeyPress(selectedTripType: TripType, event: KeyboardEvent): void {
    stopPropagation(event);
    const currentIndex = this.selectedTripTypeIndex$.getValue();
    const maxIndex = this.tripTypeRadioBtn.length - 1;

    switch (event.key) {
      case 'Down':
      case 'ArrowDown':
      case 'Right':
      case 'ArrowRight':
        this.selectedTripTypeIndex$.next(currentIndex + 1 > maxIndex ? 0 : currentIndex + 1);
        break;
      case 'Up':
      case 'ArrowUp':
      case 'Left':
      case 'ArrowLeft':
        this.selectedTripTypeIndex$.next(currentIndex - 1 < 0 ? maxIndex : currentIndex - 1);
        break;
      case 'Enter':
      case ' ':
        this.selectTripType(selectedTripType);
        break;
      default:
        break;
    }
  }

  openModal(): void {
    if (!this.bookingWidgetService.usePopoverSelectors()) {
      this.modalOpen = true;
    }
  }

  selectTripType(selectedTripType: TripType): void {
    this.modalOpen = false;
    this.setTripType.emit(selectedTripType);
  }

  closePopover(): void {
    this.popOverFormGroup.setValue({ [this.FORM_CONTROL_NAME]: this.currentTripType$.getValue() });
    this.popoverService.close(true);
  }

  //TODO: check if this function is needed
  confirmOnClose(): void {
    this.subscription.add(
      combineLatest([this.selectedTripType$, this.currentTripType$])
        .pipe(
          filter(([selected, current]) => selected !== current),
          take(1)
        )
        .subscribe(([_selected, current]) => {
          this.setTripType.emit(current);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription = null;
  }
}

<button
  #buttonElement
  *ngIf="day"
  class="day fill font-body-1 medium-type"
  [disabled]="day.disabled"
  [attr.tabindex]="isActiveCell ? '0' : '-1'"
  [attr.data-day]="day.id"
  [attr.aria-label]="ariaLabel"
  [class.nordic-blue-900-bg]="
    cellSelectionStyling === SelectedDayType.ENDDATE ||
    cellSelectionStyling === SelectedDayType.STARTDATE ||
    cellSelectionStyling === SelectedDayType.SINGLE ||
    isSelectionHover
  "
  [class.white-text]="
    cellSelectionStyling === SelectedDayType.ENDDATE ||
    cellSelectionStyling === SelectedDayType.STARTDATE ||
    cellSelectionStyling === SelectedDayType.SINGLE ||
    isSelectionHover
  "
  [class.nordic-blue-50-bg]="cellSelectionStyling === SelectedDayType.INBETWEEN"
  [class.selectionStart]="cellSelectionStyling === SelectedDayType.STARTDATE"
  [class.selectionEnd]="cellSelectionStyling === SelectedDayType.ENDDATE"
  [class.selectionSingle]="cellSelectionStyling === SelectedDayType.SINGLE"
  [class.selectableDay]="!day.disabled"
  [class.disabled-area]="day.disabled"
  (mouseenter)="hoverDay(day)"
  (mouseleave)="hoverDay(null)"
  (keydown.space)="selectDay(day); $event.preventDefault()"
  (keydown.enter)="selectDay(day); $event.preventDefault()"
  (click)="selectDay(day)"
>
  <ng-container
    *ngIf="template"
    [ngTemplateOutlet]="template"
    [ngTemplateOutletContext]="{ dayString: day.value, dayValue: day }"
  >
  </ng-container>
  <div *ngIf="!template">{{ day.value }}</div>
</button>

<div *ngIf="!day" class="day" [class.nordic-blue-50-bg]="cellSelectionStyling === SelectedDayType.INBETWEEN">
  &nbsp;
</div>

import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { filter, map, pluck, tap } from 'rxjs/operators';

import {
  CmsCollection,
  CmsContent,
  CmsContentType,
  CmsImageData,
  CmsTaxonomy,
  CmsTaxonomyGeneral,
  CmsTemplate,
  CmsViewType,
  ResponseCodeHandler,
} from '@fcom/core-api';
import { LoginStatus, ProfileTier } from '@fcom/core-api/login';
import { DdsLanguage } from '@fcom/core/interfaces';
import { isObject, isString, symbolToLangPath } from '@fcom/core/utils';
import { SentryLogger } from '@fcom/core';

const HEADER_LEVEL = 'header-level';

export const getGridClass = (content: CmsContent): string => {
  const gridClassFromTag = content?.subjectTaxonomyTags?.find((tag) => tag.startsWith(CmsTaxonomyGeneral.SECTION));
  if (gridClassFromTag) {
    return content?.subjectTaxonomyTags
      ?.filter((tag) => tag.indexOf('cmstyle-') > -1)
      .map((tag) => tag.replace('cmstyle-', ''))
      .join(' ');
  }
  if (content.viewTypeName) {
    const splitted = content.viewTypeName.split('--');
    if (splitted.length > 1) {
      return splitted[0];
    }
    if (content.viewTypeName.startsWith('section-')) {
      return content.viewTypeName;
    }
  }
  return '';
};

export const getHeaderLevel = (
  subjectTaxonomyTags: CmsTaxonomy[],
  contentId?: number,
  sentryLogger?: SentryLogger
): number | undefined => {
  const headerLevelTags = subjectTaxonomyTags?.filter((tag) => tag.startsWith(HEADER_LEVEL));
  if (headerLevelTags?.length > 1 && sentryLogger) {
    sentryLogger.error('Each CMS content should have only one header-level subjectTaxonomyTag.', contentId);
  }
  return headerLevelTags && headerLevelTags.length > 0
    ? Number(headerLevelTags[0].substring(HEADER_LEVEL.length + 1))
    : undefined;
};

export const getViewType = (content: CmsContent): CmsViewType => {
  if (content.viewTypeName) {
    const splitted = content.viewTypeName.split('--');
    if (splitted.length > 1) {
      return splitted[1] as CmsViewType;
    }
    if (content.viewTypeName.indexOf('section-') === -1) {
      return content.viewTypeName as CmsViewType;
    }
  }
  return null;
};

export const getBackgroundImageForCmsTemplate = (template$: Observable<CmsTemplate>): Observable<CmsImageData> => {
  return template$.pipe(
    pluck('main'),
    map((content) => content?.find((obj) => obj.contentType === CmsContentType.CMPlaceholder)),
    filter(Boolean),
    pluck('picture')
  );
};

export const getShowForLoginStatus = (content: CmsContent): LoginStatus => {
  const tags = content.subjectTaxonomyTags || [];
  const hasLoggedIn = tags.includes(CmsTaxonomyGeneral.SHOW_FOR_LOGGED_IN);
  const hasNotLoggedIn = tags.includes(CmsTaxonomyGeneral.SHOW_FOR_NOT_LOGGED_IN);
  if (hasLoggedIn && hasNotLoggedIn) {
    return null;
  }
  if (hasLoggedIn) {
    return LoginStatus.LOGGED_IN;
  }
  if (hasNotLoggedIn) {
    return LoginStatus.NOT_LOGGED_IN;
  }
  return null;
};

export const getShowForProfileTiers = (content: CmsContent): ProfileTier[] => {
  const tags = content.subjectTaxonomyTags || [];

  const tierTagMap = {
    [CmsTaxonomyGeneral.SHOW_FOR_BASIC_TIER]: ProfileTier.BASIC,
    [CmsTaxonomyGeneral.SHOW_FOR_SILVER_TIER]: ProfileTier.SILVER,
    [CmsTaxonomyGeneral.SHOW_FOR_GOLD_TIER]: ProfileTier.GOLD,
    [CmsTaxonomyGeneral.SHOW_FOR_PLATINUM_TIER]: ProfileTier.PLATINUM,
    [CmsTaxonomyGeneral.SHOW_FOR_PLATINUM_LUMO_TIER]: ProfileTier.LUMO,
    [CmsTaxonomyGeneral.SHOW_FOR_JUNIOR_TIER]: ProfileTier.JUNIOR,
  };

  return tags.map((tag) => tierTagMap[tag]).filter(Boolean);
};

const resolveOldWwwLinks = (obj: any, ddsLocale: DdsLanguage): void => {
  Object.keys(obj).forEach((key: string) => {
    if (isString(obj[key])) {
      obj[key] = symbolToLangPath(obj[key], ddsLocale);
    } else if (isObject(obj[key])) {
      resolveOldWwwLinks(obj[key], ddsLocale);
    }
  });
};

export const recursiveParse = (
  item: CmsContent | CmsCollection,
  sentryLogger: SentryLogger
): CmsContent | CmsCollection => {
  const recursiveParseMapper = (item: CmsContent | CmsCollection) => recursiveParse(item, sentryLogger);
  // @TODO: Remove delete responsiveImageData when CMS has migrated away from these
  if (item.picture) {
    delete item.picture['responsiveImageData'];
  }
  if (Array.isArray(item['media'])) {
    item['media'].forEach((media) => {
      delete media['responsiveImageData'];
    });
  }
  const content: CmsContent = {
    ...item,
    gridClass: getGridClass(item),
    viewType: getViewType(item),
    showForLoginStatus: getShowForLoginStatus(item),
    showForProfileTiers: getShowForProfileTiers(item),
    headerLevel: getHeaderLevel(item?.subjectTaxonomyTags, item?.contentId, sentryLogger),
  };

  if (Array.isArray(item.related) && item.related.length) {
    content.related = item.related.map(recursiveParseMapper);
  }

  if (
    (item.contentType === CmsContentType.CMTeaser || item.contentType === CmsContentType.CMPersonalized) &&
    Array.isArray(item.items) &&
    item.items.length
  ) {
    return {
      ...content,
      items: item.items.map(recursiveParseMapper),
    };
  }
  if (item.contentType === CmsContentType.CMCollection) {
    return {
      ...content,
      items: (item as CmsCollection).items.map(recursiveParseMapper),
    };
  }
  return content;
};

export const hasPersonalizedContent = (items: CmsContent[] | undefined): boolean => {
  if (!items) {
    return false;
  }
  return items.some((item) => {
    return (
      (item.contentType === CmsContentType.CMPersonalized && item.personalizationParameters?.length) ||
      hasPersonalizedContent(item.items)
    );
  });
};

export const parseCmsTemplate = (
  data: CmsTemplate,
  ddsLocale: DdsLanguage,
  sentryLogger: SentryLogger
): CmsTemplate => {
  resolveOldWwwLinks(data, ddsLocale);
  const recursiveParseMapper = (item: CmsContent | CmsCollection) => recursiveParse(item, sentryLogger);
  return {
    header: data.header.map(recursiveParseMapper),
    main: data.main.map(recursiveParseMapper),
    footer: data.footer.map(recursiveParseMapper),
  };
};

/*
 * Removes empty paragraphs and such originating from CMS.
 * Useful when e.g. teaserText has to be "empty", but CMS doesn't allow it.
 * */
export const removeEmptyCmsText = (text: string): string => {
  return (text || '').replace(/<p>-<\/p>/gi, '');
};

export const finUniversalResponseStatus =
  <T>(serverResponseCodeHandler: ResponseCodeHandler | undefined, setterRef: string, contentUrl: string) =>
  (response$: Observable<HttpResponse<T>>): Observable<HttpResponse<T>> =>
    response$.pipe(
      tap((res) => {
        if (serverResponseCodeHandler) {
          const cacheControl = res.headers.get('Cache-Control');
          const expires = res.headers.get('Expires');
          const lastModified = res.headers.get('Last-Modified');
          const edgeCacheTag = res.headers.get('X-Edge-Cache-Tag');

          serverResponseCodeHandler.setUniversalResponseStatus(setterRef, 200, contentUrl, {
            ...(cacheControl && { 'Cache-Control': cacheControl }),
            ...(expires && { Expires: expires }),
            ...(lastModified && { 'Last-Modified': lastModified }),
            ...(edgeCacheTag && { 'Edge-Cache-Tag': edgeCacheTag }),
          });
        }
      })
    );

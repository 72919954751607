import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { CmsPage } from '@fcom/core-api';
import { ConsentTextId } from '@fcom/common';
import { ConsentService } from '@fcom/common/login';
import { ConfigService, WindowRef } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';

@Injectable({
  providedIn: 'root',
})
export class ReactAndShareService {
  private script = {
    id: 'react-and-share-script',
    url: 'https://cdn.reactandshare.com/plugin/rns.js',
  };
  private containerId = 'rns-container';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private consentService: ConsentService,
    private configService: ConfigService,
    private languageService: LanguageService
  ) {}

  public openRns(page?: CmsPage): void {
    if (!this.shouldShow(page)) {
      return;
    }
    this.windowRef.nativeWindow['resetRns']?.();
    this.document.getElementById(this.containerId)?.classList.remove('is-hidden');
  }

  public hide(): void {
    this.document.getElementById(this.containerId)?.classList.add('is-hidden');
  }

  public setupReactAndShare(container: ElementRef): Observable<boolean> {
    return combineLatest([
      this.consentService.getCookieConsentStatusById(ConsentTextId.COOKIE_ANALYTICS),
      this.languageService.langKey,
    ]).pipe(
      filter(([hasConsent, lang]) => {
        const availableLanguages = Object.keys(this.configService.cfg.reactAndShareApiKeys);
        return hasConsent && availableLanguages.includes(lang);
      }),
      take(1),
      switchMap(([_hasConsent, lang]) => {
        return this.setup(container, lang);
      })
    );
  }

  public shouldShow(page: CmsPage | undefined): boolean {
    if (!page) {
      return true;
    }
    const path = page.navigationPathList;
    // is front page
    if (path.length <= 1) {
      return false;
    }

    // hide for paths
    if (this.hideReactAndShare(path[1].url)) {
      return false;
    }

    // is not-found page
    if (path[1].url.includes('404-not-found')) {
      return false;
    }
    // is form page
    if (this.isFormPage(page)) {
      return false;
    }
    return true;
  }

  private hideReactAndShare(pathUrl: string): boolean {
    return [
      'flash-sale',
      'customer-care-and-contact-information',
      'newsletter',
      'finnair100',
      'special-offers',
      'compensation-wizard',
      'kampanjerbjudanden',
      'nyhetsbrev',
      'uutiskirje',
      'kampanjahinnat',
      'asiakastuki-ja-yhteystiedot',
      'kundtjänst-och-kontaktuppgifter',
      'kundtj%C3%A4nst-och-kontaktuppgifter',
    ].some((page) => pathUrl.includes(page));
  }

  private isFormPage(cmsPage: CmsPage): boolean {
    const formPageUrls = ['claim-missing-points', 'contact-and-request-forms'];

    const url = cmsPage.navigationPathList?.slice(-1)?.[0].url;

    const alternateUrl = cmsPage.metaData?.find(
      (meta) => meta.attributes?.rel === 'alternate' && meta.attributes?.hreflang === 'en-GB'
    )?.attributes?.href;

    return !!formPageUrls.find((formPage) => url?.includes(formPage) || alternateUrl?.includes(formPage));
  }

  private setup(container: ElementRef, lang: string): Observable<boolean> {
    this.addComponentToContainer(container);
    return this.injectFiles(lang);
  }

  private injectFiles(lang: string): Observable<boolean> {
    if (this.document.getElementById(this.script.id)) {
      return of(true);
    }

    const result$ = new Subject<boolean>();
    this.windowRef.nativeWindow['rnsData'] = {
      apiKey: this.getApiKey(lang),
      categories: ['finnair.com'],
    };
    const script = this.document.createElement('script');
    script.id = this.script.id;
    script.type = 'text/javascript';
    script.src = this.script.url;
    script.onload = () => {
      result$.next(true);
    };
    script.onerror = (err) => {
      throw err;
    };
    this.document.body.appendChild(script);
    return result$.asObservable();
  }

  private getApiKey(lang: string): string {
    const availableLanguages = Object.keys(this.configService.cfg.reactAndShareApiKeys);
    const rnsLang = availableLanguages.includes(lang) ? lang : 'en';
    return this.configService.cfg.reactAndShareApiKeys[rnsLang];
  }

  private addComponentToContainer(container: ElementRef): void {
    container.nativeElement.insertAdjacentHTML(
      'beforeend',
      `<div id="${this.containerId}" class="grid-col-12 grid-max-width is-hidden">
         <div class="rns col-1-12 flex flex--center mr-xlarge-y"></div>
       </div>`
    );
  }
}

import { Params } from '@angular/router';

import { DdsCountrySiteType, DdsLanguage, DdsLanguageType, JsConfig } from '..';
import { sanitizePath } from './app-url-utils';
import { entrySet, isString } from './utils';

/**
 * @See /modules/cms/interfaces/personalization.interface.ts
 */
const cmsPersonalizationParameterValues = [
  'tier',
  'group',
  'aktiavisacreditcard',
  'p13n_test',
  'p13n_testcontext',
  'previewDate',
];

const cmsPagingParameter = 'pageNum';

/**
 * Example:
 *
 * http://localhost.finnair.com:5000/master-en?view=fragmentPreview&p13n_test=true&p13n_testcontext=24060#hashparam=works
 * -> { view: 'fragmentPreview', p13n_test: 'true', p13n_testcontext: '24060', hashparam: 'works' }
 *
 * @param path
 */
const toParams = (path: string): Params => {
  const splitted = path.split(/[#?]/);
  const paramStrings = splitted.length > 1 ? splitted.slice(1) : splitted;
  const params = paramStrings.join('&').split(/[&;]/);
  return params.reduce(function (acc, cur) {
    const parts = cur.split('=');
    acc[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    return acc;
  }, {});
};

export const pathToTemplateUrl = (cmsUrl: string, path: string, viewType = 'fin-json') => {
  const queryString = path.split('?')[1];
  const pathWithoutParams = `/${path}`.replace(/\?.*$/, '').replace(/^\/\//g, '/');
  const baseUrl = `${cmsUrl}${pathWithoutParams}?view=${viewType}`;

  if (!queryString) {
    return baseUrl;
  }

  const whiteListedParams = getWhitelistedCmsParamsMapped(queryString).join('&');

  return whiteListedParams.length > 0 ? `${baseUrl}&${whiteListedParams}` : baseUrl;
};

export const getWhitelistedCmsParams = (query: string | Params): Params => {
  const params = isString(query) ? toParams(query) : query;
  return entrySet<string>(params)
    .filter((param) => cmsPersonalizationParameterValues.indexOf(param.key) !== -1 || param.key === cmsPagingParameter)
    .reduce((acc, param) => {
      acc[param.key] = param.value;
      return acc;
    }, {} as Params);
};

export const getWhitelistedCmsParamsMapped = (query: string | Params): string[] => {
  return entrySet<Params>(getWhitelistedCmsParams(query)).map((param) => `${param.key}=${param.value}`);
};

const getCmsPagingParam = (query: string): string | undefined => {
  if (!isString(query)) {
    return;
  }
  const params: Params = toParams(query);
  const pageNum = params['pageNum'];
  if (pageNum) {
    return `pageNum=${pageNum}`;
  }
};

/**
 * Add dynamic lang params to hardcoded OLDWWWSITE path from cms
 * example input link:
 * https://www.finnair.com/$OLDWWWSITE/information-services/baggage/lost-delayed-damaged-baggage/trace-your-baggage
 * @param linkString
 * @param ddsLanguage
 */
type EmptyString = '';

export const symbolToLangPath = (linkString: string, ddsLocale: DdsLanguage): string => {
  const ddsCountrySite: DdsCountrySiteType | EmptyString = ddsLocale ? ddsLocale.countrySite : '';
  const ddsLanguageType: DdsLanguageType | EmptyString = ddsLocale ? ddsLocale.language : '';
  return linkString.replace(/\$OLDWWWSITE/g, `${ddsCountrySite.toLowerCase()}/${ddsLanguageType.toLowerCase()}`);
};

/**
 * Used to return a route path with whitelisted hash or query params
 * @param path
 * @param config Current JsConfig
 */
export function sanitizeWhiteListedPath(path: string, config: JsConfig): string {
  const basePath = sanitizePath(path);
  const whiteListedQueryParams =
    config.cmsEnv !== 'prod' && config.isCmsPreview ? getWhitelistedCmsParamsMapped(path) : [];
  const pagingParams = getCmsPagingParam(path);
  let pathWithParams = basePath;
  if (whiteListedQueryParams.length > 0) {
    pathWithParams += `?${whiteListedQueryParams.join('&')}`;
  }
  if (pagingParams) {
    const separator = whiteListedQueryParams.length ? '&' : '?';
    pathWithParams += `${separator}${pagingParams}`;
  }
  return pathWithParams;
}

export enum MmbStage {
  CHECK_IN = 'check-in',
  CHECK_IN_FLOW = 'check-in-flow',
  LOGIN = 'login',
  VIEW = 'view',
  PASSENGERS = 'passengers',
  TRAVEL_PREPARATION = 'travelPreparations',
  REVIEW = 'review',
  CHECKOUT = 'checkout',
  CONFIRMATION = 'confirmation',
  CAR_RENTAL = 'carRental',
}

export enum MmbError {
  BOOKING_NOT_FOUND = 'bookingNotFound',
  BOOKING_CANCELLED = 'bookingCancelled',
  GROUP_BOOKING = 'groupBooking',
  GROUP_BOOKING_NOT_FOUND = 'groupBookingNotFound',
  GROUP_BOOKING_LOGGING_WITH_INFANT = 'groupBookingLoggingWithInfant',
  GROUP_BOOKING_NOT_TICKETED = 'groupBookingNotTicketed',
  SEVERAL_RECORDS_FOR_TRAVEL_DOCUMENT = 'severalRecordsForTravelDocument',
  UNEXPECTED_ERROR = 'unexpectedError',
}

export enum BoundStatus {
  REBOOKED = 'rebooked',
  CANCELLED = 'cancelled',
  ORIGINAL = 'original',
  NEW = 'new',
  DEFAULT = 'default',
}

export enum ThirdPartyAncillariesProvider {
  CAR_TRAWLER_RENTAL = 'CAR_TRAWLER_RENTAL',
  CAR_TRAWLER_TRANSFER = 'CAR_TRAWLER_TRANSFER',
}

export enum BoundSelectionType {
  ALL = 'all-bounds',
  SOME = 'some-bounds',
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
  NONE = 'no-bounds',
}

<div class="product-card white-bg grow fill flex flex--column relative">
  <fcom-badge *ngIf="badgeText" [variant]="BadgeVariant.ROUNDED_TOP_RIGHT">{{ badgeText }}</fcom-badge>

  <fcom-image
    *ngIf="image"
    class="picture block rounded-top-large border-top border-left border-right border"
    [ratio]="'21x9'"
    [imageData]="image"
  ></fcom-image>

  <div
    class="flex-1 pr-small-x ps-medium-y flex flex--column rounded-bottom-large border-bottom border-left border-right border"
  >
    <h2 class="nordic-blue-900-text font-heading-4">{{ title }}</h2>

    <p *ngIf="detailText" class="ps-xsmall-t ps-small-b font-body-1 flex-1">{{ detailText }}</p>

    <ng-container *ngIf="statusText || purchasedText">
      <hr class="border-top-dashed nordic-blue-50-border" aria-hidden="true" />

      <div class="ps-small-y align-right">
        <div class="nordic-blue-900-text font-body-1" *ngIf="purchasedText">
          {{ purchasedText }}
        </div>
        <div
          class="font-body-1"
          [ngClass]="{
            'medium-type': hasAddedItems,
            'nordic-nordic-blue-900-100-text': hasAddedItems,
            'nordic-blue-900-text': !hasAddedItems,
          }"
        >
          {{ statusText }}
        </div>
        <div *ngIf="price" class="font-body-1 nordic-blue-900-text">{{ price }}</div>
      </div>

      <hr class="border-top-dashed nordic-blue-50-border" aria-hidden="true" />
    </ng-container>

    <div class="align-center">
      <fcom-button
        *ngIf="!hideButton"
        class="block"
        [ngClass]="{ 'ps-small-y': !hideViewButton, 'ps-small-t': hideViewButton }"
        [mode]="ButtonMode.FILL"
        [appearDisabled]="loading"
        [loading]="loading"
        [theme]="buttonTheme"
        [text]="buttonText"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: buttonIcon, position: IconPosition.RIGHT }"
        [title]="buttonAriaText"
        [aria]="{ label: buttonAriaText, role: 'button', loadingLabel: 'actions.loading' | finLocalization }"
        [focus$]="focusOnBtn$"
        (clickBtn)="emitProductCategory($event)"
      >
      </fcom-button>

      <fcom-notification *ngIf="notificationText" class="block ps-small-y ps-small-t" [theme]="notificationTheme">
        {{ notificationText }}
      </fcom-notification>

      <button
        #viewSummaryBtn
        class="view-details nordic-blue-900-text underline font-body-1"
        *ngIf="!hideViewButton"
        (click)="emitViewDetails($event)"
        [attr.aria-label]="viewSummaryText + ' ' + title"
      >
        {{ viewSummaryText }}
      </button>
    </div>
  </div>
</div>

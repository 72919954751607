import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { CustomIcon, IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';
import { NEVER, Observable } from 'rxjs';

import { NativeBridgeService } from '@fcom/core/services/native-bridge/native-bridge.service';

@Component({
  selector: 'fin-header-boxes',
  styleUrls: ['./header-boxes.component.scss'],
  templateUrl: './header-boxes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBoxesComponent implements OnInit {
  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;

  @Input()
  homeLink$: Observable<string> = NEVER;
  @Input()
  showLink = true;

  constructor(private nativeBridgeService: NativeBridgeService) {}

  ngOnInit(): void {
    if (this.nativeBridgeService.isInsideNativeWebView) {
      this.showLink = false;
    }
  }
}

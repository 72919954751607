import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  Inject,
  PLATFORM_ID,
  type OnInit,
  type OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { GlobalBookingTravelClass } from '@fcom/core';
import { Breakpoint } from '@fcom/common';
import { CampaignTagToLabelKey, TripType } from '@fcom/core/constants';
import { getCurrentBreakPoint } from '@fcom/common/utils/layout.utils';
import { AspectRatios } from '@fcom/ui-components';
import { isPresent } from '@fcom/core/utils';
import { CmsTripTypeMap } from '@fcom/core/utils/tripType.utils';

import { BlockOffer, MarketingOfferPrices } from '../../interfaces';

@Component({
  selector: 'fin-block-offer-tile',
  templateUrl: './block-offer-tile.component.html',
  styleUrls: ['./block-offer-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockOfferTileComponent implements OnChanges, OnInit {
  readonly CmsTripTypeMap = CmsTripTypeMap;
  readonly GlobalBookingTravelClass = GlobalBookingTravelClass;
  readonly MaxImageWidth = 600;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;

  @Output()
  linkClicked: EventEmitter<[GlobalBookingTravelClass, TripType]> = new EventEmitter(); // mainly for click tracking if normal link tracking is not enough

  @Output()
  amContinueModalClick = new EventEmitter<{ amLink: string; destination: string }>();

  @Input()
  tripType: TripType = undefined;

  @Input()
  blockOffer$: Observable<BlockOffer>;

  innerLinkHover = false;
  flightHotelEnabled = false;
  aspectRatio$: BehaviorSubject<keyof typeof AspectRatios> = new BehaviorSubject<keyof typeof AspectRatios>('16x9');
  tripType$: Observable<TripType> = of(undefined);
  badgeLabel$: Observable<string> = of(undefined);
  isHighlightPrice$: Observable<boolean> = of(false);
  priceContext$: Observable<{ price: MarketingOfferPrices }> = of(undefined);

  constructor(
    private router: Router,
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.flightHotelEnabled = this.languageService.localeValue.includes('FI');
  }

  ngOnInit(): void {
    this.tripType$ = iif(
      () => isPresent(this.tripType),
      of(this.tripType),
      this.blockOffer$.pipe(map((blockOffer) => (blockOffer.isShortHaul ? TripType.ONEWAY : TripType.RETURN)))
    );

    this.badgeLabel$ = this.blockOffer$.pipe(map((offer) => offer.badgeLabel));

    this.isHighlightPrice$ = this.badgeLabel$.pipe(
      map((badgeLabel) =>
        [CampaignTagToLabelKey.fcomCampaignSpecial, CampaignTagToLabelKey.fcomCampaignFlash].includes(
          badgeLabel as CampaignTagToLabelKey
        )
      )
    );

    this.priceContext$ = this.blockOffer$.pipe(map((blockOffer) => this.getPriceContext(blockOffer)));
  }

  ngOnChanges(): void {
    this.updateAspectRatio();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateAspectRatio();
  }

  emitClick(travelClass: GlobalBookingTravelClass, tripType?: TripType): void {
    this.linkClicked.emit([travelClass || GlobalBookingTravelClass.MIXED, tripType]);
  }

  openAmContinueModalClick(amLink: string, destination: string): void {
    this.amContinueModalClick.emit({ amLink, destination });
  }

  navigateToUrl(link: string): void {
    this.router.navigateByUrl(link);
  }

  getPriceContext(offer: BlockOffer): { price: MarketingOfferPrices } {
    return isPresent(this.tripType)
      ? { price: this.tripType === TripType.ONEWAY ? offer.onewayPrice : offer.returnPrice }
      : { price: offer.isShortHaul ? offer.onewayPrice : offer.returnPrice };
  }

  private updateAspectRatio() {
    if (isPlatformBrowser(this.platform)) {
      if (getCurrentBreakPoint() === Breakpoint.MOBILE) {
        this.aspectRatio$.next('4x3');
      } else {
        this.aspectRatio$.next('16x9');
      }
    }
  }
}

export enum KeyEventTypes {
  ESC = 'ESC',
  SPACE = 'SPACE',
  ENTER = 'ENTER',
  DOWN = 'DOWN',
  UP = 'UP',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  PAGE_UP = 'PAGE_UP',
  PAGE_DOWN = 'PAGE_DOWN',
  END = 'END',
  HOME = 'HOME',
  TAB = 'TAB',
}

export const KEYBOARDEVENTS = {
  // In Internet Explorer (tested on release 9 and 11) and Firefox 36 and earlier,
  // the Esc key returns 'Esc' instead of 'Escape'.
  [KeyEventTypes.ESC]: ['Escape', 'Esc'],
  // Older browsers may return 'Spacebar' instead of ' ' for the Space Bar key.
  // Firefox did so until version 37, as did Internet Explorer 9, 10, and 11
  [KeyEventTypes.SPACE]: [' ', 'Spacebar'],
  [KeyEventTypes.ENTER]: ['Enter'],
  // Internet Explorer, Edge (16 and earlier), and Firefox (36 and earlier) use
  // 'Left', 'Right', 'Up', and 'Down' instead of 'ArrowLeft', 'ArrowRight', 'ArrowUp', and 'ArrowDown'.
  [KeyEventTypes.DOWN]: ['ArrowDown', 'Down'],
  [KeyEventTypes.UP]: ['ArrowUp', 'Up'],
  [KeyEventTypes.LEFT]: ['ArrowLeft', 'Left'],
  [KeyEventTypes.RIGHT]: ['ArrowRight', 'Right'],
  [KeyEventTypes.PAGE_UP]: ['PageUp'],
  [KeyEventTypes.PAGE_DOWN]: ['PageDown'],
  [KeyEventTypes.END]: ['End'],
  [KeyEventTypes.HOME]: ['Home'],
  [KeyEventTypes.TAB]: ['Tab'],
};

export const KEYBOARDEVENTS_REVERSE: { [key: string]: string } = Object.keys(KEYBOARDEVENTS).reduce(
  (acc, propName) =>
    KEYBOARDEVENTS[propName].reduce((a, num) => {
      a[num] = propName;
      return a;
    }, acc),
  {}
);

export const isKeyboardEventKey = (keyEvent: KeyboardEvent): string => {
  if (KEYBOARDEVENTS_REVERSE[keyEvent.code]) {
    return KEYBOARDEVENTS_REVERSE[keyEvent.code];
  }
  return undefined;
};

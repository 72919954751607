import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, map, Observable } from 'rxjs';

import { ElementTypes, Step } from '@fcom/common';
import { MediaQueryService } from '@fcom/common/services';
import { BookingSteps } from '@fcom/common/interfaces/booking/booking-step.interface';
import { BookingService } from '@fcom/booking/services';
import { BOOKING_STEPS } from '@fcom/common/config/booking-config';
import { IconButtonTheme, IconButtonSize, ButtonTheme, IconPosition } from '@fcom/ui-components';

@Component({
  selector: 'fin-sub-header',
  styleUrls: ['sub-header.component.scss'],
  templateUrl: 'sub-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent implements OnInit {
  readonly ElementTypes = ElementTypes;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly IconLibrary = IconLibrary;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconButtonSize = IconButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly IconPosition = IconPosition;

  STEPS: BookingSteps = BOOKING_STEPS;

  @Input()
  hasBasket: boolean;
  @Input()
  isBasketDisabled: boolean;
  @Input()
  hasActiveBasketState: boolean;
  @Input()
  hasBasketActionInMobileOnly: boolean;
  @Input()
  backgroundColorClass = 'nordic-blue-900-bg';
  @Input()
  showInfoIcon: boolean;

  @Output()
  openBasket: EventEmitter<Event> = new EventEmitter<Event>();
  @Output()
  changeStep: EventEmitter<Step> = new EventEmitter<Step>();

  isMobile$: Observable<boolean>;
  step$: Observable<Step>;
  showModifyButton$: Observable<boolean>;

  constructor(
    private mediaQueryService: MediaQueryService,
    private bookingService: BookingService
  ) {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
    this.step$ = this.bookingService.step;
  }

  ngOnInit(): void {
    this.showModifyButton$ = combineLatest([this.isMobile$, this.step$]).pipe(
      map(([isMobile, step]) => !isMobile && step.phase === BOOKING_STEPS.FLIGHT_SELECTION.phase)
    );
  }
}

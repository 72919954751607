import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { Validators, type AbstractControl, type UntypedFormGroup } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, Subscription, type Observable, type Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SentryLogger } from '@fcom/core/services';
import { stopPropagation, unsubscribe } from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate';

import { ButtonMode } from '../../buttons';
import { IconPosition } from '../../icons';
import { AttachmentStatus, type Attachment, type FileUploaderI18n } from '../../interfaces';
import { ATTACHMENT_ALLOWED_FILE_EXTENSIONS } from '../enums';
import { type AttachmentResponse } from '../interfaces';

const ONE_MB_IN_BYTES = 1048576;

@Component({
  selector: 'fcom-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  readonly ButtonMode = ButtonMode;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() allowedFileTypes: string[] = ATTACHMENT_ALLOWED_FILE_EXTENSIONS;
  @Input() customAttachLabel = '';
  @Input() customRequiredLabel = '';
  @Input() disabled = false;
  @Input() id: string;
  @Input() maxSizeInBytes: number;
  @Input() multiple: boolean;
  @Input() required = false;
  @Input() uploadService: (file: File) => Observable<AttachmentResponse>;
  @Input({ required: true }) controlName: string;
  @Input({ required: true }) i18n: FileUploaderI18n;
  @Input({ required: true }) parentForm: UntypedFormGroup;

  @Output() fileDeleted: EventEmitter<Attachment> = new EventEmitter<Attachment>();
  @Output() filesUpdated: EventEmitter<Attachment[]> = new EventEmitter<Attachment[]>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  attachments$: BehaviorSubject<Attachment[]> = new BehaviorSubject([]);
  dragInProgress$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  idOrControlName: string;
  isUploading$: Observable<boolean>;
  maxSizeMb: number | undefined;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private sentryLogger: SentryLogger
  ) {}

  /**
   * Transforms an array of allowed file types to a string
   * for the `accept` property of the file input.
   *
   * @example ['gif', 'jpg', 'png']
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept MDN documentation} for more information.
   * @returns {string} `'.gif,.jpg,.png'`
   */
  get acceptValues(): string {
    return this.allowedFileTypes?.length ? `.${this.allowedFileTypes.join(',.')}` : '';
  }

  get containsErrors(): boolean {
    return !this.disabled && this.ctrlField.invalid && this.ctrlField.touched;
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  get fieldDisabled(): boolean {
    return this.disabled || this.ctrlField.disabled || (!this.multiple && this.attachments$.value.length === 1);
  }

  get fieldRequired(): boolean {
    const hasRequiredValidator = this.ctrlField.hasValidator(Validators.required);
    return this.required || this.ctrlField.errors?.required || hasRequiredValidator;
  }

  /**
   * Formats the allowed file extensions as a localized string
   * separated by a comma and the `and` word for the final entry.
   * @example ['gif', 'jpg', 'png']
   * @external Intl.ListFormat
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat MDN documentation} for more information.
   * @return {string} `'gif, jpg, and png'`
   */
  get filesSupported(): string {
    const options: Intl.ListFormatOptions = {
      style: 'long',
      type: 'conjunction',
    };
    return new Intl.ListFormat(this.languageService.langKeyValue || 'en', options).format(this.allowedFileTypes);
  }

  get labelAttach(): string {
    return this.customAttachLabel || (this.multiple ? this.i18n.attachFiles : this.i18n.attachFile);
  }

  get labelRequired(): string {
    return this.customRequiredLabel || (this.multiple ? this.i18n.errors.requiredMultiple : this.i18n.errors.required);
  }

  ngOnInit(): void {
    this.idOrControlName = this.id || this.controlName;
    this.maxSizeMb = this.maxSizeInBytes ? +(this.maxSizeInBytes / ONE_MB_IN_BYTES).toFixed(1) : undefined;

    this.subscriptions.add(
      this.ctrlField.valueChanges.subscribe((attachments: Attachment | Attachment[]): void => {
        if (!attachments) {
          this.attachments$.next([]);
          this.cdr.markForCheck();
          return;
        }
        const newAttachments = Array.isArray(attachments) ? attachments : [attachments];
        const updatedAttachments: Attachment[] = [
          ...this.attachments$.value.filter(
            (a: Attachment): boolean =>
              !newAttachments.some((b: Attachment): boolean => this.fileEquals(a.file, b.file))
          ),
          ...newAttachments,
        ];
        this.attachments$.next(updatedAttachments);
        this.cdr.markForCheck();
      })
    );

    this.subscriptions.add(
      this.attachments$
        .pipe(
          map((attachments: Attachment[]): Attachment[] =>
            // Process only files that are missing a status
            attachments.filter((a: Attachment): boolean => !a.status)
          )
        )
        .subscribe((attachments: Attachment[]): void => {
          this.filesUpdated.emit(attachments);
          if (this.uploadService) {
            this.uploadAttachments(attachments);
          }
        })
    );

    this.isUploading$ = this.attachments$.pipe(
      map((attachments: Attachment[]): boolean =>
        attachments.some((a: Attachment): boolean => a.status === AttachmentStatus.UPLOADING)
      )
    );

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  deleteAttachment(attachment: Attachment): void {
    const attachments: File[] = attachment
      ? (Array.from(this.ctrlField.value).filter(
          (a: Attachment): boolean => a.file.name !== attachment.file.name
        ) as File[])
      : this.ctrlField.value;
    this.ctrlField.setValue(attachments.length ? attachments : '');
    this.fileDeleted.emit(attachment);
    this.cdr.detectChanges();
  }

  fileDropped(event: DragEvent): void {
    stopPropagation(event);
    if (this.fieldDisabled) {
      return;
    }
    this.dragInProgress$.next(false);
    const attachments: Attachment[] = [];
    const files: FileList = event.dataTransfer?.files;
    if (files) {
      for (const file of Array.from(files)) {
        attachments.push(this.validateFile(file));
      }
      this.ctrlField.setValue(this.multiple ? attachments : attachments[0]);
    }
  }

  onDragEnter(enable: boolean): void {
    if (this.fieldDisabled) {
      return;
    }
    this.dragInProgress$.next(enable);
  }

  onDragOver(event: DragEvent): void {
    stopPropagation(event);
    if (this.fieldDisabled) {
      event.dataTransfer.dropEffect = 'none';
      event.dataTransfer.effectAllowed = 'none';
    }
  }

  onFileChange(event: Event): boolean | void {
    const target = event.target as HTMLInputElement;
    const attachments: Attachment[] = Array.from(target.files).map((f: File): Attachment => this.validateFile(f));
    this.ctrlField.setValue(this.multiple ? attachments : attachments[0]);
    this.ctrlField.markAsTouched();
    this.cdr.markForCheck();
    this.fileInput.nativeElement.value = '';
  }

  onFileCancel(event: Event): void {
    // Prevent triggering error when no files are returned from the input
    // and some already exist in the attachment list
    if (this.attachments$.value.length) {
      stopPropagation(event);
      return;
    }
    this.ctrlField.markAsTouched();
    this.cdr.markForCheck();
  }

  openFileSelector(): void {
    if (this.fieldDisabled) {
      return;
    }
    this.fileInput.nativeElement.click();
  }

  private errorCallback(attachment: Attachment, error: unknown): void {
    this.updateAttachmentDetails(attachment, { status: AttachmentStatus.ERROR });
    this.sentryLogger.warn('Failed to upload attachment:', { error });
  }

  private fileEquals(a: File, b: File): boolean {
    return a.name === b.name && a.size === b.size && a.lastModified === b.lastModified;
  }

  private uploadAttachment(attachment: Attachment): void {
    this.updateAttachmentDetails(attachment, { status: AttachmentStatus.UPLOADING });
    this.subscriptions.add(
      this.uploadService(attachment.file).subscribe({
        next: (response: AttachmentResponse): void => this.uploadCallback(attachment, response),
        error: (error: unknown): void => this.errorCallback(attachment, error),
      })
    );
  }

  private updateAttachmentDetails(attachment: Attachment, details: Partial<Attachment>): void {
    setTimeout((): void => {
      const attachments = this.attachments$.value;
      if (!attachments?.length) {
        return;
      }
      const updatedAttachments = attachments.map((a: Attachment): Attachment => {
        if (!this.fileEquals(a.file, attachment.file)) {
          return a;
        }
        return { ...a, ...details };
      });
      this.attachments$.next(updatedAttachments);
    });
  }

  private uploadAttachments(attachments: Attachment[]): void {
    attachments.forEach((attachment: Attachment): void => {
      switch (attachment.status) {
        case AttachmentStatus.READY:
        case AttachmentStatus.UPLOADING:
          return;
        case AttachmentStatus.ERROR:
          this.updateAttachmentDetails(attachment, { status: AttachmentStatus.ERROR });
          break;
        default:
          this.uploadAttachment(attachment);
      }
    });
  }

  private uploadCallback(attachment: Attachment, response: AttachmentResponse): void {
    const fileId = response?.attachmentId;
    if (fileId) {
      this.updateAttachmentDetails(attachment, { status: AttachmentStatus.READY, fileId });
    } else {
      this.updateAttachmentDetails(attachment, { status: AttachmentStatus.ERROR });
    }
  }

  private validateFile(file: File): Attachment {
    const attachment: Attachment = { file };
    const fileExtension: string = file.name.split('.').pop();
    if (!this.allowedFileTypes.includes('*') && !this.allowedFileTypes.includes(fileExtension)) {
      attachment.status = AttachmentStatus.ERROR;
      attachment.error = this.i18n.errors.invalidFileType;
    }
    if (file.size > this.maxSizeInBytes) {
      attachment.status = AttachmentStatus.ERROR;
      attachment.error = this.i18n.errors.invalidFileSize;
    }
    return attachment;
  }
}

<ng-template #selectedDate let-type="type" let-text="text" let-date="date">
  <div class="flex tr-xsmall pr-medium-l" [attr.data-testid]="'matrix-date-' + type">
    <fcom-icon class="icon-medium flex--nogrow ms-xxsmall-r" [name]="type"></fcom-icon>
    <div class="flex flex--column flex--align-start">
      <span class="dn-mobile">{{ text | finLocalization }}</span>
      <div class="medium-type">{{ date | finDateFormat: 'FULL_NO_YEAR' }}</div>
    </div>
  </div>
</ng-template>

<fcom-modal
  [(open)]="airCalendarOpen"
  [title]="'airCalendar.flexibleTravelDates' | finLocalization"
  [fitToContent]="true"
  [fullHeight]="true"
  [extraWide]="true"
  [buttons]="ModalButtons.CUSTOM"
  (close)="closeAirCalendar()"
>
  <div class="flex flex--center" *ngIf="airCalendarOpen && isAward">
    <fcom-notification [theme]="NotificationTheme.TRANSPARENT" class="block" [attr.data-testid]="'notification-points'">
      {{ 'award.notifications.totalPointsV2' | finLocalization: { points: totalPoints | finPoints | finEmpty: '-' } }}
    </fcom-notification>
  </div>

  <fin-price-matrix
    [calendarList]="prices$ | async"
    [isAward]="isAward"
    (selectedDates)="selectCalendarDates($event)"
  ></fin-price-matrix>

  <div
    buttons
    class="airCalendarFooter df-tablet-up flex--middle flex--space-between align-left font-body-1"
    data-testid="price-matrix-footer"
  >
    <div class="price-and-date-container flex">
      <div class="flex flex--column flex--align-start starting-price-container">
        <div data-testid="matrix-starting-price">
          {{ 'priceCalendar.pricesFrom' | finLocalization }}
          <span *ngIf="!isAward" class="medium-type"
            >&nbsp;{{ startingPrice$ | async | finPrice: { roundPrice: true } | finEmpty: '&ndash;' }}</span
          >
          <span *ngIf="isAward" class="medium-type"
            >&nbsp;{{
              'award.points' | finLocalization: { pointsAmount: startingPrice$ | async | finMap: 'amount' | finPoints }
            }}</span
          >
        </div>
        <div data-testid="pax-amount-breakdown">{{ paxAmountBreakdown$ | async }}</div>
      </div>
      <div class="dates df-tablet-up flex-1">
        <ng-container
          [ngTemplateOutlet]="selectedDate"
          [ngTemplateOutletContext]="{
            type: 'departure',
            text: 'departure',
            date: (travelDates$ | async)?.departureDate,
          }"
        ></ng-container>
        <ng-container
          *ngIf="(tripType$ | async) !== TripType.ONEWAY"
          [ngTemplateOutlet]="selectedDate"
          [ngTemplateOutletContext]="{ type: 'arrival', text: 'returnDate', date: (travelDates$ | async)?.returnDate }"
        ></ng-container>
      </div>
    </div>
    <div class="align-center">
      <fcom-button
        class="continue-btn"
        [theme]="ButtonTheme.LIGHT_PRIMARY_CTA_HEATHER"
        [size]="ButtonSize.LARGE"
        [text]="'bookingSearch.search' | finLocalization"
        [disabled]="continueDisabled$ | async"
        (clickBtn)="navigateToBookingFlow()"
        data-testid="aircalendar-continue-button"
      >
      </fcom-button>
    </div>
  </div>
</fcom-modal>

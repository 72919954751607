<fieldset
  *ngIf="i18n"
  class="border-none file-wrapper"
  [ngClass]="{ 'grey-600-text': fieldDisabled }"
  [formGroup]="parentForm"
>
  <legend class="font-heading-4 bold-type medium-type ms-medium-b" data-testid="file-uploader-label">
    {{ labelAttach }}
    <span *ngIf="fieldRequired" data-testid="asterisk">*</span>
  </legend>

  <div class="ms-medium-b">
    <div class="font-body-1" *ngIf="maxSizeInBytes">
      <span data-testid="max-file-size-label">{{ i18n.maxFileSize }}: </span>
      <span class="bold-type" data-testid="max-file-size-value"> {{ maxSizeMb }} {{ i18n.megaBytes }} </span>
    </div>

    <div class="font-body-1">
      <span data-testid="files-supported-label">{{ i18n.filesSupported }}: </span>
      <span class="bold-type" data-testid="files-supported-list">{{ filesSupported }}</span>
    </div>
  </div>

  <label class="dn-mobile" [for]="idOrControlName" [class.disabled]="fieldDisabled">
    <div
      class="drop-area font-body-1 medium-type border border-dashed rounded-large align-center ps-large"
      [ngClass]="{
        'error error-900-border': containsErrors,
        'grey-100-bg': (isUploading$ | async) || fieldDisabled,
        'nordic-blue-25-bg': dragInProgress$ | async,
        'nordic-blue-400-border': !fieldDisabled && !containsErrors,
      }"
      data-testid="drop-area"
      (drop)="fileDropped($event)"
      (dragover)="onDragOver($event)"
      (dragenter)="onDragEnter(true)"
      (dragleave)="onDragEnter(false)"
    >
      <span class="block">{{ i18n.dragAndDrop }}</span>
      <span>{{ i18n.or }} </span>
      <span class="underline">{{ i18n.browseFiles }}</span>
    </div>

    <input
      data-testid="file-uploader-input"
      (cancel)="onFileCancel($event)"
      (change)="onFileChange($event)"
      class="is-vishidden"
      [attr.accept]="acceptValues"
      [attr.aria-required]="fieldRequired"
      [disabled]="(isUploading$ | async) || fieldDisabled"
      [id]="idOrControlName"
      [multiple]="multiple"
      [name]="controlName"
      type="file"
      #fileInput
    />
  </label>

  <div class="dn-tablet-up">
    <fcom-button
      class="mr-xsmall-b"
      data-testid="open-file-selector"
      [disabled]="(isUploading$ | async) || fieldDisabled"
      [mode]="ButtonMode.FILL_MOBILE"
      [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.UPLOAD, position: IconPosition.LEFT }"
      [text]="labelAttach"
      (click)="openFileSelector()"
    ></fcom-button>
  </div>

  <ng-container *ngIf="containsErrors">
    <div class="flex error error-900-text pr-xxsmall-y" data-testid="error-message">
      <fcom-icon class="icon-medium ms-small-r" [name]="SvgLibraryIcon.ALERT_TRIANGLE"></fcom-icon>
      <div class="font-body-1">{{ this.labelRequired }}</div>
    </div>
  </ng-container>

  <fcom-attachment-list [attachments$]="attachments$" (fileDeleted)="deleteAttachment($event)"></fcom-attachment-list>
</fieldset>

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';
import { I18nLazyLoadingPrefix } from '@fcom/core/';

const ONE_MB_IN_BYTES = 1048576;
export enum FilePickerTheme {
  DEFAULT = 'default',
  BORDERED = 'bordered',
}

@Component({
  selector: 'fin-form-input-file',
  styleUrls: ['./form-input-file.component.scss'],
  templateUrl: 'form-input-file.component.html',
})
export class FormInputFileComponent implements OnInit, OnDestroy {
  readonly FilePickerTheme = FilePickerTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  genericTranslationPrefix = `${I18nLazyLoadingPrefix.SERVICE_FORMS}.generic.file`;

  @Input()
  multiple: boolean;

  @Input()
  id: string;

  @Input()
  maxSizeInBytes: number;

  @Input()
  allowedFileTypes = '*';

  @Input()
  theme = FilePickerTheme.DEFAULT;

  @Output()
  delete: EventEmitter<Event> = new EventEmitter();

  @ViewChild('fileInput', { static: true })
  fileInput: ElementRef<HTMLInputElement>;

  isRequired = false;
  idOrControlName: string;
  maxSizeMb: number;
  files: File[] = [];

  private subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.isRequired = this.required;
    this.idOrControlName = this.id || this.controlName;
    this.maxSizeMb = +(this.maxSizeInBytes / ONE_MB_IN_BYTES).toFixed(1);

    this.subscriptions.add(
      this.ctrlField.statusChanges.subscribe(() => {
        if (this.ctrlField.value) {
          return;
        }
        this.isRequired = this.required;
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.files = Array.from(target.files);
  }

  onRemoveFile(focusBackToElement = false): void {
    this.fileInput.nativeElement.value = '';
    this.files = [];
    this.ctrlField.reset();
    if (focusBackToElement) {
      this.fileInput.nativeElement.focus();
    }
    this.delete.emit();
  }

  get required(): boolean {
    return this.ctrlField.errors?.required;
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }
}

<div class="flex info-contaner">
  <div class="flex flex--row font-body-1">
    <fcom-icon class="flex flex--nogrow" [name]="SvgLibraryIcon.SINGLE_PAX"></fcom-icon>
    <div class="flex flex--column ps-xsmall-l">
      <div class="flex flex--wrap flex--middle">
        <h2 class="header-title nordic-blue-900-text font-heading-5 ps-small-r">
          {{ extendedPassenger.firstName }} {{ extendedPassenger.lastName }}
        </h2>
        <span data-testid="pax-type" class="font-body-1 medium-type"
          >{{ 'passenger.' + extendedPassenger.paxTypeKey | finLocalization | titlecase }}
          {{ 'passenger.age.' + extendedPassenger.paxTypeKey | finLocalization | titlecase }}
        </span>
      </div>
      <div class="flex flex--center ps-xsmall-t">
        <div class="flex-1 flex--self-start">
          <span data-testid="pax-birthdate" *ngIf="extendedPassenger.dateOfBirth">
            {{ extendedPassenger.dateOfBirth | finDateFormat: 'DATE' }}
          </span>
          <span *ngIf="extendedPassenger.dateOfBirth && extendedPassenger.email"> / </span>
          <span data-testid="pax-email" *ngIf="extendedPassenger.email">
            {{ extendedPassenger.email | lowercase }}
          </span>
          <span data-testid="pax-phonenumber" *ngIf="extendedPassenger.phoneNumbers?.length > 0">
            <ng-container *ngFor="let phone of extendedPassenger.phoneNumbers; let first = first"
              ><span *ngIf="!first"> / </span
              ><span> / (+{{ phone?.countryPrefix }}) {{ phone?.number }} </span></ng-container
            >
          </span>
          <span data-testid="pax-ffcard" *ngIf="extendedPassenger.frequentFlyerCards?.length > 0">
            <ng-container *ngFor="let card of extendedPassenger.cards; let first = first"
              ><span *ngIf="!first"> / </span
              ><span> / {{ card.airlineName }}, {{ card.cardNumber }}</span></ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="flex--nogrow flex--self-center modify-button" *ngIf="showEdit">
    <fcom-button
      [attr.data-testid]="'edit-button-' + paxIndex"
      (clickBtn)="onOpenEditModal()"
      [text]="'purchase.actions.edit' | finLocalization"
      [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
      [loading]="(isEditButtonDisabled$ | async) && (isEditButtonLoading$ | async)"
      [disabled]="isEditButtonDisabled$ | async"
      [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.EDIT, position: IconPosition.RIGHT }"
    ></fcom-button>
  </div>
</div>

<ng-container *ngIf="passengerFormGroup">
  <fcom-modal
    [title]="'purchase.actions.modifyPax' | finLocalization"
    [open]="isEditingPassenger$ | async"
    [fitToContent]="true"
    [overlayClickable]="(isEditButtonDisabled$ | async) === false"
    [buttons]="ModalButtons.CONTINUE_CANCEL"
    (close)="onCloseEditModal()"
    (confirm)="onConfirmEditModal()"
  >
    <div class="pr-large-x pr-medium-y" [formGroup]="passengerFormGroup.group">
      <div class="flex">
        <fcom-icon [name]="SvgLibraryIcon.SINGLE_PAX"></fcom-icon>
        <h2 class="header-title nordic-blue-900-text font-heading-5 ps-small-r">
          {{ extendedPassenger.firstName }} {{ extendedPassenger.lastName }}
        </h2>
        <span class="font-body-1 medium-type"
          >{{ 'passenger.' + extendedPassenger.paxTypeKey | finLocalization | titlecase }}
          {{ 'passenger.age.' + extendedPassenger.paxTypeKey | finLocalization | titlecase }}
        </span>
      </div>
      <fin-pax-details-form-group
        [groupIndex]="paxIndex"
        [passengerFormGroup]="passengerFormGroup.group"
        [profileType$]="profileType$"
        [isMainPassenger]="isMainPax"
        [controlData]="controlData$ | async"
        [frequentFlyerAirlines$]="frequentFlyerAirlines$"
        [allFrequentFlyerCards]="allFrequentFlyerCards$ | async"
        [hideDisabledFields]="true"
        [availableDates]="availableDays"
        [trackingContext]="TRACKING_CONTEXT"
      >
      </fin-pax-details-form-group>

      <div
        *ngIf="passengerFormGroup.group.get(FIELDS.type).value === C.FinnairPassengerCode.INF"
        class="traveling-with-area border-none pr-xsmall-b"
      >
        <fin-select-input
          class="block ms-small-b"
          [controlName]="FIELDS.travelling"
          [translationPrefix]="'paxDetails.fields.travelling'"
          [parentForm]="passengerFormGroup.group"
          [options]="selectableLeadPassengers$ | async"
          [attr.data-testid]="'infant'"
          [displayMargin]="false"
        >
        </fin-select-input>
        <fcom-notification
          [theme]="NotificationTheme.WARNING"
          [linkText]="'paxDetails.fields.travelling.backToPaxDetailsLink' | finLocalization"
          (linkClick)="backToPaxDetails.emit()"
        >
          {{ 'paxDetails.fields.travelling.lockedChangeInfo' | finLocalization }}
        </fcom-notification>
      </div>
    </div>
  </fcom-modal>
</ng-container>

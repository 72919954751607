/* eslint-disable rxjs/no-subject-value */
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { LocalDate } from '@fcom/core/utils/tz-date';

import { DateSelection, Day } from '../../../utils/date.interface';

@Injectable()
export class CalendarService {
  // Selection state
  private _datesSubject$: BehaviorSubject<{
    [key: string]: DateSelection;
  }> = new BehaviorSubject<{
    [key: string]: DateSelection;
  }>({});

  public readonly datesSubject$: Observable<{
    [key: string]: DateSelection;
  }> = this._datesSubject$.asObservable();

  getDatesSelected(identifier: string): Observable<DateSelection> {
    this.setupNewDatesIfNeeded(identifier);

    return this._datesSubject$
      .asObservable()
      .pipe(
        map((dates) => {
          return dates[identifier];
        })
      )
      .pipe(distinctUntilChanged());
  }

  // Minimum and maximum selectable dates
  private _selectableDatesRange$: BehaviorSubject<DateSelection> = new BehaviorSubject<DateSelection>({
    startDate: undefined,
    endDate: undefined,
  });

  // Minimum and maximum selectable dates
  private _isDateRange$: BehaviorSubject<{
    [key: string]: boolean;
  }> = new BehaviorSubject({});
  public readonly isDateRange$: Observable<{
    [key: string]: boolean;
  }> = this._isDateRange$.asObservable();

  getIsDateRange(identifier: string): boolean {
    return this._isDateRange$.getValue()[identifier] || false;
  }

  // Focusable active cell (last focused) state
  private _activeCell$: BehaviorSubject<LocalDate> = new BehaviorSubject<LocalDate>(null);
  public readonly activeCell$: Observable<LocalDate> = this._activeCell$.asObservable();

  // Hover state
  private _hoveredDay$: BehaviorSubject<Day> = new BehaviorSubject<Day>(null);
  public readonly hoveredDay$: Observable<Day> = this._hoveredDay$.asObservable();

  // Visible month state
  private _visibleMonthIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly visibleMonthIndex$: Observable<number> = this._visibleMonthIndex$.asObservable();

  selectDay(identifier: string, day: Day): void {
    const currentDates = this._datesSubject$.getValue();
    const currentSelection: DateSelection = currentDates[identifier];
    if (
      !currentSelection.startDate ||
      currentSelection.endDate ||
      day.date.lt(currentSelection.startDate) ||
      !this._isDateRange$.getValue()[identifier]
    ) {
      this._datesSubject$.next({
        ...currentDates,
        [identifier]: {
          startDate: day.date,
          endDate: undefined,
        },
      });
    } else {
      this._datesSubject$.next({
        ...currentDates,
        [identifier]: {
          ...currentDates[identifier],
          endDate: day.date,
        },
      });
    }

    this.setActiveCell(day.date);
  }

  setDate(identifier: string, date: LocalDate | undefined, isEndDate: boolean): void {
    const currentDates = this._datesSubject$.getValue();
    const currentSelection: DateSelection = currentDates[identifier];
    const currentCorrespondingDateSelection = isEndDate ? currentSelection.endDate : currentSelection.startDate;
    const isAlreadySelected = currentCorrespondingDateSelection && date?.equals(currentCorrespondingDateSelection);
    const isEndBeforeStart = isEndDate && currentSelection.startDate && date?.lt(currentSelection.startDate);
    const isOutOfRange =
      date?.lt(this._selectableDatesRange$.value.startDate) || date?.gt(this._selectableDatesRange$.value.endDate);

    if (isAlreadySelected) {
      return;
    } else if (isEndBeforeStart) {
      throw new Error('endBeforeStart');
    } else if (isOutOfRange) {
      throw new Error('outOfRange');
    } else {
      const newSelections = isEndDate ? { endDate: date } : { startDate: date };
      this._datesSubject$.next({
        ...currentDates,
        [identifier]: {
          ...currentDates[identifier],
          ...newSelections,
        },
      });
    }

    if (date) {
      this.setActiveCell(date);
    }
  }

  setDates(identifier: string, dateSelection: DateSelection): void {
    this._datesSubject$.next({
      ...this._datesSubject$.getValue(),
      [identifier]: dateSelection,
    });
  }

  clearDates(identifier: string): void {
    this._datesSubject$.next({
      ...this._datesSubject$.getValue(),
      [identifier]: {
        startDate: undefined,
        endDate: undefined,
      },
    });
  }

  setActiveCell(date: LocalDate): void {
    this._activeCell$.next(date);
  }

  setHoveredDay(date: Day): void {
    this._hoveredDay$.next(date);
  }

  setSelectableDatesRange(startDate: LocalDate, endDate: LocalDate): void {
    this._selectableDatesRange$.next({ startDate, endDate });
  }

  setVisibleMonthIndex(index: number): void {
    this._visibleMonthIndex$.next(index);
  }

  setIsDateRange(identifier: string, isRange: boolean): void {
    this._isDateRange$.next({
      ...this._isDateRange$.getValue(),
      [identifier]: isRange,
    });
  }

  getActiveDate(): LocalDate {
    return this._activeCell$.value;
  }

  private setupNewDatesIfNeeded(identifier: string): void {
    const currentDates = this._datesSubject$.getValue();

    if (!currentDates[identifier]) {
      this._datesSubject$.next({
        ...currentDates,
        [identifier]: {
          startDate: null,
          endDate: null,
        },
      });
    }
  }
}

<div class="nordic-blue-900-bg" id="booking-header-summary-section" role="region">
  <div class="relative">
    <div
      data-id="status-bar-location-container"
      class="col-1-12 overflow-hidden flex flex--middle flex--wrap white-text"
    >
      <fin-booking-header-summary-item
        [isClickable]="isClickable"
        (clickAction)="openBookingWidget()"
        *ngIf="bookingSummary.tripType !== TripType.MULTICITY && (this.isMobile$ | async) === false"
        customClasses="pr-xxsmall-r border-right ms-xxsmall-l white-text"
        type="trip-type"
      >
        <span class="content">
          {{ 'tripCard.tripType.' + bookingSummary.tripType | finLocalization }}
        </span>
      </fin-booking-header-summary-item>
      <fin-booking-header-summary-item
        (clickAction)="openBookingWidget()"
        [isClickable]="isClickable"
        customClasses="{{
          bookingSummary.tripType === TripType.MULTICITY ? 'pr-xxsmall-r' : 'pr-xxsmall-x'
        }} border-right"
        type="location"
      >
        <span class="content">
          <span>
            {{
              bookingSummary.tripType === TripType.MULTICITY
                ? ('tripCard.tripType.complex' | finLocalization)
                : bookingSummary.locations?.[0].originLocationCode
            }}
          </span>
          <span class="flex--nogrow ps-xxsmall-x">
            <fcom-icon
              class="icon-small"
              [name]="
                bookingSummary.tripType === TripType.RETURN
                  ? SvgLibraryIcon.RETURN_TRIP
                  : SvgLibraryIcon.AIRCRAFT_HORIZONTAL
              "
            ></fcom-icon>
          </span>
          <span>
            {{
              bookingSummary.tripType === TripType.MULTICITY
                ? (isMobile$ | async)
                  ? bookingSummary.locations.length
                  : ('bookingWidget.numberOfFlights'
                    | finLocalization: { numberOfFlights: bookingSummary.locations.length })
                : bookingSummary.locations?.[0].destinationLocationCode
            }}
          </span>
        </span>
      </fin-booking-header-summary-item>
      <fin-booking-header-summary-item
        (clickAction)="openBookingWidget()"
        [isClickable]="isClickable"
        customClasses="pr-xxsmall-x border-right"
        type="pax-amount"
      >
        <span class="content">
          <fcom-icon
            class="icon-small"
            [name]="bookingSummary.paxAmount !== 1 ? SvgLibraryIcon.MULTI_PAX : SvgLibraryIcon.SINGLE_PAX"
          ></fcom-icon>

          {{ bookingSummary.paxAmount }}
        </span>
      </fin-booking-header-summary-item>
      <fin-booking-header-summary-item
        (clickAction)="openBookingWidget()"
        [isClickable]="isClickable"
        customClasses="pr-xxsmall-l ms-xxsmall-r"
        type="date"
      >
        <span class="content">
          {{
            bookingSummary.dates?.returnDate
              ? (bookingSummary.dates?.departureDate | finDateFormat: 'DATE_SHORT') +
                ' - ' +
                (bookingSummary.dates?.returnDate | finDateFormat: 'DATE_SHORT')
              : (bookingSummary.dates?.departureDate | finDateFormat: 'FULL_NO_YEAR')
          }}
        </span>
      </fin-booking-header-summary-item>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { makeStateKey, StateKey } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { MultivariateTestService } from '@fcom/common/multivariate-test/services/multivariate-test.service';
import { AppState, ConfigService, SentryLogger, StateTransferService } from '@fcom/core';
import { CmsContent, CmsContentType, CmsTemplate, RESPONSE_CODE_HANDLER, ResponseCodeHandler } from '@fcom/core-api';
import { Profile } from '@fcom/core-api/login';
import { profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';
import { finShare, retryWithBackoff } from '@fcom/rx';
import { LanguageService } from '@fcom/ui-translate';
import { getWhitelistedCmsParamsMapped, pathToTemplateUrl, stringHashCode } from '@fcom/core/utils';

import { CmsPersonalizationParameter } from '../../interfaces';
import {
  finUniversalResponseStatus,
  hasPersonalizedContent,
  parseCmsTemplate,
  recursiveParse,
} from '../cms-utils/cms-utils';

interface CmsPageTemplateWithPath {
  path: string;
  template: CmsTemplate;
}

// @TODO: We should have proper static error page rendered
export const error404template: CmsTemplate = {
  header: [],
  main: [],
  footer: [],
};

@Injectable()
export class CmsTemplateService {
  private segment$: Observable<number>;
  private currentCmsPageTemplate$: BehaviorSubject<CmsPageTemplateWithPath | undefined> = new BehaviorSubject<
    CmsPageTemplateWithPath | undefined
  >(undefined);
  static createStateCacheKey = (templateUrl: string): StateKey<CmsTemplate> =>
    makeStateKey<CmsTemplate>(`cts-${stringHashCode(templateUrl)}`);
  static createP13CacheKey = (contentUrl: string): StateKey<CmsContent> =>
    makeStateKey<CmsContent>(`p13-${stringHashCode(contentUrl)}`);

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sentryLogger: SentryLogger,
    @Optional()
    @Inject(RESPONSE_CODE_HANDLER)
    private serverResponseCodeHandler: ResponseCodeHandler,
    private stateTransferService: StateTransferService,
    private languageService: LanguageService,
    private multivariateTestService: MultivariateTestService,
    private store$: Store<AppState>
  ) {
    this.segment$ = this.multivariateTestService.segmentsFeed$.pipe(map((value) => value.segment));
  }

  /**
   * Load the template for the given path in CMS.
   * <p>
   * Translates path
   * <tt>/en/destination/germany/berlin</tt>
   * to e.g.
   * <tt>http://test.coremedia.dev.app.finnair.com/en/destinations/europe/germany/berlin?view=fin-json</tt>
   *
   * @param path the path to retrieve
   * @returns {Observable<string>} the template as Observable
   */
  load(path: string): Observable<CmsTemplate> {
    const defaultTemplate$ = this.loadOnce(path);
    const personalisedTemplate$ = this.getP13NTemplate(defaultTemplate$);

    // NOTE: there is race here so ensure that defaultTemplate$ will always emit ones first and
    // personalisedTemplate$ comes later or not
    return merge(defaultTemplate$, personalisedTemplate$).pipe(
      tap((template) => this.currentCmsPageTemplate$.next({ path, template }))
    );
  }

  getTemplateForPathIfExists(path: string): Observable<CmsTemplate | undefined> {
    return this.currentCmsPageTemplate$.pipe(
      distinctUntilChanged((a, b) => a?.path === b?.path),
      map((currentPageTemplate) => (currentPageTemplate?.path === path ? currentPageTemplate.template : undefined))
    );
  }

  private loadOnce(path: string): Observable<CmsTemplate> {
    const templateUrl = pathToTemplateUrl(this.config.cmsUrl, path);
    const stateKey = CmsTemplateService.createStateCacheKey(templateUrl);
    return this.stateTransferService.wrapToStateCache(stateKey, () => this.getTemplate(templateUrl).pipe(finShare()));
  }

  private getTemplate(templateUrl: string): Observable<CmsTemplate> {
    return this.http
      .get<CmsTemplate>(templateUrl, {
        withCredentials: this.config.cfg.enableCorsCredentials,
        observe: 'response',
      })
      .pipe(
        retryWithBackoff(1),
        finUniversalResponseStatus(this.serverResponseCodeHandler, 'CmsTemplateService', templateUrl),
        map((res) => res.body),
        map((data: CmsTemplate) => parseCmsTemplate(data, this.languageService.ddsLocaleValue, this.sentryLogger)),
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        catchError((error: any) => {
          const statusCode = error.status || 500;

          if (statusCode !== 404) {
            this.sentryLogger.error('Internal server error', { error });
          }
          if (this.serverResponseCodeHandler) {
            this.serverResponseCodeHandler.setUniversalResponseStatus('CmsTemplateService', statusCode, templateUrl);
          }
          const errorTemplateFromError: CmsTemplate | undefined = (error || {}).error;
          const errorTemplate = errorTemplateFromError?.main ? errorTemplateFromError : error404template;
          return of({ ...errorTemplate, status: statusCode });
        })
      );
  }

  private fetchCustomizedContent(contentUrl: string, originalContent: CmsContent): Observable<CmsContent> {
    return this.http
      .get<CmsContent>(contentUrl, {
        withCredentials: this.config.cfg.enableCorsCredentials,
        observe: 'response',
      })
      .pipe(
        retryWithBackoff(1),
        finUniversalResponseStatus(this.serverResponseCodeHandler, 'CmsTemplateService', contentUrl),
        map((res) => recursiveParse(res.body, this.sentryLogger)),
        catchError((error: unknown) => {
          this.sentryLogger.error('Internal server error', { error });
          // Return original content block in case of error so that stream won't be broken
          return of(originalContent);
        })
      );
  }

  private getP13NTemplate(template$: Observable<CmsTemplate>): Observable<CmsTemplate> {
    return combineLatest([
      template$,
      this.store$.pipe(profileOrUndefinedIfNotLoggedIn()),
      this.segment$.pipe(take(1)),
    ]).pipe(
      filter(([template]) => hasPersonalizedContent(template.main)),
      switchMap(([template, profileValue, segment]) => {
        const items$: Observable<CmsContent>[] = template.main.map((item) =>
          this.getPersonalizedContentItem(item, profileValue, segment)
        );
        return forkJoin([...items$]).pipe(map((items) => ({ ...template, main: items })));
      }),
      finShare()
    );
  }

  private getPersonalizedContentItem(
    contentItem: CmsContent,
    profileValue: Profile,
    segment: number
  ): Observable<CmsContent> {
    if (contentItem.contentType !== CmsContentType.CMPersonalized) {
      const children$ = contentItem.items?.map((item) => this.getPersonalizedContentItem(item, profileValue, segment));
      return children$
        ? forkJoin([...children$]).pipe(map((children: CmsContent[]) => ({ ...contentItem, items: children })))
        : of(contentItem);
    }

    // eslint-disable-next-line prefer-spread
    const personalizationSearchParams = contentItem.personalizationParameters;
    const profileTierParam =
      personalizationSearchParams.indexOf(CmsPersonalizationParameter.TIER) !== -1
        ? `tier=${profileValue?.tier ?? ''}`
        : '';
    const segmentGroupParam =
      personalizationSearchParams.indexOf(CmsPersonalizationParameter.GROUP) !== -1 ? `group=${segment}` : '';
    const aktiaCreditParam =
      personalizationSearchParams.indexOf(CmsPersonalizationParameter.AKTIA_CREDIT_CARD) !== -1
        ? `aktiavisacreditcard=${profileValue?.hasAktiaVisaCreditCard ? 'true' : 'false'}`
        : '';

    const path = contentItem.url;
    const oldQueryParams = getWhitelistedCmsParamsMapped(path);

    const allPersonalizationParams = oldQueryParams
      .concat(profileTierParam, segmentGroupParam, aktiaCreditParam)
      .filter(Boolean)
      .join('&');

    const pathWithoutParams = `/${path}`.replace(/\?.*$/, '').replace(/^\/\//g, '/');

    const url = pathToTemplateUrl(
      this.config.cmsUrl,
      `${pathWithoutParams}?${allPersonalizationParams}`,
      'fin-p13n-json'
    );

    const stateKey = CmsTemplateService.createP13CacheKey(url);
    return this.stateTransferService
      .wrapToStateCache(stateKey, () => this.fetchCustomizedContent(url, contentItem))
      .pipe(finShare());
  }
}

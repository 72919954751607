<div class="ps-small-x">
  <table
    class="monthWrapper fill"
    role="grid"
    [class.monthWrapper--hidden]="isVisiblyHidden"
    [attr.aria-multiselectable]="isDateRange ? 'true' : 'false'"
  >
    <caption class="title font-body-1 nordic-blue-900-text bold-type ps-medium">
      {{
        monthLabel
      }}
    </caption>
    <thead>
      <tr>
        <th
          class="weekDay font-body-2 light-type"
          scope="col"
          *ngFor="let dayName of dateLabels?.weekdays; let i = index"
          id="{{ dayName }}"
        >
          <abbr title="{{ dayName }}">{{ (dateLabels?.weekdaysShort)[i] }}</abbr>
        </th>
      </tr>
    </thead>
    <tbody class="calendarBlock">
      <ng-container *ngFor="let week of weeks">
        <tr>
          <td
            fcom-calendar-day
            *ngFor="let day of week.days"
            class="dayWrapper"
            [class.dayCell]="!day.spacer"
            [template]="dayTemplate"
            [uiDay]="day"
            [month]="month"
            [selectedLabel]="uiLabels?.selected"
            [attr.colspan]="day.spacer"
            [attr.aria-selected]="day.selected"
            (selected)="day.selected = $event"
            [identifier]="identifier"
          ></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

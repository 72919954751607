<div
  *ngIf="profile$ | async as profile"
  class="profile-quick-view white-bg grow relative {{
    (tierDataMap[profile.tier] || tierDataMap[ProfileTier.ACCOUNT]).className
  }}"
>
  <div class="top ps-medium-t ps-large-x border-bottom overflow-hidden relative">
    <div class="flex flex--space-between" data-hj-suppress>
      <div>
        <a
          *ngIf="dashboardUrl$ | async as dashboardUrl; else profileName"
          [finLink]="dashboardUrl"
          [external]="true"
          class="ps-xlarge-r name inline-block nordic-blue-900-text bold-type font-body-1 no-underline"
        >
          <ng-container *ngTemplateOutlet="profileName" />
        </a>
        <ng-template #profileName> {{ profile.firstname }}&nbsp;{{ profile.lastname }} </ng-template>
      </div>
    </div>
    <div class="ms-large-b">
      <span class="nordic-blue-900-text font-body-1 regular-type" data-hj-suppress>
        <ng-container *ngIf="profile.tier === ProfileTier.CORPORATE; else tierName">
          {{ profile.corporate.name }}
        </ng-container>
        <ng-template #tierName>
          <ng-container *ngIf="profile.isJuniorMember">
            {{ tierDataMap[profile.tier].juniorName || tierDataMap[ProfileTier.JUNIOR].name | finLocalization }}
          </ng-container>
          <ng-container *ngIf="!profile.isJuniorMember">
            {{ (tierDataMap[profile.tier] || tierDataMap[ProfileTier.ACCOUNT]).name | finLocalization }}
          </ng-container>
        </ng-template>
      </span>
    </div>
    <div class="ms-medium-y font-body-1 regular-type" *ngIf="profile.isCorporate">
      {{ tierDataMap[ProfileTier.CORPORATE][profile.corporate.userRole] | finLocalization }}
    </div>
    <div class="ms-large-y points" *ngIf="profile.tier !== ProfileTier.ACCOUNT && !profile.isCorporate">
      <span
        class="nordic-blue-900-text font-body-1 regular-type"
        [innerHTML]="'finnairPlus.awardPoints' | finLocalization: { awardPoints: profile.awardPoints || 0 | finPoints }"
      ></span>
    </div>
    <div class="plus-card absolute" *ngIf="tierDataMap[profile.tier] && tierDataMap[profile.tier].cardIcon">
      <fcom-icon class="grow" [category]="IconLibrary.CUSTOM" [name]="tierDataMap[profile.tier].cardIcon"></fcom-icon>
    </div>
  </div>
  <div class="bottom ps-small-b ps-large-x">
    <ul class="nordic-blue-900-text unstyled" role="list">
      <ng-container *ngFor="let item of menuItems$ | async">
        <li role="listitem" *ngIf="item">
          <a
            *ngIf="item.link"
            (beforeNavigation)="close.emit()"
            [class.current-item]="item.active"
            [finLink]="item.link"
            [external]="item.external"
            class="flex flex--space-between no-underline nordic-blue-900-text font-body-1 ps-medium-y regular-type border-bottom"
            [attr.target]="item.openInNewTab ? '_blank' : ''"
            ><span class="valign-middle">{{ item.teaserTitle }}</span>
            <fcom-icon
              *ngIf="item.external"
              class="right external-link icon-medium"
              [name]="SvgLibraryIcon.EXTERNAL_TAB"
            ></fcom-icon
          ></a>
        </li>
      </ng-container>
      <li role="listitem">
        <a
          data-testid="profile-quick-view-logout-btn"
          class="block no-underline nordic-blue-900-text font-body-1 ps-medium-y regular-type"
          href=""
          (click)="logout($event)"
          >{{ 'login.logoutButton' | finLocalization }}</a
        >
      </li>
    </ul>
  </div>
</div>

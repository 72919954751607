import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { LanguageService } from '@fcom/ui-translate';
import { recurseLangParam } from '@fcom/common/utils/route-utils';
import { SentryLogger } from '@fcom/core';
import { ddsLangToNewLang } from '@fcom/core/selectors/dds-languages';

@Injectable()
export class LanguageGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private sentryLogger: SentryLogger,
    private languageService: LanguageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.redirectToRootIfLanguageNotApplicable(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.redirectToRootIfLanguageNotApplicable(childRoute, state);
  }

  private redirectToRootIfLanguageNotApplicable(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLangSupported = (locale) => this.languageService.allowableLangs.includes(locale);
    const getLang = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const langFromParam = recurseLangParam(route);
      if (isLangSupported(langFromParam)) {
        return langFromParam;
      }

      const langFromDds = ddsLangToNewLang(state.url);
      if (isLangSupported(langFromDds)) {
        return langFromDds;
      }

      return null;
    };

    const lang = getLang(route, state);
    if (!lang) {
      this.sentryLogger.warn('Url has invalid language', { url: state.url });
      this.router.navigateByUrl('/', { replaceUrl: true });
      return false;
    }
    return true;
  }
}

import { DateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

import { LocalDate, pad } from '@fcom/core/utils';

export const dayMonthYearFormat = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const DEFAULT_MONTH_NAMES = {
  long: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
};

const DEFAULT_DAY_OF_WEEK_NAMES = {
  long: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  narrow: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
};

let SUPPORTS_INTL_API;
try {
  SUPPORTS_INTL_API = typeof Intl != 'undefined';
} catch (_a) {
  SUPPORTS_INTL_API = false;
}

const range = (length, valueFunction) => {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
};

@Injectable({ providedIn: 'root' })
export class CustomDateAdapter extends DateAdapter<LocalDate> {
  parse(value: string): LocalDate | null {
    if (value.indexOf('/') > -1 || value.indexOf('.') > -1) {
      const splitDateRegex = /[ .:;?!~,`''&|()<>{}[\]\r\n/\\]+/;
      const [date, month, year] = value.split(splitDateRegex).map(Number);

      try {
        return LocalDate.forDate(year, month, date);
      } catch {
        return null;
      }
    }
    return null;
  }

  compareDate(first: LocalDate, second: LocalDate): number {
    return first.lt(second) ? -1 : first.equals(second) ? 0 : 1;
  }

  getDateNames(): string[] {
    return Array(31)
      .fill(0)
      .map((_, i) => String(i + 1));
  }

  format(date: LocalDate): string {
    return `${pad(date.localDay)}.${pad(date.localMonth)}.${date.localYear}`;
  }

  addCalendarDays(date: LocalDate, days: number): LocalDate {
    return date.plusDays(days);
  }

  addCalendarMonths(date: LocalDate, months: number): LocalDate {
    return date.plusMonths(months);
  }

  addCalendarYears(date: LocalDate, years: number): LocalDate {
    return date.plusYears(years);
  }

  clone(date: LocalDate): LocalDate {
    return new LocalDate(date.toString());
  }

  /**
   * @param year
   * @param month index based number starting from 0
   * @param date
   */
  createDate(year: number, month: number, date: number): LocalDate {
    return LocalDate.forDate(year, month + 1, date);
  }

  getDate(date: LocalDate): number {
    return date.localDay;
  }

  getDayOfWeek(date: LocalDate): number {
    return date.weekday;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (SUPPORTS_INTL_API) {
      const dtf = new Intl.DateTimeFormat(this.locale, { weekday: style, timeZone: 'utc' });
      return range(7, (i) =>
        this.stripDirectionalityCharacters(this.intFormat(dtf, LocalDate.forDate(2017, 1, i + 1)))
      );
    }

    return DEFAULT_DAY_OF_WEEK_NAMES[style];
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  /**
   * @param date
   * @return number zero based number of month
   */
  getMonth(date: LocalDate): number {
    return date.localMonth - 1;
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (SUPPORTS_INTL_API) {
      const dtf = new Intl.DateTimeFormat(this.locale, { month: style, timeZone: 'utc' });
      return range(12, (i) =>
        this.stripDirectionalityCharacters(this.intFormat(dtf, LocalDate.forDate(2017, i + 1, 1)))
      );
    }

    return DEFAULT_MONTH_NAMES[style];
  }

  getNumDaysInMonth(date: LocalDate): number {
    return LocalDate.getAmountOfDaysInMonth(date.localYear, date.localMonth);
  }

  getYear(date: LocalDate): number {
    return date.localYear;
  }

  getYearName(date: LocalDate): string {
    return date.localYear.toString();
  }

  invalid(): LocalDate {
    return null;
  }

  isDateInstance(obj: unknown): boolean {
    return obj instanceof LocalDate;
  }

  isValid(date: LocalDate | null): boolean {
    if (date === null) {
      return false;
    }
    return !isNaN(date.toDate().getTime());
  }

  toIso8601(date: LocalDate): string {
    return date.toISOString();
  }

  today(): LocalDate {
    return LocalDate.now();
  }

  private stripDirectionalityCharacters(str): string {
    return str.replace(/[\u200e\u200f]/g, '');
  }

  private intFormat(dtf: Intl.DateTimeFormat, date: LocalDate) {
    return dtf.format(date.toDate());
  }
}

import { Component, OnInit } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { GlobalBookingFlight, LocationPair } from '@fcom/common/store';
import { Location, LocationSuggestions } from '@fcom/core-api';
import { GlobalBookingTripDates } from '@fcom/common';
import { ButtonTheme, IconButtonSize, IconButtonTheme } from '@fcom/ui-components';
import { ConfigService } from '@fcom/core';

import { LocationParam } from '../../interfaces';
import { BookingWidgetService } from '../../services/booking-widget.service';

@Component({
  selector: 'fin-multicity-selector',
  templateUrl: './multicity-selector.component.html',
  styleUrls: ['./multicity-selector.component.scss'],
})
export class MulticitySelectorComponent implements OnInit {
  readonly ButtonTheme = ButtonTheme;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconButtonSize = IconButtonSize;
  readonly IconLibrary = IconLibrary;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  flights$: Observable<GlobalBookingFlight[]>;

  suggestedLocations$: Observable<LocationSuggestions>;

  trendingDestinations$: Observable<Location[]>;
  enableMultiCity = false;

  constructor(
    private bookingWidgetService: BookingWidgetService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.flights$ = this.bookingWidgetService.flights$;
    this.suggestedLocations$ = this.bookingWidgetService.suggestedLocations$;
    this.trendingDestinations$ = this.bookingWidgetService.trendingDestinations$;
    this.enableMultiCity = this.configService.cfg.enableMultiCity;
  }

  setLocations({ locations }: LocationParam, index?: number): void {
    this.bookingWidgetService.setLocations(locations, index);
  }

  locationsHaveChanged(i: number, location: LocationPair): string {
    return `${i}-${location.origin?.locationCode}-${location.destination?.locationCode}`;
  }

  setTravelDates(dates: GlobalBookingTripDates, index: number, isAirCalendar = false, enableMultiCity: boolean): void {
    if (enableMultiCity) {
      this.bookingWidgetService.setTravelDates(dates, index, isAirCalendar, false);
    } else {
      //TODO: remove when new multicity release to all
      // update dates separately to fullfill the multi-city flight structure
      this.bookingWidgetService.setTravelDates({ departureDate: dates.departureDate }, 0, isAirCalendar, false);
      this.bookingWidgetService.setTravelDates({ departureDate: dates.returnDate }, 1, isAirCalendar, false);
    }
  }

  setFlexibleDates(isFlexibleDates: boolean): void {
    this.bookingWidgetService.setFlexibleDates(isFlexibleDates);
  }

  addFlight(): void {
    this.bookingWidgetService.addFlight();
  }

  removeFlight(index: number): void {
    this.bookingWidgetService.removeFlight(index);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';
import { BehaviorSubject, combineLatest, distinctUntilChanged, EMPTY, map, Observable, Subscription, take } from 'rxjs';

import { Cabin, TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { Amount } from '@fcom/dapi/interfaces';
import { unsubscribe } from '@fcom/core/index';
import { NotificationButtonStyle, TagTheme } from '@fcom/ui-components';
import { CabinClassSelectionFareFamily } from '@fcom/booking/modules/ticket-selection/interfaces';
import { effectiveCabinClass } from '@fcom/booking/modules/ticket-selection/utils/offer.utils';

import { DimensionsAndDetails, ExtendedFareFamily } from '../../../interfaces';

const SEATS_LEFT_LIMIT = 9;

@Component({
  selector: 'fin-fare-family-list',
  styleUrls: ['./fare-family-list.component.scss'],
  templateUrl: './fare-family-list.component.html',
})
export class FlightSelectionFareFamilyListComponent implements OnInit, OnDestroy {
  readonly Cabin = Cabin;
  readonly NotificationButtonStyle = NotificationButtonStyle;
  readonly SEATS_LEFT_LIMIT = SEATS_LEFT_LIMIT;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagTheme = TagTheme;

  @Input({ required: true }) fareFamilies$: Observable<ExtendedFareFamily[]>;
  @Input({ required: true }) currencyCode: string;
  @Input() showUpsellCard = false;
  @Input() showKoreaNotification = false;
  @Input() roundedTop = false;
  @Input() allowAutoExpand = false;
  @Input()
  isShortHaul$: Observable<boolean>;
  @Input()
  isLongHaulLegAndAy$: Observable<boolean> = EMPTY;

  @Output() selectFareFamily = new EventEmitter<{
    fareFamily: ExtendedFareFamily;
    checked: boolean;
  }>();

  @Output() cabinIsExpanded = new EventEmitter<Cabin>();

  @Output() openCabinClassModal = new EventEmitter<string>();

  private subscriptions: Subscription = new Subscription();

  fareFamiliesByCabin$: Observable<Record<Cabin, ExtendedFareFamily[]>>;
  cabins$: Observable<Cabin[]>;
  cabinIsExpanded$ = new BehaviorSubject<Cabin>(null);
  minPricePerCabin$: Observable<
    Record<
      Cabin,
      {
        totalPrice: Amount;
        originalTotalPrice: Amount;
        quota: number;
      }
    >
  >;
  selectedFareFamilyPerCabin$: Observable<Record<Cabin, ExtendedFareFamily>>;
  fareFamilyBenefits$: Observable<Record<string, TranslatedBenefitRow[]>>;
  uuid: string;
  isTicketTypeModalOpen = false;
  ticketTypeInfo$: Observable<DimensionsAndDetails>;
  cabinUpsellMap = {
    [Cabin.ECONOMY]: Cabin.ECOPREMIUM,
    [Cabin.ECOPREMIUM]: Cabin.BUSINESS,
  };
  upsellCardFareFamily$: Observable<CabinClassSelectionFareFamily>;

  ngOnInit(): void {
    this.uuid = uuid();
    this.fareFamiliesByCabin$ = this.fareFamilies$.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map((fareFamilies) => {
        return fareFamilies.reduce(
          (acc, fareFamily) => {
            const cabin = effectiveCabinClass(fareFamily) as Cabin;

            acc[cabin] = acc[cabin] || [];
            acc[cabin].push(fareFamily);

            return acc;
          },
          {} as Record<Cabin, ExtendedFareFamily[]>
        );
      })
    );

    this.cabins$ = this.fareFamilies$.pipe(
      map((fareFamilies) => {
        return fareFamilies.reduce((acc, fareFamily) => {
          const cabin = effectiveCabinClass(fareFamily) as Cabin;
          if (!acc.includes(cabin)) {
            acc.push(cabin);
          }

          return acc;
        }, []);
      })
    );

    this.selectedFareFamilyPerCabin$ = this.fareFamilies$.pipe(
      map((fareFamilies) => {
        return fareFamilies.reduce(
          (acc, fareFamily) => {
            const cabinClass = effectiveCabinClass(fareFamily);

            acc[cabinClass] = acc[cabinClass] || null;

            if (fareFamily.selected) {
              acc[effectiveCabinClass(fareFamily)] = fareFamily;
            }

            return acc;
          },
          {} as Record<Cabin, ExtendedFareFamily>
        );
      })
    );

    this.subscriptions.add(
      this.cabins$.pipe(take(1)).subscribe((cabins) => {
        if (cabins.length === 1 && this.allowAutoExpand) {
          this.cabinIsExpanded$.next(cabins[0]);
        }
      })
    );

    this.minPricePerCabin$ = this.fareFamilies$.pipe(
      map((fareFamilies) => {
        return fareFamilies.reduce(
          (acc, fareFamily) => {
            const cabin = effectiveCabinClass(fareFamily);
            const currentLowestPrice = acc[cabin]?.totalPrice;

            if (!currentLowestPrice || fareFamily.totalPrice < currentLowestPrice.amount) {
              acc[cabin] = {
                totalPrice: {
                  amount: fareFamily.totalPrice,
                  currencyCode: this.currencyCode,
                },
                quota: fareFamily.quota || 9,
                ...(fareFamily.originalTotalPrice
                  ? {
                      originalTotalPrice: {
                        amount: fareFamily.originalTotalPrice,
                        currencyCode: this.currencyCode,
                      },
                    }
                  : {}),
              };
            }

            return acc;
          },
          {} as Record<Cabin, { totalPrice: Amount; originalTotalPrice: Amount; quota: number }>
        );
      })
    );

    this.ticketTypeInfo$ = combineLatest([this.fareFamilies$, this.cabinIsExpanded$]).pipe(
      map(([fareFamilies, expandedCabin]) => {
        if (!expandedCabin) {
          return null;
        }

        return {
          extendedFareFamilies: fareFamilies,
          cabinClass: expandedCabin,
          itineraries: [],
        };
      })
    );

    this.upsellCardFareFamily$ = combineLatest([this.cabinIsExpanded$, this.fareFamiliesByCabin$]).pipe(
      map(([currentCabin, fareFamiliesByCabin]) => {
        if (!this.showUpsellCard) {
          return null;
        }

        if (!currentCabin) {
          return null;
        }

        const nextCabinClass: ExtendedFareFamily[] = fareFamiliesByCabin[this.cabinUpsellMap[currentCabin]];

        if (!nextCabinClass) {
          return null;
        }

        return {
          ...nextCabinClass[0],
          cabinClass: this.cabinUpsellMap[currentCabin],
          preSelectFareFamily: nextCabinClass[0],
        } as CabinClassSelectionFareFamily;
      })
    );

    this.subscriptions.add(
      this.cabinIsExpanded$.pipe(distinctUntilChanged()).subscribe((cabin) => {
        this.cabinIsExpanded.emit(cabin);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  toggleCabin(cabin: Cabin): void {
    const currentValue = this.cabinIsExpanded$.getValue();

    this.cabinIsExpanded$.next(currentValue === cabin ? null : cabin);
  }

  trackByFn(_i: number, item: ExtendedFareFamily): string {
    return item.fareFamilyCode;
  }

  openCabinAndSelect(fareFamily: CabinClassSelectionFareFamily): void {
    this.cabinIsExpanded$.next(fareFamily.cabinClass as Cabin);
  }
}

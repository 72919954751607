<ng-container *ngIf="content$ | async as content">
  <div class="teaser-list grid-col-12 no-gutters">
    <ng-container *ngIf="content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_LIST_TEASER_STYLE); else defaultTemplate">
      <ng-container *ngIf="items$ | async as items">
        <fin-teaser
          *ngFor="let teaserItem of items; trackBy: trackByUrl"
          #listItem
          [class]="content.gridClass || 'col-1-12 md-col-1-8'"
          [content]="teaserItem"
        ></fin-teaser>
      </ng-container>
    </ng-container>
    <ng-template #defaultTemplate>
      <ng-container *ngIf="items$ | async as items">
        <fin-teaser-card
          *ngFor="let teaserItem of items; trackBy: trackByUrl"
          #listItem
          class="col-1-12 teaser-wrapper"
          [aspectRatio]="'16x9'"
          [content]="teaserItem"
        ></fin-teaser-card>
      </ng-container>
    </ng-template>
    <div class="col-1-12 ms-small-y flex load-more" *ngIf="showLoadMore$ | async">
      <a [href]="nextPageLink$ | async" class="btn no-underline bold-type" (click)="loadNextItems($event)">
        <span *ngIf="!loading">{{ 'actions.loadMore' | finLocalization }}</span>
        <fcom-loader *ngIf="loading" [loaderType]="LoaderTheme.INLINE_TEXT" [color]="LoaderColor.WHITE"></fcom-loader>
      </a>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { catchError, EMPTY, filter, map, Observable, of } from 'rxjs';

import {
  FinnairOrderCancelRefund,
  FinnairPassengerItem,
  FinnairServiceItem,
  FinnairTotalPricesDetails,
  ServiceFlow,
} from '@fcom/dapi/api/models';
import { NotificationLayout, NotificationTheme, ModalButtons } from '@fcom/ui-components';
import { hasUnitPrices } from '@fcom/common/utils';
import { SentryLogger } from '@fcom/core/services';
import { asPaxTypeKey } from '@fcom/dapi/utils';

import { getPassengerServices, hasPassengers } from '../../utils/order.utils';
import { BreakdownModel } from '../price-breakdown-renewed/price-breakdown-renewed.component';

@Component({
  selector: 'fin-refund-detail',
  templateUrl: './refund-detail.component.html',
  styleUrls: ['./refund-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefundDetailComponent implements OnInit {
  readonly asPaxTypeKey = asPaxTypeKey;
  readonly ModalButtons = ModalButtons;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly ServiceFlow = ServiceFlow;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  cancelRefund$: Observable<FinnairOrderCancelRefund>;

  @Input()
  services$: Observable<FinnairServiceItem[]>;

  @Input()
  flightNotificationKey: string;

  @Input()
  showNotifications = false;

  summaryModalOpen: boolean;
  summaryModalData: { prices: FinnairTotalPricesDetails };
  breakdowns$: Observable<BreakdownModel[]> = of([]);
  constructor(private sentryLogger: SentryLogger) {}

  ngOnInit(): void {
    if (this.cancelRefund$) {
      this.breakdowns$ = this.cancelRefund$.pipe(
        filter(
          (cancelRefund) =>
            hasPassengers(cancelRefund.order.passengers) && hasUnitPrices(cancelRefund.refundAmount, true)
        ),
        map((cancelRefund) => {
          const refundAmount = cancelRefund.refundAmount;
          return cancelRefund.order.passengers
            .filter((passenger) => parseFloat(refundAmount.total.totalPerPax?.[passenger.id]?.totalAmount.amount) > 0)
            .map((passenger: FinnairPassengerItem) => ({
              ...passenger,
              prices:
                refundAmount.flight?.totalPerPax?.[passenger.id] || refundAmount.services?.totalPerPax?.[passenger.id],
              services: getPassengerServices(refundAmount.services?.totalPerCategory, passenger),
              total: refundAmount.total.totalPerPax?.[passenger.id]?.totalAmount,
            }));
        }),
        catchError((e: unknown) => {
          this.sentryLogger.error('Exception in handling prices of passengers', e);
          return EMPTY;
        })
      );
    }
  }

  openSummaryModal = (event: Event, prices: FinnairTotalPricesDetails): void => {
    event.stopPropagation();

    this.summaryModalOpen = true;
    this.summaryModalData = {
      prices,
    };
  };

  closeSummaryModal = (): void => {
    this.summaryModalData = null;
  };
}

<div data-testid="fcom-filters-desktop" *ngIf="(isMobile$ | async) === false">
  <button
    class="filters-button flex flex--no-wrap rounded-medium ps-small-x font-body-1 white-bg border nordic-blue-400-border"
    fcomPopover
    [options]="popoverOptions"
    [content]="contentPopover"
    [disabled]="disabled"
    [ngClass]="{ 'has-filtering': hasFiltering }"
    aria-controls="expandContainerId"
    [id]="expandButtonId"
    (click)="popoverOpeningClick.emit()"
  >
    <span class="ps-small-y">{{ hasFiltering ? buttonTextWhenFiltered : buttonText }}</span>
    <span>{{ hasFiltering && numberToShowWhenFiltered ? '+' + numberToShowWhenFiltered : '' }}</span>
    <fcom-icon
      *ngIf="!disabled"
      [name]="SvgLibraryIcon.CHEVRON_DOWN"
      class="rotate icon-small ms-xxsmall-l flex flex--self-center"
    ></fcom-icon>
  </button>

  <ng-template #contentPopover>
    <div [attr.aria-labelledby]="expandButtonId" id="expandContainerId" class="flex flex--space-between ps-medium">
      <h4 class="font-heading-5">{{ headerText }}</h4>
    </div>
    <ng-content select="[popover-content]"></ng-content>
    <div class="flex flex--row-reverse grey-50-bg ps-medium rounded-bottom-large">
      <fcom-button
        class="ms-small-l"
        [text]="primaryFooterButtonTitle"
        (clickBtn)="primaryFooterButtonClick.emit()"
      ></fcom-button>
      <fcom-button
        [text]="secondaryFooterButtonTitle"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        (clickBtn)="secondaryFooterButtonClick.emit()"
      ></fcom-button>
    </div>
  </ng-template>
</div>

<div data-testid="fcom-filters-mobile" *ngIf="isMobile$ | async">
  <button
    class="filters-button flex flex--no-wrap flex--center rounded-medium width--100 font-body-1 white-bg border nordic-blue-400-border"
    [disabled]="disabled"
    (click)="showModal.emit()"
  >
    <fcom-icon
      class="icon-small ms-xxsmall-l flex flex--self-center"
      [name]="SvgLibraryIcon.FILTER_OPTIONS"
    ></fcom-icon>
    <span class="ms-xxsmall-l ps-small-y flex">
      {{ buttonText }}
      <span
        *ngIf="hasFiltering"
        class="ms-xxsmall-l font-body-2 flex flex--self-center nordic-blue-900-bg white-text dot"
      >
        {{ numberToShowWhenFiltered }}
      </span>
    </span>
  </button>

  <fcom-modal
    [fullHeight]="false"
    [title]="modalTitle"
    [(open)]="isModalOpen"
    (close)="closeModal.emit()"
    [buttons]="ModalButtons.CUSTOM"
  >
    <ng-content select="[modal-content]"></ng-content>
    <fcom-notification
      data-testid="fcom-filters-mobile-notification"
      *ngIf="displayNotification"
      messageBox
      [theme]="NotificationTheme.WARNING"
      [layout]="NotificationLayout.EDGE_TO_EDGE"
      [excludeColumns]="true"
      [iconCategory]="IconLibrary.SVG_LIBRARY"
      [iconName]="SvgLibraryIcon.INFO_BLOCK"
      [compact]="true"
    >
      <span> {{ notificationContent }}</span>
    </fcom-notification>
    <div buttons>
      <div class="flex flex--center flex--row-reverse grey-50-bg ps-medium rounded-bottom-large">
        <fcom-button
          class="ms-small-l"
          [text]="primaryFooterButtonTitle"
          (clickBtn)="primaryFooterButtonClick.emit()"
        ></fcom-button>
        <fcom-button
          [text]="secondaryFooterButtonTitle"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          (clickBtn)="secondaryFooterButtonClick.emit()"
        ></fcom-button>
      </div>
    </div>
  </fcom-modal>
</div>

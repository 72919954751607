<div class="form-container service-form">
  <div #fcomNotification *ngIf="formStatus === FormStatus.COMPLETE || formStatus === FormStatus.ERROR">
    <fcom-info-box
      aria-live="polite"
      class="pr-small-y pr-clear-t mr-clear block notification"
      [text]="
        (formStatus === FormStatus.ERROR ? 'SERVICE_FORMS.serviceForms.error.title' : 'plus.profile.updateSuccess')
          | finLocalization
      "
      [isAlert]="formStatus === FormStatus.ERROR"
      [icon]="formStatus === FormStatus.ERROR ? SvgLibraryIcon.INFO : SvgLibraryIcon.CHECKMARK"
    >
    </fcom-info-box>
  </div>
  <!-- While sending -->
  <div *ngIf="formStatus === FormStatus.LOADING" class="relative loader">
    <fcom-loader [loaderType]="LoaderTheme.INLINE"></fcom-loader>
  </div>
  <form [formGroup]="reactiveForm" (ngSubmit)="onSubmit()">
    <ng-container *ngIf="formStatus !== FormStatus.LOADING">
      <span class="block mr-medium-b font-body-1">{{ 'plus.profile.chooseMarketingMessages' | finLocalization }}</span>
      <div class="flex flex--row flex--wrap form-subgroup">
        <fin-form-input-select
          [parentForm]="reactiveForm"
          [controlName]="'preferredLanguage'"
          [options]="languages$ | async"
          [translationPrefix]="'SERVICE_FORMS.serviceForms.fields.preferredLanguage'"
        ></fin-form-input-select>
      </div>
      <div class="flex flex--row flex--wrap form-subgroup">
        <fieldset class="border-none ps-clear">
          <legend class="font-body-1 nordic-blue-900-text flex--basis-full">
            {{ consentGroupIntroductionText$ | async | finStripHtml }}
          </legend>

          <div *ngFor="let consent of consents$ | async" class="ps-small-t">
            <fcom-checkbox
              [cid]="consent.consentTextId"
              [formControlName]="consent.consentTextId"
              [formGroup]="reactiveForm"
            >
              <span class="font-body-1 light-type">{{ consent.text | finStripHtml }}</span>
            </fcom-checkbox>
          </div>
        </fieldset>
      </div>
      <div class="flex flex--row flex--wrap mr-medium-t">
        {{ consentGroupProfileAfterwordText$ | async | finStripHtml }}
        <cms-unstyled-fragment
          *ngIf="'fragments.privacyPolicyLink.url' | finLocalization"
          class="privacy-policy block font-body-1 mr-large-t"
          [contentUrl$]="'fragments.privacyPolicyLink.url' | finLocalization | finToObservable"
        >
        </cms-unstyled-fragment>
      </div>
    </ng-container>
    <div class="ps-small-t align-right mr-xxsmall-r" *ngIf="'SERVICE_FORMS.serviceForms.save' | finHasLocalizationKey">
      <fcom-button
        [type]="ButtonType.SUBMIT"
        [disabled]="!reactiveForm.valid || formStatus === FormStatus.LOADING"
        [text]="'SERVICE_FORMS.serviceForms.save' | finLocalization"
      ></fcom-button>
    </div>
  </form>
</div>

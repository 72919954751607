import {
  FinnairItineraryItemFlight,
  FinnairLocation,
  ItineraryItemFlight,
  ItineraryItemLayover,
} from '@fcom/dapi/api/models';
import { isPresent } from '@fcom/core/utils';
import { Location } from '@fcom/core-api';

import { isFlight } from './common-booking.utils';

/**
 * List of short haul country codes
 * Taken from ancillaries microservice
 */
const shortHaulCountries: string[] = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SK',
  'IL',
  'HR',
  'TR',
  'SI',
  'MT',
  'AE',
  'EE',
  'RU',
  'IS',
  'EG',
];

export const isShortHaulCountry = (countryCode: string): boolean =>
  shortHaulCountries.some((country) => country === countryCode);

export const isShortHaulFlight = (
  departureLocation: FinnairLocation | Location,
  arrivalLocation: FinnairLocation | Location
): boolean => {
  if (!isPresent(departureLocation) || !isPresent(arrivalLocation)) {
    return true;
  }
  return isShortHaulCountry(departureLocation.countryCode) && isShortHaulCountry(arrivalLocation.countryCode);
};

export const isLongHaulLegAndAy = (
  itineraryArray: (ItineraryItemFlight | ItineraryItemLayover)[],
  location: {
    [key: string]: FinnairLocation;
  }
): boolean => {
  return itineraryArray
    .filter(isFlight)
    .filter((itinerary: FinnairItineraryItemFlight) => itinerary.operatingAirline.code === 'AY')
    .some((itinerary: FinnairItineraryItemFlight) => {
      const departureLocation = itinerary.departure.locationCode;
      const destinationLocation = itinerary.arrival.locationCode;

      const departureCountry = location[departureLocation].countryCode;
      const destinationCountry = location[destinationLocation].countryCode;

      return !isShortHaulFlight(
        { countryCode: departureCountry } as FinnairLocation,
        { countryCode: destinationCountry } as FinnairLocation
      );
    });
};

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { PageMetaService } from '@fcom/common';
import { TripType } from '@fcom/core/constants';
import { CmsTaxonomy, CmsTaxonomyGeneral } from '@fcom/core-api';
import { GlobalBookingTravelClass, LocationRouteCffService } from '@fcom/core';
import { finShare } from '@fcom/rx';

import { BlockOffer, BlockOfferData } from '../../interfaces';
import { getHeaderLevel } from '../../services/cms-utils/cms-utils';

@Component({
  selector: 'fin-offer-grid',
  styleUrls: ['./offer-grid.component.scss'],
  templateUrl: './offer-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferGridComponent implements OnInit {
  @Output()
  destinationClicked: EventEmitter<[BlockOffer, number]> = new EventEmitter();
  @Output()
  scrolledPastDestination: EventEmitter<[BlockOffer, number]> = new EventEmitter();

  @Output()
  flightHotelClicked: EventEmitter<{ amLink: string; destination: string }> = new EventEmitter();

  @Input()
  offers$: Observable<BlockOfferData[]>;
  @Input()
  tripType: TripType;
  @Input()
  title: string;
  @Input()
  cmsTags: CmsTaxonomy[];

  public readonly TAXONOMY: typeof CmsTaxonomyGeneral = CmsTaxonomyGeneral;

  selectedOriginLocationName$: Observable<string>;
  headerLevel: number | undefined;

  constructor(
    private languageService: LanguageService,
    private locationRouteCffService: LocationRouteCffService,
    private pageMetaService: PageMetaService
  ) {}

  ngOnInit(): void {
    this.headerLevel = getHeaderLevel(this.cmsTags);

    /**
     * Fetch translated location name for selected origin
     */
    const origin$ = this.pageMetaService.defaultOriginLocationCode$.pipe(
      switchMap((origin) => this.locationRouteCffService.bestGuessFor(origin, this.languageService.localeValue)),
      distinctUntilChanged(),
      finShare()
    );

    this.selectedOriginLocationName$ = combineLatest([this.pageMetaService.defaultOriginLocationCode$, origin$]).pipe(
      map(([originLocationCode, originLocation]) => originLocation.cityName ?? originLocationCode)
    );
  }

  destinationClick([destinationItem, _travelClass]: [BlockOffer, GlobalBookingTravelClass], index: number): void {
    this.destinationClicked.emit([destinationItem, index]);
  }
}

import { createReducer, on } from '@ngrx/store';
import { routerNavigatedAction } from '@ngrx/router-store';

import { GlobalActions } from '@fcom/core/actions';
import { updateStoreAction } from '@fcom/core/store/init-store.action';
import { DataUtils, setIn, unique, uniqueBy } from '@fcom/core/utils';
import { CustomServiceType, ServiceSelections, ServicesState, ServicesStatus, TravelerService } from '@fcom/dapi';
import { Category, FinnairPassengerServiceItem, FinnairServiceBoundItem, SubCategory } from '@fcom/dapi/api/models';
import { GlobalBookingActions } from '@fcom/common/store';
import { BookingActions, CartActions, OffersActions } from '@fcom/booking/store/actions';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { pathIsWithinHardcodedPath } from '@fcom/core/utils/app-url-utils';
import { RootPaths } from '@fcom/core/constants';
import { leaveError } from '@fcom/common/store/actions/error.actions';

import { OrderActions, ServicesActions } from '../actions';
import { CartOrOrder } from '../../interfaces';
import { isInMmb } from '../../utils/common-booking.utils';
import {
  asTravelerService,
  loadBoundSelection,
  updateSelectionPricesFromServiceCatalog,
} from '../utils/services.utils';
import { isBoundBasedCategory, isJourneyBasedCategory } from '../../modules/ancillaries/utils';
import { CommonBookingFeatureState } from '../store.interface';
import { COMMON_BOOKING_FEATURE_KEY } from '../constants';

const flightServices = (bounds: Array<FinnairServiceBoundItem>, flightId: string): FinnairPassengerServiceItem[] => {
  if (!bounds) {
    return [];
  }
  return [
    ...bounds.reduce(
      (allServices, b) => allServices.concat(...(b.segments.find((s) => s.id === flightId)?.passengers || [])),
      []
    ),
  ];
};

const getInitialValue = (state: ServicesState, selection: string) =>
  Object.keys(state[selection] || {}).length ? setIn(state, selection, {}) : state;

const getOnlyIncludedCheckedAndSpecialDiet = (cartOrOrderData: CartOrOrder | undefined, category: Category) => {
  const includedServices = cartOrOrderData?.services?.included?.find((s) => s.category === category)?.bounds ?? [];
  return includedServices.map((service) => {
    return {
      ...service,
      segments: service.segments.map((segment) => {
        return {
          ...segment,
          passengers: segment.passengers.map((passengerServiceItem) => {
            return {
              ...passengerServiceItem,
              services: passengerServiceItem.services.filter((s) =>
                [SubCategory.SPECIAL_DIET, SubCategory.CHECKED_BAGGAGE].includes(s.subCategory)
              ),
            };
          }),
        };
      }),
    };
  });
};

const loadServiceSelectionsForCategoryFromCartOrOrderData = (
  initialValue: ServicesState,
  cartOrOrderData: CartOrOrder | undefined,
  category: Category,
  multi = false
) => {
  const unpaidServices = cartOrOrderData?.services?.unpaid?.find((s) => s.category === category)?.bounds ?? [];
  // We support only special diet and checked bags as include services atm
  const includedServices = getOnlyIncludedCheckedAndSpecialDiet(cartOrOrderData, category);
  const unpaidAndIncludedServices = unpaidServices.concat(includedServices);
  return (
    unpaidAndIncludedServices.reduce((servicesState: ServicesState, currentBound) => {
      return currentBound.segments.reduce((boundServiceState: ServicesState, currentSegment) => {
        const servicesForFlight = flightServices(unpaidAndIncludedServices, currentSegment.id);
        const newServiceCatalogServices = servicesForFlight
          .filter((passengerServiceItem) => passengerServiceItem.services.length > 0)
          .reduce(
            (passengerServices, service) => {
              const sellableServices = service.services.filter(
                (s) => !s.includedInTierBenefit && !s.includedInTicketType
              );
              const id = category === Category.COVER ? CustomServiceType.GROUP : service.id;
              if (multi) {
                passengerServices[id] = sellableServices.map((serviceItem) => asTravelerService(serviceItem));
              } else if (isBoundBasedCategory(category)) {
                const boundBasedPassengerServices = loadBoundSelection({ ...service, services: sellableServices });
                passengerServices[id] = ((passengerServices[id] as TravelerService[]) || []).concat(
                  boundBasedPassengerServices.filter((passengerService) => passengerService.quantity > 0)
                );
              } else if (sellableServices[0]) {
                passengerServices[id] = asTravelerService(sellableServices[0]);
              }
              return passengerServices;
            },
            {} as { [key: string]: TravelerService | TravelerService[] }
          );

        const getFragmentId = () => {
          if (isBoundBasedCategory(category)) {
            return currentBound.id;
          }
          if (isJourneyBasedCategory(category)) {
            return CustomServiceType.JOURNEY;
          }
          return currentSegment.id;
        };
        if (!Object.keys(newServiceCatalogServices).length) {
          return boundServiceState;
        }

        return DataUtils.wrap(boundServiceState)
          .setIn(['selections', category as any, getFragmentId()], newServiceCatalogServices)
          .value();
      }, servicesState);
    }, initialValue) || initialValue
  );
};

export const initialState: ServicesState = Object.seal({
  status: ServicesStatus.INITIAL,
  campaignsShown: [],
});

const loadSelectionsFromCartOrOrder = (state: ServicesState, cartOrOrderData: CartOrOrder): ServicesState => {
  const supportedCategories = [
    Category.CABIN_BAGGAGE,
    Category.BAGGAGE,
    Category.MEAL,
    Category.WIFI,
    Category.LOUNGE,
    Category.COVER,
    Category.PET,
    Category.SPORT,
  ];

  // TODO: Current way of loading selections will in some cases result in an incorrect selection state with pax-ancillaries enabled.
  // Refactor to work with pax-ancillaries.
  return supportedCategories.reduce((currentState: ServicesState, category) => {
    const innerState = currentState || state;

    currentState = loadServiceSelectionsForCategoryFromCartOrOrderData(
      getInitialValue(innerState, `selections.${category}`),
      cartOrOrderData,
      category,
      category === Category.COVER
    );

    return currentState;
  }, null as ServicesState);
};

export const servicesReducer = createReducer(
  initialState,
  on(updateStoreAction, (state, { payload, features }) => {
    if (features.includes(COMMON_BOOKING_FEATURE_KEY)) {
      return loadSelectionsFromCartOrOrder(
        state,
        (payload as BookingAppState)?.cart?.cartData ??
          (payload as CommonBookingFeatureState)?.commonBooking?.order?.orderData
      );
    }
    return state;
  }),
  on(ServicesActions.startLoading, (state) => ({ ...state, status: ServicesStatus.PENDING })),
  on(ServicesActions.error, (state) => ({ ...state, status: ServicesStatus.LOAD_ERROR })),
  on(ServicesActions.resetCategory, (state, { category }) =>
    DataUtils.wrap(state).deleteIn(['selections', category]).value()
  ),
  on(ServicesActions.setSelectionsForFragment, (state, { category, fragmentId, selections }) =>
    DataUtils.wrap(state)
      .updateIn(['selections', category as keyof ServiceSelections, fragmentId], () => selections)
      .value()
  ),
  on(ServicesActions.setSelectionForFragmentForTraveler, (state, { category, fragmentId, travelerId, selection }) =>
    DataUtils.wrap(state)
      .updateIn(
        ['selections', category as keyof ServiceSelections, fragmentId, travelerId],
        (currentSelection: TravelerService | TravelerService[]): TravelerService | TravelerService[] => {
          if (Array.isArray(currentSelection)) {
            const selectionExists = currentSelection.find((ser) => ser.variant === selection.variant);
            return selectionExists
              ? currentSelection.map((s) => (s.variant === selection.variant ? selection : s))
              : [...currentSelection, selection];
          }

          return selection;
        }
      )
      .value()
  ),
  on(ServicesActions.setCoverForId, (state, { journeyId, travelerId, service }) =>
    DataUtils.wrap(state)
      .updateIn(['selections', Category.COVER, journeyId, travelerId], (covers) => {
        return [service]
          .concat(covers)
          .filter(Boolean)
          .filter(uniqueBy((cover) => cover.variant));
      })
      .value()
  ),
  // TODO: update seat selection similarly to other selections
  on(ServicesActions.setServiceCatalog, (state, { catalog }) => ({
    ...state,
    status: ServicesStatus.OK,
    services: catalog,
    campaignsShown: [],
    ...(state.selections && { selections: updateSelectionPricesFromServiceCatalog(state.selections, catalog) }),
  })),
  on(ServicesActions.setServiceAvailability, (state, { serviceAvailability }) => ({
    ...state,
    serviceAvailability,
  })),
  on(ServicesActions.setCampaignShown, (state, { id }) => ({
    ...state,
    campaignsShown: (Array.isArray(state.campaignsShown) ? state.campaignsShown : []).concat(id).filter(unique),
  })),
  on(ServicesActions.setUpsell, (state, upsell) => ({
    ...state,
    upsell,
  })),
  on(ServicesActions.clearUpsell, (state) => DataUtils.wrap(state).deleteIn(['upsell']).value()),
  on(routerNavigatedAction, (state, { payload }) => {
    if (
      pathIsWithinHardcodedPath(payload.routerState.url, RootPaths.BOOKING_ROOT) ||
      isInMmb(payload.routerState.url)
    ) {
      return state;
    }
    return DataUtils.wrap(state)
      .setIn('status', ServicesStatus.INITIAL)
      .deleteIn('services')
      .deleteIn('selections')
      .deleteIn('upsell')
      .value();
  }),
  on(CartActions.setCartData, (state, { cartData }) => {
    return loadSelectionsFromCartOrOrder({ ...state }, cartData);
  }),
  on(OrderActions.setOrderData, (state, { order }) => {
    return loadSelectionsFromCartOrOrder({ ...state }, order);
  }),
  on(
    ServicesActions.reset,
    // Data Clean-ups: BookingActions
    BookingActions.setTravelType, // @todo Do we need to fix this, as we moved setTravelType from the booking to the globalBooking store?
    BookingActions.selectTravelClass, // @todo Do we need to fix this, as we moved selectTravelClass from the booking to the globalBooking store?
    // Data Clean-ups: GlobalBookingActions
    GlobalBookingActions.setFlights,
    GlobalBookingActions.updateFlight,
    GlobalBookingActions.setPaxAmount,
    GlobalBookingActions.increasePaxAmountField,
    GlobalBookingActions.decreasePaxAmountField,
    // Data Clean-ups: When order created
    GlobalActions.confirmation,
    leaveError,
    // Data Clean-ups: OffersActions
    OffersActions.reset,
    OffersActions.setInboundId,
    OffersActions.setInboundFareFamily,
    OffersActions.setOutboundId,
    OffersActions.setOutboundFareFamily,
    () => ({ ...initialState })
  )
);

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DateFormat, LocalDate, Pattern } from '@fcom/core/utils';

import { Month, Day, SpacerType, UiDay } from '../../../utils/date.interface';
import { CalendarService } from '../services/calendar.service';

interface Week {
  days: (Day & UiDay)[];
}

@Component({
  selector: 'fcom-calendar-month',
  styleUrls: ['./month.component.scss'],
  templateUrl: './month.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthComponent implements OnChanges, OnInit, OnDestroy {
  private ngDestroyed$ = new Subject();

  @Input() dateLabels: any;
  @Input() uiLabels: any;
  @Input() value: Month;
  @Input() dayTemplate: TemplateRef<{ dayString: number; dayValue: Day }>;
  @Input() identifier: string;

  isDateRange = false;
  month: Month;
  monthLabel: string;
  weeks: Week[] = [];
  isVisiblyHidden = true;

  constructor(
    private calendarService: CalendarService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.calendarService.visibleMonthIndex$.pipe(takeUntil(this.ngDestroyed$)).subscribe((index) => {
      this.isVisiblyHidden = !(this.month.monthArrayIndex === index || this.month.monthArrayIndex === index + 1);
      this.cd.detectChanges();
    });

    this.calendarService.isDateRange$.pipe(takeUntil(this.ngDestroyed$)).subscribe((isRange) => {
      this.isDateRange = isRange[this.identifier];
    });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('value')) {
      this.setMonth(this.value);
    }
  }

  private setMonth(month: Month): void {
    this.month = month;
    this.monthLabel = new DateFormat(this.dateLabels).format(
      LocalDate.forDate(month.year, month.value, 1),
      DateFormat['LONG_MONTH_AND_YEAR']
    );

    // Construct week layout
    this.weeks = month.days.reduce((weeks, day, i, array) => {
      if (i === 0 || day.weekday === 0) {
        weeks.push({ days: [] });
      }
      const current = weeks[weeks.length - 1];
      // Add spacer to the start if needed
      if (i === 0 && day.weekday !== 0) {
        current.days.push({
          reference: day,
          spacer: day.weekday,
          spacerType: SpacerType.START,
        });
      }
      // Add actual day
      const label = new DateFormat(this.dateLabels).format(day.date, new Pattern('dateFormatFullDetailed', true));
      current.days.push({
        instance: day,
        first: i === 0,
        last: i === array.length - 1,
        label,
      });
      // Add spacer to the end if needed
      if (i === array.length - 1 && day.weekday !== 6) {
        current.days.push({
          reference: day,
          spacer: 6 - day.weekday,
          spacerType: SpacerType.END,
        });
      }
      return weeks;
    }, []);
  }
}

<div class="sidebar-overlay" *ngIf="showSideBar$ | async" @overlayInOut></div>
<div
  *ngIf="showSideBar$ | async"
  (fcomClickOutside)="onClose()"
  class="sidenav flex flex--column no-outline"
  role="dialog"
  [attr.aria-labelledby]="sidebarTitleId"
  [attr.aria-describedby]="sidebarDescriptionId"
  fcomTrapFocus
  @sliderInOut
>
  <header
    class="modal-header border-bottom flex--nogrow flex--basis-header flex flex--row flex--center fill relative white-bg"
  >
    <h2
      #sideBarHeading
      [id]="sidebarTitleId"
      data-testid="sidebar-title"
      class="font-heading-3 flex-1 truncate overflow-hidden nordic-blue-900-text pr-large-x pr-xsmall-y"
      tabindex="-1"
    >
      {{ title }}
    </h2>
    <button
      class="close-button flex--nogrow flex--self-stretch btn--no-outline focus-inset pr-large-x pr-xsmall-y"
      [title]="ariaClose"
      [attr.aria-label]="ariaClose"
      (click)="open = false"
    >
      <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
    </button>
  </header>
  <div class="flex flex-1 scroll" [attr.id]="sidebarDescriptionId" data-testid="sidebar-content">
    <ng-content></ng-content>
  </div>
</div>

<fcom-button
  *ngIf="showButton$ | async"
  [class]="'login-btn'"
  [finElemTracker]="(loginStatus$ | async) !== LoginStatus.LOGGED_IN ? 'open-login' : 'open-profile'"
  [finElemContext]="trackingContext"
  [finElemType]="ElementTypes.BUTTON"
  [aria]="{
    label:
      ((loginStatus$ | async) !== LoginStatus.LOGGED_IN ? 'login.openLogin' : 'login.displayProfile') | finLocalization,
    role: 'button',
  }"
  [icon]="{
    category: IconLibrary.SVG_LIBRARY,
    name: (loginStatus$ | async) === LoginStatus.LOGGED_IN ? SvgLibraryIcon.LOGGED_IN : SvgLibraryIcon.SINGLE_PAX,
    position: IconPosition.LEFT,
  }"
  [text]="
    (isMobile$ | async)
      ? ''
      : showName && (loginStatus$ | async) === LoginStatus.LOGGED_IN
        ? (profileName$ | async)
        : showName
          ? ('navigation.login' | finLocalization)
          : ''
  "
  [mode]="ButtonMode.INLINE"
  [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
  data-hj-suppress
></fcom-button>

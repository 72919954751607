import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';

import { IconsModule } from '../icons/icons.module';
import { AbstractNgModelComponent } from './abstracts/abstract-ng-model.component';
import { AbstractCheckboxComponent } from './abstracts/abstract-checkbox.component';
import { AbstractInputComponent } from './abstracts/abstract-input.component';
import { FloatingInputComponent } from './floating-input/floating-input.component';
import { CheckboxComponent, FcomCheckboxRequiredValidator } from './checkbox/checkbox.component';
import { BlockSelectionGroupComponent } from './block-selection-group/block-selection-group.component';
import { BlockSelectionComponent, BlockSelectionRequiredValidator } from './block-selection/block-selection.component';
import { PopoverModule } from '../popover/popover.module';
import { RadioItemComponent } from './radio-item/radio-item.component';
import { TextInputComponent } from './text-input/text-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { ButtonModule } from '../buttons';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { AttachmentListComponent } from './file-uploader/attachment-list.component';
import { NotificationsModule } from '../notifications';
import { LoaderModule } from '../loader';
import { RadioItemGroupComponent } from './radio-item-group/radio-item-group.component';
import { ComboBoxComponent } from './combo-box/combo-box.component';

@NgModule({
  declarations: [
    AbstractCheckboxComponent,
    AbstractInputComponent,
    AbstractNgModelComponent,
    AttachmentListComponent,
    FileUploaderComponent,
    FloatingInputComponent,
    BlockSelectionGroupComponent,
    BlockSelectionComponent,
    BlockSelectionRequiredValidator,
    CheckboxComponent,
    FcomCheckboxRequiredValidator,
    RadioItemComponent,
    RadioItemGroupComponent,
    TextInputComponent,
    SelectInputComponent,
    ComboBoxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    UiTranslateModule,
    IconsModule,
    ButtonModule,
    PopoverModule,
    NotificationsModule,
    LoaderModule,
  ],
  exports: [
    AbstractCheckboxComponent,
    AbstractInputComponent,
    AbstractNgModelComponent,
    AttachmentListComponent,
    FileUploaderComponent,
    FloatingInputComponent,
    BlockSelectionGroupComponent,
    BlockSelectionComponent,
    BlockSelectionRequiredValidator,
    CheckboxComponent,
    FcomCheckboxRequiredValidator,
    RadioItemComponent,
    RadioItemGroupComponent,
    TextInputComponent,
    SelectInputComponent,
    ComboBoxComponent,
  ],
})
export class FcomFormsModule {}

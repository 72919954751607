import { GlobalBookingTravelClass } from '@fcom/core';
import { TripType } from '@fcom/core/constants';

import { DestinationSearchItem, DestinationSearchTripDates } from './destination-search.interface';

/**
 * Object with ID to support having different states for different instances
 */
export interface DestinationSearchFilterStateMeta {
  id: string;
}

export interface DestinationSearchState {
  [destinationSearchStateId: string]: DestinationSearchFilters;
}

/**
 * The filter combination to limit results that match.
 */
export interface DestinationSearchFilters {
  selectedDestinations: DestinationSearchItem[];
  fromLocationCode?: string;
  tripDates?: DestinationSearchTripDates;
  tripType?: TripType;
  travelClass?: GlobalBookingTravelClass;
  skipRouteUpdate?: boolean;
  restrictToDestinations?: string[];
}

/**
 * Filter: From Location
 */
export interface DestinationSearchFilterFromLocation {
  title: string;
  locationCode: string;
}

/**
 * Sorting
 */
export enum SortBehavior {
  ALPHABETICAL = 'sortByName',
  PRICE = 'sortByPrice',
  CUSTOM = 'sortByCustom',
}

import { FormControl, FormGroup } from '@angular/forms';

import { CorporateUserRole } from '@fcom/core-api/login';
import { LocalDate } from '@fcom/core/utils';
import { Attachment } from '@fcom/ui-components/components/interfaces';

export enum CorporateContactRole {
  PRIMARY = 'Online Primary Contact',
  SECONDARY = 'Online Secondary Contact',
  NONE = 'No role',
}

interface CorporateContactPerson {
  id: string;
  name: string;
  email: string;
}

export interface CorporateUser {
  clientId: string;
  contactRole: CorporateContactRole;
  email: string;
  firstname: string;
  lastname: string;
  role: CorporateUserRole;
  title?: string;
  mobile?: string;
  workPhone?: string;
  jobTitle?: string;
  finnairPlus?: string;
  passportNumber?: string;
  issued?: string;
  expires?: string;
  birthdate?: string;
  nationality?: string;
  userId: string;
  siebelId?: string;
}

export interface CompanyAgreement {
  agreementType: string;
  agreementStart: string;
  agreementEnd: string;
}

export interface Company {
  agreement1: CompanyAgreement;
  agreement2: CompanyAgreement;
  businessId: string;
  city: string;
  clientId: string;
  corporateCode: string;
  country: string;
  language: string;
  name: string;
  prefecture: string;
  primaryAdmin: CorporateContactPerson;
  province: string;
  secondaryAdmin: CorporateContactPerson;
  state: string;
  street: string;
  territory: string;
  zipcode: string;
}

export interface CompanyUpdate {
  street: string;
  zipcode: string;
  city: string;
  primaryAdmin?: string;
  secondaryAdmin?: string;
}

export interface CorporateContactForm {
  topic: FormControl<string>;
  message: FormControl<string>;
  attachments: FormControl<Attachment[] | undefined>;
  companyName: FormControl<string>;
  country: FormControl<string>;
  salutation: FormControl<string | undefined>;
  firstName: FormControl<string>;
  familyName: FormControl<string>;
  email: FormControl<string>;
  phonePrefix: FormControl<string>;
  phoneNumber: FormControl<string>;
  customerNumber: FormControl<string | undefined>;
}

export interface CorporateUserForm {
  personalDetails: FormGroup<PersonalDetailsForm>;
  passportDetails: FormGroup<PassportDetailsForm>;
  userDetails: FormGroup<UserDetailsForm>;
}

export interface PersonalDetailsForm {
  salutation: FormControl<string | undefined>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  phonePrefix: FormControl<string>;
  phoneNumber: FormControl<string>;
  dateOfBirth: FormControl<LocalDate | undefined>;
  workTitle: FormControl<string | undefined>;
  fPlusNumber: FormControl<string | undefined>;
}

export interface PassportDetailsForm {
  passportNumber: FormControl<string | undefined>;
  issueDate: FormControl<LocalDate | undefined>;
  expirationDate: FormControl<LocalDate | undefined>;
  nationality: FormControl<string | undefined>;
}

export interface UserDetailsForm {
  role: FormControl<CorporateUserRole | undefined>;
}

export interface CompanyDetailsForm {
  address: FormControl<string>;
  postalCode: FormControl<string>;
  city: FormControl<string>;
}

export interface ContactPersonForm {
  primaryContact: FormControl<string>;
  secondaryContact: FormControl<string>;
}

export interface CorporateSalesForceUser {
  allowEmails?: boolean;
  birthdate?: string;
  expires?: string;
  firstname: string;
  fplusNumber?: string;
  issued?: string;
  jobTitle?: string;
  lastname: string;
  mobileNumber: string;
  nationality?: string;
  passportNumber?: string;
  role: CorporateUserRole;
  title: string;
  travelingClass?: string;
  upClientId?: string;
  upEmail: string;
}

export enum TripType {
  ONEWAY = 'ONEWAY',
  RETURN = 'RETURN',
  MULTICITY = 'MULTICITY',
}

export interface TripPassenger {
  firstName: string;
  lastName: string;
}

export interface TripFlight {
  flightNumber: string;
  id: string;
  arrival: TripEndPoint;
  departure: TripEndPoint;
}

export interface TripBound {
  arrival: TripEndPoint;
  departure: TripEndPoint;
  flights: TripFlight[];
}

export interface TripEndPoint {
  dateTime: string;
  locationCode: string;
  locationTitle?: string;
}

export interface Trip {
  arrival: TripEndPoint;
  bookingReference: string;
  bounds: TripBound[];
  departure: TripEndPoint;
  flights: TripFlight[];
  passengers: TripPassenger[];
  tripType: TripType;
}

export interface Pagination {
  from: number;
  total: number;
  size: number;
}

export interface TripFilterDateRange {
  fromDate: string;
  toDate: string;
}

export interface TripsRequest {
  from?: number;
  size?: number;
  fromSaved?: boolean;
  filter?: TripsFilter;
}

export interface TripsFilter {
  partialName?: string;
  dateRange?: TripFilterDateRange;
  myTrips?: boolean;
}

export interface TripsResponse {
  pagination: Pagination;
  trips: Trip[];
}

<header *ngIf="isVisible$ | async" class="medium-type fill" data-elastic-exclude>
  <!-- Top nav desktop start here -->
  <div
    class="navigation-heading-top center grid-max-width padding-left-gutter padding-right-gutter flex dn-laptop-down flex--space-between flex--middle ps-xsmall-y font-body-1"
    data-testid="header-heading"
  >
    <div class="flex flex--middle">
      <a [finLink]="homeLink$ | async">
        <span class="is-vishidden">{{ 'actions.home' | finLocalization }}</span>
        <fcom-icon
          class="finnair-blue nordic-blue-900-text"
          [category]="IconLibrary.CUSTOM"
          [name]="CustomIcon.FINNAIR_LOGO"
        ></fcom-icon>
      </a>

      <a [finLink]="oneworldLink$ | finMap: 'url' | async" data-testid="oneworld-link" class="ms-large-l font-body-1">
        <span class="is-vishidden">{{ oneworldLink$ | finMap: 'title' | async }}</span>
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ONEWORLD_LOGO"></fcom-icon>
      </a>
    </div>

    <div class="flex flex--middle">
      <!-- Language selector -->
      <div class="align-right">
        <a
          *ngIf="!enableNewLanguageSelector"
          class="no-underline nordic-blue-900-text misc-country link-transparent ps-medium-x ps-xsmall-y"
          [finLink]="'/'"
          [attr.aria-label]="
            ('menu.language' | finLocalization) +
            ' ' +
            (countries$ | finMap: 'name' | async) +
            ' - ' +
            (currentLanguage$ | async)
          "
          [finElemTracker]="'top-header: language'"
          [finElemContext]="GaContext.TOP_NAVIGATION"
          [finElemType]="ElementTypes.LINK"
        >
          <span class="flag-{{ countries$ | finMap: 'code' | async | lowercase }} valign-top ms-xsmall-r"></span>
          <span>{{ countries$ | finMap: 'name' | async }} - {{ currentLanguage$ | async | uppercase }}</span>
        </a>
        <fin-language-selector
          [context]="LANGUAGE_SELECTOR_CONTEXT"
          *ngIf="enableNewLanguageSelector"
        ></fin-language-selector>
      </div>

      <!-- Login -->
      <div class="login-item ms-large-l align-right font-body-1 mr-xsmall-l">
        <ng-container *ngIf="loginStatus$ | async as loginStatus">
          <fin-login-button
            *ngIf="loginStatus === LoginStatus.NOT_LOGGED_IN || loginStatus === LoginStatus.LOGGED_IN"
            [showName]="true"
            [trackingContext]="GaContext.TOP_NAVIGATION"
            class="font-body-1 grow flex nordic-blue-900-text"
            (click)="handleLoginButtonClick($event, loginStatus)"
          ></fin-login-button>
          <fin-login-form
            *ngIf="(loginDialogOpen$ | async) && (mobileOrTablet$ | async) === false"
            class="absolute ms-xxsmall-t dn-laptop-down"
          ></fin-login-form>
        </ng-container>
      </div>

      <fcom-button
        *ngIf="enableLoginDialog && (loginStatus$ | async) === LoginStatus.NOT_LOGGED_IN"
        data-testid="join-finnair-plus-button"
        (clickBtn)="joinFinnairPlus()"
        [class]="'mr-xsmall-l'"
        [text]="'login.joinFinnairPlus' | finLocalization"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.FINNAIR_PLUS, position: IconPosition.LEFT }"
        [mode]="ButtonMode.INLINE"
        [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
      >
      </fcom-button>
      <div
        *ngIf="(joinService.joinDialogOpen$ | async) && (mobileOrTablet$ | async) === false"
        class="relative dn-laptop-down"
      >
        <fin-join-form class="absolute ms-large-t"></fin-join-form>
      </div>
      <fin-welcome-back-message *ngIf="welcomeBackMessageOpen$ | async"></fin-welcome-back-message>
    </div>
  </div>
  <fin-login-form
    *ngIf="(loginDialogOpen$ | async) && (mobileOrTablet$ | async)"
    class="dn-laptop-up"
    [fullScreen]="true"
  >
  </fin-login-form>
  <fin-join-form
    *ngIf="(joinService.joinDialogOpen$ | async) && (mobileOrTablet$ | async)"
    class="dn-laptop-up"
    [fullScreen]="true"
  >
  </fin-join-form>
  <fin-login-widget></fin-login-widget>

  <!--Top nav mobile start here  -->
  <div
    class="grid-col-12 no-row-gap grid-max-width border-top-dark-ice dn-laptop-up mobile-header-controls border-bottom"
  >
    <fin-header-boxes class="col-f" [homeLink$]="homeLink$">
      <div left class="flex flex--center">
        <fin-navigation-trigger class="flex--nogrow inline-block relative"></fin-navigation-trigger>
      </div>
      <div right>
        <ng-container *ngIf="(isMenuOpen$ | async) !== true && loginStatus$ | async as loginStatus">
          <fin-login-button
            class="grow flex nordic-blue-900-text"
            (click)="openLoginWidget($event)"
            [showName]="false"
            [trackingContext]="GaContext.TOP_NAVIGATION"
          ></fin-login-button>
        </ng-container>
      </div>
    </fin-header-boxes>
    <div class="col-f"></div>
  </div>

  <!-- Lower nav start here -->
  <div class="relative z2" (finClickOutside)="clickOutside()" data-testid="navigation-header">
    <div class="nordic-blue-900-bg" data-testid="navigation-heading">
      <div
        class="center grid-max-width padding-left-gutter padding-right-gutter flex flex--space-between flex--middle navigation-heading-bottom"
        data-testid="navigation-heading-container"
      >
        <!-- Menu button set start here -->
        <div class="flex dn-laptop-down misc-menu padding-right-gutter">
          <fcom-button
            data-testid="navigation-toggle"
            [aria]="{
              controls: 'main-navigation',
              expanded: isOpen,
            }"
            [icon]="{
              name: isOpen ? SvgLibraryIcon.CLOSE_DELETE : SvgLibraryIcon.MENU_LIST,
              category: IconLibrary.SVG_LIBRARY,
              position: IconPosition.LEFT,
            }"
            [text]="'navigation.menu' | finLocalization"
            [size]="ButtonSize.SMALL"
            [theme]="ButtonTheme.DARK_TERTIARY_GHOST"
            (clickBtn)="toggleNavigation($event)"
          ></fcom-button>
        </div>

        <!-- Menu category options start here -->
        <div class="desktop-header-wrapper nordic-blue-900-bg fill">
          <div class="flex flex--center center align-center grid-max-height white-text">
            <div
              *ngFor="let item of (menuItems$ | async)?.top"
              class="menu-item ps-xsmall-y pr-xsmall-x text-hover"
              [class.active]="item.active"
            >
              <a
                class="no-underline white-text inline-block fill font-body-1"
                [attr.aria-current]="item.active ? 'page' : null"
                [finLink]="item.link"
                [external]="item.external"
                [attr.target]="item.openInNewTab ? '_blank' : ''"
                [finElemTracker]="'top-header: ' + item.teaserTitle"
                [finElemContext]="GaContext.TOP_NAVIGATION"
                [finElemType]="ElementTypes.LINK"
              >
                {{ item.teaserTitle }}
                <span *ngIf="item.active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span>
              </a>
            </div>
          </div>
        </div>

        <!-- Search set start here -->
        <div class="padding-left-gutter misc-search dn-laptop-down">
          <div class="relative grow">
            <fin-site-search-header-widget-starter
              class="search-component block icon-button white-text grow"
              [expandable]="true"
              [enableNewLanguageSelector]="enableNewLanguageSelector"
            >
            </fin-site-search-header-widget-starter>
          </div>
        </div>
      </div>
    </div>

    <!-- Main menu start here -->
    <nav
      id="main-navigation"
      class="border-bottom ps-small-t absolute z1 white-bg fill"
      [attr.aria-label]="'navigation.mainNavigation' | finLocalization"
      [class.is-vishidden]="!isOpen"
    >
      <div class="grid-max-width padding-left-gutter padding-right-gutter relative desktop-navigation-wrapper center">
        <div class="desktop-navigation-head flex flex--space-between">
          <ng-container *ngFor="let item of (menuItems$ | async)?.main; trackBy: trackItems; index as catIdx">
            <button
              class="navigation-category nordic-blue-900-text font-body-2 ps-xxsmall no-decoration"
              [attr.aria-controls]="'category-' + catIdx"
              [attr.aria-expanded]="(expandedCategory$ | async) === item ? true : false"
              *ngIf="item.teaserTitle && item.items && item.items.length > 0 && !item.hideInMenu"
              [class.active]="(expandedOrActiveNaviCategory$ | async) === item"
              [finElemTracker]="'level-1: ' + item.teaserTitle"
              [finElemContext]="GaContext.TOP_NAVIGATION"
              [finElemType]="ElementTypes.LINK"
              (click)="setCategoryAsExpanded($event, item)"
            >
              <span *ngIf="(activeNaviCategory$ | async) === item" class="is-vishidden">{{
                'navigation.currentPageUnderTopic' | finLocalization
              }}</span>
              {{ item.teaserTitle }}
            </button>
            <div
              class="white-bg desktop-navigation-body absolute z2"
              [attr.aria-labelledby]="'category-' + catIdx"
              [attr.aria-hidden]="(expandedCategory$ | async) === item ? false : true"
              [class.navi-expanded]="(expandedCategory$ | async) === item"
              (click)="$event.stopPropagation()"
            >
              <div
                class="desktop-navigation-body-content center grid-max-width padding-left-gutter padding-right-gutter ps-xxlarge-t ps-xsmall-b font-body-2 regular-type"
                [class.nav-active-category]="(expandedCategory$ | async) === item"
              >
                <ng-container *ngFor="let section of item.items; trackBy: trackItems">
                  <dl *ngIf="section.link && !section.hideInMenu" class="ms-large-b pr-medium-r">
                    <dt class="ms-xsmall-b">
                      <a
                        [finLink]="section.link"
                        class="nordic-blue-900-text bold-type no-decoration flex flex--middle"
                        (click)="closeNaviMenu()"
                        [class.current-item]="section.active"
                        [attr.aria-current]="section.active ? 'page' : null"
                        [external]="section.external"
                        [finElemTracker]="'level-2: ' + section.link"
                        [finElemContext]="GaContext.TOP_NAVIGATION"
                        [finElemType]="ElementTypes.LINK"
                        [attr.target]="section.openInNewTab ? '_blank' : ''"
                      >
                        {{ section.teaserTitle }}
                        <span *ngIf="section.active" class="is-vishidden">{{
                          'navigation.currentPage' | finLocalization
                        }}</span>
                        <fcom-icon
                          *ngIf="section.external"
                          [name]="SvgLibraryIcon.EXTERNAL_TAB"
                          class="icon-small ms-xxsmall-l"
                        ></fcom-icon>
                        <fcom-icon
                          *ngIf="section.loginRequired"
                          [name]="SvgLibraryIcon.LOCK_OPEN"
                          class="icon-small ms-xxsmall-l"
                        ></fcom-icon>
                      </a>
                    </dt>
                    <ng-container *ngFor="let child of section.items; trackBy: trackItems">
                      <dd *ngIf="child.link && child.teaserTitle && !child.hideInMenu">
                        <a
                          [finLink]="child.link"
                          class="nordic-blue-900-text no-decoration ps-xxsmall-t block flex flex--middle"
                          (click)="closeNaviMenu()"
                          [class.current-item]="child.active"
                          [attr.aria-current]="child.active ? 'page' : null"
                          [external]="child.external"
                          [finElemTracker]="'level-3: ' + child.link"
                          [finElemContext]="GaContext.TOP_NAVIGATION"
                          [finElemType]="ElementTypes.LINK"
                          [attr.target]="child.openInNewTab ? '_blank' : ''"
                        >
                          {{ child.teaserTitle }}
                          <span *ngIf="child.active" class="is-vishidden">{{
                            'navigation.currentPage' | finLocalization
                          }}</span>
                          <fcom-icon
                            *ngIf="child.external"
                            [name]="SvgLibraryIcon.EXTERNAL_TAB"
                            class="icon-small ms-xxsmall-l"
                          ></fcom-icon>
                          <fcom-icon
                            *ngIf="child.loginRequired"
                            [name]="SvgLibraryIcon.LOCK_OPEN"
                            class="icon-small ms-xxsmall-l"
                          ></fcom-icon>
                        </a>
                      </dd>
                    </ng-container>
                  </dl>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </nav>
  </div>

  <fin-breadcrumb *ngIf="showBreadcrumbs" class="z1 fill"></fin-breadcrumb>
</header>

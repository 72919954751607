import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Input,
  Optional,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject, fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * This directive fires an event when click outside of the element where it is attached to is detected.
 * Used for example to close modals when user clicks outside of the modal content.
 *
 * @example
 * <code>
 *   <pre>
 *    <div (fcomClickOutside)="doSomething()">
 *      Some content
 *     </div>
 *   </pre>
 * </code>
 */
@Directive({
  selector: '[fcomClickOutside]',
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  /**
   * Tag name that you want to exclude from firing the output event
   */
  @Input() clickOutsideException = '';

  @Output()
  fcomClickOutside: EventEmitter<Event> = new EventEmitter();

  private ngDestroyed$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    @Optional() @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit(): void {
    if (!this.document) {
      return;
    }
    // setTimeout needed for the event not to occur when click inserts this directive in DOM
    setTimeout(() => {
      const source$ = fromEvent(this.document, 'click');

      source$
        .pipe(
          filter((event: MouseEvent) => {
            if (!(event.target instanceof Element)) {
              return false;
            }
            return (
              !(this.elementRef.nativeElement as HTMLElement).contains(event.target) && !this.exceptionInPath(event)
            );
          }),
          takeUntil(this.ngDestroyed$)
        )
        .subscribe((event) => this.fcomClickOutside.emit(event));
    });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(true);
  }

  private exceptionInPath(event: Event): boolean {
    if (this.clickOutsideException === '') {
      return false; // dont even check if input is not given
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (!event.composedPath) {
      return true; // native clicks have this but test suite clicks dont
    }
    return !!event.composedPath().find((pathElem: Element) => pathElem.localName === this.clickOutsideException);
  }
}

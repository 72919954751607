import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of, switchMap, take } from 'rxjs';

import { TripType } from '@fcom/core/constants';
import { finShare } from '@fcom/rx';
import { NotificationTheme } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { isNotBlank, isPresent } from '@fcom/core/utils';
import { Location } from '@fcom/core-api';
import { GlobalBookingFlight } from '@fcom/common/store';

import { BookingWidgetAppState, notificationWarnings } from '../../store';
import {
  NotificationData,
  NotificationSpecific,
  NotificationWarning,
  SeasonalNotificationData,
  WarningData,
} from '../../interfaces';

@Component({
  selector: 'fin-booking-widget-notifications',
  templateUrl: './booking-widget-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingWidgetNotificationComponent implements OnInit {
  @Input()
  locations$: Observable<GlobalBookingFlight[]> = of([]);

  @Input()
  tripType$: Observable<TripType> = of(undefined);

  @Input()
  isAm = false;

  notifications$: Observable<NotificationData[]>;

  private readonly busConnectionLocationCodes = ['TKU', 'TMP', 'XTP', 'XTZ'];

  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(
    private store$: Store<BookingWidgetAppState>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.notifications$ = combineLatest([
      this.locations$,
      this.tripType$,
      this.store$.pipe(notificationWarnings(), finShare()),
    ]).pipe(
      switchMap(([locations, tripType, warnings]) => {
        const destinationNotification$ = this.mapDestinationNotification(locations[0]?.destination);
        const storeNotifications = Object.entries(warnings).map((entry) =>
          this.mapWarningToNotification(entry, tripType, locations)
        );
        const busConnectionNotification = this.mapBusConnectionNotification(locations);
        const amEarnPointsNotification = this.isAm ? this.mapAmEarnPointsNotification() : undefined;
        const showMaxNumberOfFlightsNotification =
          tripType === TripType.MULTICITY ? this.showMaxNumberOfFlightsNotification(locations) : undefined;
        const showNotConsecutiveNotification =
          tripType === TripType.MULTICITY ? this.showNotConsecutiveNotification(locations) : undefined;

        return destinationNotification$.pipe(
          map((destinationNotification) =>
            [
              busConnectionNotification,
              destinationNotification,
              amEarnPointsNotification,
              showMaxNumberOfFlightsNotification,
              showNotConsecutiveNotification,
              ...storeNotifications,
            ].filter(Boolean)
          )
        );
      })
    );
  }

  private mapWarningToNotification(
    [type, warningData]: [string, WarningData],
    tripType: TripType,
    flights: GlobalBookingFlight[]
  ): NotificationData {
    if (!warningData.isActive) {
      return undefined;
    }

    switch (type) {
      case NotificationWarning.SEASONAL_ROUTE:
        return isPresent(warningData?.data) && flights[0]?.origin && flights[0]?.destination
          ? this.mapSeasonalNotification(warningData.data, flights)
          : null;
      case NotificationWarning.NO_FLIGHTS:
        return this.mapNoFlightNotification(tripType, flights, false);
      case NotificationWarning.NO_AWARD_FLIGHTS:
        return this.mapNoFlightNotification(tripType, flights, true);
      case NotificationWarning.GENERAL:
        return this.mapFlightGeneralNotification();
      default:
        return undefined;
    }
  }

  private mapDestinationNotification(destination: Location): Observable<NotificationData> {
    if (!isPresent(destination?.countryCode)) {
      return of(undefined);
    }

    return this.languageService.translate(`dynamicNotifications.destination.${destination.countryCode}`).pipe(
      take(1),
      map((notificationText: string) =>
        isNotBlank(notificationText)
          ? {
              type: NotificationSpecific.DESTINATION_SPECIFIC,
              theme: NotificationTheme.INFO,
              text: { label: `dynamicNotifications.destination.${destination.countryCode}` },
            }
          : undefined
      ),
      finShare()
    );
  }

  private mapSeasonalNotification(
    seasonalDataNotification: SeasonalNotificationData,
    flights: GlobalBookingFlight[]
  ): NotificationData {
    return {
      type: NotificationWarning.SEASONAL_ROUTE,
      theme: NotificationTheme.INFO,
      text: {
        label: `timetable.seasonalNotification.${
          Object.keys(seasonalDataNotification).length <= 2 ? 'singular' : 'plural'
        }`,
        params: {
          ...seasonalDataNotification,
          origin: flights[0].origin.cityName,
          destination: flights[0].destination.cityName,
        },
      },
      link: {
        url: `/${this.languageService.langValue}/timetables?dest=${flights[0].destination.locationCode}&origin=${flights[0].origin.locationCode}`,
        text: 'routeNotification.timetable.forRouteLinkText',
      },
    };
  }

  private mapFlightGeneralNotification(): NotificationData {
    return {
      type: NotificationWarning.GENERAL,
      theme: NotificationTheme.WARNING,
      text: {
        label: 'errors.booking.title',
      },
      text1: {
        label: 'errors.booking.description',
      },
    };
  }

  private mapNoFlightNotification(
    tripType: TripType,
    flights: GlobalBookingFlight[],
    isAward: boolean
  ): NotificationData {
    return {
      type: isAward ? NotificationWarning.NO_AWARD_FLIGHTS : NotificationWarning.NO_FLIGHTS,
      theme: NotificationTheme.WARNING,
      text: {
        label: isAward ? 'bookingSearch.errors.noAwardFlightsFound' : 'bookingSearch.errors.noFlightsFound',
      },
      ...(tripType !== TripType.MULTICITY &&
        !isAward && {
          text1: { label: 'routeNotification.timetable.checkTimeTableText' },
        }),
      link: {
        openInNewTab: true,
        ...(tripType === TripType.MULTICITY && { external: true }),
        url:
          tripType !== TripType.MULTICITY
            ? `/${this.languageService.langValue}/timetables?dest=${flights[0].destination.locationCode}&origin=${flights[0].origin.locationCode}`
            : 'bookingSearch.errors.multiCity.seeMoreLink.url',
        text:
          tripType !== TripType.MULTICITY
            ? 'routeNotification.timetable.forRouteLinkText'
            : 'bookingSearch.errors.multiCity.seeMoreLinkText',
      },
    };
  }

  private mapBusConnectionNotification(flights: GlobalBookingFlight[]): NotificationData {
    const isBusConnectionNeeded = flights.some((flight) => {
      return ['origin', 'destination'].some((key) =>
        this.busConnectionLocationCodes.includes(flight[key]?.locationCode)
      );
    });

    return isBusConnectionNeeded
      ? {
          type: NotificationSpecific.BUS_CONNECTION,
          theme: NotificationTheme.INFO,
          text: { label: 'routeNotification.busConnections.notificationText' },
          link: {
            openInNewTab: true,
            url: 'routeNotification.busConnections.link.url',
            text: 'routeNotification.busConnections.linkText',
          },
        }
      : undefined;
  }

  private mapAmEarnPointsNotification(): NotificationData {
    return {
      type: NotificationSpecific.AM_EARN_POINTS,
      theme: NotificationTheme.INFO,
      text: { label: 'aurinkomatkat.notification.teaser' },
      text1: { label: 'aurinkomatkat.notification.content' },
    };
  }

  private showMaxNumberOfFlightsNotification(flights: GlobalBookingFlight[]): NotificationData {
    const hasReachedMaxNumberOfFlights = flights.length >= 6;

    return hasReachedMaxNumberOfFlights
      ? {
          type: NotificationSpecific.MULTICITY_MAX_NUMBER_OF_FLIGHTS,
          theme: NotificationTheme.INFO,
          text: { label: 'multiCityNotification.maxNumberOfFlights.notificationText' },
          link: {
            openInNewTab: true,
            url: 'multiCityNotification.maxNumberOfFlights.link.url',
            text: 'multiCityNotification.maxNumberOfFlights.linkText',
          },
        }
      : undefined;
  }

  private showNotConsecutiveNotification(flights: GlobalBookingFlight[]): NotificationData {
    const isAfterNextDepartureDate: boolean = flights.some((flight, i) => {
      if (!flight.departureDate) {
        return false;
      }

      const nextFlight = flights[i + 1];

      if (flight.departureDate.gt(nextFlight?.departureDate)) {
        return true;
      }

      return false;
    });

    return isAfterNextDepartureDate
      ? {
          type: NotificationSpecific.MULTICITY_NOT_CONSECUTIVE,
          theme: NotificationTheme.ALERT,
          text: { label: 'multiCityNotification.notConsecutiveDates.notificationText' },
        }
      : undefined;
  }
}

import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Inject,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  PLATFORM_ID,
  TemplateRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap, filter, tap, withLatestFrom, distinctUntilChanged, skip, debounceTime } from 'rxjs/operators';

import { breakpoints } from '@finnairoyj/fcom-ui-styles';

import {
  DateField,
  DateFormat,
  findScrollContainer,
  isTruthy,
  LocalDate,
  quantityOfMonths,
  rangeFrom,
  toMonthId,
  unsubscribe,
  WeekdayMap,
} from '@fcom/core/utils';
import { MediaQueryService } from '@fcom/common/services/media-query/media-query.service';
import { ScrollService } from '@fcom/common/services/scroll/scroll.service';
import { WindowRef } from '@fcom/core/providers';

import { isKeyboardEventKey, KeyEventTypes } from '../../../utils/keyevents.utils';
import { Month, Day, DateSelection } from '../../../utils/date.interface';
import { ButtonSize, ButtonTheme, IconButtonTheme, IconButtonSize } from '../../buttons';
import { IconPosition } from '../../icons';
import { CalendarService } from '../services/calendar.service';
import { TagTheme } from '../../tag';
import { DateRange, Calendar } from '../interfaces';

const DAYS_AMOUNT = 360; // Default calendar size
const ACCEPT_EVENTS: { [key: string]: [DateField, number] } = {
  [KeyEventTypes.UP]: [DateField.DAY, -7],
  [KeyEventTypes.DOWN]: [DateField.DAY, 7],
  [KeyEventTypes.LEFT]: [DateField.DAY, -1],
  [KeyEventTypes.RIGHT]: [DateField.DAY, 1],
  [KeyEventTypes.PAGE_UP]: [DateField.MONTH, -1],
  [KeyEventTypes.PAGE_DOWN]: [DateField.MONTH, 1],
  [KeyEventTypes.HOME]: [DateField.MONTH, -15], // Large enough to go to start and end of calendar
  [KeyEventTypes.END]: [DateField.MONTH, 15],
};

// TODO: calendar header height should be read dynamic
const CALENDAR_HEADER_HEIGHT = 230;
@Component({
  selector: 'fcom-calendar',
  styleUrls: ['./calendar.component.scss'],
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  public readonly ButtonTheme = ButtonTheme;
  public readonly ButtonSize = ButtonSize;
  public readonly IconLibrary = IconLibrary;
  public readonly IconPosition = IconPosition;
  public readonly TagTheme = TagTheme;
  public readonly IconButtonTheme = IconButtonTheme;
  public readonly IconButtonSize = IconButtonSize;
  public readonly SvgLibraryIcon = SvgLibraryIcon;

  @ViewChild('monthTagParent') monthTagParent: ElementRef<HTMLElement>;
  @ViewChild('monthTags') monthTags: ElementRef<HTMLElement>;

  private breakpoints = breakpoints;
  @ViewChild('calendarMonths') calendarMonths: ElementRef;

  /**
   * Object of translated and localized date labels
   * (e.g. month names, date formats)
   */
  @Input() dateLabels: any;

  /**
   * Object of translated UI labels
   * (e.g. error messages, instructions)
   */
  @Input() uiLabels: any;

  /**
   * Initially preselected dates
   * Array of (one or two) YYYY-MM-DD strings
   */
  @Input() selectedDates: [LocalDate] | DateRange;

  /**
   * Defined range of calendar (first and last selectable date)
   * Array of two YYYY-MM-DD strings
   */
  @Input() selectableDatesRange: DateRange;

  /**
   * Ranges where dates should be disabled
   * An array which includes array of two LocalDates
   */
  @Input() disabledDateRanges?: DateRange[];

  /**
   * If datepicker should select range or single date
   */
  @Input() isDateRange = false;

  /**
   * Show month tag buttons on top of calendar
   */
  @Input() showTags = true;

  @Input() noOfMonthsVisible: 1 | 2 = 2;

  @Input() dayTemplate: TemplateRef<{ dayValue: Day; dayString: number }>;

  @Input() expandOnMobile = true;

  @Input() scrollOnInit = true;

  @Input() scrollToMonthIndex$: Observable<number> = of(0);

  @Input() calendarScrollEnabled = false;

  @Input() isActive = false;

  @Input() identifier: string;

  /**
   * Calendar vew will start be shown from this date
   */
  @Input() firstVisibleDate: LocalDate;

  @Output() monthChange: EventEmitter<Month> = new EventEmitter();

  @ViewChildren('tag', { read: ElementRef }) tags: QueryList<ElementRef>;

  months: Month[];
  visibleMonthIndex = 0;
  minSelectableDate: LocalDate;
  maxSelectableDate: LocalDate;
  private currentDate: LocalDate = LocalDate.now();
  private subscription: Subscription = new Subscription();
  private readonly isVisible$: Subject<void> = new Subject<void>();

  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private calendarService: CalendarService,
    private cd: ChangeDetectorRef,
    private mediaQueryService: MediaQueryService,
    @Inject(PLATFORM_ID) private platform: object,
    private scrollService: ScrollService,
    private windowRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.calendarService.visibleMonthIndex$.subscribe((index: number) => {
        this.visibleMonthIndex = index;
        this.monthChange.emit(this.months[index]);
        this.cd.detectChanges();
      })
    );

    if (isPlatformBrowser(this.platform)) {
      this.subscription.add(
        this.calendarService.activeCell$
          .pipe(skip(1), withLatestFrom(this.calendarService.getDatesSelected(this.identifier)))
          .subscribe(([date, selectedDates]: [LocalDate, DateSelection]) => {
            // Focus handling for keyboard navigation
            const dateString = date.toString();
            const dateMonthId = dateString.substring(0, 7);
            const dayToFocus = this.element.nativeElement.querySelector(`button[data-day="${dateString}"]`);

            const visibleMonthShiftNeeded =
              !selectedDates.endDate && // we should shift only for start date
              dateMonthId !== this.months[this.visibleMonthIndex]?.id && // is selected month not visible (left one)
              this.months[this.visibleMonthIndex + 1]?.monthArrayIndex !== this.months.length - 1 && // is next month is not a last one
              dateMonthId === (this.months[this.visibleMonthIndex + 1]?.id || undefined); // is it selected next month

            const focusIsWithinCalendar = this.document.activeElement.closest('fcom-calendar-month');

            if (dayToFocus && visibleMonthShiftNeeded) {
              this.calendarService.setVisibleMonthIndex(this.months.findIndex((o) => o.id === dateMonthId));
            }

            if (dayToFocus && focusIsWithinCalendar) {
              const timeoutForMonthShift = visibleMonthShiftNeeded ? 400 : 0;
              // Set timeout necessary to let animation finish before moving focus
              this.windowRef.nativeWindow.setTimeout(() => {
                dayToFocus.focus();
              }, timeoutForMonthShift);
            }
          })
      );

      this.subscription.add(
        this.mediaQueryService
          .isBreakpoint$('tablet_down')
          .pipe(
            filter(() => this.isActive),
            distinctUntilChanged()
          )
          .subscribe(() => {
            this.scrollToMonthChip();
            this.scrollToMonth(this.visibleMonthIndex);
          })
      );
    }

    this.subscription.add(
      this.scrollToMonthIndex$
        .pipe(
          filter(() => this.isActive),
          distinctUntilChanged()
        )
        .subscribe((scrollToIndex) => {
          this.selectMonth(scrollToIndex);
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('selectableDatesRange') || changes.hasOwnProperty('disabledDateRanges')) {
      this.minSelectableDate = this.selectableDatesRange[0] ? this.selectableDatesRange[0] : this.currentDate;

      this.maxSelectableDate = this.selectableDatesRange[1]
        ? this.selectableDatesRange[1]
        : this.currentDate.plusDays(DAYS_AMOUNT).firstDayOfMonth.plusMonths(1).minusDays(1);

      this.calendarService.setSelectableDatesRange(this.minSelectableDate, this.maxSelectableDate);

      const calendarObject = this.createCalendar('mon', this.minSelectableDate, this.maxSelectableDate);
      // @todo: Ensure that calendar starts by correct weekday in locales
      this.months = calendarObject.months;

      this.calendarService.setActiveCell(this.determineActiveCell());
      this.calendarService.setVisibleMonthIndex(
        this.months.findIndex((o) => o.value === this.determineActiveCell().localMonth)
      );
    }

    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('isDateRange')) {
      this.calendarService.setIsDateRange(this.identifier, this.isDateRange);
    }

    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('selectedDates') && this.selectedDates) {
      this.calendarService.setDates(this.identifier, this.dateArrayToDateSelection(this.selectedDates));
    }

    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('isActive') && changes.isActive.currentValue) {
      this.isVisible$.next();
      this.selectMonth(
        this.months.findIndex(
          (o) => o.value === (this.firstVisibleDate?.localMonth ?? this.determineActiveCell().localMonth)
        )
      );
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }
    if (this.scrollOnInit) {
      this.scrollToSelection(this.calendarService.getActiveDate());
    }
    // Only listen on events within calendarMonths as to only capture keyboard
    // events while focus is within the calendarMonths
    this.subscription.add(
      fromEvent(this.calendarMonths.nativeElement, 'keydown')
        .pipe(
          filter((event: KeyboardEvent) => isTruthy(ACCEPT_EVENTS[isKeyboardEventKey(event)])),
          tap((event: KeyboardEvent) => {
            // Prevents browser default behaviours like scrolling with arrow keys
            event.preventDefault();
          }),
          withLatestFrom(this.calendarService.activeCell$),
          map(([event, activeCell]: [KeyboardEvent, LocalDate]) => {
            const [field, amount] = ACCEPT_EVENTS[isKeyboardEventKey(event)];
            const minDate = this.minSelectableDate;
            const maxDate = this.maxSelectableDate;
            const date = activeCell.plus(field, amount);
            if (minDate.gte(date)) {
              return minDate;
            } else if (maxDate.lte(date)) {
              return maxDate;
            }
            return date;
          }),
          distinctUntilChanged((a: LocalDate, b: LocalDate) => a.equals(b))
        )
        .subscribe((date) => this.calendarService.setActiveCell(date))
    );

    if (this.calendarScrollEnabled) {
      this.subscription.add(
        this.isVisible$
          .pipe(
            switchMap(() =>
              this.scrollService
                .listen(this.calendarMonths)
                .pipe(debounceTime(100), distinctUntilChanged())
                .pipe(
                  filter(() => this.windowRef.nativeWindow.innerWidth < parseInt(this.breakpoints.laptop)),
                  map(() => {
                    const months = this.calendarMonths.nativeElement.children;
                    const container = findScrollContainer(this.calendarMonths.nativeElement);
                    const scrollTop = container.scrollTop;
                    const containerHeight = container.offsetHeight - CALENDAR_HEADER_HEIGHT;

                    let closestIndex = -1;
                    let closestDistance = Number.MAX_VALUE;

                    for (let i = 0; i < months.length; i++) {
                      const month = months[i] as HTMLElement;
                      const monthHeight = month.offsetHeight;

                      const monthTop = month.offsetTop - CALENDAR_HEADER_HEIGHT;
                      const monthBottom = monthTop + monthHeight;

                      // calculate the distance to the viewport for the month
                      let distanceToViewport: number;

                      const isFirstAndScrolledToTop =
                        i === 0 && scrollTop === 0 && monthBottom <= scrollTop + containerHeight;

                      if (
                        isFirstAndScrolledToTop ||
                        (scrollTop <= monthTop && monthBottom <= scrollTop + containerHeight)
                      ) {
                        // month is fully visible in the viewport
                        distanceToViewport = 0;
                      } else if (monthTop <= scrollTop && scrollTop < monthBottom) {
                        // month is partially visible at the top of the viewport
                        distanceToViewport = scrollTop - monthTop;
                      } else if (monthTop < scrollTop + containerHeight && scrollTop + containerHeight <= monthBottom) {
                        // month is partially visible at the bottom of the viewport
                        distanceToViewport = monthBottom - (scrollTop + containerHeight);
                      } else {
                        // month is outside the viewport
                        distanceToViewport = Math.min(
                          Math.abs(monthTop - scrollTop),
                          Math.abs(monthBottom - (scrollTop + containerHeight))
                        );
                      }

                      if (distanceToViewport <= closestDistance) {
                        closestDistance = distanceToViewport;
                        closestIndex = i;
                      }

                      if (closestDistance === 0) {
                        if (scrollTop + containerHeight + CALENDAR_HEADER_HEIGHT < container.scrollHeight) {
                          break;
                        }
                        closestIndex = months.length - 1;
                      }
                    }

                    return closestIndex;
                  }),
                  distinctUntilChanged()
                )
            )
          )
          .subscribe((monthIndex: number) => {
            this.selectMonth(monthIndex, false);
          })
      );
    }
  }

  selectMonth(monthIndex: number, shouldScrollToMonth = true): void {
    let wantedMonth = monthIndex;

    if (monthIndex === this.months.length - 1 && this.noOfMonthsVisible === 2) {
      wantedMonth = monthIndex - 1;
    }

    if (monthIndex <= this.months.length - 1 && monthIndex >= 0) {
      this.calendarService.setVisibleMonthIndex(wantedMonth);
      this.calendarService.setActiveCell(this.months[wantedMonth].days[0].date);
    }

    this.monthChange.emit(this.months[monthIndex]);

    this.scrollToMonthChip();

    if (shouldScrollToMonth) {
      this.scrollToMonth(monthIndex);
    }
  }

  scrollToMonthChip(): void {
    // TODO: bw-migration maybe a more elegant solution and maybe add a test case
    if (!this.showTags) {
      return;
    }

    if (!this.expandOnMobile) {
      const tag = this.tags.get(this.visibleMonthIndex);
      tag.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    } else {
      const monthTagsWidth = this.monthTagParent.nativeElement.offsetWidth;
      const containerWidth = this.monthTags.nativeElement.scrollWidth;
      const maxPercentageToScroll = containerWidth / monthTagsWidth - 1;
      const tagPercentageOffsetPerTag = Math.max(0, maxPercentageToScroll) / (this.months.length - 2);

      this.monthTags.nativeElement.style.transform =
        'translateX(-' + this.visibleMonthIndex * (tagPercentageOffsetPerTag * 100) + '%)';
    }
  }

  getMonthNameForChip(month: Month): string {
    return new DateFormat(this.dateLabels).format(
      LocalDate.forDate(month.year, month.value, 1),
      DateFormat['SHORT_MONTH_AND_YEAR']
    );
  }

  isChipHighlighted(index: number): boolean {
    if (index === this.visibleMonthIndex) {
      return true;
    }

    return this.noOfMonthsVisible === 2 && index === this.visibleMonthIndex + 1;
  }

  getDayClasses(_day: Day): string {
    return 'custom-day grow ps-xsmall-y';
  }

  private isWithinSelectableRange(date: LocalDate): boolean {
    return this.minSelectableDate.lte(date) && this.maxSelectableDate.gte(date);
  }

  private createCalendar(weekStartsOn: string, firstAvailableDate: LocalDate, lastAvailableDate: LocalDate): Calendar {
    const startDate: LocalDate = firstAvailableDate.firstDayOfMonth;
    const endDate: LocalDate = lastAvailableDate;
    const weekdayMap: WeekdayMap = new WeekdayMap(weekStartsOn);
    const i18nMap: WeekdayMap = new WeekdayMap('Mon');

    let today: Day;
    const months = rangeFrom(0, quantityOfMonths(startDate, endDate))
      .map((i: number): LocalDate => startDate.plusMonths(i))
      .map((firstOfMonth: LocalDate, index: number): Month => {
        const firstDate = index === 0 ? firstAvailableDate : firstOfMonth;
        const { month, todayIfAvailable } = this.createMonth(
          firstDate,
          firstOfMonth,
          this.currentDate,
          endDate,
          weekdayMap,
          i18nMap,
          index
        );
        if (todayIfAvailable) {
          today = todayIfAvailable;
        }
        return month;
      });

    return { today, months };
  }

  private createMonth(
    firstDate: LocalDate,
    firstOfMonth: LocalDate,
    currentDate: LocalDate,
    endDate: LocalDate,
    weekdayMap: WeekdayMap,
    i18nMap: WeekdayMap,
    monthArrayIndex: number
  ): { month: Month; todayIfAvailable: Day } {
    const monthBase = {
      id: toMonthId(firstOfMonth),
      year: firstOfMonth.localYear,
      value: firstOfMonth.localMonth,
      i18n: firstOfMonth.localMonth - 1,
      monthArrayIndex,
    };
    const lastOfMonth =
      monthBase.id === toMonthId(endDate) ? endDate.localDay : firstOfMonth.plusMonths(1).minusDays(1).localDay;
    const lengthOfMonth = lastOfMonth - firstDate.localDay + 1;
    let todayIfAvailable: Day;
    const month = Object.assign(
      {
        days: rangeFrom(0, lengthOfMonth)
          .map((i) => firstDate.plusDays(i))
          .map((date: LocalDate) => {
            const today = date.equals(currentDate);
            const day: Day = {
              id: date.id,
              month: monthBase,
              value: date.localDay,
              date,
              i18n: i18nMap.get(date.weekday),
              weekday: weekdayMap.get(date.weekday),
              today,
              past: date.lt(currentDate),
              future: date.gte(endDate),
              disabled: this.disabledDateRanges
                ? this.disabledDateRanges
                    .filter((disabledDateRange) => disabledDateRange.length)
                    .some((disabledDateRange) => date.isBetween(disabledDateRange[0], disabledDateRange[1]))
                : false,
            };
            if (today) {
              todayIfAvailable = day;
            }
            return day;
          }),
      },
      monthBase
    );
    return { month, todayIfAvailable };
  }

  private scrollToMonth(index: number): void {
    if (
      !this.calendarScrollEnabled ||
      (this.calendarScrollEnabled &&
        (0 > index ||
          index >= this.months.length ||
          (this.isWindowDefined() && this.windowRef.nativeWindow.innerWidth >= parseInt(this.breakpoints.laptop))))
    ) {
      return;
    }

    this.scrollService.smoothScroll(this.calendarMonths.nativeElement.children[index], {
      offsetY: CALENDAR_HEADER_HEIGHT,
    });
  }

  private scrollToSelection(date: LocalDate): void {
    requestAnimationFrame(() => {
      const activeElem = this.element.nativeElement.querySelector(`[data-day="${date.toString()}"]`);
      if (activeElem && this.windowRef.nativeWindow.innerWidth < this.breakpoints.laptop) {
        activeElem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  }

  private determineActiveCell(): LocalDate {
    if (this.selectedDates && this.selectedDates[0] && this.isWithinSelectableRange(this.selectedDates[0])) {
      return this.selectedDates[0];
    } else if (this.isWithinSelectableRange(this.currentDate)) {
      return this.currentDate;
    } else {
      return this.minSelectableDate;
    }
  }

  private dateArrayToDateSelection(dateArray: LocalDate[]): DateSelection {
    const dateSelection: DateSelection = { startDate: undefined, endDate: undefined };
    if (dateArray[0] && this.isWithinSelectableRange(dateArray[0])) {
      dateSelection.startDate = dateArray[0];
      if (dateArray[1] && this.isWithinSelectableRange(dateArray[1])) {
        dateSelection.endDate = dateArray[1];
      }
    }
    return dateSelection;
  }

  // This check is added just to make sure window is present and omit sentry error
  // ReferenceError: window is not defined
  private isWindowDefined(): boolean {
    return typeof window !== 'undefined';
  }
}

import { Component, Input, OnDestroy } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';

import { NavigationMenuService } from '../../../../services';

@Component({
  selector: 'fin-navigation-trigger',
  styleUrls: ['./navigation-trigger.component.scss'],
  templateUrl: './navigation-trigger.component.html',
})
export class NavigationTriggerComponent implements OnDestroy {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  isNavigationMenuOpen$: Observable<boolean>;
  isOpen: boolean;

  @Input()
  whiteIcon = false;

  private subscription: Subscription = new Subscription();

  constructor(private navigationMenuService: NavigationMenuService) {
    this.isNavigationMenuOpen$ = this.navigationMenuService.isNavigationMenuOpen$;
    this.subscription.add(this.isNavigationMenuOpen$.subscribe((isOpen) => (this.isOpen = isOpen)));
  }

  toggleMenu(_event: any): void {
    this.navigationMenuService.toggleMenuOpen();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }
}

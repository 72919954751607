import { ThirdPartyAncillariesProvider } from './enums';

export const MANAGE_BOOKING_FEATURE_KEY = 'manage-booking';

export const STATIC_OLD_MMB_POST_PARAMS = {
  SITE: 'FINRFINR',
  PAGE: 'BKGD',
};

const prefix = 'apis-update';
export const OPEN_TRAVEL_PREPARATIONS_NOTIFICATION = `${prefix}_open-travel-preparations-notification`;
export const CANCEL_APIS_UPDATE = `${prefix}-details_apis-cancel`;
export const FULL_APIS_UPDATE = `${prefix}-details-full_apis-success`;
export const PARTIAL_APIS_UPDATE = `${prefix}-details-partial_apis-success`;
export const ERROR_APIS_UPDATE = `${prefix}-details_apis-error`;
export const APIS_REQUIRED = `${prefix}_required`;
export const USER_OTHER_ADDRESS_APIS = `${prefix}-use-other-address_apis`;

export const mapToGaString = {
  [ThirdPartyAncillariesProvider.CAR_TRAWLER_RENTAL]: 'third-party-box_cartrawler-rental',
  [ThirdPartyAncillariesProvider.CAR_TRAWLER_TRANSFER]: 'third-party-box_cartrawler-transfer',
};

export const carTrawlerSmartBlockConfig = {
  clientId: '608790',
  partnerName: 'finnair',
  CTProduct: 'widget',
};

/* eslint-disable */

/**
 * List of ineligibility reasons for check-in.
 */
export enum FinnairCheckInEligibilityReason {
  NOT_ELIGIBLE_EMAIL_MISSING = 'NOT_ELIGIBLE_EMAIL_MISSING',
  NOT_ELIGIBLE_SSR = 'NOT_ELIGIBLE_SSR',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  NOT_ELIGIBLE_NOT_OPENED = 'NOT_ELIGIBLE_NOT_OPENED',
  NOT_ELIGIBLE_TICKET_RECONCILIATION_NEEDED = 'NOT_ELIGIBLE_TICKET_RECONCILIATION_NEEDED',
  NOT_ELIGIBLE_DISRUPTED_MANAGEABLE_ONLINE = 'NOT_ELIGIBLE_DISRUPTED_MANAGEABLE_ONLINE',
  NOT_ELIGIBLE_DISRUPTED_NOT_MANAGEABLE_ONLINE = 'NOT_ELIGIBLE_DISRUPTED_NOT_MANAGEABLE_ONLINE'
}


import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';

import { EMPTY, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ProfileType } from '@fcom/core-api/login';
import { isDeepEqual, SentryLogger, WindowRef } from '@fcom/core';
import { ConfigService } from '@fcom/core/services/config/config.service';
import { replaceURIFormat } from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate';
import { finShare, retryWithBackoff } from '@fcom/rx';

import { Consent, ConsentData, ConsentGroup, ConsentStatus, ConsentTextId } from '../../interfaces';
import { GtmService } from '../../gtm';

const asConsentLang = (lang: string) => {
  const index: number = lang.indexOf('-');
  if (index < 0) {
    return lang;
  }
  return lang.substring(index + 1);
};

export const FINNAIR_COOKIE_SNIPPET = 'finnairCookieSnippet';

@Injectable()
export class ConsentService {
  cookieConsents$: Observable<ConsentGroup>;

  private readonly cookieConsentsSavedToStorage$ = new ReplaySubject<boolean>(1);
  private cookieSnippetLoaded$ = new Subject<boolean>();
  private MARKETING_CONSENT = 'fplus-marketing';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private configService: ConfigService,
    private gtmService: GtmService,
    private sentryLogger: SentryLogger,
    private languageService: LanguageService,
    private ngZone: NgZone,
    private windowRef: WindowRef
  ) {
    this.cookieConsents$ = merge(this.cookieSnippetLoaded$, this.cookieConsentsSavedToStorage$).pipe(
      switchMap(() => this.getCookieConsents()),
      distinctUntilChanged(isDeepEqual),
      finShare()
    );
  }

  getMarketingConsents(loginToken: string, type: ProfileType): Observable<ConsentGroup | undefined> {
    if (type === ProfileType.FINNAIR_ACCOUNT) {
      return of(undefined);
    }

    const headers = new HttpHeaders().append('Authorization', `Bearer ${loginToken}`);

    return this.languageService.lang.pipe(
      map(asConsentLang),
      switchMap((lang: string) =>
        this.http
          .get(
            `${this.configService.cfg.consentApiUrl}v2/consents?locale=${lang}&consentGroup=${this.MARKETING_CONSENT}`,
            {
              headers,
            }
          )
          .pipe(
            retryWithBackoff(1),
            map((consentGroups: ConsentGroup[]) =>
              consentGroups.find((group) => group.consentGroup === this.MARKETING_CONSENT)
            ),
            catchError((e: HttpErrorResponse) => this.handleError(e))
          )
      )
    );
  }

  getMarketingConsentTexts(): Observable<ConsentGroup> {
    return this.languageService.lang.pipe(
      map(asConsentLang),
      switchMap((lang: string) =>
        this.http
          .get(`${this.configService.cfg.consentApiUrl}v1/texts?locale=${lang}&consentGroup=${this.MARKETING_CONSENT}`)
          .pipe(
            retryWithBackoff(1),
            map((consentGroups: ConsentGroup[]) => consentGroups[0]),
            catchError((e: HttpErrorResponse) => this.handleError(e))
          )
      )
    );
  }

  sendConsents(loginToken: string, consents: Consent[], source?: string): Observable<ConsentGroup | unknown> {
    const headers = new HttpHeaders().append('Authorization', `Bearer ${loginToken}`);

    return this.http
      .post(
        `${this.configService.cfg.consentApiUrl}v1/consents`,
        {
          consents,
          locale: this.languageService.localeValue,
          source: source || this.document.location.hostname,
        },
        { headers }
      )
      .pipe(catchError((e: HttpErrorResponse) => this.handlePostError(e)));
  }

  setCookieConsents(consentData: ConsentData): void {
    try {
      this.ngZone.run(() => {
        this.windowRef.nativeWindow[FINNAIR_COOKIE_SNIPPET]?.setConsents(consentData);
      });
    } catch (e) {
      this.sentryLogger.warn('Setting consents failed!', e);
    }

    this.gtmService.setConsents(consentData);
    this.cookieConsentsSavedToStorage$.next(true);
  }

  getCookieConsentStatusById(consentId: ConsentTextId): Observable<boolean> {
    return this.cookieConsents$.pipe(
      map((cg: ConsentGroup) => cg?.consents?.find((c) => c.consentTextId === consentId)),
      map((c: Consent) => c?.consentStatus !== ConsentStatus.WITHDRAWN && c?.consentStatus === ConsentStatus.ACCEPTED)
    );
  }

  getCookieIdFromSnippet(): string {
    return this.windowRef.nativeWindow[FINNAIR_COOKIE_SNIPPET]?.getOrGenerateFinnairCookieId();
  }

  injectCookieSnippet(): void {
    const cookieSnippetScript: HTMLScriptElement = this.document.createElement('script');
    cookieSnippetScript.setAttribute('async', '');
    cookieSnippetScript.src = replaceURIFormat(this.configService.cfg.cookieSnippetUrl, {
      locale: this.languageService.localeValue,
    });
    cookieSnippetScript.onload = () => {
      this.cookieSnippetLoaded$.next(true);
    };
    this.document.body.appendChild(cookieSnippetScript);
  }

  private getCookieConsents(): Observable<ConsentGroup> {
    return new Observable<ConsentGroup>((observer) => {
      try {
        this.ngZone.run(() => {
          this.windowRef.nativeWindow[FINNAIR_COOKIE_SNIPPET]?.getConsents((consentGroups) => {
            observer.next(consentGroups);
            observer.complete();
          });
        });
      } catch (e) {
        observer.error(e);
        // eslint-disable-next-line rxjs/no-redundant-notify
        observer.complete();
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return function dispose() {};
    });
  }

  private handleError(error: HttpErrorResponse): Observable<ConsentGroup> {
    if (error.status !== 403) {
      this.sentryLogger.error('Error getting marketing consent data', { error });
    }
    return EMPTY;
  }

  private handlePostError(error: HttpErrorResponse): Observable<ConsentGroup> {
    if (error.status !== 403) {
      this.sentryLogger.error('Error sending marketing consent data to consent api', { error });
    }
    return EMPTY;
  }
}
